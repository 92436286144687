import {
    Box,
    Button,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Select,
    useColorModeValue,
    useToast,
    VStack,
    Checkbox,
    Heading,
    } from '@chakra-ui/react'
    import * as React from 'react'
    import 'react-datepicker/dist/react-datepicker.css';
    import { StudentAPI } from '../../../api/StudentAPI';
    import { Grades, Student, MediaConsent } from '../../../models/student';
    import { DateTime } from '../../../utils/DateTime';
    import DatePicker from '../../DatePicker'
    
    interface Card {
        student: Student
        set_student: React.Dispatch<React.SetStateAction<Student | undefined>>
    }
    
    export const ConsentCard = React.memo(({student, set_student}: Card) => {
        const toast = useToast()
    
        const update_consent_state = (name: any, value: any) => {
            set_student(
                prevState => (prevState ? {
                    ...prevState,
                    consent: { ...prevState.consent, [name]: value}
                } : undefined)
            );
        }

        const update_media_consent_state = (name: any, value: any) => {
            set_student(
                prevState => (prevState ? {
                    ...prevState,
                    consent: {
                        ...prevState.consent,
                        media: {
                            ...prevState.consent.media,
                            [name]: value
                        }
        }
                    }
                 : undefined));

        }
    
        const update_consent = () => {
            StudentAPI.update_consent(student._id, student.consent)
                .then((res) => {
                    StudentAPI.get_student(student._id.$oid).then(res => set_student(res!))
                    toast({
                        title: 'Success',
                        description: "Saved Consent",
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    })
                })
                .catch((err) => {
                    console.log(err)
                    toast({
                        title: 'Error.',
                        description: "Failed to update consent",
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    })
                })
            
        }
    
        return (
            <Box
                as="form"
                bg="bg-surface"
                boxShadow={useColorModeValue('sm', 'sm-dark')}
                borderRadius="lg"
                flex="1"
            >
                <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                    <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                        <FormControl id="media" isRequired>
                            <Heading size="sm">Media Consent</Heading>
                            <FormLabel>I give permission for my child to be:</FormLabel>
                            <Stack>
                                <Checkbox colorScheme='green' spacing={5} isChecked={student.consent.media.interview_for_school_publication} onChange={(e) => update_media_consent_state("interview_for_school_publication", e.target.checked)}>
                                    Interviewed for publication by school staff or students (newsletters, websites, etc.)
                                </Checkbox>
                                <Checkbox colorScheme='green' spacing={5} isChecked={student.consent.media.interview_for_public_media} onChange={(e) => update_media_consent_state("interview_for_public_media", e.target.checked)}>
                                    Interviewed for publication by media (newspaper, radio, TV)
                                </Checkbox>
                                <Checkbox colorScheme='green' spacing={5} isChecked={student.consent.media.photo_video_for_school_publication} onChange={(e) => update_media_consent_state("photo_video_for_school_publication", e.target.checked)}>
                                    Photographed or to appear on video for publication by school staff or students (newsletters, websites, etc.)
                                </Checkbox>
                                <Checkbox colorScheme='green' spacing={5} isChecked={student.consent.media.photo_video_for_public_media} onChange={(e) => update_media_consent_state("photo_video_for_public_media", e.target.checked)}>
                                    Photographed or to appear on video for publication by media (newspaper, radio, TV, etc.)
                                </Checkbox>
                                <Checkbox colorScheme='green' spacing={5} isChecked={student.consent.media.photo_video_for_social_media} onChange={(e) => update_media_consent_state("photo_video_for_social_media", e.target.checked)}>
                                Photographed or to appear on video for publication by Alfajrul Bassem Academy’s Facebook page, Instagram page and/or Twitter site
                                </Checkbox>
                                <Checkbox colorScheme='green' spacing={5} isChecked={student.consent.media.no_permission} onChange={(e) => update_media_consent_state("no_permission", e.target.checked)} isDisabled={student.consent.media.photo_video_for_social_media || student.consent.media.photo_video_for_public_media || student.consent.media.photo_video_for_school_publication || student.consent.media.interview_for_public_media || student.consent.media.interview_for_school_publication}>
                                    I do not give permission for my child to appear in any media, video or publication of any kind
                                </Checkbox>
                            </Stack>
                        </FormControl>
                    </Stack>
                    <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    <FormControl id="trip" isRequired>
                        <Heading size="sm">School Trip Consent</Heading>
                        <FormLabel>On occasion, the class may take a nature walk, go to a neighborhood park for exercise, or might take part in other outdoor activities or a short school trip. While we always strive to inform parents about these trips, they are not always planned.</FormLabel>
                        <Stack>
                            <Checkbox colorScheme='green' spacing={5} isChecked={student.consent.school_trip} onChange={(e) => update_consent_state("school_trip", e.target.checked)}>
                                I give my child/(ren) permission to participate in school trips
                            </Checkbox>
                        </Stack>
                    </FormControl>
                    </Stack>
                </Stack>
                <Divider />
                <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
                    <Button onClick={update_consent}>
                        Save
                    </Button>
                </Flex>
            </Box>
        )
    
    })
    