import { ChevronRightIcon } from "@chakra-ui/icons";
import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    Center,
    Grid,
    Heading,
    HStack,
    Stack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { FamilyAPI } from "../../api/FamilyAPI";
import { Family } from "../../models/family";
import { to_oid } from "../../utils/ObjectId";
import { FamilyCard } from "../cards/family/FamilyCard";
import { BlCard } from "../layout/Card";
import { FamilyStudentList } from "../parents/FamilyStudentList";

export const FamilyRoute: React.FC = () => {
    let { family_id } = useParams();
    const [family, set_family] = React.useState<Family>();

    useEffect(()=> {
        FamilyAPI.get_family(to_oid(family_id!))
            .then((res) => {
                set_family(res)
            })
    }, [family_id])

    return (
        <>
        <Box>
                <Box overflowX="auto" overflowY="hidden">
                    { family &&
                    <>
                    <Center>
                        <Heading size="md" mb="6">
                            {family.name}
                        </Heading>
                    </Center>
                    <HStack>
                        <Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
                            <BreadcrumbItem>
                                <BreadcrumbLink href='/home'>Home</BreadcrumbLink>
                            </BreadcrumbItem>

                            <BreadcrumbItem>
                                <BreadcrumbLink href='/family'>Family</BreadcrumbLink>
                            </BreadcrumbItem>

                            <BreadcrumbItem isCurrentPage>
                                <BreadcrumbLink href={'/home/family/' + family._id.$oid}>{family.name}</BreadcrumbLink>
                            </BreadcrumbItem>
                        </Breadcrumb>
                    </HStack>
                    <Grid templateColumns='repeat(2, 1fr)' gap={5}>
                        <BlCard>
                            <FamilyStudentList family={family} read_only={true} redirect_to_profile={true}/>
                        </BlCard>

                        <Stack>
                            <FamilyCard family={family} read_only={true}/>
                        </Stack>    
                    </Grid>
                    </>
                    }   
                </Box>
            </Box>
        </>
    )
}