import { Skeleton, Box, SimpleGrid } from '@chakra-ui/react';

const GClassViewerPlaceholder: React.FC = () => {
    return (
        <>
            <SimpleGrid columns={{ sm: 1, md: 2, lg: 4 }} spacing={4} mb={8}>
                {/* Four smaller skeletons */}
                {Array.from({ length: 4 }, (_, index) => (
                    <Skeleton key={index} height="150px" rounded="md" />
                ))}
            </SimpleGrid>

            {/* Two large skeletons */}
            <Box mb={8}>
                <Skeleton height="300px" rounded="md" />
            </Box>

            <Box mb={8}>
                <Skeleton height="300px" rounded="md" />
            </Box>
        </>
    );
};

export default GClassViewerPlaceholder;
