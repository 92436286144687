import {
Box,
Button,
Divider,
Flex,
FormControl,
FormLabel,
Input,
Stack,
Select,
useColorModeValue,
useToast,
InputGroup,
InputLeftAddon,
} from '@chakra-ui/react'
import * as React from 'react'
import 'react-datepicker/dist/react-datepicker.css';
import { StudentAPI } from '../../../api/StudentAPI';
import { Family, FamilyContact } from '../../../models/family';
import { Student } from '../../../models/student';

interface Card {
    family_contact: FamilyContact
    save_family_contact: (family_contact: FamilyContact) => void;
    read_only?: boolean
}

export const FamilyContactCard = React.memo(({family_contact, save_family_contact, read_only}: Card) => {
    const [family_contact_state, set_family_contact_state] = React.useState({...family_contact})

    const update_state = (name: any, value: any) => {
        set_family_contact_state({
            ...family_contact_state,
            [name]: value
        });
        save_family_contact({
            ...family_contact_state,
            [name]: value
        })
    }

    return (
        <>
        <Box
        as="form"
        bg="bg-surface"
        boxShadow={useColorModeValue('sm', 'sm-dark')}
        borderRadius="lg"
        flex="1"
    >
        <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
            <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                <FormControl id="name" isRequired isDisabled={read_only}>
                    <FormLabel>Full Name</FormLabel>
                    <Input 
                        value={family_contact_state.name} 
                        onChange={(value) => {
                            update_state(value.target.id, value.target.value)
                        }}
                    />
                </FormControl>
                <FormControl id="email" isRequired={true} isDisabled={read_only}>
                    <FormLabel>Email</FormLabel>
                    <Input 
                        value={family_contact_state.email} 
                        onChange={(value) => {
                            update_state(value.target.id, value.target.value)
                        }}
                    />
                </FormControl>
                <FormControl id="phone" isRequired isDisabled={read_only}>
                    <FormLabel>Primary Phone</FormLabel>
                    <InputGroup>
                        <InputLeftAddon children='+1' />
                        <Input 
                            value={family_contact_state.phone} 
                            onChange={(value) => {
                                update_state(value.target.id, value.target.value)
                            }}
                        />
                    </InputGroup>
                </FormControl>
            </Stack>
        </Stack>
    </Box>
    </>
    )
})