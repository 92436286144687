import * as yup from "yup";
import { ObjectId } from "../utils/ObjectId";  // Ensure you have the correct path for ObjectId

export const updateReportLayoutSchema = yup.object().shape({
    _id: yup.mixed<ObjectId>().required("Report Layout ID is required"),
    course_id: yup.mixed<ObjectId>().required("Course ID is required"),
    comment_char_limit: yup.number().required("Comment Character Limit is required"),
    sections: yup.array().of(
        yup.object().shape({
            _id: yup.mixed<ObjectId>().required("Section ID is required"),
            name: yup.string().required("Section name is required"),
        })
    ).required("At least one section is required"),
    check_boxes: yup.array().of(
        yup.object().shape({
            _id: yup.mixed<ObjectId>().required("Check Box ID is required"),
            name: yup.string().required("Check Box name is required"),
        })
    ).required("At least one checkbox is required"),
});