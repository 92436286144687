import { DateTime } from "../utils/DateTime";
import { ObjectId } from "../utils/ObjectId";

export class Timeslot {
    _id: ObjectId;
    name: string;
    description?: string;
    start_time: DateTime;
    end_time: DateTime;
    course_id?: ObjectId;
    subject_id?: ObjectId;
    teacher_id?: ObjectId;

    constructor(data: any){
        this._id = data._id
        this.name = data.name
        this.description = data.description
        this.start_time = new DateTime(data.start_time)
        this.end_time = new DateTime(data.end_time)
        this.course_id = data.course_id
        this.subject_id = data.subject_id
        this.teacher_id = data.teacher_id
    }
}
