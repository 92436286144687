import {
Box,
FormControl,
FormLabel,
Input,
Stack,
useColorModeValue,
Switch,
} from '@chakra-ui/react'
import * as React from 'react'
import 'react-datepicker/dist/react-datepicker.css';
import { Student } from '../../../models/student';
import { DateTime } from '../../../utils/DateTime';
import DatePicker from '../../DatePicker'

interface Card {
    student: Student
    set_student: React.Dispatch<React.SetStateAction<Student | undefined>>
}

export const ImmigrationStatusCard = React.memo(({student, set_student}: Card) => {    
    const update_immigration_status_state = (name: any, value: any) => {
        set_student(
            prevState => (prevState ? {
                ...prevState,
                office_information: { 
                    ...prevState.office_information, 
                    immigration_status: {
                        ...prevState.office_information.immigration_status,
                        [name]: value 
                    }
                }
            } : undefined)
        );
    }


    return (
        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
        >
            <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    <FormControl id="born_in_canada" isRequired>
                        <FormLabel>Born in Canada</FormLabel>
                        <Switch 
                            isChecked={student.office_information.immigration_status.born_in_canada}
                            onChange={(value) => {
                                update_immigration_status_state("born_in_canada", value.target.checked)
                            }}
                        />
                    </FormControl>
                </Stack>
                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    
                    <FormControl id="birth_country">
                        <FormLabel>Birth Country</FormLabel>
                        <Input 
                            value={student.office_information.immigration_status.birth_country} 
                            onChange={(value) => {
                                update_immigration_status_state(value.target.id, value.target.value)
                            }}
                        />
                    </FormControl>
                    <FormControl id="status_in_canada">
                        <FormLabel>Status in Canada</FormLabel>
                        <Input 
                            value={student.office_information.immigration_status.status_in_canada} 
                            onChange={(value) => {
                                update_immigration_status_state(value.target.id, value.target.value)
                            }}
                        />
                    </FormControl>
                    <FormControl id="arrival_date" >
                        <FormLabel>Arrival Date</FormLabel>
                        <DatePicker 
                            selectedDate={student.office_information.immigration_status.arrival_date ? student.office_information.immigration_status.arrival_date.to_date() : undefined} 
                            onChange={(value) => {
                                update_immigration_status_state("arrival_date", DateTime.from_date(value as Date))
                            }}
                        />
                    </FormControl>
                </Stack>    
            </Stack>
        </Box>
    )

})
