import { Migration } from "../models/migration";
import { ObjectId } from "../utils/ObjectId";
import HTTPApi from "./ApiCommon";



export namespace MigrationAPI {
    export const migrations = async () => {
        return HTTPApi.get("migrations/get_migrations").then((res) => {
            let data: [] = res.data!
            return data.map(e => new Migration(e))
        })
    }

    export const get_migration = async (_id: ObjectId) => {
        return HTTPApi.post(
            "migrations/get_migration", {
                _id: _id
            }
        ).then((res) => {
            return new Migration(res.data)
        })
    }

    export const run = async (_id: ObjectId) => {
        return HTTPApi.post(
            "migrations/run", {
                _id: _id
            }
        ).then((res) => {
            return new Migration(res.data)
        })
    }

    export const roll_back = async (_id: ObjectId) => {
        return HTTPApi.post(
            "migrations/roll_back", {
                _id: _id
            }
        ).then((res) => {
            return new Migration(res.data)
        })
    }

    export const archive = async (_id: ObjectId) => {
        return HTTPApi.post(
            "migrations/archive", {
                _id: _id
            }
        ).then((res) => {
            return new Migration(res.data)
        })
    }
}