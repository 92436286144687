import HTTPApi from "./ApiCommon";
import { Homeroom } from "../models/homeroom";
import { Teacher } from "../models/teacher";
import { Student } from "../models/student";
import { Attendance } from "../models/attendance";
import { Course } from "../models/course";
import { utils } from "../utils/date_utils";
import { ObjectId } from "../utils/ObjectId";
import { DateTime } from "../utils/DateTime";
import download from 'js-file-download';


export namespace AttendanceAPI {
    export interface AttendanceHomerooms {
        homerooms: Array<Homeroom>,
        teachers: {[key: string]: Teacher},
        entry_status: {[key: string]: boolean}

    }
    export const get_homerooms = async (id: ObjectId, date: Date,  show_all_classes: boolean): Promise<AttendanceHomerooms | void> => {
        return HTTPApi.get("/attendance/homerooms/" + id.$oid + "/" + utils.get_utc_date(date).toISOString() + "/" + String(show_all_classes))
                .then((res) => {
                    return res.data as AttendanceHomerooms
                })
                .catch((e) => {
                    console.log(e);
                })
    }

    export interface AttendanceHomeroomData {
        students: {[key: string]: Student},
        attendance_entries: {[key: string]: Attendance},
        homeroom: Homeroom
    }
    export const get_homeroom_attendance_entries = async (homeroom_id: string, date: Date, period_number: number) => {
        return HTTPApi.post(
                "attendance/homerooms/data", {
                    homeroom: homeroom_id,
                    date: utils.get_utc_date(date).toISOString(),
                    period_number: period_number
                }
            ).then((res) => {
                return res.data as AttendanceHomeroomData
            })
            .catch((e) =>{
                console.log(e)
            })
    }

    export interface AttendanceCourses {
        courses: Array<Course>,
        teachers: {[key: string]: Teacher},
        entry_status: {[key: string]: boolean}

    }
    export const get_courses = async (id: ObjectId, date: Date,  show_all_classes: boolean): Promise<AttendanceCourses | void> => {
        return HTTPApi.get("/attendance/courses/" + id.$oid + "/" + utils.get_utc_date(date).toISOString() + "/" + String(show_all_classes))
                .then((res) => {
                    return res.data as AttendanceCourses
                })
                .catch((e) => {
                    console.log(e);
                })
    }

    export interface AttendanceCourseData {
        students: {[key: string]: Student},
        attendance_entries: {[key: string]: Attendance},
        course: Course
    }
    export const get_course_attendance_entries = async (course_id: string, date: Date, period_number: number) => {
        return HTTPApi.post(
            "attendance/courses/data", {
                course: course_id,
                date: utils.get_utc_date(date).toISOString(),
                period_number: period_number
            }
        ).then((res) => {
            return res.data as AttendanceCourseData
        })
            .catch((e) =>{
                console.log(e)
            })
    }

    export const get_student_course_attendance_entries = async (course_id: string, student_id: string, start_date: Date, end_date: Date) => {
        return HTTPApi.post(
            "attendance/courses/student_data", {
                course_id: course_id,
                student_id: student_id,
                start_date: utils.get_utc_date(start_date).toISOString(),
                end_date: utils.get_utc_date(end_date).toISOString(),
                // period_number
            }
        ).then((res) => {
            return res.data as Array<Attendance>
        })
            .catch((e) =>{
                console.log(e)
            })
    }

    export const get_student_homeroom_attendance_entries = async (homeroom_id: string, student_id: string, start_date: Date, end_date: Date) => {
        return HTTPApi.post(
            "attendance/homeroom/student_data", {
                homeroom_id: homeroom_id,
                student_id: student_id,
                start_date: utils.get_utc_date(start_date).toISOString(),
                end_date: utils.get_utc_date(end_date).toISOString(),
            }
        ).then((res) => {
            return res.data as Array<Attendance>
        })
            .catch((e) =>{
                console.log(e)
            })
    }

    export const update_excuse_student = async (attendance_id: string, reason: string) => {
        const response = await HTTPApi.post("attendance/update_excuse_student", {
            entry_id: attendance_id,
            reason: reason
        });

        return response.data;
    };


    export const update_entry_status = async (attendance_id: string, status: string) => {
        return HTTPApi.post(
            "attendance/update_status", {
                entry_id: attendance_id,
                status: status
            }
        )
    }

    export const update_signed_time = async (attendance_id: string, time_type: string, time: Date) => {
        return HTTPApi.post(
                "attendance/update_time", {
                    entry_id: attendance_id,
                    time_type: time_type,
                    time: time
                }
            )
    }

    export const update_reason = async (attendance_id: string, reason: string) => {
        return HTTPApi.post(
                "attendance/update_reason", {
                    entry_id: attendance_id,
                    reason: reason,
                }
            )
    }

    export const update_late_dismissal_date = async (attendance_id: string, late_dismissal_date?: Date) => {
        return HTTPApi.post(
                "attendance/update_late_dismissal_date", {
                    entry_id: attendance_id,
                    late_dismissal_date: late_dismissal_date,
                }
            )
    }

    export const homeroom_attendance_csv_data = async (homeroom: Homeroom, start_date: Date, end_date: Date) => {
        return HTTPApi.post(
            "attendance/homerooms/attendance_csv_data", {
                homeroom: homeroom._id,
                start_date: utils.get_utc_date(start_date).toISOString(),
                end_date: utils.get_utc_date(end_date).toISOString()
            }
        ).then((res) => {
            download(res.data, homeroom.name+"_attendance.csv")
        })
    }

    export const course_attendance_csv_data = async (course: Course, start_date: Date, end_date: Date) => {
        return HTTPApi.post(
            "attendance/courses/attendance_csv_data", {
                course: course._id,
                start_date: utils.get_utc_date(start_date).toISOString(),
                end_date: utils.get_utc_date(end_date).toISOString(),
            }
        ).then((res) => {
            download(res.data, course.name+"_attendance.csv")
        })
    }

    export const late_dismissal_csv_data = async (start_date: Date, end_date: Date) => {
        return HTTPApi.post(
            "attendance/late_dismissal_csv_data", {
                start_date: utils.get_utc_date(start_date).toISOString(),
                end_date: utils.get_utc_date(end_date).toISOString(),
            }
        ).then((res) => {
            download(res.data, "late_dismissal.csv")
        })
    }

    export interface AttendanceCourseDataSingleStudent {
        student: Student,
        attendance_entry: Attendance,
        course: Course
    }
    export const get_course_attendance_entry_for_single_student = async (student_id: string, course_id: string, date: Date) => {
        return HTTPApi.post(
            "attendance/courses/data/student", {
                student: student_id,
                course: course_id,
                date: utils.get_utc_date(date).toISOString()
            }
        ).then((res) => {
            return res.data as AttendanceCourseDataSingleStudent
        })
    }

    export interface AttendanceHomeroomDataSingleStudent {
        student: Student,
        attendance_entry: Attendance,
        homeroom: Homeroom
    }
    export const get_homeroom_attendance_entry_for_single_student = async (student_id: string, homeroom_id: string, date: Date) => {
        return HTTPApi.post(
            "attendance/homerooms/data/student", {
                student: student_id,
                course: homeroom_id,
                date: utils.get_utc_date(date).toISOString()
            }
        ).then((res) => {
            return res.data as AttendanceHomeroomDataSingleStudent
        })
    }
    
}