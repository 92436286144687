import {FormControl, FormLabel, HStack, Spacer, Stack} from "@chakra-ui/react";
import {Select as SearchSelect} from "chakra-react-select";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {StudentAPI} from "../../../api/StudentAPI";
import {to_oid} from "../../../utils/ObjectId";
import {EvaluationAPI} from "../../../api/EvaluationAPI";
import {Student} from "../../../models/student";
import {StudentView} from "./StudentView";


interface StudentMarkData {
    student: Student,
    final_mark?: number
    maximum_potential_mark?: number
}


interface Props {
    students_marks: Array<StudentMarkData>
}


export const StudentSelector = ({students_marks}: Props) => {

    const [student_final_mark, set_student_final_mark] = useState<StudentMarkData>()

    return (
        <Stack>
            <HStack>
                <FormControl>
                    <FormLabel>
                        Mark Book Categories
                    </FormLabel>
                    <SearchSelect
                        name="flavors"
                        options={students_marks?.map((res) => {
                            return {
                                label: res.student.profile.first_name + " " + res.student.profile.last_name,
                                value: res
                            }
                        })}
                        placeholder={"Student Name"}
                        onChange={(value) => {
                             set_student_final_mark(value!.value)
                        }}
                    />
                </FormControl>
            </HStack>
            <Spacer/>
            <Spacer/>
            <Spacer/>
            <StudentView student_mark={student_final_mark!}/>
        </Stack>
    )
}