import HTTPApi from "./ApiCommon";
import { Homeroom } from "../models/homeroom";
import { Student } from "../models/student";
import { Course } from "../models/course";
import { utils } from "../utils/date_utils";
import { Violation, ViolationReason, ViolationStatus } from "../models/violation";
import { ObjectId } from "../utils/ObjectId";



export namespace ViolationsAPI {

    export interface ViolationHomeroomData {
        students: {[key: string]: Student},
        violation_entries: {[key: string]: Violation},
        homeroom: Homeroom
    }
    export const get_homeroom_violation_entries = async (homeroom_id: string, date: Date) => {
        return HTTPApi.post(
                "violations/homerooms/data", {
                    homeroom: homeroom_id,
                    date: utils.get_utc_date(date).toISOString()
                }
            ).then((res) => {
                return res.data as ViolationHomeroomData
            })
            .catch((e) =>{
                console.log(e)
            })
    }

    export interface ViolationCourseData {
        students: {[key: string]: Student},
        violation_entries: {[key: string]: Violation},
        course: Course
    }
    export const get_course_violation_entries = async (course_id: string, date: Date) => {
        return HTTPApi.post(
                "violations/courses/data", {
                    course: course_id,
                    date: utils.get_utc_date(date).toISOString()
                }
            ).then((res) => {
                return res.data as ViolationCourseData
            })
            .catch((e) =>{
                console.log(e)
            })
    }


    export const update_entry_status = async (entry_id: ObjectId, status: Array<ViolationStatus>, reasons: ViolationReason) => {
        return HTTPApi.post(
                "violations/update_status", {
                    entry_id: entry_id,
                    status: status,
                    reasons: reasons
                }
            )
    }

    export const update_notes = async (entry_id: ObjectId, notes: string) => {
        return HTTPApi.post(
                "violations/update_notes", {
                    entry_id: entry_id,
                    notes: notes,
                }
            )
    }
    
}