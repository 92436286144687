import {Student} from "../../../models/student";
import {EvaluationEntry} from "../../../models/evaluation_entry";
import {Evaluation} from "../../../models/evaluation";
import {Badge, Box, HStack, Input, Skeleton, Spacer, Stack, Text, useToast} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {EvaluationEntryAPI} from "../../../api/EvaluationEntryAPI";
import {TableContent} from "../../table/TableContent";
import {ObjectId, to_oid} from "../../../utils/ObjectId";
import {EvaluationAPI} from "../../../api/EvaluationAPI";
import {useParams} from "react-router-dom";
import {MarkCategory} from "../../../models/mark_category";
import {MarkCategoriesAPI} from "../../../api/MarkCategoryAPI";



interface EntryData {
    evaluation: Evaluation,
    data: EntryDataData
}

interface EntryDataData{
    evaluation_entry: EvaluationEntry,
    evaluation: Evaluation,
    page_refresh: any
}

interface Props {
    student_id: ObjectId
}

export const StudentEvaluationList = ({student_id}: Props) => {

    let { course_id } = useParams();
    let { subject_id } = useParams();
    let class_id: string;

    if (course_id) {
        // course_id is defined, use it
        class_id = course_id;
    } else if (subject_id) {
        // subject_id is defined, use it
        class_id = subject_id;
    }

    const [entry_data, set_entry_data] = useState<Array<EntryData> | undefined>()

    const get_evaluations = () => {

        EvaluationAPI.get_evaluation_list_from_course(to_oid(class_id))
            .then((res) => {
                let evaluations = res!
                EvaluationEntryAPI.get_evaluation_entries_list_for_student(student_id!)
                    .then((res) => {
                        let student_entries = res!
                        let entry = evaluations.map((evaluation) => {
                            return {
                                evaluation: evaluation,
                                data: {
                                    evaluation_entry: student_entries[student_entries.findIndex((e) => e.evaluation_id.$oid === evaluation._id.$oid)],
                                    evaluation: evaluation,
                                    page_refresh: page_refresh,
                                }
                            }
                        })
                        set_entry_data(undefined)
                        set_entry_data(entry)
                    })
            })
    }

    useEffect(() => {
        get_evaluations()
    }, [student_id])


    const page_refresh = () => {
        get_evaluations()
    }


    return (
        <Box>
            {entry_data?
                    <TableContent data={entry_data} columns={StudentEvaluationListColumns}/>
            :
                <Stack>
                    <br/>
                <Skeleton height='100px' />
                <Skeleton height='100px' />
                <Skeleton height='100px' />
                <Skeleton height='100px' />
                <Skeleton height='100px' />
                <Skeleton height='100px' />
                <Skeleton height='100px' />
                <Skeleton height='100px' />
                <Skeleton height='100px' />
                    </Stack>
            }
        </Box>
    );
}

let StudentEvaluationListColumns = [
    {
        Header: "Evaluation",
        accessor: "evaluation",
        Cell: function StatusCell(evaluation: Evaluation) {

            return (
                <Box minW='100px'>
                    <Text colorScheme={"green"}>
                        {evaluation.name!}
                    </Text>
                </Box>
            )
        },
    },
    {
        Header: "Mark Category",
        accessor: "evaluation",
        Cell: function StatusCell(evaluation: Evaluation) {

            const [mark_category_name, set_mark_category_name] = useState<string>();

            const get_mark_category_name = () => {
                if (evaluation.mark_category == undefined) {
                    set_mark_category_name("Unassigned")
                } else {
                    MarkCategoriesAPI.get_mark_category(evaluation.mark_category)
                        .then((res) => {
                            set_mark_category_name(res.name)
                        })
                }
            }

            useEffect(() => {
                get_mark_category_name()
            }, [])

            return (
                <Box minW='170px'>
                    <Text>
                        {mark_category_name}
                    </Text>
                </Box>
            )
        },
    },
    {
        Header: "Total",
        accessor: "evaluation",
        Cell: function StatusCell(evaluation: Evaluation) {
            return (
                <Box minW='60px' maxW='80px'>
                    <Text
                        colorScheme={"green"}>
                        {evaluation.total!}
                    </Text>
                </Box>
            )
        },
    },
    {
        Header: "Weight",
        accessor: "evaluation",
        Cell: function StatusCell(evaluation: Evaluation) {
            return (
                <Box minW='60px' maxW='80px'>
                    <Text>
                        {evaluation.weight!}
                    </Text>
                </Box>
            )
        },
    },
    {
        Header: "Mark",
        accessor: "data",
        Cell: function StatusCell(d: EntryDataData) {
            const toast = useToast()
            const [mark, set_mark] = React.useState<string | undefined>(d.evaluation_entry.mark?.toString())

            const update = (value: string) => {
                if((value.length == 0 && d.evaluation_entry.mark == undefined) || Number(value) == d.evaluation_entry.mark){
                    return
                }
                if (value.length === 0) {
                    d.evaluation_entry.mark = undefined
                }
                else {
                    d.evaluation_entry.mark = Number(value)
                }
                EvaluationEntryAPI.update_evaluation_entry(d.evaluation_entry)
                    .then(()=> {
                        d.page_refresh()
                        toast({
                            title: 'Success.',
                            description: "Updated Entry",
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                        })
                    })
                    .catch((e) => {console.log(e)
                        toast({
                            title: 'Error.',
                            description: "Failed to update " + d.evaluation_entry.mark + " mark",
                            status: 'error',
                            duration: 5000,
                            isClosable: true,
                        })
                    })
                //}
            }
            return (
                <Box>
                    <HStack>
                        <Input
                            minW='60px' maxW='80px'
                            placeholder={"N/A"}
                            value={mark}
                            onChange={(value) => set_mark(value.target.value)}
                            onBlur={(v) => update(v.target.value)}
                        />
                        <Text>
                            / {d.evaluation.total}
                        </Text>
                        <Spacer/>
                        {
                            typeof (d.evaluation_entry.mark)=="number"?
                                <Badge colorScheme={"green"}>
                                    {(d.evaluation_entry.mark!/d.evaluation.total*100).toFixed(2)}%
                                </Badge>
                                :
                                <></>
                        }
                    </HStack>
                </Box>
            )
        },
    },
]