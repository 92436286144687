import { DateTime } from "../utils/DateTime"
import { ObjectId } from "../utils/ObjectId"

export enum ReportType {
    Progress = "Progress",
    Midterm = "Midterm",
    Final = "Final"
}

export interface CheckBox {
    check_box_id: ObjectId,
    state: boolean,
}

interface Section {
    layout_id: ObjectId,
    mark: string,
    check_boxes: Array<CheckBox>
}


export interface ReportEntry {
    _id: ObjectId,
    student: ObjectId,
    report_type: ReportType,
    comment: string,

    // homeroom will be NOT null when its a homeroom
    homeroom?: ObjectId,
    // homeroom_subject_layout will be null if report_type is progress
    homeroom_subject_layout?: ObjectId,

    // course will be NOT null when its a course
    course?: ObjectId,

    // If course or if the homeroom's report config is standalone or if the report type is progress
    standalone_mark?: string,
    standalone_checkboxes?: Array<CheckBox>,

    // If homeroom's report config is NOT standalone
    sections?: Array<Section>,

    created?: DateTime
}
