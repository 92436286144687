import {
    Stack,
    Text,
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    HStack,
    Button,
    Spacer,
    Center,
    useToast,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    useDisclosure,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
} from '@chakra-ui/react';
import React, {RefObject, useEffect} from "react"
import { ProfileCard } from "./Cards/ProfileCard";
import { FormDataStatus, SensitiveStudent, Student, StudentStatus } from "../../models/student";
import { StudentAPI } from '../../api/StudentAPI';
import { StudentFamilyCard } from './Cards/StudentFamilyCard';
import { HealthCard } from './Cards/HealthCard';
import { OfficeInformationCard } from './Cards/OfficeInfoCard';
import { DocumentsCard } from './Cards/DocumentsCard';
import { FinancialCard } from './Cards/FinancialCard';
import { ConsentCard } from './Cards/ConsentCard';
import { UniformCard } from './Cards/UniformCard';
import { FormStatusCard } from '../parents/FormStatus';
import { FiAlertTriangle, FiArrowDown } from 'react-icons/fi';
import { ObjectId } from '../../utils/ObjectId';
import {FocusableElement } from "@chakra-ui/utils"
import LocalStorageService from '../../api/LocalStorageService';
import { UserRole, UserType } from '../../api/UserApi';
import { IncidentCard } from './Cards/IncidentCard';
import { TransactionsCard } from './Cards/TransactionsCard';
import { FormCard } from './Cards/FormCard';
import {DonationCard} from "./Cards/DonationCard";
import { StudentCourseList } from './Cards/StudentCourseList';
import { StudentHomeroomList } from './Cards/StudentHomeroomList';
import GClassStudentViewer from '../gclass/GClassStudentViewer';
import StudentOverview from './Cards/OverviewCard';
import { LightButton } from '../layout/LightButton';
import { useUser } from '../../hooks/useUser';
import { useLocation } from 'react-router-dom';

interface Card {
    onboarding: boolean | undefined
    re_registration: boolean | undefined
    student_id: ObjectId
}

export const StudentProfile = ({onboarding, re_registration, student_id}: Card) =>  {
    const location = useLocation()

    const [student, set_student] = React.useState<Student>();
    const [sensitive_student, set_sensitive_student] = React.useState<SensitiveStudent>()

    const { user, userIsTeacher } = useUser() 
    const canAccessIncident = userIsTeacher && user!.has_role(UserRole.Can_Access_Student_Incident)

    useEffect(() => {
        StudentAPI.get_student(student_id.$oid)
            .then((res) => {
                set_student(res!)
            })

        StudentAPI.get_sensitive_student(student_id.$oid).then((res) => {
            let s = res!
            set_sensitive_student(s)
        })
    }, [student_id.$oid]);

    const openInNewTab = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <Stack>
        <HStack mb="4">
            <Text fontWeight="bold" fontSize="2xl">{student?.profile.first_name} {student?.profile.last_name}</Text>

            <Spacer/>

            { student && !onboarding && student.status === StudentStatus.Enrolled && userIsTeacher &&
                <ChangePasswordPopover student_id={student!._id}/>
            }
            { student && (student.status === StudentStatus.Enrolled || student.status === StudentStatus.Applicant || StudentStatus.ReRegistration) && userIsTeacher &&
                <WithdrawPopOver student={student}/> 
            }
        </HStack>

                <Tabs isFitted>
                
                    {student && !onboarding && !re_registration?
                        <TabList mb='1em'>
                            <Tab>Overview</Tab>
                            <Tab>Profile</Tab>
                            <Tab>Family</Tab>
                            <Tab>Health</Tab>
                            <Tab>History</Tab>
                            <Tab>Documents</Tab>
                            {sensitive_student &&
                                <Tab>Financial</Tab>
                            }
                            {sensitive_student &&
                                <Tab>Donation</Tab>
                            }
                            <Tab>Misc</Tab>
                            {canAccessIncident &&
                                <Tab>Incidents</Tab>
                            }
                            <Tab>Transactions</Tab>
                            <Tab>Forms</Tab>
                            <Tab>Academic Tracker</Tab>
                        </TabList>
                        : student &&
                        <TabList mb='1em'>
                            <Tab>Registration Form</Tab>
                            <Tab>Profile {get_status(student.form_data.profile.status)}</Tab>
                            <Tab>Family {get_status(student.form_data.family.status)}</Tab>
                            <Tab>Health {get_status(student.form_data.health.status)}</Tab>
                            <Tab>History{get_status(student.form_data.office_information.status)}</Tab>
                            <Tab>Documents {get_status(student.form_data.documents.status)}</Tab>
                            {sensitive_student &&
                                <Tab>Financial {get_status(student.form_data.financial.status)}</Tab>
                            }
                            <Tab>Donation {get_status(student.form_data.donation.status)}</Tab>
                            <Tab>Misc {get_status(student.form_data.consent.status)} {get_status(student.form_data.uniform.status)}</Tab>
                        </TabList>
                    }
                    
                {student &&
                
                    !onboarding && !re_registration ?
                        <TabPanels>
                            <TabPanel>
                                <Stack gap="4">
                                    <StudentOverview student={student}/>

                                    <StudentHomeroomList student={student} />

                                    <StudentCourseList student={student} link={`${location.pathname}/course/`} />
                                </Stack>
                            </TabPanel>
                            <TabPanel>
                                <ProfileCard student={student} set_student={set_student}/>
                            </TabPanel>
                            <TabPanel>
                                <StudentFamilyCard student={student}/>
                            </TabPanel>
                            <TabPanel>
                                <HealthCard student={student} set_student={set_student}/>
                            </TabPanel>
                            <TabPanel>
                                <OfficeInformationCard student={student} set_student={set_student}/>
                            </TabPanel>
                            <TabPanel>
                                <DocumentsCard student={student} set_student={set_student}/>
                            </TabPanel>
                            {sensitive_student &&
                                <TabPanel>
                                    <FinancialCard student={student} sensitive_student={sensitive_student} set_sensitive_student={set_sensitive_student}/>
                                </TabPanel>
                            }

                            <TabPanel>
                                <DonationCard student={student} set_student={set_student}/>
                            </TabPanel>
                            <TabPanel>
                                <Stack>
                                    <ConsentCard student={student} set_student={set_student}/>
                                    <UniformCard student={student} set_student={set_student}/>
                                </Stack>
                            </TabPanel>
                            {canAccessIncident &&
                                <TabPanel>
                                    <IncidentCard student={student} set_student={set_student}/>
                                </TabPanel>
                            }
                            <TabPanel>
                                <>
                                <TransactionsCard student={student}/>
                                </>
                            </TabPanel>
                            <TabPanel>
                                <>
                                <FormCard student={student} form_name={"Registration 24-25"}/>
                                <br></br>
                                <FormCard student={student} form_name={"Re-Registration 24-25"}/>
                                </>
                            </TabPanel>

                            <TabPanel>
                                <GClassStudentViewer type="homeroom" studentId={student._id} />

                                <GClassStudentViewer type="course" studentId={student._id} />
                            </TabPanel>
                        </TabPanels>
                        : 
                        student &&
                        <TabPanels>
                            <TabPanel>
                                <Stack>
                                <StudentOverview student={student}/>
                                { onboarding ?
                                    <FormCard student={student} form_name={"Registration 24-25"}/>
                                    :
                                    <FormCard student={student} form_name={"Re-Registration 24-25"}/>
                                }
                                
                                </Stack>
                            </TabPanel>
                            <TabPanel>
                                <FormStatusCard form_type="profile" student={student} set_student={set_student}/>
                            </TabPanel>
                            <TabPanel>
                                <FormStatusCard form_type="family" student={student} set_student={set_student}/>
                            </TabPanel>
                            <TabPanel>
                                <FormStatusCard form_type="health" student={student} set_student={set_student}/>
                            </TabPanel>
                            <TabPanel>
                                <FormStatusCard form_type="office_information" student={student} set_student={set_student}/>
                            </TabPanel>
                            <TabPanel>
                                <FormStatusCard form_type="documents" student={student} set_student={set_student}/>
                            </TabPanel>
                            {sensitive_student &&
                                <TabPanel>
                                    <FormStatusCard form_type="financial" student={student} set_student={set_student}/>
                                </TabPanel>
                            }
                            <TabPanel>
                                <FormStatusCard form_type="donation" student={student} set_student={set_student}/>
                            </TabPanel>
                            <TabPanel>
                                <Stack>
                                    <FormStatusCard form_type="consent" student={student} set_student={set_student}/>
                                    <FormStatusCard form_type="uniform" student={student} set_student={set_student}/>
                                </Stack>
                            </TabPanel>
                        </TabPanels>
                }
                </Tabs>

                <HStack>
                    <Spacer />

                    <LightButton color="purple" onClick={() => openInNewTab('/students/printable/' + student?._id.$oid)}>
                        Printable Page
                    </LightButton>
                </HStack>
        </Stack>
    )
}

const get_status = (status: FormDataStatus) => {
    if(status === FormDataStatus.None || status === FormDataStatus.Waiting || status === FormDataStatus.NeedsChange){
        return "🛑"
    }
    else if(status === FormDataStatus.Approved){
        return "✅"
    }
    else if(status === FormDataStatus.Submitted){
        return "❗"
    }
    return "🛑"
}

interface ChangePasswordPopoverCard{
    student_id: ObjectId
}
const ChangePasswordPopover = ({student_id}: ChangePasswordPopoverCard) => {
    const toast = useToast()

    const { isOpen, onToggle, onClose } = useDisclosure()

    const [password, set_password] = React.useState<string>("");
    const [change_password_at_next_login, set_change_password_at_next_login] = React.useState<boolean>(true);

    const change_password = () => {
        StudentAPI.change_password(student_id, password, change_password_at_next_login)
        .then((res) => {
            onToggle()
            set_password("")
            toast({
                title: 'Success',
                description: "Password Change",
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
        })
        .catch((err) => {
            set_password("")
            toast({
                title: 'Error.',
                description: "Failed to change password",
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        })
    }


    return (

    <Popover
    isOpen={isOpen}
    onClose={onClose}
    >
        <PopoverTrigger >
            <Button colorScheme="green" rightIcon={<FiArrowDown/>} onClick={onToggle}>Change Password</Button>
        </PopoverTrigger>
        <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>Change Password</PopoverHeader>
            <PopoverBody>
                <>
                <FormControl isRequired>
                    <FormLabel>Password</FormLabel>
                    <Input placeholder='Password' type="password" value={password} onChange={(value) => {set_password(value.target.value)}}/>
                </FormControl>
                <br/>
                <FormControl isRequired>
                    <Checkbox 
                        colorScheme="green" 
                        isChecked={change_password_at_next_login}
                        onChange={(e) => set_change_password_at_next_login(e.target.checked)}
                        >Change Password at Next Login</Checkbox>                
                </FormControl>
                <br/>
                <Center>
                    <Button colorScheme="green" onClick={change_password}>Submit</Button>
                </Center>
                </>
            </PopoverBody>
        </PopoverContent>
    </Popover>

    )
}


interface WithdrawPopoverCard{
    student: Student
}
const WithdrawPopOver = ({student}: WithdrawPopoverCard) => {
    const toast = useToast()


    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef() as RefObject<FocusableElement>

    const [loading, set_loading] = React.useState<boolean>(false)
    

    const withdraw = () => {
        set_loading(true)
        if(student.status === StudentStatus.Enrolled || StudentStatus.ReRegistration){
            StudentAPI.withdraw(student._id)
            .then((res) => {
                onClose()
                toast({
                    title: 'Success',
                    description: "Student Withdrawn",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
                window.location.reload();
            })
            .catch((err) => {
                set_loading(false)
                toast({
                    title: 'Error.',
                    description: "Failed to withdraw student",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            })
        }
        else{
            StudentAPI.reject(student._id)
            .then((res) => {
                onClose()
                toast({
                    title: 'Success',
                    description: "Student Rejected",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
                window.location.reload();
            })
            .catch((err) => {
                set_loading(false)
                toast({
                    title: 'Error.',
                    description: "Failed to reject student",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            })
        }
        
    }


  return (
    <>
      <Button colorScheme='red' rightIcon={<FiAlertTriangle/>} onClick={onOpen}>
        {student.status === StudentStatus.Enrolled ? "Withdraw" : "Reject"} Student
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                {student.status === StudentStatus.Enrolled ? "Withdraw" : "Reject"} Student?
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef as React.LegacyRef<HTMLButtonElement>} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={withdraw} ml={3} isLoading={loading}>
                {student.status === StudentStatus.Enrolled ? "Withdraw" : "Reject"}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}