import React, { useState, useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { StudentAPI } from '../../api/StudentAPI';
import { ObjectId } from '../../utils/ObjectId';
import SubmissionStats from './SubmissionStats';
import { CourseOrHomeroomData, GClassStudentSubmission } from '../../models/gclass';
import { CourseAPI } from '../../api/CourseAPI';
import { HomeroomAPI } from '../../api/HomeroomAPI';
import { mergeSubmissionsWithCourseWork } from '../../utils/GClass';
import GClassViewerPlaceholder from './GClassViewerPlaceholder';
import GClassItemCard from './GClassItemCard';

interface Props {
    studentId: ObjectId
    type: 'homeroom' | 'course';
    itemId?: ObjectId
}

const GClassStudentViewer: React.FC<Props> = ({ type, studentId, itemId }) => {
    const [courseOrHomeroomData, setCourseOrHomeroomData] = useState<CourseOrHomeroomData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        // Reset data when a new student is selected
        setCourseOrHomeroomData([]);
        setLoading(true);

        const fetchItems = itemId
        ? // Fetch a single item if itemId is provided
        (type === 'homeroom'
            ? HomeroomAPI.get_homeroom(itemId).then(item => [item])  // Wrap the object in an array
            : CourseAPI.get_course(itemId).then(item => [item]))  // Wrap the object in an array
        : // Otherwise, fetch all items
            (type === 'homeroom'
                ? StudentAPI.get_homerooms(studentId, false)
                : StudentAPI.get_courses(studentId));

        fetchItems.then((items) => {
            // Loop over each item (course or homeroom) and fetch its submissions and course work
            Promise.all(items.map(async (item) => {
                const submissions: GClassStudentSubmission[] = await (type === 'homeroom'
                    ? StudentAPI.get_gclass_homeroom_submissions(studentId, item._id)
                    : StudentAPI.get_gclass_course_submissions(studentId, item._id));

                const courseWork = await (type === 'homeroom'
                    ? HomeroomAPI.get_gclass_work(item._id)
                    : CourseAPI.get_gclass_work(item._id));

                const mergedSubmissions = mergeSubmissionsWithCourseWork(submissions, courseWork);

                return {
                    _id: item._id,
                    name: item.name,
                    submissions: mergedSubmissions,
                };
            })).then((allData) => {
                setCourseOrHomeroomData(allData);
                setLoading(false);
            });
        });
    }, [itemId, studentId, type]);

    return (
        <Box mt={4}>
            { loading ? 
                <GClassViewerPlaceholder />                    
                :
                !!courseOrHomeroomData.length &&
                <>
                    <Text fontSize="2xl" fontWeight="bold" mb={4} textTransform="capitalize">Overall Student Statistics</Text>

                    {/* Display aggregated stats for all courses/homerooms */}
                    <SubmissionStats submissions={courseOrHomeroomData.flatMap(data => data.submissions)} />

                    <Text fontSize="2xl" fontWeight="bold" mb={4} textTransform="capitalize">{type} List</Text>

                    {
                        courseOrHomeroomData.map(data => (
                            <GClassItemCard key={data._id.$oid} data={data} />
                        ))
                    }
                </>
            }
        </Box>
    );
};

export default GClassStudentViewer;
