import {ObjectId} from "../utils/ObjectId";
import {DateTime} from "../utils/DateTime";

export class Semester {
    _id: ObjectId;
    archived: boolean;
    name: string;
    start_date: Date;
    end_date: Date;

    constructor(data: any) {
        this._id = data._id
        this.archived = data.archived
        this.name = data.name
        this.start_date = new DateTime(data.start_date).to_date()
        this.end_date =  new DateTime(data.end_date).to_date()

    }
}
