import {
    Button,
    Stack,
    useToast,
    AlertDialogFooter,
    AlertDialogBody,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    AlertDialog,
    FormControl,
    FormLabel,
    Input,
    Skeleton,
    Text,
    HStack,
    IconButton,
    Collapse,
    Box,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel, Switch,
} from '@chakra-ui/react';
import {
    Select as SearchSelect,
} from "chakra-react-select";
import { memo, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CourseAPI } from "../../api/CourseAPI";
import { Homeroom } from '../../models/homeroom';
import { Lookup } from '../../models/lookup';
import { ObjectId, new_oid } from "../../utils/ObjectId";
import {ReportLayout, ReportLayoutCheckbox, ReportLayoutSection} from "../../models/report_layout";
import { AddIcon, MinusIcon, ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import {Course} from "../../models/course";

interface CreateCourseDialogProps {
    isOpen: boolean
    onClose: any
    teachers?: Lookup[]
    semesters: Lookup[]
    homeroom?: Homeroom
    clone_course_from?: Course;
    clone_report_layout_from?: ReportLayout;
}

const defaultSection: ReportLayoutSection = {
    _id: new_oid(),
    name: 'Default Section',

};
const defaultCheckboxes: ReportLayoutCheckbox[] = [
    { _id: new_oid(), name: 'IEP' },
    { _id: new_oid(), name: 'ESL/EDL' }

];

export const CreateCourseDialog = memo(({isOpen, onClose, teachers, semesters, homeroom, clone_course_from, clone_report_layout_from}: CreateCourseDialogProps) => {
    const toast = useToast()
    const navigate = useNavigate()
    const [loading, set_loading] = useState<boolean>(false);

    const [name, set_name] = useState<string>("");
    const [year, set_year] = useState<string>()
    const [course_code, set_course_code] = useState<string>()
    const [teacher, set_teacher] = useState<ObjectId>();
    const [semester_id, set_semester_id] = useState<ObjectId>()
    const [sections, set_sections] = useState<ReportLayoutSection[]>([defaultSection])
    const [check_boxes, set_check_boxes] = useState<ReportLayoutCheckbox[]>(defaultCheckboxes)
    const [char_limit, set_char_limit] = useState<number>(800)
    const [isOpenSections, setIsOpenSections] = useState<boolean>(true);
    const [isOpenCheckboxes, setIsOpenCheckboxes] = useState<boolean>(true);
    const [google_classroom_to_be_cloned, set_google_classroom_to_be_cloned] = useState<boolean>(true);
    const [gclass_create, set_gclass_create] = useState<boolean>(!homeroom);
    const cancelRef = useRef<HTMLButtonElement>(null);

    const create = async () => {
        // Ensure all required fields are filled
        if (name && teacher && year && course_code && semester_id) {
            set_loading(true);

            // Determine the correct submit function based on whether we're cloning or creating
            const isCloning = Boolean(clone_course_from);
            const submitFunction = homeroom
                ? CourseAPI.homeroom_create
                : isCloning
                    ? CourseAPI.clone
                    : CourseAPI.create;

            // Build the body for the request
            const body: any = {
                name,
                year,
                course_code,
                semester_id,
                homeroom_id: homeroom?._id,
                teachers: [teacher],
                char_limit,
                sections,
                check_boxes,
                gclass_create: gclass_create
            };

            // Conditionally add the google_classroom field if cloning is true and google_classroom_to_be_cloned is checked
            if (isCloning && google_classroom_to_be_cloned) {
                body.google_classroom_to_be_cloned = clone_course_from?._id;
            }

            try {
                const res = await submitFunction(body);
                set_loading(false);
                cancel();
                toast({
                    title: 'Success.',
                    description: "Created course successfully.",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                navigate("/class/courses/" + res._id.$oid);
                window.location.reload();
            } catch (error) {
                set_loading(false);
                toast({
                    title: 'Error.',
                    description: "Failed to create course.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        } else {
            toast({
                title: 'Error.',
                description: "Please fill in all the required information",
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const cancel = () => {
        set_name(clone_course_from?.name || "");
        set_year("");
        set_course_code(clone_course_from?.course_code || "");
        set_teacher(undefined);
        set_semester_id(undefined);

        set_sections(clone_report_layout_from?.sections || [defaultSection]);
        set_check_boxes(clone_report_layout_from?.check_boxes || defaultCheckboxes);
        set_char_limit(clone_report_layout_from?.comment_char_limit || 800);

        onClose();
    };
    const onChangeCourseName = (value: string) => {
        set_name(value)
        const newSections = [...sections];
        newSections[0].name = value;
        set_sections(newSections);
    }
    const onUpdateSectionName = (value: string, index: number) => {
        const newSections = [...sections];
        newSections[index].name = value;
        set_sections(newSections);
    }
    const onCharLimitChange= (value: any) => {

        // Check if the input is empty and set to "" or parse it to a number
        if (value === "") {
            set_char_limit(0);  // or set to null if you prefer
        } else {
            const parsedValue = Number(value);
            if (!isNaN(parsedValue)) {
                set_char_limit(parsedValue);
            }
        }
    }

    useEffect(() => {
        // update semester id if homeroom is passed
        set_semester_id(homeroom?.semester._id)
    }, [homeroom])

    useEffect(() => {
        if (clone_course_from) {
            set_name(clone_course_from.name);
            set_course_code(clone_course_from.course_code);
            set_semester_id(clone_course_from.semester._id);
        }

        if (clone_report_layout_from) {
            set_sections(clone_report_layout_from.sections);
            set_check_boxes(clone_report_layout_from.check_boxes || defaultCheckboxes);
            set_char_limit(clone_report_layout_from.comment_char_limit || 800);
        }
    }, [clone_course_from, clone_report_layout_from]);

    useEffect(() => {

    })

    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        {clone_course_from
                            ? `Cloning Course From ${clone_course_from.name}`
                            : 'Create Course'
                        }
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        {loading ?
                            <Stack>
                                <Text>Please Wait... You will be redirected soon</Text>
                                <Skeleton height='20px' />
                                <Skeleton height='20px' />
                                <Skeleton height='20px' />
                            </Stack>
                            :
                            <Tabs variant="enclosed">
                                <TabList>
                                    <Tab>Course Details</Tab>
                                    <Tab>Report Layout</Tab>
                                </TabList>

                                <TabPanels>
                                    {/* Course Details Tab */}
                                    <TabPanel>
                                        <FormControl id="name" isRequired>
                                            <FormLabel>Course Name</FormLabel>
                                            <Input isRequired
                                                   value={name}
                                                   onChange={(value) => onChangeCourseName(value.target.value)}
                                            />
                                        </FormControl>
                                        <br></br>
                                        {/* No need to show the semester tab if the course is related to a homeroom */}
                                        {
                                            !homeroom && (
                                                <>
                                                    <FormControl id="semester" isRequired>
                                                        <FormLabel>Semester</FormLabel>

                                                        <SearchSelect
                                                            name="flavors"
                                                            options={semesters}
                                                            placeholder="Select a semester..."
                                                            onChange={(e: any) => {set_semester_id(e.value as ObjectId)}}
                                                        />
                                                    </FormControl>

                                                    <br></br>
                                                </>
                                            )
                                        }

                                        <FormControl id="teacher" isRequired>
                                            <FormLabel>Teacher</FormLabel>
                                            <SearchSelect
                                                name="flavors"
                                                options={teachers}
                                                placeholder="Select a teacher..."
                                                onChange={(e: any) => {set_teacher(e.value as ObjectId)}}
                                            />
                                        </FormControl>
                                        <br></br>
                                        <FormControl id="year" isRequired>
                                            <FormLabel>Year</FormLabel>
                                            <Input isRequired
                                                   value={year}
                                                   onChange={(value) => {set_year(value.target.value)}}
                                            />
                                        </FormControl>
                                        <br></br>
                                        <FormControl id="course_code" isRequired>
                                            <FormLabel>Course Code</FormLabel>
                                            <Input isRequired
                                                   value={course_code}
                                                   onChange={(value) => {set_course_code(value.target.value)}}
                                            />
                                        </FormControl>
                                        <br></br>
                                        <FormControl id="gclass_create" isRequired>
                                            <FormLabel htmlFor="create-gclass" mb="0">
                                                {gclass_create ? "Create Google Class" : "Create Google Class Disabled"}
                                            </FormLabel>
                                            <Switch
                                                id="create-gclass"
                                                isChecked={gclass_create}
                                                onChange={() => set_gclass_create(!gclass_create)}
                                                colorScheme="teal"
                                            />
                                        </FormControl>
                                        <br></br>
                                        {clone_course_from && (
                                            <FormControl display="flex" alignItems="center" id="google_classroom_to_be_cloned">
                                                <FormLabel htmlFor="google_classroom_to_be_cloned" mb="0">
                                                    Clone Google Classroom
                                                </FormLabel>
                                                <Switch
                                                    id="google_classroom_to_be_cloned"
                                                    isChecked={google_classroom_to_be_cloned}
                                                    onChange={(e) => set_google_classroom_to_be_cloned(e.target.checked)}
                                                />
                                            </FormControl>
                                        )}
                                        <br></br>
                                    </TabPanel>

                                    {/* Report Layout Tab */}
                                    <TabPanel>
                                        {/* Display the first section separately */}
                                        <Box p="4" borderWidth="1px" borderRadius="lg" mb={4}>
                                            <HStack alignItems="center">
                                                <FormControl isRequired>
                                                    <FormLabel>Report Character Limit</FormLabel>
                                                    <Input
                                                        type="number"
                                                        isRequired
                                                        value={char_limit || ""}
                                                        onChange={(e) => onCharLimitChange(e.target.value)}
                                                        placeholder="Report Character Limit"
                                                        />
                                                </FormControl>

                                            </HStack>


                                        </Box>

                                        <HStack>
                                            <Text fontSize={"md"} fontWeight="bold">
                                                Checkboxes
                                            </Text>
                                            <IconButton
                                                aria-label="Toggle Checkboxes"
                                                icon={isOpenSections ? <ChevronUpIcon /> : <ChevronDownIcon />}
                                                onClick={() => setIsOpenSections(!isOpenSections)}
                                                variant="outline"
                                                size="sm"
                                            />
                                        </HStack>
<br></br>
                                        {/* Checkboxes */}
                                        <Collapse in={!isOpenSections}>
                                            <Box p="4" borderWidth="1px" borderRadius="lg" mb={4}>
                                            <Stack spacing={4} mt={4}>
                                                {check_boxes.map((checkbox, checkboxIndex) => (
                                                    <HStack key={checkboxIndex}>
                                                        <Input
                                                            value={checkbox.name}
                                                            onChange={(e) => {
                                                                const new_check_boxes = [...check_boxes];
                                                                new_check_boxes[checkboxIndex].name = e.target.value;
                                                                set_check_boxes(new_check_boxes);
                                                            }}
                                                            placeholder="Checkbox Name"
                                                        />
                                                        <IconButton
                                                            aria-label="Remove Section"
                                                            icon={<MinusIcon />}
                                                            onClick={() => set_check_boxes(check_boxes.filter((_, i) => i !== checkboxIndex))}
                                                            variant="outline"
                                                            colorScheme="red"
                                                            size="sm"
                                                        />
                                                    </HStack>
                                                ))}
                                                <IconButton
                                                    aria-label="Add Checkbox"
                                                    icon={<AddIcon />}
                                                    onClick={() => set_check_boxes( [...check_boxes, { _id: new_oid(), name: '' }])}
                                                    variant="outline"
                                                    border="1px dashed"
                                                    borderColor="gray.400"
                                                    size="sm"
                                                    alignSelf="start"
                                                />
                                            </Stack>
                                            </Box>
                                        </Collapse>

                                        <HStack>
                                            <Text fontSize={"md"} fontWeight="bold">
                                                Sections
                                            </Text>
                                            <IconButton
                                                aria-label="Toggle Checkboxes"
                                                icon={isOpenCheckboxes ? <ChevronUpIcon /> : <ChevronDownIcon />}
                                                onClick={() => setIsOpenCheckboxes(!isOpenCheckboxes)}
                                                variant="outline"
                                                size="sm"
                                            />
                                        </HStack>
                                        <br></br>
                                        <Collapse in={!isOpenCheckboxes}>
                                            <Box p="4" borderWidth="1px" borderRadius="lg" mb={4}>
                                        <Box key={0} p="1" borderWidth="0px" borderRadius="lg" mb={1}>
                                            <HStack alignItems="center">
                                                <Input
                                                    value={sections[0].name}
                                                    onChange={(e) => {
                                                        const newSections = [...sections];
                                                        newSections[0].name = e.target.value;
                                                        set_sections(newSections);
                                                    }}
                                                    placeholder="Section Name"
                                                    isDisabled={true}
                                                />
                                                <IconButton
                                                    aria-label="Remove Section"
                                                    icon={<MinusIcon />}
                                                    onClick={() => set_sections(sections.filter((_, i) => i !== 0))}
                                                    variant="outline"
                                                    colorScheme="red"
                                                    size="sm"
                                                    isDisabled={true}
                                                />
                                            </HStack>
                                        </Box>
                                        {/* Iterate through the rest of the sections */}
                                        {sections.slice(1).map((section, index) => (
                                            <Box key={index+1} p="1" borderWidth="0px" borderRadius="lg" mb={1}>
                                                <HStack alignItems="center">
                                                    <Input
                                                        value={section.name}
                                                        onChange={(e) => onUpdateSectionName(e.target.value, index+1)}
                                                        placeholder="Section Name"
                                                    />
                                                    <IconButton
                                                        aria-label="Remove Section"
                                                        icon={<MinusIcon />}
                                                        onClick={() => set_sections(sections.filter((_, i) => i !== index+1))}
                                                        variant="outline"
                                                        colorScheme="red"
                                                        size="sm"
                                                    />
                                                </HStack>
                                            </Box>
                                        ))}

                                        {/* Add Section Button */}
                                        <HStack justifyContent="flex-start" mt={4}>
                                            <IconButton
                                                aria-label="Add Section"
                                                icon={<AddIcon />}
                                                onClick={() => set_sections([...sections, { _id: new_oid(), name: '' }])}
                                                variant="outline"
                                                border="1px dashed"
                                                borderColor="gray.400"
                                                size="md"
                                            />
                                            <Text>Add New Section</Text>
                                        </HStack>
                                            </Box>
                                        </Collapse>
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        }
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={cancel}>
                            Cancel
                        </Button>

                        <Button colorScheme='green' ml={3} isLoading={loading} onClick={create}>
                            {clone_course_from ? 'Clone' : 'Create'}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
})