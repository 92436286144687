import { object, string } from 'yup'
import { ObjectIdSchema, objectWithId } from './schema'

export const updateCourseSchema = objectWithId.concat(
	object().shape({
		name: string().required('Name is required'),
		semester_id: ObjectIdSchema.required('semester id is required'),
		year: string().required('Year is Required'),
		course_code: string().required('Course Code is Required')
	})
)
