import React from 'react';
import { Grid, GridItem, Box, useBreakpointValue, Stack, Image } from '@chakra-ui/react';
import image from "../../images/auth.jpg"

interface AuthLayoutProps {
  children: React.ReactNode;
}

export const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  return (
    <Grid templateColumns={{ base: '1fr', md: '1fr 1fr' }} minH="100vh">
      {/* Left column with image, hidden on small screens */}
      <GridItem 
        display={{ base: 'none', md: 'flex' }}
		bg="#e4eff5" 
        alignItems="center" 
        justifyContent="center"
		
      >
		<Image src={image} maxH="500" />
      </GridItem>

      {/* Right column with the child content */}
      <GridItem bg="white">
        <Box as={Stack} justifyContent="center" boxShadow="none" h="100%">
          {children}
        </Box>
      </GridItem>
    </Grid>
  );
};
