import React, { useEffect, useState } from "react";
import { Box, Heading, Center } from '@chakra-ui/react';
import { HomeroomList } from "./HomeroomList";
import { Lookup } from "../../models/lookup";
import { SemesterAPI } from "../../api/SemesterAPI";
import { CourseList } from "./CourseList";
import { TabRoutes } from "../layout/TabRoutes";
import { useTeachersLookup } from "../../hooks/useTeachersLookup";
import { HomeroomAPI } from "../../api/HomeroomAPI";
import SkeletonTable from "../table/TableSkeleton";

export const Class = React.memo(() => {
    const [semesters, set_semesters] = useState<Lookup[]>([]);
    const [defaultTabIndex, setDefaultTabIndex] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(true);

    const { teachers } = useTeachersLookup();

    const tabs = [
        {
            label: "Homerooms",
            path: "/class/homerooms",
            element: (
                <HomeroomList 
                    teachers={teachers} 
                    semesters={semesters} 
                />
            ),
        },
        {
            label: "Courses",
            path: "/class/courses",
            element: (
                <CourseList 
                    teachers={teachers} 
                    semesters={semesters} 
                />
            ),
        },
    ];

    useEffect(() => {
        SemesterAPI.semester_list()
            .then((res) => {
                let data = res!.semesters.map((e) => {
                    return {
                        "value": e._id,
                        "label": e.name
                    }
                })
                set_semesters(data)
            })
        
            HomeroomAPI.homeroom_list(null, null, undefined, false)
                .then((res) => {
                    // check if homeroom is empty => redirect to courses page
                    if (!res.homerooms_count) setDefaultTabIndex(1) // courses page is in the tab 1 
                })
                .finally(() => {
                    setLoading(false)
                })
    }, [])

    return (
        <Box overflowX="auto" overflowY="hidden">
            <Center>
                <Heading size="md" mb="6">
					Classes {defaultTabIndex}
                </Heading>
            </Center>

            {
                loading ? 
                    <SkeletonTable></SkeletonTable> 
                :  
                    <TabRoutes tabs={tabs} defaultTabIndex={defaultTabIndex} />
            }
        </Box>
    );
});
