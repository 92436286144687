import { FrontReportEntry } from "../models/front_report_entry";
import { Homeroom } from "../models/homeroom";
import { ReportEntry } from "../models/report_entry";
import { ObjectId } from "../utils/ObjectId";
import HTTPApi from "./ApiCommon";
import download from 'js-file-download';
import { Course } from "../models/course";
import { Subject } from "../models/subject";



export namespace ReportsAPI {
    export interface HomeroomReports {
        homeroom: Homeroom
        reports: Array<ReportEntry>
        subjects: Subject[]
        progress_front_report_entry: FrontReportEntry
        midterm_front_report_entry: FrontReportEntry
        final_front_report_entry: FrontReportEntry
    }
    export const homeroom_reports = async (student_id: ObjectId, homeroom_id: ObjectId) => {
        return HTTPApi.post(
            "reports/homeroom_reports", {
                student_id: student_id,
                homeroom_id: homeroom_id
            }
        ).then((res) => {
            return res.data as HomeroomReports
        })
        .catch((e) => {
            console.log(e);
        })
    }

    export interface CourseReports {
        course: Course
        midterm_entry: ReportEntry
        final_entry: ReportEntry
    }
    export const course_reports = async (student_id: ObjectId, course_id: ObjectId) => {
        return HTTPApi.post(
            "reports/course_reports", {
                student_id: student_id,
                course_id: course_id
            }
        ).then((res) => {
            return res.data as CourseReports
        })
    }

    export const update_report_entry = async (report_entry: ReportEntry) => {
        return HTTPApi.post(
            "reports/update_report_entry", {
                report: report_entry,
            }
        )
    }

    export const update_front_report_entry = async (report_entry: FrontReportEntry) => {
        return HTTPApi.post(
            "reports/update_front_report_entry", {
                report: report_entry,
            }
        )
    }

    // Elementary Report Cards
    export const elementary_final_report_card_pdf = async (student_id: ObjectId, homeroom_id: ObjectId, name: string) => {
        return HTTPApi.post(
            "reports/elementary_final_report_card_pdf", {
                student_id: student_id,
                homeroom_id: homeroom_id
            }
        ).then((res) => {
            download(res.data, name+"_" + student_id.$oid + ".pdf")
        })
    }

     export const elementary_progress_report_card_pdf = async (student_id: ObjectId, homeroom_id: ObjectId, name: string) => {
        return HTTPApi.post(
            "reports/elementary_progress_report_card_pdf", {
                student_id: student_id,
                homeroom_id: homeroom_id
            }
        ).then((res) => {
            download(res.data, name+"_" + student_id.$oid + ".pdf")
        })
    }

    // Secondary Report Cards
    export const secondary_midterm_report_card_pdf = async (student_id: ObjectId, name: string) => {
        return HTTPApi.post(
            "reports/secondary_midterm_report_card_pdf", {
                student_id: student_id,
            }
        ).then((res) => {
            download(res.data, name+"_" + student_id.$oid + ".pdf")
        })
    }
}
