

export const processErrorMessage = (err: any): string => {
    if (err.response && err.response.data && typeof err.response.data === 'string') {
        const errorData = err.response.data;
        const backtraceIndex = errorData.indexOf('Backtrace:');

        // Truncate the message at "Backtrace:" if it exists
        return backtraceIndex !== -1
            ? errorData.substring(0, backtraceIndex).trim()
            : errorData;
    }

    if (err.message) {
        // Handle cases where `err.response.data` might not be a string
        return err.message;
    }

    return 'An unexpected error occurred (processErrorMessage):';
}