import { Button, Flex, HStack, Spacer, Stack, Switch } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react"
import { FiPlus } from "react-icons/fi"
import { HomeroomAPI } from "../../api/HomeroomAPI";
import LocalStorageService from "../../api/LocalStorageService"
import { UserRole } from "../../api/UserApi"
import { useChipCell } from "../../hooks/useChipCell"
import { useTeacherCell } from "../../hooks/useTeacherCell"
import { HomeroomObj } from "../../models/homeroom"
import { Lookup } from "../../models/lookup"
import { Semester } from "../../models/semester"
import { ObjectId } from "../../utils/ObjectId"
import SearchInput from "../forms/SearchInput";
import { TableContent } from "../table/TableContent";
import { TablePagination } from "../table/TablePagination";
import { CreateHomeroomDialog } from "./CreateHomeroomDialog"

interface Card {
	teachers: Lookup[]
	semesters: Lookup[]
}

export const HomeroomList = ({teachers, semesters } : Card) => {
	const [is_initialized, set_is_initialized] = useState<boolean>(false); // Tracks if the localStorage fetch has completed
	
	const user = LocalStorageService.getInstance().getUser();
    const canAccessAllHomeroom = user?.has_role(UserRole.Can_Access_All_Homeroom);

    const [show_all_homerooms, set_show_all_homerooms] = useState(false)
    const [show_archived, set_show_archived] = useState(false)

	const [isOpen_homeroom, setIsOpen_homeroom] = useState(false)
    const onClose_homeroom = () => setIsOpen_homeroom(false)

	const PER_PAGE = 15

	const [homerooms_data, set_homerooms_data] = useState<HomeroomObj[]>();
    const [homerooms_page, set_homerooms_page] = useState<number>(1);
    const [homerooms_max_page, set_homerooms_max_page] = useState<number>();
    const [total_homerooms, set_total_homerooms] = useState<number>();
    const [homerooms_name_search, set_homerooms_name_search] = useState<string>();
    const [loading, setLoading] = useState<boolean>(true);

	const columns = useMemo(() => [
		{
			Header: "Name",
			accessor: "name",
		},
		{
			Header: "Teacher Name",
			accessor: "teachers",
			Cell: (data: ObjectId[]) => useTeacherCell(data, teachers)
		},
		{
			Header: "Semester",
			accessor: "semester",
			Cell: (data: Semester) => useChipCell(data.name, 'blue')
		},
		{                
			Header: "Grade",
			accessor: "grade",
			Cell: (data: string) => useChipCell(data, 'purple')
		}
	], [teachers])

	const on_show_all_homerooms_change = useCallback((v: boolean) => {
        LocalStorageService.getInstance().set_show_all_classes(v)
        if(canAccessAllHomeroom){
            set_show_all_homerooms(v)
        }
    }, [canAccessAllHomeroom])

	useEffect(() => {
        if(canAccessAllHomeroom) {
            on_show_all_homerooms_change(LocalStorageService.getInstance().get_show_all_classes())
        }

		set_is_initialized(true);

    }, [canAccessAllHomeroom, on_show_all_homerooms_change])

	// Watch for search term change
	useEffect(() => {
		set_homerooms_page(1); // Reset to first page on search term change
	}, [homerooms_name_search]);

	useEffect(() => {
		// Only run this effect if the initialization has completed
		if (!is_initialized) return;

		const teacherParam = !show_all_homerooms ? user!._id : undefined

		setLoading(true);

		HomeroomAPI.homeroom_list(PER_PAGE, homerooms_page, homerooms_name_search, show_archived, teacherParam)
			.then((res) => {
				let homerooms = res.homerooms.map((homeroom) => ({
					...homeroom,
					link: "/class/homerooms/" + homeroom._id.$oid
				}))

				set_homerooms_data(homerooms)
				set_total_homerooms(res.homerooms_count)
				set_homerooms_max_page(Math.ceil((res.homerooms_count || 0 ) / 15))
		}).finally(() => {
			setLoading(false);
		})	

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [homerooms_name_search, homerooms_page, show_all_homerooms, show_archived, is_initialized]);

	

	return (
		<>
			<HStack mb={4}>
				<Flex direction={{ base: 'column', md: 'row' }} gap="2">
					{ canAccessAllHomeroom &&
						<Switch isChecked={show_all_homerooms} onChange={(e) => on_show_all_homerooms_change(e.target.checked)}>Show All Homerooms</Switch>
					}

					<Switch isChecked={show_archived} onChange={(e) => set_show_archived(e.target.checked)}>Show Archived</Switch>
				</Flex>
			<Spacer />

			{ user?.has_role(UserRole.Can_Create_Homeroom) &&
			<>
				<Button 
					colorScheme='green'
					leftIcon={<FiPlus/>}
					onClick={() => setIsOpen_homeroom(true)}
				>
					Add Homeroom
				</Button>

				<CreateHomeroomDialog 
					isOpen={isOpen_homeroom} onClose={onClose_homeroom} semesters={semesters} teachers={teachers}/>
			</>
			}
			</HStack>

			<Stack spacing="4" direction={{ base: 'column', md: 'row' }} justify="space-between">
				<HStack>
					<SearchInput onChange={set_homerooms_name_search} />
				</HStack>
			</Stack>

			<TableContent loading={loading} data={homerooms_data} columns={columns} />

			<TablePagination text={"Showing " + PER_PAGE*homerooms_page + " of " + total_homerooms} set_page={set_homerooms_page} page={homerooms_page} max_page={homerooms_max_page!}/>
		</>
	)
}
