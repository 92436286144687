import { Button, ButtonGroup, Flex, Text, useColorModeValue as mode } from '@chakra-ui/react'
import * as React from 'react'
interface UserProps {
  page: number
  max_page: number
  set_page: any
  text: string
}

export const TablePagination = ({page, max_page, set_page, text}: UserProps) => {
  const prev = () =>{
    if(page != 1){
      set_page(page-1)
    }
  }
  const next = () =>{
    if(page != max_page){
      set_page(page+1)
    }
  }

  return (
    <Flex align="center" justify="space-between">
      <Text color={mode('gray.600', 'gray.400')} fontSize="sm">
        {text}
      </Text>
      <ButtonGroup variant="outline" size="sm">
        <Button as="a" rel="prev" onClick={prev}>
          Previous
        </Button>
        <Button as="a" rel="next" onClick={next}>
          Next
        </Button>
      </ButtonGroup>
    </Flex>
  )
}
