import React from 'react';
import { FormControl, FormLabel, Input, FormErrorMessage } from '@chakra-ui/react';
import { UseFormRegisterReturn } from 'react-hook-form';
import './styles/form-input.css';

interface FormInputProps {
    name: string;
    label: string;
    register: UseFormRegisterReturn;
    error?: string;
    isDisabled?: boolean;
    isRequired?: boolean;
    isHeader?: boolean;
}

const FormInput: React.FC<FormInputProps> = ({ name, label, register, error, isDisabled = false, isRequired= false, isHeader =  false }) => {
    return (
        <FormControl isInvalid={!!error}>
            {label && (
                <FormLabel htmlFor={name} className={isRequired ? 'required' : ''}>
                    {label}
                </FormLabel>
            )}

            <Input
				id="name"
                {...register}  // Spread the register object directly into the Input
                isDisabled={isDisabled}
                fontWeight={isHeader ? 'bold' : 'normal'}
            />
            <FormErrorMessage>
                {error}
            </FormErrorMessage>
        </FormControl>
    );
};

export default FormInput;