import React from "react"
import {
    Box,
    Grid,
    Center,
    Heading,
    Badge,
    Table,
    TableCaption,
    Thead,
    Tr,
    Th,
    Td,
    Tbody,
    Tfoot, TabPanels, TabPanel, GridItem, Tabs, HStack, Breadcrumb, BreadcrumbItem, BreadcrumbLink, VStack, Spacer, Stack, Text, Container, LinkOverlay, LinkBox, useColorModeValue, Button, Checkbox, useToast, Alert, AlertIcon

}
    from '@chakra-ui/react'
import {useLocation, useNavigate, useParams} from "react-router-dom";
import { TableContent } from "../table/TableContent";
import { Document, DocumentType, FormDataInterface, FormDataStatus, Student } from "../../models/student";
import { StudentAPI } from "../../api/StudentAPI";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { CheckboxCard, CheckboxCardGroup } from "../3rd_party/CheckboxCardGroup";
import { Payments } from "../payments/Payments";
import { Item } from "../../models/transaction";
import SignatureCanvas from 'react-signature-canvas'
import SignaturePad from "signature_pad";
import ReactSignatureCanvas from "react-signature-canvas";
import { DateTime } from "../../utils/DateTime";
import { ObjectId, to_oid } from "../../utils/ObjectId";
import { PaymentAPI } from "../../api/PaymentAPI";
import { BrowserView, MobileView } from "react-device-detect";
import LocalStorageService from "../../api/LocalStorageService";
//import {Student} from "../../models/student";

interface Card {
    student_id: ObjectId
    FORM_NAME: string
    show_uniform: boolean
}


export const StudentForms = ({student_id, FORM_NAME, show_uniform}: Card) => {    
    const toast = useToast()

    const FORMS: Array<keyof FormDataInterface> = ["documents", "profile", "financial", "health", "family", "office_information","donation" ,"consent", "uniform"]

    const [student, set_student] = React.useState<Student>()
    const [data, set_data] = React.useState<Array<StudentFormColumns>>()
    const [documents_data, set_documents_data] = React.useState<Array<StudentFormColumns>>()
    const [form_submitted, set_form_submitted] = React.useState<boolean>(false)
    const [forms_filled, set_forms_filled] = React.useState<boolean>(false)
    const [items, set_items] = React.useState<Array<Item>>()
    const [paid, set_paid] = React.useState<boolean>(false)

    const [agree_1, set_agree_1] = React.useState<boolean>(false)
    const [agree_2, set_agree_2] = React.useState<boolean>(false)

    const color = useColorModeValue('sm', 'sm-dark')

    let sigPad: ReactSignatureCanvas | null = null;

    const _form_submitted = () => {
        let res = student!.forms.filter((form) => form.form_name == FORM_NAME).length >= 1
        set_form_submitted(res)
        return res
    }

    const _forms_filled = () => {
        let res = Object.values(student!.form_data).every((item) => item.status != FormDataStatus.None)
        console.log(res)
        set_forms_filled(res)
        return res
    }

    const get_checked_data = () => {
        return data!
            .filter(e => e.status == FormDataStatus.Approved || e.status == FormDataStatus.Waiting || e.status == FormDataStatus.Submitted)
            .map(e => e.form_name).concat(documents_data!
            .filter(e => e.status == FormDataStatus.Approved || e.status == FormDataStatus.Waiting || e.status == FormDataStatus.Submitted)
            .map(e => e.form_name))
    }

    const _payment_items = () => {
        if(student){
            let uniform: Item[] = []
            if(show_uniform) {
                uniform = [
                    {
                        "name": student.uniform.day_quantity + "x Day Uniform",
                        "price": student.uniform.day_quantity * 150
                    },
                    {
                        "name": student.uniform.gym_quantity + "x Gym Uniform",
                        "price": student.uniform.gym_quantity * 150
                    }
                ]
            }

            if(!FORM_NAME.includes("Re-Registration")){
                if(["pk", "jk", "sk"].indexOf(student.profile.grade) > -1) {
                    set_items(
                        [
                            ...uniform,
                            {
                                "name": "Technology Fee",
                                "price": 150
                            }
                        ]
                    )
                }
                else {
                    set_items([
                        ...uniform,
                        {
                            "name": "Technology Fee",
                            "price": 150
                        }
                    ])
                }
            }
            else {
                set_items(
                    [
                        ...uniform,
                        {
                            "name": "Re-Registration Fee",
                            "price": 150
                        }
                    ]
                )
            }
            
            
        }
    }

    const submit = async() => {
        if(_forms_filled() && agree_1 && agree_2 && !sigPad!.isEmpty()){
            const blob = await (await fetch(sigPad!.toDataURL('image/png'))).blob();
            const file = new File([blob], 'sig.png', {type:"image/png", lastModified:new Date().getUTCDate()})

            StudentAPI.upload_document(student!._id, FORM_NAME + "signature", DocumentType.Signature, file)
                .then((res) => {
                    let document = res.data as Document
                    document.date = new DateTime(document.date)
                    StudentAPI.get_student(student!._id.$oid).then((res) => {
                        let s = res!
                        set_student(s)

                        let form = {
                            form_name: FORM_NAME,
                            data: s,
                            signature: {
                                document_id: document._id,
                                signed_by: LocalStorageService.getInstance().getUser()!._id,
                            },
                        }

                        StudentAPI.submit_form(student!._id, form, FORMS)
                            .then((res) => {
                                StudentAPI.get_student(student!._id.$oid).then((_res) => {
                                    set_student(_res!)
                                })
                            })
                            .catch((res) => console.log(res))

                        
                    })
                    
                })

        }
        else{
            if(sigPad!.isEmpty()){
                toast({
                    title: 'Error.',
                    description: "Please sign.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            }
            else {
                toast({
                    title: 'Error.',
                    description: "Fill in the required information.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            }
            
        }
    }

    React.useEffect(()=>{
        if(student) {
            
            _form_submitted()
            _forms_filled()
            _payment_items()

            if(_form_submitted()){
                PaymentAPI.get_paid_transaction(student._id, FORM_NAME)
                .then((res) => set_paid(true))
                .catch((res) => set_paid(false))
            }

            let forms = [
                {
                    form_name: "Student Information",
                    description: "Basic student information",
                    status: student.form_data.profile.status,
                    link: "forms/profile/" + student._id.$oid,
                },
                {
                    form_name: "Student Health",
                    description: "Student's health, doctor's and medication information",
                    status: student.form_data.health.status,
                    link: "forms/health/" + student._id.$oid,
                },
                {
                    form_name: "Student Family",
                    description: "Guardians, household and emergency contact information",
                    status: student.form_data.family.status,
                    link: "forms/family/" + student._id.$oid,
                },
                {
                    form_name: "Historicial Information",
                    description: "Information regarding the student's status in Canada & other schools",
                    status: student.form_data.office_information.status,
                    link: "forms/office_information/" + student._id.$oid,
                },
                
            ]

            set_data(forms)

            let document_forms = [
                {
                    form_name: "Student Documents",
                    description: "Required documents for the student such as proof of address, citizenship, etc...",
                    status: student.form_data.documents.status,
                    link: "forms/documents/" + student._id.$oid,
                },
                {
                    form_name: "Financial Information",
                    description: "Banking information for tuition payments",
                    status: student.form_data.financial.status,
                    link: "forms/financial/" + student._id.$oid,
                },
                {
                    form_name: "Donation",
                    description: "Help support the School",
                    status: student.form_data.donation.status,
                    link: "forms/donation/" + student._id.$oid,
                },
                {
                    form_name: "Consent Forms",
                    description: "Consent forms for things such as media & trips",
                    status: student.form_data.consent.status,
                    link: "forms/consent/" + student._id.$oid,
                },
            ]

            if(show_uniform){
                document_forms = [...document_forms, 
                    {
                        form_name: "Uniform",
                        description: "Uniform sizes and quantity",
                        status: student.form_data.uniform.status,
                        link: "forms/uniform/" + student._id.$oid,
                    }
                ]
            }

            set_documents_data(document_forms)
        }
    }, [student])

    React.useEffect(()=>{
        StudentAPI.get_student(student_id.$oid).then((res) => {
            let s = res!
            set_student(s)
        })
    }, [])

    const signature = (
        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
            width={325}
            height={225}
        >
            <Center>
                <Text>Sign Here:</Text>
                <Spacer/>
                <Button onClick={() => sigPad!.clear()}>Clear</Button>
            </Center>
            <Center>
                <SignatureCanvas penColor='black' backgroundColor={'rgba(211,211,211,5)'} canvasProps={{width: 300, height: 150,className: 'sigCanvas'}} ref={(ref) => sigPad = ref}/>
            </Center>
        </Box>
    )

    const forms = (
        <>
        {data && documents_data &&
            <VStack>
                <Heading size="sm">Forms</Heading>
                <Box
                as="form"
                bg="bg-surface"
                boxShadow={color}
                borderRadius="lg"
                flex="1"
                >
                    {check_boxes(data!, get_checked_data())}
                </Box>
            </VStack>
        }
        </>
    )

    const documents = (
        <>
        {data && documents_data &&
            <VStack>
                <Heading size="sm">Documents</Heading>
                <Box
                as="form"
                bg="bg-surface"
                boxShadow={color}
                borderRadius="lg"
                flex="1"
                >
                    {check_boxes(documents_data!, get_checked_data())}
                </Box>
            </VStack>
        }
        </> 
    )

    const submit_form = (
                <Center>
                    <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                        <VStack>
                            <Text>Please open & read the policies below</Text>
                            <Checkbox colorScheme='green' size="md" isChecked={agree_1} onChange={(e) => set_agree_1(e.target.checked)}>I agree to the terms and conditions outlined in <a href="/ab_coc.pdf" target="_blank" style={{ color: 'blue' }}>the School Handbook</a></Checkbox>
                            <Checkbox colorScheme='green' size="md" isChecked={agree_2} onChange={(e) => set_agree_2(e.target.checked)}>I acknowledge that all the information I have entered are correct</Checkbox>

                            {signature}
                            {forms_filled ?
                                <Text fontSize="sm">After you submit, you will be directed to the payment page</Text>
                                :
                                <Text fontSize="sm" color="red.500">You must fill in all the forms before submitting</Text>
                            }
                            
                            <Button colorScheme="green" isDisabled={!forms_filled} onClick={submit}>Submit</Button>
                        </VStack> 
                    </Stack>
                </Center>
    )

    const payment_form = (
        <>
        <Alert status='warning'>
            <AlertIcon></AlertIcon>
            <Center>Pay Now</Center>
        </Alert>
        {student &&
            <Payments user_id={student!._id} description={FORM_NAME} items={items!}/>
        }
        
        </>
    )

    const paid_form = (
        <Center>
            <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                <VStack spacing={5}>
                    <Text fontSize="2xl" color="green" fontWeight="bold">Thank you.</Text>
                    <Text fontSize="lg">The {FORM_NAME} is complete</Text>
                    <Text fontSize="md">In the upcoming days, the student's form will be verified and approved.</Text>
                    <Text fontSize="md">We may ask you to come back here to fill any missing or un-verifiable information.</Text>
                    <br></br>
                    <Text fontSize="md">Feel free to reach out to the office if you have any questions</Text>
                    
                </VStack> 
            </Stack>
        </Center>
)

    const finalize = (
        <VStack>
            <Heading size="sm">Finalize Documents</Heading>
            <Box
            as="form"
            bg="bg-surface"
            boxShadow={color}
            borderRadius="lg"
            flex="1"
            >    
                {!form_submitted ?
                    submit_form
                    :
                    !paid ?
                        payment_form
                        :
                        paid_form
                }
            </Box>
        </VStack>
    )

    return (
            <Box>
                <Box overflowX="auto" overflowY="hidden">
                    <Center>
                        <Heading size="lg" mb="6">
                            Student Forms
                        </Heading>
                    </Center>
                    <Center>
                        <Text fontSize={"xl"}>Form: {FORM_NAME}</Text>
                    </Center>
                    <Stack>
                        <Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
                            <BreadcrumbItem>
                                <BreadcrumbLink href='/parent/home'>Home</BreadcrumbLink>
                            </BreadcrumbItem>

                            <BreadcrumbItem>
                                <BreadcrumbLink href='/parent/family'>Family</BreadcrumbLink>
                            </BreadcrumbItem>

                            <BreadcrumbItem isCurrentPage>
                                {student&& <BreadcrumbLink href='#'>{student?.profile.first_name + " " + student?.profile.last_name}</BreadcrumbLink>}
                            </BreadcrumbItem>
                        </Breadcrumb>
                    
         
                    </Stack>
                    <br></br>
                                
                    <br></br>
                    {data && documents_data &&
                    <>
                    <BrowserView>
                        <Box
                        as="form"
                        bg="bg-surface"
                        boxShadow={color}
                        borderRadius="lg"
                        flex="1"
                        >
                            <HStack>
                                <Spacer/>
                                {forms}
                                <Spacer/>
                                {documents}
                                <Spacer/>
                                {finalize}
                                <Spacer/>
                            </HStack>
                        </Box>
                    </BrowserView>
                    <MobileView>
                        <VStack>
                            {forms}
                            <br/><br/>
                            {documents}
                            <br/><br/>
                            {finalize}
                        </VStack>
                    </MobileView>
                    </>  
                    }
                    <br></br>
                                        
                </Box>
            </Box>
    );
}


const check_boxes = (data: Array<StudentFormColumns>, checked_data: Array<string>) => {
    return (
            <VStack>
                <Box as="section" py={{ base: '4', md: '8' }}>
                    <Container maxW="lg">
                        <CheckboxCardGroup defaultValue={checked_data} spacing="3">
                            {data.map((e) => (
                            <CheckboxCard key={e.form_name} value={e.form_name} link={e.link}>
                                <HStack>
                                    <Text color="emphasized" fontWeight="medium" fontSize="sm">
                                    {e.form_name}
                                    </Text>
                                    <Spacer/>
                                    <Badge fontSize="xs" colorScheme={badgeEnum[e.status]}>
                                        {badgeEnumName[e.status]}
                                    </Badge>
                                </HStack>
                                
                                <Text color="muted" fontSize="sm">
                                {e.description}
                                </Text>

                            </CheckboxCard>
                            ))}
                        </CheckboxCardGroup>
                    </Container>
                </Box>
            </VStack>
    )
}
const badgeEnum: Record<string, string> = {
    None: "red",
    Waiting: "orange",
    NeedsChange: "red",
    Approved: "green",
    Submitted: "green"
}

const badgeEnumName: Record<string, string> = {
    None: "Needs to be Done",
    Waiting: "Filled",
    NeedsChange: "Admin Requested Changes",
    Approved: "Approved",
    Submitted: "Submitted"
}


const StudentFormColumns = [
    {
        Header: "Form Name",
        accessor: "form_name",
    },
    {
        Header: "Form Status",
        accessor: "status",
        Cell: function StatusCell(data: any) {
            return (
                <Badge fontSize="xs" colorScheme={badgeEnum[data]}>
                    {badgeEnumName[data]}
                </Badge>
            )
        },
    }
]

interface StudentFormColumns{
    form_name: string,
    description: string,
    status: string,
    link: string
}
