import React from "react";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import { Navigate, NavLink, Route, Routes, useLocation } from "react-router-dom";

interface TabRoute {
    label: string;
    path: string;
    element: React.ReactNode;
}

interface TabRoutesProps {
    tabs: TabRoute[];
    defaultTabIndex?: number;
}

export const TabRoutes: React.FC<TabRoutesProps> = ({ tabs, defaultTabIndex = 0 }) => {
    const location = useLocation();

    // Determine the active tab based on the current path
    const activeIndex = tabs.findIndex(tab => location.pathname.includes(tab.path)) ?? defaultTabIndex;

    return (
        <>
        <Tabs index={activeIndex}>
            <TabList color={"blue.600"}>
                {tabs.map((tab, index) => (
                    <Tab key={index} as={NavLink} to={tab.path}>
                        {tab.label}
                    </Tab>
                ))}
            </TabList>

            
            <TabPanels>
                {tabs.map((tab, index) => (
                    <TabPanel key={index}>
                        {tab.element}
                    </TabPanel>
                ))}
            </TabPanels>
        </Tabs>

        <Routes>
            <Route path="/" element={<Navigate to={tabs[defaultTabIndex].path} />} />
        </Routes>
        </>
    );
};
