import {Box, Button, IconButton, Stack} from "@chakra-ui/react";
import {new_oid, ObjectId, to_oid} from "../../../utils/ObjectId";
import React, {useEffect, useState} from "react";
import {MarkCategoryTable} from "./MarkCategoryTable";
import {FiPlus} from "react-icons/fi";
import {useParams} from "react-router-dom";
import {MarkCategoriesAPI} from "../../../api/MarkCategoryAPI";
import {MarkCategory} from "../../../models/mark_category";
import {Evaluation} from "../../../models/evaluation";
import {EvaluationAPI} from "../../../api/EvaluationAPI";


interface Props{
    class_id: ObjectId
}

interface MarkCategoryObj {
    mark_category_obj: MarkCategory,
    run1: any
}

interface MarkCategoryTableObj {
    data: MarkCategoryObj,
}

export const MarkBookCategories = React.memo(({class_id}: Props) => {
    const [mark_categories_data, set_mark_categories_data] = useState<Array<MarkCategoryTableObj>>([]);

    const create_new_mark_category = () => {
        let mark_category = {
            _id: new_oid(),
            class_id: class_id,
            name: "",
            weight: 0,
        }
        MarkCategoriesAPI.create_mark_category(mark_category).then(() => {
            get_mark_categories()
        })
    }

    const get_mark_categories = () => {

        MarkCategoriesAPI.get_mark_category_list_from_class(class_id)
            .then((res) => {
                let data = res!;
                let entry = data.map((mark_category) => {
                    return {
                        data: {
                            mark_category_obj: mark_category,
                            run1: get_mark_categories_fun,
                        }
                    }
                })
                set_mark_categories_data([])
                set_mark_categories_data(entry)
                get_total_weight(entry)
        })
    }

    const get_mark_categories_fun = () => {

        MarkCategoriesAPI.get_mark_category_list_from_class(class_id)
            .then((res) => {
                let data = res!;
                let entry = data.map((mark_category) => {
                    return {
                        data: {
                            mark_category_obj: mark_category,
                            run1: get_mark_categories,
                        }
                    }
                })
                set_mark_categories_data([])
                set_mark_categories_data(entry)
                get_total_weight(entry)
        })
    }

    const update_mark_categories = () => {
        mark_categories_data.forEach(function (entry) {
                MarkCategoriesAPI.update_mark_category(entry.data.mark_category_obj)
                    .then()
                    .catch((res) => {
                        console.log(res)
                    })
                    .finally(() => {
                        get_mark_categories()
                    })
            }

        )}

    const [total_weight, set_total_weight] = useState<number>();

    const get_total_weight = (mark_categories: Array<MarkCategoryTableObj>) => {
        set_total_weight(mark_categories.reduce((total, entry) =>  total = total + entry.data.mark_category_obj.weight , 0))
    }

    useEffect(() => {
        get_mark_categories()
    }, []);

    return(
        <>
            <Box>
                <Stack>
                    <>
                        <MarkCategoryTable entry_data={mark_categories_data}
                                           total_weight={total_weight!}
                                           update_func={update_mark_categories}/>
                    </>
                    <>
                        <IconButton aria-label={"Add Mark Category"} colorScheme='green' icon={<FiPlus/>} onClick={create_new_mark_category}/>
                    </>
                </Stack>
            </Box>
        </>
    )
})
