import * as React from "react"
import { useState } from "react";
import {
    Center,
    Heading,
    Grid,
    GridItem,
    VStack,
    Link,
    Text,
    Stack
}
from '@chakra-ui/react'
import { Card } from "../3rd_party/Card";
import { CardContent } from "../3rd_party/CardContent";
import { CardHeader } from "../3rd_party/CardHeader";
import { Property } from "../3rd_party/Property";
import { Calendar, momentLocalizer, Views, Event } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { LabeledProperty } from "../3rd_party/LabeledProperty";
import {BrowserView, MobileView} from 'react-device-detect';
import LocalStorageService from "../../api/LocalStorageService";


const localizer = momentLocalizer(moment)

export const HomeParent: React.FC = (props) =>  {

    const [events, setEvents] = useState<Event[]>([
        {
          title: 'Learn cool stuff',

        },
      ])
    
    const important_links = (
        <Card maxW="3xl" mx="auto">
            <CardHeader title="Important Links"/>
            <CardContent>
                <LabeledProperty label="How To Fill The Forms" value={<Link href='https://youtu.be/lSuTtM6BF2w' isExternal> Click Here</Link>}/>
                {/* <LabeledProperty label="Student Reporting Tool" value={<Link href='https://forms.gle/5Tkuvofy3LdeX7o57' isExternal> Click Here</Link>}/>
                <LabeledProperty label="Software Wiki/How's" value={<Link href='https://wiki.bassemlabs.com' isExternal> Click Here</Link>}/>
                <LabeledProperty label="Software Bug Reports" value={<Link href='https://forms.gle/Vuis7ZNHX1xGzdsF8' isExternal> Click Here</Link>}/> */}
            </CardContent>
        </Card>
    )

    const announcements = (
        <Card maxW="3xl" mx="auto">
            <CardHeader title="Announcements"/>
            <CardContent>
                <Property label="Feb 28, 2022" value="Starting Wednesday March 2nd, parents will receive attendance notifications" />
                <Property label="Feb 25, 2022" value="Initial software's web based application release" />
            </CardContent>
        </Card>
    )

    const calendar = (
        <div>
        <Calendar
            events={events}
            views={allViews}
            step={60}
            showMultiDayTimes
            components={{
                timeSlotWrapper: ColoredDateCellWrapper,
            }}
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
            style={{ height: 500 }}
        />
        </div>
    )

    return (
        <VStack>
            <Heading as="h1" size="md">Hello {LocalStorageService.getInstance().getUser()?.full_name}!</Heading>
            <Text fontSize="xl">Date: {new Date().toDateString()}</Text>
            <Center>
                <BrowserView>
                    <Grid
                    templateColumns='repeat(2, 1fr)'
                    gap={4}
                    >
                        <GridItem>
                            {important_links}
                        </GridItem>
                        <GridItem>
                            {announcements}
                        </GridItem>
                        {/* <GridItem>
                        
                        </GridItem>
                        <GridItem>
                            {calendar}
                        </GridItem> */}
                    </Grid>
                </BrowserView>
                <MobileView>
                    <Stack>
                    {important_links}
                    {announcements}
                    {calendar}
                    </Stack>
                </MobileView>

            </Center>
        </VStack>

    );
}

// @ts-expect-error
let allViews = Object.keys(Views).map(k => Views[k])

const ColoredDateCellWrapper = ({ children }: any) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: 'lightblue',
    },
  })
