import { Box, Flex, FlexProps, useColorModeValue, VStack } from '@chakra-ui/react'
import * as React from 'react'

interface Props extends FlexProps {
  label: string
  value: string
}

export const Property = (props: Props) => {
  const { label, value, ...flexProps } = props
  return (
    <Flex
      as="dl"
      direction={{ base: 'column', sm: 'row' }}
      px="6"
      py="4"
      _even={{ bg: useColorModeValue('gray.50', 'gray.600') }}
      {...flexProps}
    >
      <VStack spacing={4} align='stretch'>
        <Box as="dd" flex="1">{label}</Box>
        <Box as="dd" flex="1" fontWeight="semibold">{value}</Box>
      </VStack>
      
    </Flex>
  )
}
