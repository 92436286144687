import React from 'react';
import { Stack, Icon, Text } from '@chakra-ui/react';
import { FaInbox } from 'react-icons/fa';

const EmptyPlaceholder = ({ message = "No items to show", icon = FaInbox, iconSize = 126, iconColor = "gray.200", title = "" }) => {
    return (
        <Stack justifyContent="center" alignItems="center" spacing={4}>
            <Icon as={icon} boxSize={iconSize} color={iconColor} />

            {
                title && 
                <Text fontSize={'2xl'} fontWeight="bold" align="center">
                    {title}
                </Text>
            }

            <Text align="center">
                {message}
            </Text>
        </Stack>
    );
};

export default EmptyPlaceholder;
