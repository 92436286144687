import React from 'react';
import { Box, Text, HStack, Icon, Spacer, SimpleGrid } from '@chakra-ui/react';
import {
    HiOutlineCheck,
    HiOutlineClock,
    HiOutlineDocument,
    HiOutlineExclamationCircle,
} from 'react-icons/hi';
import {  SubmissionsWithGClassWork } from '../../models/gclass';
import { useSubmissionCounts } from '../../hooks/useSubmissionsCount';

interface SubmissionStatsProps {
    submissions: SubmissionsWithGClassWork[];
}

const SubmissionStats: React.FC<SubmissionStatsProps> = ({ submissions }) => {
	const {
        notSubmittedCount,
        notSubmittedLateCount,
        submittedOnTimeCount,
        submittedLateCount
    } = useSubmissionCounts(submissions);

    return (
		<>
		<SimpleGrid columns={{ sm: 1, md: 2, lg: 4 }} spacing={4} mb={8}>
			<Box as={HStack} bgColor="blue.50" border='2px' borderColor='blue.500' borderStyle='dashed' rounded='lg' px={8} py={4}>
				<div>
					<Text fontWeight="black" fontSize={'3xl'} color="blue.500">{notSubmittedCount}</Text>

					<Text fontWeight="medium" textColor="gray.600">Not Submitted</Text>
				</div>

				<Spacer />

				<Icon as={HiOutlineDocument} boxSize={12} color="blue.500" />
			</Box>

			<Box as={HStack} bgColor="red.50" border='2px' borderColor='red.500' borderStyle='dashed' rounded='lg' px={8} py={4}>
				<div>
					<Text fontWeight="black" fontSize={'3xl'} color="red.500">{notSubmittedLateCount}</Text>

					<Text fontWeight="medium" textColor="gray.600">Not Submitted & Late</Text>
				</div>

				<Spacer />

				<Icon as={HiOutlineExclamationCircle} boxSize={12} color="red.500" />
			</Box>

			<Box as={HStack} bgColor="green.50" border='2px' borderColor='green.500' borderStyle='dashed' rounded='lg' px={8} py={4}>
				<div>
					<Text fontWeight="black" fontSize={'3xl'} color="green.500">{submittedOnTimeCount}</Text>

					<Text fontWeight="medium" textColor="gray.600">Submitted On Time</Text>
				</div>

				<Spacer />

				<Icon as={HiOutlineCheck} boxSize={12} color="green.500" />
			</Box>

			<Box as={HStack} bgColor="yellow.50" border='2px' borderColor='yellow.500' borderStyle='dashed' rounded='lg' px={8} py={4}>
				<div>
					<Text fontWeight="black" fontSize={'3xl'} color="yellow.500">{submittedLateCount}</Text>

					<Text fontWeight="medium" textColor="gray.600">Submitted Late</Text>
				</div>

				<Spacer />

				<Icon as={HiOutlineClock} boxSize={12} color="yellow.500" />
			</Box>
		</SimpleGrid>
		</>
    );
};

export default SubmissionStats;
