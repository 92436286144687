import {
Box,
Button,
Divider,
Flex,
FormControl,
FormLabel,
Input,
InputGroup,
InputLeftAddon,
Stack,
Textarea,
useColorModeValue,
useToast,
} from '@chakra-ui/react'
import * as React from 'react'
import 'react-datepicker/dist/react-datepicker.css';
import { StudentAPI } from '../../../api/StudentAPI';
import { Student } from '../../../models/student';

interface Card {
    student: Student
    set_student: React.Dispatch<React.SetStateAction<Student | undefined>>
}

export const HealthCard = React.memo(({student, set_student}: Card) => {
    const toast = useToast()

    const required_info = () => {
        return student.health.health_num != "" &&
        student.health.doc_num != "" &&
        student.health.doc_name != "" &&
        student.health.medical_conditions != "" &&
        student.health.diet != "" &&
        student.health.medications != "" &&
        student.health.learning_ability != "" &&
        student.health.allergies_reactions != "" &&
        student.health.allergies != ""
    }

    const update_health_state = (name: any, value: any) => {
        set_student(
            prevState => (prevState ? {
                ...prevState,
                health: { ...prevState.health, [name]: value}
            } : undefined)
        );
    }

    const update_health = () => {
        if(required_info()){
            StudentAPI.update_health(student._id, student.health)
                .then((res) => {
                    StudentAPI.get_student(student._id.$oid).then(res => set_student(res!))
                    toast({
                        title: 'Success',
                        description: "Saved Health Info",
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                        })
                })
                .catch((err) => {
                    console.log(err)
                    toast({
                        title: 'Error.',
                        description: "Failed to update health",
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                        })
                })
        }
        else {
            toast({
                title: 'Error.',
                description: "Please fill in all required fields.",
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }
    
    }

    return (
        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
        >
            <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    <FormControl id="health_num" isRequired>
                        <FormLabel>Health Card</FormLabel>
                        <Input 
                            value={student.health.health_num} 
                            onChange={(value) => {
                                update_health_state(value.target.id, value.target.value)
                            }}
                        />
                    </FormControl>
                    <FormControl id="doc_name" isRequired>
                        <FormLabel>Family Doctor's Name</FormLabel>
                        <Input 
                            value={student.health.doc_name} 
                            onChange={(value) => {
                                update_health_state(value.target.id, value.target.value)
                            }}
                        />
                    </FormControl>
                    <FormControl id="doc_num" isRequired>
                        <FormLabel>Family Doctor's Number</FormLabel>
                        <InputGroup>
                            <InputLeftAddon children='+1' />
                            <Input 
                                value={student.health.doc_num} 
                                onChange={(value) => {
                                    update_health_state(value.target.id, value.target.value)
                                }}
                            />
                        </InputGroup>
                    </FormControl>
                </Stack>
                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    <FormControl id="medical_conditions" isRequired>
                        <FormLabel>Medical Conditions</FormLabel>
                        <Textarea
                            value={student.health.medical_conditions}
                            onChange={(value) => {
                                update_health_state(value.target.id, value.target.value)
                            }}
                            placeholder='Please enter any medical conditions the student may have. Feel free to be as expressive as possible. If nothing please fill with (n/a)'
                        />
                    </FormControl>
                    <FormControl id="diet" isRequired>
                        <FormLabel>Dietary Restrictions</FormLabel>
                        <Textarea
                            value={student.health.diet}
                            onChange={(value) => {
                                update_health_state(value.target.id, value.target.value)
                            }}
                            placeholder='Please enter any dietary restrictions the student may have. Feel free to be as expressive as possible. If nothing please fill with (n/a)'
                        />
                    </FormControl>
                    <FormControl id="medications" isRequired>
                        <FormLabel>Medications</FormLabel>
                        <Textarea
                            value={student.health.medications}
                            onChange={(value) => {
                                update_health_state(value.target.id, value.target.value)
                            }}
                            placeholder='Please enter any medications the student may take. Feel free to be as expressive as possible. If nothing please fill with (n/a)'
                        />
                    </FormControl>
                </Stack>

                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    <FormControl id="learning_ability" isRequired>
                        <FormLabel>Learning Accommodations</FormLabel>
                        <Textarea
                            value={student.health.learning_ability}
                            onChange={(value) => {
                                update_health_state(value.target.id, value.target.value)
                            }}
                            placeholder='Please enter any learning accommodations the student may need. Feel free to be as expressive as possible. If nothing please fill with (n/a)'
                        />
                    </FormControl>
                    <FormControl id="allergies" isRequired>
                        <FormLabel>Allergies</FormLabel>
                        <Textarea
                            value={student.health.allergies}
                            onChange={(value) => {
                                update_health_state(value.target.id, value.target.value)
                            }}
                            placeholder='Please enter any allergies the student may have. Feel free to be as expressive as possible. If nothing please fill with (n/a)'
                        />
                    </FormControl>
                    <FormControl id="allergies_reactions" isRequired>
                        <FormLabel>Allergic Reactions</FormLabel>
                        <Textarea
                            value={student.health.allergies_reactions}
                            onChange={(value) => {
                                update_health_state(value.target.id, value.target.value)
                            }}
                            placeholder='Please enter any allergic reactions the student may have. Feel free to be as expressive as possible. If nothing please fill with (n/a)'
                        />
                    </FormControl>
                </Stack>     
            </Stack>
            <Divider />
            <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
                <Button onClick={update_health}>
                    Save
                </Button>
            </Flex>
        </Box>
    )

})
