import { Box, Button, Checkbox, Divider, Flex, FormControl, Heading, HStack, Input, PinInput, PinInputField,
    SimpleGrid, Skeleton, Spacer, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Textarea,
    useColorModeValue, useToast, VStack, toast } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { CourseAPI } from "../../api/CourseAPI";
import { ReportsAPI } from "../../api/ReportsAPI";
import { StudentAPI } from "../../api/StudentAPI";
import { Course } from "../../models/course";
import { FrontReportEntry } from "../../models/front_report_entry";
import { Homeroom } from "../../models/homeroom";
import { CheckBox, ReportEntry, ReportType } from "../../models/report_entry";
import { Student } from "../../models/student";
import { ObjectId, to_oid } from "../../utils/ObjectId";
import { FrontPageReport } from "./FrontPageReport";
import { HomeroomSubject } from "./HomeroomSubject";


export const CourseReportCard = () =>  {

    const toast = useToast();

    let { class_id, student_id } = useParams();

    let [student, set_student] = React.useState<Student>()
    let [course, set_course] = React.useState<Course>()

    let [midterm_report_entry, set_midterm_report_entry] = React.useState<ReportEntry>()
    let [final_report_entry, set_final_report_entry] = React.useState<ReportEntry>()

    let [pdf_downloading, set_pdf_downloading] = React.useState<boolean>(false)


    const get_report = () => {
        StudentAPI.get_student(student_id!).then((e) => set_student(e!))
        CourseAPI.get_course(to_oid(class_id!)).then((e) => set_course(e!))
        ReportsAPI.course_reports(to_oid(student_id!), to_oid(class_id!))
            .then((data) => {
                set_midterm_report_entry(data.midterm_entry)
                set_final_report_entry(data.final_entry)
            })
    }
    useEffect(() => {
        get_report()
    }, []);

    return (
        <>
        <Box >
            <HStack>
                <VStack>
                    <Text>Student: {student?.profile.first_name} {student?.profile.last_name}</Text>
                    <Text>OEN: {student?.profile.OEN}</Text>
                </VStack>
                <Spacer/>
                <Button
                    colorScheme="green"
                    isLoading={pdf_downloading}
                    onClick={() => {
                        if(student){
                            set_pdf_downloading(true)
                            ReportsAPI.secondary_midterm_report_card_pdf(to_oid(student_id!), student.profile.first_name + "_" + student.profile.last_name).then((res) => {
                                set_pdf_downloading(false)
                            })
                            .catch((err) => {
                                toast({
                                    title: 'Error.',
                                    description: "Failed to download PDF",
                                    status: 'error',
                                    duration: 5000,
                                    isClosable: true,
                                })
                                set_pdf_downloading(false)
                            })
                        }

                    }}
                >Download Midterm PDF</Button>
            </HStack>
            { course && midterm_report_entry && final_report_entry &&

                <Stack>
                    <Tabs isFitted>
                    <TabList>
                        <Tab>Midterm</Tab>
                        <Tab>Final</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <ReportCardEntry course={course} report_entry={midterm_report_entry}/>
                        </TabPanel>
                        <TabPanel>
                            <ReportCardEntry course={course} report_entry={final_report_entry}/>
                        </TabPanel>
                    </TabPanels>
                    </Tabs>
                </Stack>
            }

        </Box>
        </>
    )
}

interface Card {
    course: Course
    report_entry: ReportEntry
}
const ReportCardEntry = ({course, report_entry}: Card) => {
    const toast = useToast()


    let [mark, set_mark] = React.useState<string>("")
    let [Responsibility, set_Responsibility] = React.useState<string>("")
    let [Organization, set_Organization] = React.useState<string>("")
    let [Independent, set_Independent] = React.useState<string>("")
    let [Collaboration, set_Collaboration] = React.useState<string>("")
    let [Initiative, set_Initiative] = React.useState<string>("")
    let [Self, set_Self] = React.useState<string>("")

    let [check_boxes, set_check_boxes] = React.useState<Array<CheckBox>>(report_entry.standalone_checkboxes!)

    let [comment, _set_comment] = React.useState<string>(report_entry.comment)


    const handle_comment_change = (e: any) => {
        if(e.target.value.length <= course.report_card_comment_char_limit){
            _set_comment(e.target.value)
        }
    }

    const update_standalone_check_box = (state: boolean, check_box_id: ObjectId) => {
        let new_checkboxes = check_boxes!


        let check_box_index = new_checkboxes.findIndex((e) => e.check_box_id.$oid == check_box_id.$oid)

        new_checkboxes[check_box_index].state = state

        // Stupid way to fix updating checkbox in UI FIX THIS ASAP, but was pulling me hair while trying to fix it
        set_check_boxes([])
        setTimeout(function () {
            set_check_boxes(new_checkboxes)
        }, 0.01);

    }

    // Stupid work around to store everything inside report_entry.mark, instead of adding new keys to ReportEntry
    const set_marks = () => {
        if(report_entry.standalone_mark! != ""){
            let marks = report_entry.standalone_mark!.split("|$|#|")
            set_mark(marks[0])
            set_Responsibility(marks[1])
            set_Organization(marks[2])
            set_Independent(marks[3])
            set_Collaboration(marks[4])
            set_Initiative(marks[5])
            set_Self(marks[6])
        }
    }

    const encode_marks = () => {
        return mark + "|$|#|" + Responsibility + "|$|#|" + Organization + "|$|#|" + Independent + "|$|#|" + Collaboration + "|$|#|" + Initiative + "|$|#|" + Self
    }

    const update_report = () => {
        let new_entry = {
            ...report_entry,
            comment: comment,
            standalone_checkboxes: check_boxes,
            standalone_mark: encode_marks()
        }

        ReportsAPI.update_report_entry(new_entry).then((res) => {
            toast({
                title: 'Success',
                description: "Saved Entry",
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
        }).catch((err) => {
            console.log(err)
            toast({
                title: 'Error.',
                description: "Failed to update entry",
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        })
    }

    useEffect(() => {
        set_marks()
    }, []);

    return (
        <>
        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
        >
            <Heading px={{ base: '4', md: '4' }} fontSize="xx-large">{course.name}</Heading>
            <Stack direction="row" spacing={8}  px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                <Box shadow='md' borderWidth='1px' px={{ base: '4', md: '2' }} py={{ base: '5', md: '2' }} borderRadius="lg" overflow="auto" flex="1">

                <Stack>
                    <Text>Mark: </Text>
                    <Input
                        placeholder='Mark'
                        value={mark}
                        onChange={(e) => set_mark(e.target.value)}
                    />
                    <br/>
                    <Text fontSize='md'>Learning Skills: </Text>
                    <HStack>
                        <Text fontSize='sm'>Responsibility: </Text>
                        <Input
                            placeholder='Mark'
                            value={Responsibility}
                            onChange={(e) => set_Responsibility(e.target.value)}
                        />
                        <Text fontSize='sm'>Organization: </Text>
                        <Input
                            placeholder='Mark'
                            value={Organization}
                            onChange={(e) => set_Organization(e.target.value)}
                        />
                    </HStack>
                    <HStack>
                        <Text fontSize='sm'>Independent Work: </Text>
                        <Input
                            placeholder='Mark'
                            value={Independent}
                            onChange={(e) => set_Independent(e.target.value)}
                        />
                        <Text fontSize='sm'>Collaboration: </Text>
                        <Input
                            placeholder='Mark'
                            value={Collaboration}
                            onChange={(e) => set_Collaboration(e.target.value)}
                        />
                    </HStack>
                    <HStack>
                        <Text fontSize='sm'>Initiative: </Text>
                        <Input
                            placeholder='Mark'
                            value={Initiative}
                            onChange={(e) => set_Initiative(e.target.value)}
                        />
                        <Text fontSize='sm'>Self-Regulation: </Text>
                        <Input
                            placeholder='Mark'
                            value={Self}
                            onChange={(e) => set_Self(e.target.value)}
                        />
                    </HStack>
                    <br></br>
                    <Text>Checkboxes: </Text>
                    <SimpleGrid columns={2} spacing={5}>
                        {check_boxes?.map((check_box) => {
                            let course_check_box = course.report_check_boxes.find((i) => i._id.$oid == check_box.check_box_id.$oid)
                            // if(!state_check_box){
                            //     return (
                            //         <FormControl id="last_name">
                            //             <Checkbox
                            //             disabled={true}
                            //             colorScheme="green"
                            //             >{check_box.name}</Checkbox>
                            //         </FormControl>
                            //     )
                            // }
                            return (
                                <FormControl id="last_name">
                                    <Checkbox
                                        isChecked={check_box.state}
                                        colorScheme="green"
                                        onChange={(e) => update_standalone_check_box(e.target.checked, check_box.check_box_id)}
                                        >{course_check_box!.name}</Checkbox>
                                </FormControl>
                            )
                        })}
                    </SimpleGrid>
                </Stack>

                </Box>
                <Box shadow='md' borderWidth='1px' flex='3' px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }} borderRadius="lg">
                    <Stack>
                        <Text mb='8px'>Comment: </Text>
                        <Textarea
                            value={comment}
                            onChange={handle_comment_change}
                            placeholder='Write the comments here...'
                            size='sm'
                        />
                        <HStack>
                            <Spacer/>
                            <Text fontSize="small">Character Limit {comment.length}/{course.report_card_comment_char_limit}</Text>
                        </HStack>
                    </Stack>


                </Box>
            </Stack>
            <Divider />
            <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
                <Button colorScheme="green" onClick={update_report}>
                    Save
                </Button>
            </Flex>
        </Box>
        </>
    )
}
