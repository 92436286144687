import {
Box,
Button,
Divider,
Flex,
FormControl,
FormLabel,
Input,
Stack,
Select,
useColorModeValue,
useToast,
Badge,
AlertDialog,
AlertDialogBody,
AlertDialogFooter,
AlertDialogHeader,
AlertDialogContent,
AlertDialogOverlay,
Skeleton,
} from '@chakra-ui/react'
import * as React from 'react'
import 'react-datepicker/dist/react-datepicker.css';
import { StudentAPI } from '../../../api/StudentAPI';
import { Document, DocumentType, Grades, Student } from '../../../models/student';
import { DateTime } from '../../../utils/DateTime';
import { DropUpload } from '../../3rd_party/DropUpload';
import DatePicker from '../../DatePicker'
import { TableContent } from '../../table/TableContent';
import { FileUploader } from "react-drag-drop-files";

interface Card {
    student: Student
    set_student: React.Dispatch<React.SetStateAction<Student | undefined>>
    isOpen: boolean
    onClose: any
    cancelRef: any
}

export const DocumentUploadCard = React.memo(({student, set_student, isOpen, onClose, cancelRef}: Card) => {
    const toast = useToast() 
    const [file, setFile] = React.useState<File>();
    const [loading, set_loading] = React.useState<boolean>(false);
    const [name, setName] = React.useState<string>("");
    const [document_type, set_document_type] = React.useState<DocumentType>()
    const handleChange = (file: File) => {
      setFile(file);
    };
    
    const upload = () => {
      if(file && document_type && name != ""){
        set_loading(true)
        StudentAPI.upload_document(student._id, name, document_type, file)
        .then((res) => {
          let document = res.data as Document
          document.date = new DateTime(document.date)
          StudentAPI.get_student(student._id.$oid).then(res => set_student(res!))
          
          setFile(undefined)
          setName("")
          set_document_type(undefined)
          set_loading(false)
          onClose()
        })
        .catch((res) => {
          console.log("ERROR")
          console.log(res)
          set_loading(false)
          toast({
            title: 'Error.',
            description: "Failed to upload document",
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        })
      } else{
        let msg = ""
        if(!file){
          msg += "Please upload a file "
        }
        if(!document_type){
          msg += "Please select a type "
        }
        if(name==""){
          msg += "Please enter a name "
        }
        toast({
          title: 'Error.',
          description: msg,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    }

    const cancel = () => {
      setFile(undefined)
      setName("")
      set_document_type(undefined)
      onClose()
    }

    return (
        <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Upload Document
            </AlertDialogHeader>

            <AlertDialogBody>
              {loading ?
                <Stack>
                  <Skeleton height='20px' />
                  <Skeleton height='20px' />
                  <Skeleton height='20px' />
                </Stack>
                :
                <>
                  <FormControl id="name">
                    <FormLabel>Document Name</FormLabel>
                    <Input 
                        value={name}
                        onChange={(value) => {setName(value.target.value)}}
                    />
                  </FormControl>
                  <br></br>
                  <FormControl id="document_type">
                    <FormLabel>Document Type</FormLabel>
                      <Select value={document_type} placeholder="Please Select a Type" onChange={(e) => set_document_type(e.target.value as any)}>
                          <option value={DocumentType.LegalDocument}>Legal Document</option>
                          <option value={DocumentType.HealthRecord}>Health Record</option>
                          <option value={DocumentType.ProofOfAddress}>Proof Of Address</option>
                          <option value={DocumentType.EducationDocument}>Education Document</option>
                          <option value={DocumentType.Other}>Other</option>
                      </Select>
                  </FormControl>
                  <br></br>
                  <FileUploader handleChange={handleChange} name="file" hoverTitle="Drop here"/>
                </>
              }
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={cancel}>
                Cancel
              </Button>
              <Button colorScheme='green' onClick={upload} ml={3}>
                Upload
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    )

})

