import * as React from "react"
import { Container, Center, Button, Heading, Image, Stack } from '@chakra-ui/react';
import { FaUserGraduate } from "react-icons/fa";
import { RiParentFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import 'firebase/compat/auth';
import logo from "../images/logo_transparent.png"
import { AuthLayout } from "./auth/authLayout";
import '../css/login.css';


export const LoginSelector: React.FC = (props) =>  {
    return (
        <AuthLayout>
            <Center>   
                <Container>
                    <Container maxW='container.sm' mb="12" maxWidth={"xl"}>
                        <Image src={logo}></Image>
                    </Container>
                    
                    <Center>
                        <Heading size='sm'>
                            Sign In
                        </Heading>
                    </Center>

                    <br />

                    <Stack align={"center"} maxW="500" mx="auto">
                        <Button as={Link} to="/login/parent" minW="220" leftIcon={<RiParentFill />} colorScheme="primary" >
                            Login As Parent
                        </Button>

                        <Button as={Link} to="/login/teacher" minW="220"  leftIcon={<FaUserGraduate />} border="1px solid #8d53a2" color="#8d53a2" colorScheme="white">
                            Login As Teacher
                        </Button>

                    </Stack>
                </Container>
            </Center>
        </AuthLayout>
    );
}