import React, { useState } from 'react'
import { Box, Text, HStack, Badge, Divider, Spacer, Collapse, IconButton, Fade } from '@chakra-ui/react'
import { CourseOrHomeroomData } from '../../models/gclass'
import SubmissionStats from './SubmissionStats'
import SubmissionList from './SubmissionList'
import EmptyPlaceholder from '../layout/EmptyPlaceholder'
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { useSubmissionCounts } from '../../hooks/useSubmissionsCount'
import { FlexWrap } from '../layout/FlexWrap'

interface props {
	data: CourseOrHomeroomData
}

const GClassItemCard: React.FC<props> = ({ data }) => {
	const [isExpanded, setIsExpanded] = useState(false)

	const { notSubmittedCount, notSubmittedLateCount, submittedOnTimeCount, submittedLateCount } = useSubmissionCounts(data.submissions)

	const handleToggle = () => {
		setIsExpanded(prev => !prev)
	}

	return (
		<Box bg='gray.50' borderRadius='lg' flex='1' spacing='5' mb='4' p='4'>
			<HStack justify='space-between' align='center'>
				<FlexWrap>
					<Text fontSize='md' fontWeight='bold'>
						{data.name}
					</Text>

					<Fade in={!isExpanded}>
						<HStack spacing="0" gap='2' wrap='wrap'>
							<Badge size='sm' fontWeight='bold' rounded='md' colorScheme='blue'>
								{notSubmittedCount} New
							</Badge>

							<Badge size='sm' fontWeight='bold' rounded='md' colorScheme='red'>
								{notSubmittedLateCount} Late
							</Badge>

							<Badge size='sm' fontWeight='bold' rounded='md' colorScheme='green'>
								{submittedOnTimeCount} On Time
							</Badge>

							<Badge size='sm' fontWeight='bold' rounded='md' colorScheme='yellow'>
								{submittedLateCount} Submitted Late
							</Badge>
						</HStack>
					</Fade>
				</FlexWrap>

				<Spacer />

				<IconButton
					size="sm"
					onClick={handleToggle}
					icon={isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
					aria-label={isExpanded ? 'Collapse' : 'Expand'}
				/>
			</HStack>

			<Collapse in={isExpanded}>
				<Box mt='4'>
					{data.submissions.length ? (
						<>
							<SubmissionStats submissions={data.submissions} />
							<Divider my='8' />
							<SubmissionList submissions={data.submissions} />
						</>
					) : (
						<EmptyPlaceholder />
					)}
				</Box>
			</Collapse>
		</Box>
	)
}

export default GClassItemCard
