import { UserRole } from "../api/UserApi";
import { DateTime } from "../utils/DateTime";
import { ObjectId } from "../utils/ObjectId";


export enum StudentStatus {
    Active = "Active",
    Inactive = "Inactive",
}

export interface Profile {
    first_name: string,
    last_name: string,
    gender: string,
    email: string,
    personal_email: string
    date_of_birth: DateTime,
    phone_number: string,
}

export class Teacher {
    _id: ObjectId;
    roles: UserRole[]
    status: StudentStatus;
    username: string;
    profile: Profile;
    constructor(data: any){
        this._id = data._id
        this.username = data.username
        this.roles = data.roles

        this.status = data.status

        this.profile = data.profile
        this.profile.date_of_birth = new DateTime(data.profile.date_of_birth)
    }
}