import {
Box,
FormControl,
FormLabel,
Input,
InputGroup,
Stack,
useColorModeValue,
InputLeftElement,
} from '@chakra-ui/react'
import { PhoneIcon } from '@chakra-ui/icons'
import * as React from 'react'
import 'react-datepicker/dist/react-datepicker.css';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { Student } from '../../../models/student';

interface Card {
    student: Student
    set_student: React.Dispatch<React.SetStateAction<Student | undefined>>
}

export const PreviousSchoolCard = React.memo(({student, set_student}: Card) => {    
    const update_previous_school_state = (name: any, value: any) => {
        set_student(
            prevState => (prevState ? {
                ...prevState,
                office_information: { 
                    ...prevState.office_information, 
                    previous_school: {
                        ...prevState.office_information.previous_school,
                        [name]: value 
                    }
                }
            } : undefined)
        );
    }


    return (
        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
        >
            <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    
                    <FormControl id="previous_school_name">
                        <FormLabel>Previous School Name</FormLabel>
                        <Input 
                            value={student.office_information.previous_school.previous_school_name} 
                            onChange={(value) => {
                                update_previous_school_state(value.target.id, value.target.value)
                            }}
                        />
                    </FormControl>
                    <FormControl id="previous_school_phone">
                        <FormLabel>Previous School Phone</FormLabel>
                        <InputGroup>
                            <InputLeftElement
                            pointerEvents='none'
                            children={<PhoneIcon color='gray.300' />}
                            />
                            <Input 
                                value={student.office_information.previous_school.previous_school_phone} 
                                onChange={(value) => {
                                    update_previous_school_state(value.target.id, value.target.value)
                                }}
                            />
                        </InputGroup>
                    </FormControl>
                    <FormControl id="previous_school_email">
                        <FormLabel>Previous School Email</FormLabel>
                        <Input 
                            value={student.office_information.previous_school.previous_school_email} 
                            onChange={(value) => {
                                update_previous_school_state(value.target.id, value.target.value)
                            }}
                        />
                    </FormControl>
                </Stack>
                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    <FormControl id="address">
                        <FormLabel>Address</FormLabel>
                        <GooglePlacesAutocomplete 
                            apiKey='AIzaSyAIp4Dd96GTcqFXfDuzUaEQY_OD5dWmvNs'
                            selectProps={{
                                placeholder: student.office_information.previous_school.previous_school_address,
                                onChange: (value:any) => {
                                    update_previous_school_state("previous_school_address", value.label)
                                },
                            }}    
                        />
                    </FormControl>
                </Stack>
                
            </Stack>
        </Box>
    )

})
