import React from "react"
import {
    Box,
    Heading,
    Center,
}
    from '@chakra-ui/react'


export const Markbook = React.memo(() =>  {


    return (
                <Box>
                    <Box overflowX="auto" overflowY="hidden">
                        <Center>
                            <Heading size="md" mb="6">
                                Mark Book
                            </Heading>
                        </Center>
                            {/* SHOULD BE REFACTORED */}
                            {/* <ClassList report_card={true}/> */}
                    </Box>
                </Box>
    );
} );
