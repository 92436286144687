import React, { useState, useEffect } from 'react';
import {
    Box,
    VStack,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    useDisclosure,
    Text,
    Stack,
    Skeleton,
    useColorModeValue, Td, IconButton, HStack, Spacer,
} from '@chakra-ui/react';
import { DateTime } from '../../utils/DateTime';
import { AttendanceTableData } from './AttendanceCourse';
import {MiscCell, SignInCell, SignOutCell, StatusCell} from "./AttendanceCells";
import {ArrowRightIcon} from "@chakra-ui/icons";

interface AttendanceMobileTableProps {
    entry_data: AttendanceTableData[] | undefined;
    single_student_view: boolean;
    editable: boolean;
}

interface AttendanceDetailsModalProps {
    isOpen: boolean;
    onClose: () => void;
    studentData: AttendanceTableData;
    editable: boolean;
}

export const AttendanceMobileTable: React.FC<AttendanceMobileTableProps> = React.memo(({ entry_data, editable = true, single_student_view = false }: AttendanceMobileTableProps) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [selectedStudent, setSelectedStudent] = useState<AttendanceTableData | null>(null);

    const bgLight = useColorModeValue('gray.50', 'gray.700');
    const bgDark = useColorModeValue('white', 'gray.800');

    const handleStudentClick = (student: AttendanceTableData) => {
        setSelectedStudent(student);
        onOpen();
    };

    return (
        <>
            <>
                {entry_data ? (
                    <VStack align="stretch" w={"100%"}>
                        {entry_data.map((row, index) => (
                            <Box
                                key={single_student_view ? row.date : row.student.id} // Use date as key when single_student_view is true
                                bg={index % 2 === 0 ? bgLight : bgDark}
                                p={4}
                                minW={"350px"}
                                borderRadius="md"
                            >
                                <HStack>
                                    <VStack align="stretch">
                                        <Text fontWeight="bold" textAlign="left">
                                            {single_student_view ? row.date : row.name}
                                        </Text>
                                        <StatusCell attendance={row.attendance_entry} table_data={row} editable={editable} mobile={true} />
                                    </VStack>
                                    <Spacer/>
                                    <IconButton aria-label="open" onClick={() => handleStudentClick(row)}>
                                        <ArrowRightIcon />
                                    </IconButton>
                                </HStack>
                            </Box>
                        ))}
                    </VStack>
                ) : (
                    <Stack>
                        <Skeleton height="50px" />
                        <Skeleton height="50px" />
                        <Skeleton height="50px" />
                    </Stack>
                )}
            </>

            {selectedStudent && (
                <Box width={"90%"}>
                    <AttendanceDetailsModal
                        isOpen={isOpen}
                        onClose={onClose}
                        studentData={selectedStudent}
                        editable={editable}
                    />
                </Box>

            )}
        </>
    );
});

const getStatusText = (status: string) => {
    const statusText: Record<string, string> = {
        '1': 'Present',
        '2': 'Late',
        '3': 'Absent'
    };
    return statusText[status] || 'None';
};

const getStatusColor = (status: string) => {
    const statusColor: Record<string, string> = {
        '1': 'green',
        '2': 'orange',
        '3': 'red'
    };
    return statusColor[status] || 'gray';
};

const AttendanceDetailsModal: React.FC<AttendanceDetailsModalProps> = ({ isOpen, onClose, studentData, editable }) => {

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent w="90%">
                <ModalHeader>Attendance Details</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack align="stretch" spacing={4}>
                        <Text fontWeight="bold">
                            {studentData.name}
                        </Text>
                        <StatusCell attendance={studentData.attendance_entry} table_data={studentData} editable={editable} mobile={true} />
                        <SignInCell table_data={studentData} editable={editable} />
                        <SignOutCell table_data={studentData} editable={editable} />
                        <MiscCell table_data={studentData} editable={editable} />
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="blue" onClick={onClose}>Close</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};