import { Item, Transaction } from "../models/transaction";
import { ObjectId } from "../utils/ObjectId";
import HTTPApi from "./ApiCommon";

export namespace PaymentAPI {
    export const new_payment = async (user_id: ObjectId, customer_name: string, customer_email: string, description: string, items: Array<Item>) => {
        return HTTPApi.post(
                "payments/new", {
                    description: description,
                    items: items,
                    user: {
                        _id: user_id,
                        name: customer_name,
                        email: customer_email
                    }
                }
            )
    }

    export const get_paid_transaction = async (user: ObjectId, description: string) => {
        return HTTPApi.post(
                "payments/get_paid_transaction", {
                    description: description,
                    user: user
                }
            ).then((res) => {
                return new Transaction(res.data)
            })
    }

    export const get_transactions = async (user: ObjectId) => {
        return HTTPApi.post(
                "payments/get_transactions", {
                    user: user
                }
            ).then((res) => {
                return (res.data as Transaction[]).map(e => new Transaction(e))
            })
    }
}