import HTTPApi from "./ApiCommon";
import { ObjectId } from "../utils/ObjectId";
import {Evaluation, EvaluationStatistics, StudentFinalMark} from "../models/evaluation";
import {MarkCategory} from "../models/mark_category";


export namespace MarkCategoriesAPI {

    export const get_mark_category_list_from_class = async (class_id: ObjectId) => {
        return HTTPApi.get("/mark_category/mark_category_list/" + class_id.$oid)
            .then((res) => {
                return res.data as Array<MarkCategory>
            })
    }

    export const get_mark_category = async (mark_category_id: ObjectId) => {
        return HTTPApi.get("/mark_category/" + mark_category_id.$oid)
            .then((res) => {
                return res.data as MarkCategory
            })
    }

    export const update_mark_category = async (mark_category: MarkCategory) => {
        return HTTPApi.post(
                    "mark_category/update",
                    {
                        _id: mark_category._id,
                        name: mark_category.name,
                        weight: mark_category.weight,
                    }
                ).then((res) => {
                    return res.data as MarkCategory
                })
    }

    export const create_mark_category = async (mark_category: MarkCategory) => {
        return HTTPApi.post(
                    "/mark_category/create",
                    {
                        class_id: mark_category.class_id,
                        name: mark_category.name,
                        weight: mark_category.weight,
                    }
                ).then((res) => {
                    return res.data as MarkCategory
                })
    }

    export const delete_mark_category = async (mark_category: ObjectId) => {
        return HTTPApi.get("/mark_category/delete/" + mark_category.$oid)
            .then()
    }
}
