import HTTPApi from "./ApiCommon";

import {Schedule, ScheduleInput, ScheduleOutput} from "../models/schedule";
import {Timeslot} from "../models/timeslot";


export namespace ScheduleAPI {

    export const get_schedule = async (schedule_input: ScheduleInput) => {
        return HTTPApi.post("/schedule/generate_schedule",

            {
                scheduling_params: {
                            start: schedule_input.scheduling_params.start.toISOString(),
                            end: schedule_input.scheduling_params.end.toISOString(),
                            solution_limit: schedule_input.scheduling_params.solution_limit
                        },
                courses:schedule_input.courses,
                subjects: schedule_input.subjects,
                timeslots: schedule_input.timeslots
            }

        ).then((res) => {
             return res.data as ScheduleOutput

        })
    }

    export const publish_schedule = async (repeat_by: number, schedule: Schedule, end_date: Date , holidays: Array<Timeslot>) => {
        return HTTPApi.post("/schedule/publish_schedule",
            {
                schedule: schedule,
                repeat_by: repeat_by,
                end_date: end_date,
                holidays: holidays
            }



            )
    }

}
