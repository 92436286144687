import { useParams } from "react-router-dom";
import { to_oid } from "../../utils/ObjectId";
import { StudentProfile } from "./StudentProfile";

interface Card {
    onboarding: boolean | undefined
    re_registration: boolean | undefined
}

export const StudentProfileRoute = ({onboarding, re_registration}: Card) =>  {
    let { student_id } = useParams();

    return (
        <>
        <StudentProfile onboarding={onboarding} re_registration={re_registration} student_id={to_oid(student_id!)}/>
        </>
    )
}