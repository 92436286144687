import { ObjectId } from "../utils/ObjectId";

export interface GClassStudentSubmission {
    gclass_student_submission_id: string;
    gclass_course_id: string;
    gclass_course_work_id: string;
    gclass_user_id: string;
    gclass_link: string;
    student_id: ObjectId;
    homeroom_id?: ObjectId;
    course_id?: ObjectId;
    submission_state: States;
    work_not_submitted_and_late: boolean;
    assigned_grade: number;
    work_type: WorkType;
    submission_history: SubmissionHistory[];
    assignment_submission?: AssignmentSubmission;
    short_answer_submission?: ShortAnswerSubmission;
    multiple_choice_submission?: MultipleChoiceSubmission;
}

export interface GClassCourseWork {
    gclass_course_work_id: string
    gclass_course_id: string
    homeroom_id: ObjectId
    course_id: ObjectId
    title: string
    description: string
    gclass_link: string
    materials: Attachment[]
    creation_time: string
    due_date: string
    work_type: WorkType
}

export interface SubmissionsWithGClassWork extends GClassStudentSubmission {
    title: string;
    student_name?: string;
    due_date: string | null; // Due date is optional and can be null
}

export interface CourseOrHomeroomData {
    _id: ObjectId;
    name: string;
    submissions: SubmissionsWithGClassWork[];
}


interface SubmissionHistory {
    gradeHistory?: GradeHistory;
    stateHistory?: StateHistoryData;
}

interface GradeHistory {
    actor_user_id: string;
    grade_change_type?: GradeChangeType;
    grade_timestamp?: Date;
    max_points: number;
    points_earned: number;
}

interface StateHistoryData {
    actorUserId: string;
    state?: StateHistory;
    stateTimestamp?: Date;
}

interface AssignmentSubmission {
    attachments: Attachment[];
}

interface ShortAnswerSubmission {
    answer: string;
}

interface MultipleChoiceSubmission {
    answer: string;
}

interface Attachment {
    drive_file?: DriveFile;
    form?: Form;
    link?: Link;
    you_tube_video?: YouTubeVideo;
}

interface DriveFile {
    alternate_link: string;
    id: string;
    thumbnail_url: string;
    title: string;
}

interface Form {
    form_url: string;
    response_url: string;
    thumbnail_url: string;
    title: string;
}

interface Link {
    thumbnail_url: string;
    title: string;
    url: string;
}

interface YouTubeVideo {
    alternate_link: string;
    id: string;
    thumbnail_url: string;
    title: string;
}

export enum States {
    Created = "CREATED",
    New = "NEW",
    ReclaimedByStudent = "RECLAIMED_BY_STUDENT",
    Returned = "RETURNED",
    SubmissionStateUnspecified = "SUBMISSION_STATE_UNSPECIFIED",
    TurnedIn = "TURNED_IN",
    Noop = "",
    FallthroughString = "FALLTHROUGH_STRING"
}

enum WorkType {
    Assignment = "ASSIGNMENT",
    CourseWorkTypeUnspecified = "COURSE_WORK_TYPE_UNSPECIFIED",
    MultipleChoiceQuestion = "MULTIPLE_CHOICE_QUESTION",
    ShortAnswerQuestion = "SHORT_ANSWER_QUESTION",
    Noop = "",
    FallthroughString = "FALLTHROUGH_STRING"
}

enum GradeChangeType {
    AssignedGradePointsEarnedChange = "ASSIGNED_GRADE_POINTS_EARNED_CHANGE",
    DraftGradePointsEarnedChange = "DRAFT_GRADE_POINTS_EARNED_CHANGE",
    MaxPointsChange = "MAX_POINTS_CHANGE",
    UnknownGradeChangeType = "UNKNOWN_GRADE_CHANGE_TYPE",
    Noop = "",
    FallthroughString = "FALLTHROUGH_STRING"
}

export enum StateHistory {
    Created = "CREATED",
    ReclaimedByStudent = "RECLAIMED_BY_STUDENT",
    Returned = "RETURNED",
    StateUnspecified = "STATE_UNSPECIFIED",
    StudentEditedAfterTurnIn = "STUDENT_EDITED_AFTER_TURN_IN",
    TurnedIn = "TURNED_IN",
    Noop = "",
    FallthroughString = "FALLTHROUGH_STRING"
}
