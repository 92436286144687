import React, { useRef, useState } from "react"
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    Button,
}
    from '@chakra-ui/react'

interface DialogProps {
    item?: string
    isOpen: boolean
    onClose: () => void;
    action: () => Promise<void> | void
}

export const DeleteDialog = React.memo(({item, isOpen, onClose, action}: DialogProps) =>  {

    const [isLoading, setIsLoading] = useState(false);
    const cancelRef = useRef<HTMLButtonElement>(null);
    
    async function performAction() {
        setIsLoading(true);
        try {
            await action();
        } finally {
            setIsLoading(false);
            onClose();
        }
    }

    return (
        <>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Delete Action!
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure {item && 'remove this ' + item}? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='red' onClick={performAction} isLoading={isLoading} ml={3}>
                                Delete
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
        </AlertDialog>
        </>
        )
    }
);
