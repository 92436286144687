import { Box, Center, Flex, Heading, HStack, Spacer, Stack, Text, Textarea, useColorModeValue, Tabs, TabList, TabPanels, Tab, TabPanel, Input, SimpleGrid, Checkbox, Button, Divider, FormControl, useToast, RadioGroup, Radio } from "@chakra-ui/react"
import React, { useEffect } from "react"
import { FiMaximize2 } from "react-icons/fi"
import { Outlet, useLocation } from "react-router-dom"
import { ReportsAPI } from "../../api/ReportsAPI"
import { Homeroom } from "../../models/homeroom"
import { ReportEntry, ReportType } from "../../models/report_entry"
import { Subject } from "../../models/subject"
import { ObjectId, to_oid } from "../../utils/ObjectId"

interface Card {
    report_entry: ReportEntry,
    homeroom: Homeroom,
    subjects: Subject[],
    set_refresh: React.Dispatch<React.SetStateAction<boolean>>
}

export const HomeroomSubject = ({report_entry, homeroom, subjects, set_refresh}: Card) => {
    const toast = useToast()


    let [report_entry_state, set_report_entry_state] = React.useState<ReportEntry>()
    let [subject, set_subject] = React.useState<Subject>()

    const handle_comment_change = (e: any) => {
        if(e.target.value.length <= subject!.report_layout.comment_char_limit){
            set_report_entry_state(
                prevState => (prevState ? {
                    ...prevState,
                    comment: e.target.value
                } : undefined)
            )
        }
    }

    const update_check_box = (state: boolean, section: ObjectId, check_box_id: ObjectId) => {
        let section_index = report_entry_state!.sections!.findIndex((e) => e.layout_id.$oid == section.$oid)
        let check_box_index = report_entry_state!.sections![section_index].check_boxes.findIndex((e) => e.check_box_id.$oid == check_box_id.$oid)

        let new_sections = report_entry_state!.sections!
        new_sections[section_index].check_boxes[check_box_index].state = state

        set_report_entry_state(
            prevState => (prevState ? {
                ...prevState,
                sections: new_sections
            } : undefined)
        )
    }

    const update_mark = (mark: string, section: ObjectId) => {
        let section_index = report_entry_state!.sections!.findIndex((e) => e.layout_id.$oid == section.$oid)

        let new_sections = report_entry_state!.sections!
        new_sections[section_index].mark = mark

        set_report_entry_state(
            prevState => (prevState ? {
                ...prevState,
                sections: new_sections
            } : undefined)
        )
    }

    const update_standalone_mark = (mark: string) => {
        set_report_entry_state(
            prevState => (prevState ? {
                ...prevState,
                standalone_mark: mark
            } : undefined)
        )
    }

    const update_standalone_check_box = (state: boolean, check_box_id: ObjectId) => {
        let check_box_index = report_entry_state!.standalone_checkboxes!.findIndex((e) => e.check_box_id.$oid == check_box_id.$oid)

        let new_checkboxes = report_entry_state!.standalone_checkboxes!
        new_checkboxes[check_box_index].state = state

        set_report_entry_state(
            prevState => (prevState ? {
                ...prevState,
                standalone_checkboxes: new_checkboxes
            } : undefined)
        )
    }

    const update_report = () =>{
        ReportsAPI.update_report_entry(report_entry_state!).then((res) => {
            set_refresh(true)
            toast({
                title: 'Success',
                description: "Saved Entry",
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
        }).catch((err) => {
            console.log(err)
            toast({
                title: 'Error.',
                description: "Failed to update entry",
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        })
    }

    useEffect(() => {
        set_report_entry_state(report_entry)

        subjects.forEach(e => {
            if(e._id.$oid == report_entry.homeroom_subject_layout!.$oid){
                set_subject(e)
            }
        });
    }, []);  

    return (
        <>
        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
        >{ subject &&
            <>

            <Heading px={{ base: '4', md: '4' }} fontSize="xx-large">{subject.name}</Heading>
            <Stack direction="row" spacing={8}  px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                <Box shadow='md' borderWidth='1px' px={{ base: '4', md: '2' }} py={{ base: '5', md: '2' }} borderRadius="lg" overflow="auto" flex="1">
                    {   report_entry_state?.report_type == ReportType.Progress ?
                            <>
                            <Stack>
                                <Text>Mark: </Text>
                                <RadioGroup onChange={update_standalone_mark} value={report_entry_state.standalone_mark!}>
                                <Stack direction='row'>
                                    <Radio value='Progressing With Difficulty'>Progressing With Difficulty</Radio>
                                    <Radio value='Progressing Well'>Progressing Well</Radio>
                                    <Radio value='Progressing Very Well'>Progressing Very Well</Radio>
                                </Stack>
                                </RadioGroup>
                                <br></br>
                                <Text>Checkboxes: </Text>
                                <SimpleGrid columns={2} spacing={5}>
                                    {subject.report_layout.progress_checkboxes.map((check_box) => {
                                        let state_check_box = report_entry_state!.standalone_checkboxes!.find(i => i.check_box_id.$oid == check_box._id.$oid)
                                        if(!state_check_box){
                                            return (
                                                <FormControl id="last_name">
                                                    <Checkbox 
                                                    disabled={true}
                                                    colorScheme="green"
                                                    >{check_box.name}</Checkbox>
                                                </FormControl>  
                                            )
                                        }
                                        let state = state_check_box!.state
                                        return (
                                            <FormControl id="last_name">
                                                <Checkbox 
                                                    isChecked={state} 
                                                    colorScheme="green" 
                                                    onChange={(e) => update_standalone_check_box(e.target.checked, check_box._id)}
                                                    >{check_box.name}</Checkbox>
                                            </FormControl>
                                        )
                                    })}
                                </SimpleGrid>
                            </Stack>
                            </>
                        :
                        <>
                        <Tabs isFitted variant='enclosed'>
                        <TabList overflowY="hidden" overflowX="auto">
                            {
                                subject.report_layout.sections.map((e) => {
                                    return <Tab>{e.name}</Tab>
                                })
                            }
                        </TabList>

                        <TabPanels>
                            {
                                subject.report_layout.sections.map((e) => {
                                    console.log(e)
                                    let report_section = () => report_entry_state!.sections!.find(i => i.layout_id.$oid == e._id.$oid)!
                                    if(!report_section()){
                                        return (
                                            <TabPanel>
                                            <>
                                                <Stack>
                                                <Text>Error: Failed to load subject</Text>
                                                </Stack>
                                            </>
                                            </TabPanel>
                                        )
                                    }
                                    return (
                                        <TabPanel>
                                            <>
                                                <Stack>
                                                    <Text>Mark: </Text>
                                                    <Input 
                                                        placeholder='Mark' 
                                                        value={report_section().mark} 
                                                        onChange={(e) => update_mark(e.target.value, report_section().layout_id)}
                                                    />
                                                    <br></br>
                                                    <Text>Checkboxes: </Text>
                                                    <SimpleGrid columns={2} spacing={5}>
                                                        {e.check_boxes.map((check_box) => {
                                                            let state_check_box = report_section().check_boxes.find(i => i.check_box_id.$oid == check_box._id.$oid)
                                                            if(!state_check_box){
                                                                return (
                                                                    <FormControl id="last_name">
                                                                        <Checkbox 
                                                                        disabled={true}
                                                                        colorScheme="green"
                                                                        >{check_box.name}</Checkbox>
                                                                    </FormControl>  
                                                                )
                                                            }
                                                            let state = state_check_box!.state
                                                            return (
                                                                <FormControl id="last_name">
                                                                    <Checkbox 
                                                                        isChecked={state} 
                                                                        colorScheme="green" 
                                                                        onChange={(e) => update_check_box(e.target.checked, report_section().layout_id, check_box._id)}
                                                                        >{check_box.name}</Checkbox>
                                                                </FormControl>
                                                            )
                                                        })}
                                                    </SimpleGrid>
                                                </Stack>
                                            </>
                                        </TabPanel>
                                    )
                                })
                            }
                        </TabPanels>
                        </Tabs>
                        </>
                    }
                </Box>
                <Box shadow='md' borderWidth='1px' flex='3' px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }} borderRadius="lg"> 
                    <Stack>
                        <Text mb='8px'>Comment: </Text>
                        <Textarea
                            value={report_entry_state!.comment}
                            onChange={handle_comment_change}
                            placeholder='Write the comments here...'
                            size='sm'
                        />
                        <HStack>
                            <Spacer/>
                            <Text fontSize="small">Character Limit {report_entry_state!.comment.length}/{subject.report_layout!.comment_char_limit}</Text>
                        </HStack>
                    </Stack>

                    
                </Box>
            </Stack>
            <Divider />
            <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
                <Button colorScheme="green" onClick={update_report}>
                    Save
                </Button>
            </Flex>
        </>   
        }</Box>
        </>
        
    )
}