import { Center, Heading } from "@chakra-ui/react";
import React from "react"
import { Outlet, useLocation } from "react-router-dom"

export const ReportClassList: React.FC = () => {
    const location = useLocation();

    return (
        <>
        <Center>
            <Heading size="md" mb="6">
                Report Cards
            </Heading>             
        </Center>
        {location.pathname === "/reports" ?
            {/* SHOULD BE REFACTORED */}
            // <ClassList report_card={true}/>
        :
            <Outlet/>
        }
        </>
        
    )
}