import { ObjectId } from "../utils/ObjectId";
import { DateTime } from "../utils/DateTime";

export interface Violation {
    _id: ObjectId,
    student: ObjectId,
    course: ObjectId | null,
    homeroom: ObjectId | null,
    date: DateTime,
    notes: string,
    status: Array<ViolationStatus>,
    reasons: ViolationReason
}

export enum ViolationStatus {
    Warning = "Warning",
    Detention = "Detention",
    EscalatedToOffice = "EscalatedToOffice",
    LetterHome = "LetterHome",
}

export interface ViolationReason {
    Warning: string,
    Detention: string,
    LetterHome: string,
    EscalatedToOffice: string
}