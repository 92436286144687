import React, {useEffect, useRef, useState} from "react";
import {
    Box,
    RadioGroup,
    Radio,
    Stack,
    Skeleton,
    useToast,
    Button,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    Center,
    Textarea,
    VStack,
    Text,
    Checkbox,
    SimpleGrid,
    useMediaQuery,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    AlertDialog,
    AlertDialogBody,
    AlertDialogOverlay,
    AlertDialogFooter,
    AlertDialogContent,
    AlertDialogHeader,
    Td, Badge,
} from '@chakra-ui/react';
import FocusLock from 'react-focus-lock';
import { FiPlus, FiCheck } from "react-icons/fi";
import { AttendanceAPI } from "../../api/AttendanceAPI";
import TimePicker from "../TimePicker";
import { Attendance } from "../../models/attendance";
import { DateTime } from "../../utils/DateTime";
import { useColorModeValue } from '@chakra-ui/react';
import { AttendanceTableData } from "./AttendanceCourse";
import { RStatusRadioEnum, StatusRadioEnum } from "./Attendance";
import {UserType} from "../../api/UserApi";
import {MiscCell, SignInCell, SignOutCell, StatusCell} from "./AttendanceCells";

interface AttendanceDesktopTableProps {
    entry_data: Array<AttendanceTableData> | undefined,
    single_student_view: boolean
    update?: () => void;
    editable: boolean,
    show_periods: boolean
}

export const AttendanceDesktopTable = React.memo(({ entry_data, single_student_view, update,  editable=true, show_periods }: AttendanceDesktopTableProps) => {
    // Define background colors for different modes
    const bgLight = useColorModeValue('gray.50', 'gray.700');
    const bgDark = useColorModeValue('white', 'gray.800');

    return (
        <Box minW={"900px"}>
            {entry_data ?
                <Table variant="simple">
                    {single_student_view ? (
                        <>
                            <Thead>
                                <Tr>
                                    <Th>Date</Th>
                                    <Th>Status</Th>
                                    <Th>Sign In Time</Th>
                                    <Th>Sign Out Time</Th>
                                    <Th>Misc</Th>
                                    { show_periods ? (
                                        <Th>Period</Th>): (<></>)
                                    }
                                </Tr>
                            </Thead>
                            <Tbody>
                                {entry_data.map((row, index) => (
                                    <Tr key={row.date} bg={index % 2 === 0 ? bgLight : bgDark}>
                                        <Td>{row.date}</Td>
                                        <Td>
                                            <StatusCell attendance={row.attendance_entry} table_data={row} editable={editable} update={update} />
                                        </Td>
                                        <Td>
                                            <SignInCell table_data={row} editable={editable} />
                                        </Td>
                                        <Td>
                                            <SignOutCell table_data={row} editable={editable} />
                                        </Td>
                                        <Td>
                                            <MiscCell table_data={row} editable={editable} />
                                        </Td>
                                        { show_periods ? (
                                            <Td>{row.attendance_entry.period_number}</Td>): (<></>)
                                        }
                                    </Tr>
                                ))}
                            </Tbody>
                        </>
                    ) : (
                        <>
                            <Thead>
                                <Tr>
                                    <Th>Name</Th>
                                    <Th>Status</Th>
                                    <Th>Sign In Time</Th>
                                    <Th>Sign Out Time</Th>
                                    <Th>Misc</Th>
                                    { show_periods ? (
                                        <Th>Period</Th> ): (<></>)
                                    }
                                </Tr>
                            </Thead>
                            <Tbody>
                                {entry_data.map((row, index) => (
                                    <Tr key={row.student.id} bg={index % 2 === 0 ? bgLight : bgDark}>
                                        <Td>{row.name}</Td>
                                        <Td>
                                            <StatusCell attendance={row.attendance_entry} table_data={row} editable={editable}  update={update} />
                                        </Td>
                                        <Td>
                                            <SignInCell table_data={row} editable={editable} />
                                        </Td>
                                        <Td>
                                            <SignOutCell table_data={row} editable={editable} />
                                        </Td>
                                        <Td>
                                            <MiscCell table_data={row} editable={editable} />
                                        </Td>
                                        { show_periods ? (
                                            <Td>{row.attendance_entry.period_number}</Td>): (<></>)
                                        }
                                    </Tr>
                                ))}
                            </Tbody>
                        </>
                    )}
                </Table>
                :
                <Stack>
                    <Skeleton height='100px' />
                    <Skeleton height='100px' />
                    <Skeleton height='100px' />
                    <Skeleton height='100px' />
                    <Skeleton height='100px' />
                </Stack>
            }
        </Box>
    );
});
