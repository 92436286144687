import LocalStorageService from "../api/LocalStorageService"
import { UserType } from "../api/UserApi"

export const useUser = () => {
    let user = LocalStorageService.getInstance().getUser();

	const userIsTeacher = user?.user_type === UserType.Teacher
	const userIsParent = user?.user_type === UserType.Parent

	return {
		user,
		userIsParent,
		userIsTeacher
	} 
}
