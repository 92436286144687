import { HStack, Spacer, Stack } from '@chakra-ui/react';
import { Student } from '../../models/student';
import GClassStudentViewer from '../gclass/GClassStudentViewer'
import { LightButton } from '../layout/LightButton';
import { TabRoutes } from '../layout/TabRoutes';
import StudentOverview from '../students/Cards/OverviewCard';
import { StudentCourseList } from '../students/Cards/StudentCourseList';
import { StudentHomeroomList } from '../students/Cards/StudentHomeroomList';

interface Card {
	student: Student
}

export const ParentsStudentProfile = ({ student }: Card) => {

	const tabs = [
        {
            label: "Overview",
            path: `/parent/student/${student._id.$oid}/overview`,
            element: (
			<>
				<Stack gap={4}>
					<StudentOverview  student={student} />

					<StudentHomeroomList student={student} />

					<StudentCourseList student={student}/>

					<HStack>
						<Spacer />

						<LightButton color="purple" onClick={() => openInNewTab('/students/printable/' + student?._id.$oid)}>
							Printable Page
						</LightButton>
					</HStack>
				</Stack>
			</>
			)
        },
        {
            label: "Academic Tracker",
            path: `/parent/student/${student._id.$oid}/academic-tracker`,
            element: (
				<>
					<GClassStudentViewer type='homeroom' studentId={student._id} />

					<GClassStudentViewer type='course' studentId={student._id} />
				</>
            ),
        },
		{
            label: "Attendance",
            path: `/parent/student/${student._id.$oid}/attendance`,
            element: 
				<>
					<StudentHomeroomList student={student} />

					<StudentCourseList student={student} link={`/parent/student/${student._id.$oid}/attendance/course/`} />
				</>
        },
    ];

	const openInNewTab = (url: string) => {
		window.open(url, '_blank', 'noopener,noreferrer')
	}

	return (
		<TabRoutes tabs={tabs} />
	)
}
