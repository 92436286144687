import HTTPApi from "./ApiCommon";
import {
    DocumentType,
    Health,
    OfficeInformation,
    Student,
    StudentProfile,
    FormDataInterface,
    FormDataObj,
    SensitiveStudent,
    Financial,
    Consent,
    StudentUniform,
    StudentForm,
    StudentStatus,
    FormDataDocuments,
    Donation
} from "../models/student";
import { ObjectId } from "../utils/ObjectId";
import { DateTime } from "../utils/DateTime";
import { StudentIncident } from "../models/student_incident";
import { Course } from "../models/course";
import { Homeroom } from "../models/homeroom";
import { GClassStudentSubmission } from "../models/gclass";

export namespace StudentAPI {
    export interface StudentList {
        students: Array<Student>,
        students_count: number
    }
    export const students_list = async (
            per_page: number | undefined = undefined, 
            page: number | undefined = undefined, 
            name_search: string | null = null, 
            status: StudentStatus | undefined = undefined,
            course_id: ObjectId | undefined = undefined,
            homeroom_id: ObjectId | undefined = undefined
        ): Promise<StudentList | void> => {

        return HTTPApi.post(
                "students/students_list", {
                    per_page: per_page,
                    page: page,
                    name_search: name_search,
                    status: status,
                    homeroom_id: homeroom_id,
                    course_id: course_id
                })
                .then((res) => {
                    return res.data as StudentList
                })
                .catch((e) => {
                    console.log(e);
                })
    }

    export const students_by_ids = async (students : ObjectId[]): Promise<Student[]> => {
        return HTTPApi.post(
                "students/students_by_ids", { students })
                .then((res) => {
                    return res.data as Student[]
                })
    }

    export const get_student = async (student_id: string) => {
        return HTTPApi.get("/students/" + student_id)
                .then((res) => {
                    return new Student(res.data)
                })
                .catch((e) => {
                    console.log(e);
                })
    }

    export const get_students = async (students: Array<ObjectId>) => {
        return HTTPApi.post(
            "students/get_students", {
                students: students,
            }
        )
        .then((res) => {
            let data = res!.data
            let students: Array<Student> = data.map((e: any) => new Student(e))
            return students
        })
    }

    export const get_sensitive_student = async (student_id: string) => {
        return HTTPApi.get("/students/sensitive/" + student_id)
                .then((res) => {
                    return res.data as SensitiveStudent
                })
                .catch((e) => {
                    console.log(e);
                })
    }

    export const update_profile = async (student_id: ObjectId, profile: StudentProfile) => {
        return HTTPApi.post(
            "students/update_profile", {
                _id: student_id,
                profile: profile
            }
        )
    }

    export const get_courses = async (student_id: ObjectId, include_archived?: boolean) => {
        return HTTPApi.post(
            "students/courses", {
                student: student_id,
                include_archived: include_archived
            }).then((res) => {
                return res.data as Course[]
            })
    }

    export const get_homerooms = async (student_id: ObjectId, include_archived?: boolean) => {
        return HTTPApi.post(
            "students/homerooms", {
                student: student_id,
                include_archived: include_archived
            }).then((res) => {
                return res.data as Homeroom[]
            })
    }

    export const get_gclass_course_submissions = async (student: ObjectId, course: ObjectId) => {
        return HTTPApi.post(
            "students/get_gclass_course_submissions", {
                student, 
                course
            }).then((res) => {
                return res.data as GClassStudentSubmission[]
            })
    }
    
    export const get_gclass_homeroom_submissions = async (student: ObjectId, homeroom: ObjectId) : Promise<GClassStudentSubmission[]> => {
        return HTTPApi.post(
            "students/get_gclass_homeroom_submissions", {
                student, 
                homeroom
            }).then((res) => {
                return res.data as GClassStudentSubmission[]
            })
    }

    export const update_health = async (student_id: ObjectId, health: Health) => {
        return HTTPApi.post(
            "students/update_health", {
                _id: student_id,
                health: health
            }
        )
    }
    export const update_donation = async (student_id: ObjectId, donation: Donation) => {
        return HTTPApi.post(
            "students/update_donation", {
                _id: student_id,
                donation: donation
            }
        )
    }

    export const update_office_information = async (student_id: ObjectId, office_information: OfficeInformation) => {
        return HTTPApi.post(
            "students/update_office_information", {
                _id: student_id,
                office_information: office_information
            }
        )
    }

    export const upload_document = async (student_id: ObjectId, name: string, document_type: DocumentType, file: File) => {
        const form = new FormData();
        form.append("student_id", student_id.$oid)
        form.append("name", name)
        form.append("document_type", document_type)
        form.append("file", file)
        return HTTPApi.post(
            "documents/upload_document",
            form
        )
    }

    export const get_document_url = async (student_id: ObjectId, document_id: ObjectId) => {
        return HTTPApi.post(
            "documents/get_document_url", {
                student_id: student_id,
                document_id: document_id
            }
        )
    }

    export const update_form_data = async (student_id: ObjectId, form_type: keyof FormDataInterface, form_data: FormDataObj) => {
        return HTTPApi.post(
            "students/update_form_data", {
                _id: student_id,
                form_type: form_type,
                form_data: form_data
            }
        )
    }

    export const update_documents_form_data = async (student_id: ObjectId, form_data: FormDataDocuments) => {
        return HTTPApi.post(
            "students/update_documents_form_data", {
                _id: student_id,
                form_data: form_data
            }
        )
    }

    export const update_financial = async (student_id: ObjectId, financial: Financial) => {
        return HTTPApi.post(
            "students/update_financial", {
                _id: student_id,
                financial: financial
            }
        )
    }

    export const update_consent = async (student_id: ObjectId, consent: Consent) => {
        return HTTPApi.post(
            "students/update_consent", {
                _id: student_id,
                consent: consent
            }
        )
    }

    export const update_uniform = async (student_id: ObjectId, uniform: StudentUniform) => {
        return HTTPApi.post(
            "students/update_uniform", {
                _id: student_id,
                uniform: uniform
            }
        )
    }

    export const submit_form = async (student_id: ObjectId, form: StudentForm, forms_submitted: Array<keyof FormDataInterface> ) => {
        return HTTPApi.post(
            "students/submit_form", {
                _id: student_id,
                form: form,
                forms_submitted: forms_submitted
            }
        )
    }

    export const enroll = async (student_id: ObjectId) => {
        return HTTPApi.post(
            "students/enroll", {
                _id: student_id
            }
        )
    }

    export const re_register = async (student_id: ObjectId) => {
        return HTTPApi.post(
            "students/re_register", {
                _id: student_id
            }
        )
    }

    export const change_password = async (student_id: ObjectId, password: string, change_password_at_next_login: boolean) => {
        return HTTPApi.post(
            "students/change_password", {
                _id: student_id,
                password: password,
                change_password_at_next_login: change_password_at_next_login
            }
        )
    }


    export const withdraw = async (student_id: ObjectId) => {
        return HTTPApi.post(
            "students/withdraw", {
                _id: student_id
            }
        )
    }

    export const reject = async (student_id: ObjectId) => {
        return HTTPApi.post(
            "students/reject", {
                _id: student_id
            }
        )
    }

    export const new_student = async (first_name: string, last_name: string, grade: string, gender: string, family_id: ObjectId) => {
        return HTTPApi.post(
            "students/new", {
                first_name: first_name,
                last_name: last_name,
                gender: gender,
                grade: grade,
                family_id: family_id,
            }
        )
    }

    export const create_student_incidents = async (student_id: ObjectId, title: string, date: DateTime) => {
        return HTTPApi.post(
            "student_incident/create_student_incidents", {
                student_id: student_id,
                title: title,
                date: date.to_date()
            }
        ).then((res) => {
            return new StudentIncident(res.data)
        })
    }

    export const update_student_incidents = async (incident: StudentIncident) => {
        return HTTPApi.post(
            "student_incident/update_student_incidents", {
                incident: incident
            }
        ).then((res) => {
            return new StudentIncident(res.data)
        })
    }

    export const get_student_incidents = async (student_id: ObjectId) => {
        return HTTPApi.post(
            "student_incident/get_student_incidents", {
                _id: student_id
            }
        ).then((res) => {
            return (res.data as Array<StudentIncident>).map((e) => new StudentIncident(e))
        })
    }
}