import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    FormControl,
    FormLabel,
    HStack,
    Skeleton,
    Spacer,
    Stack,
    Text,
    useToast,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select'
import React, { useEffect, useRef } from 'react'
import { HomeroomAPI } from '../../api/HomeroomAPI'
import { CourseAPI } from '../../api/CourseAPI'
import { TeacherAPI } from '../../api/TeacherAPI'
import { ObjectId, to_oid } from '../../utils/ObjectId'
import { TableContent } from '../table/TableContent'
import { getLookupLabel } from '../../models/lookup'
import { RemoveIconButton } from '../layout/RemoveIconButton'

interface AddTeachersDialogProps {
	homeroom?: ObjectId
	course?: ObjectId
	isOpen: boolean
	onClose: () => void
}

export default React.memo(({ homeroom, course, isOpen, onClose }: AddTeachersDialogProps) => {
	const toast = useToast()

	const [loading, setLoading] = React.useState(false)
	const [teachers_list, set_teachers_list] = React.useState<{ value: ObjectId; label: string }[]>([])
	const [teachers, set_teachers] = React.useState<ObjectId[]>([])

	const cancelRef = useRef<HTMLButtonElement>(null)

	useEffect(() => {
		TeacherAPI.teachers_list().then(res => {
			const data = res!.teachers.map(e => ({
				value: e._id,
				label: `${e.profile.first_name} ${e.profile.last_name}`
			}))
			set_teachers_list(data)
		})
	}, [])

	const add = () => {
		if (teachers.length > 0) {
			setLoading(true)

			const apiCall = homeroom ? HomeroomAPI.add_teachers(homeroom, teachers) : CourseAPI.add_teachers(course!, teachers)

			apiCall
				.then(() => {
					setLoading(false)
					cancel()
				})
				.catch(() => {
					setLoading(false)
					toast({
						title: 'Error.',
						description: 'An error occurred while adding teachers.',
						status: 'error',
						duration: 5000,
						isClosable: true
					})
				})

		} else {
			toast({
				title: 'Error.',
				description: 'Please add teachers',
				status: 'error',
				duration: 5000,
				isClosable: true
			})
		}
	}

	const cancel = () => {
		set_teachers([])
		onClose()
	}

	const TeacherColumns = [
		{
			Header: 'Name',
			accessor: '$oid',
			Cell: function cell($oid: string) {
				return (
					<HStack>
						<Text>{getLookupLabel(teachers_list, to_oid($oid))}</Text>

						<Spacer />

						<RemoveIconButton
							onClick={() => set_teachers(teachers.filter(e => e.$oid !== $oid))}
						/>
					</HStack>
				)
			}
		}
	]

	return (
		<AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize='lg' fontWeight='bold'>
						Add Teachers
					</AlertDialogHeader>

					<AlertDialogBody>
						{loading ? (
							<Stack>
								<Text>Please Wait... Teachers are being added</Text>
								<Skeleton height='20px' />
								<Skeleton height='20px' />
								<Skeleton height='20px' />
							</Stack>
						) : (
							<>
								<FormControl id='teacher' isRequired>
									<FormLabel>Teacher:</FormLabel>

									<Select 
										options={teachers_list} 
										placeholder='Select a teacher...' 
										onChange={e => set_teachers([...teachers, { $oid: e!.value.$oid } ])} 
									/>
								</FormControl>
								<br />
								<FormLabel>Teachers:</FormLabel>
								<TableContent data={teachers} columns={TeacherColumns} />
							</>
						)}
					</AlertDialogBody>

					<AlertDialogFooter>
						{!loading && (
							<>
								<Button ref={cancelRef} onClick={cancel}>
									Cancel
								</Button>
								<Button colorScheme='green' onClick={add} ml={3}>
									Add
								</Button>
							</>
						)}
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	)
})
