import React, {useEffect} from "react"
import { useState } from "react";
import {
    Box,
    Heading,
    Badge,
    Center,
}
from '@chakra-ui/react'
import { Outlet, useLocation } from 'react-router-dom'
import { StudentsList } from "./StudentsList";
import { StudentStatus } from "../../models/student";

export const Students = React.memo((props) =>  {
    const location = useLocation();

    return (
            <Box >
                <Center>
                    <Heading size="md" mb="6">
                        Students
                    </Heading>
                </Center>
                {location.pathname == "/students" ?
                    <StudentsList status={StudentStatus.Enrolled}/>
                :
                    <Outlet/>
                }
                
            </Box>
    );
} );
