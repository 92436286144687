import HTTPApi from "./ApiCommon";
import { Family } from "../models/family";
import { ObjectId } from "../utils/ObjectId";
import { Student } from "../models/student";


export namespace FamilyAPI {
    export const get_all_families = async () => {
        return HTTPApi.get("/family/get_all_families")
            .then((res) => {
                return res.data as Family[]
            })
    }

    export const get_parent_families = async () => {
        return HTTPApi.get("/family/parent/families")
            .then((res) => {
                return res.data as Family[]
            })
    }

    export const get_family = async (family_id: ObjectId) => {
        return HTTPApi.get("/family/get/" + family_id.$oid)
            .then((res) => {
                return res.data as Family
            })
    }

    export const get_students = async (family_id: ObjectId) => {
        return HTTPApi.get("/family/students/"+family_id.$oid)
            .then((res) => {
                return res.data as Student[]
            })
    }

    export const create = async (family: Family) => {
        return HTTPApi.post(
            "family/create",
            {
                family: family,
            }
        ).then((res) => {
            return res.data as Family
        })
    }

    export const update = async (family: Family) => {
        return HTTPApi.post(
            "family/update",
            {
                family: family,
            }
        ).then((res) => {
            return res.data as Family
        })
    }
}