import { Box, Heading, Center } from '@chakra-ui/react';
import { Outlet, useLocation, useParams } from 'react-router-dom'
import { StudentsList } from "../students/StudentsList";
import { to_oid } from "../../utils/ObjectId";

interface Card {
    course?: boolean
}

export const ReportStudents = ({course}: Card) =>  {
    let { class_id } = useParams();
    const location = useLocation();

    return <>
        <Center>
            <Heading size="md" mb="6">
                Report Cards
            </Heading>             
        </Center>
        <Box >
            {(location.pathname.match(/homeroom/g) || []).length !== 2 ?
               course ?
                    <StudentsList course_id={to_oid(class_id!)}/>
                    :
                    <StudentsList homeroom_id={to_oid(class_id!)} /> 
                :
                <Outlet></Outlet>

            }
            
        </Box>
    </>;
};
