// SkeletonTable.tsx
import React from 'react';
import { Stack, Skeleton } from '@chakra-ui/react';

const SkeletonTable: React.FC = () => {
    return (
        <Stack>
            {Array.from({ length: 5 }, (_, index) => (
                <Skeleton key={index} height="100px" />
            ))}
        </Stack>
    );
};

export default SkeletonTable;
