import {
Box,
Button,
Divider,
Flex,
FormControl,
FormLabel,
Input,
Stack,
Select,
useColorModeValue,
useToast,
InputGroup,
InputLeftAddon,
} from '@chakra-ui/react'
import * as React from 'react'
import 'react-datepicker/dist/react-datepicker.css';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { GOOGLE_MAP_API_KEY } from '../../../api/ApiCommon';
import { StudentAPI } from '../../../api/StudentAPI';
import { Family } from '../../../models/family';
import { Parent } from '../../../models/parent';
import { Student } from '../../../models/student';

interface Card {
    guardian: Parent
    save_guardian: (guardian: Parent) => void
    save_on_edit: boolean
    read_only?: boolean
}
    
export const FamilyGuardianCard = React.memo(({guardian, save_guardian, save_on_edit, read_only}: Card) => {
    const [guardian_state, set_guardian_state] = React.useState({...guardian})

    const update_state = (name: any, value: any) => {
        set_guardian_state({
            ...guardian_state,
            [name]: value
        });
        if(save_on_edit) {
            save_guardian({
                ...guardian_state,
                [name]: value
            });
        }
    }

    return (
        <>
        <Box
        as="form"
        bg="bg-surface"
        boxShadow={useColorModeValue('sm', 'sm-dark')}
        borderRadius="lg"
        flex="1"
    >
        <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
            <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                <FormControl id="title" isRequired isDisabled={read_only}>
                    <FormLabel>Title</FormLabel>
                    <Select 
                        value={guardian_state.title}
                        onChange={(value) => {
                            update_state(value.target.id, value.target.value)
                        }}
                    >
                            <option value=''></option>
                            <option value='Ms'>Ms</option>
                            <option value='Mr'>Mr</option>
                            <option value='Mrs'>Mrs</option>
                            <option value='Dr'>Dr</option>
                    </Select>
                </FormControl>
                <FormControl id="name" isRequired isDisabled={read_only}>
                    <FormLabel>Full Name</FormLabel>
                    <Input 
                        value={guardian_state.name} 
                        onChange={(value) => {
                            update_state(value.target.id, value.target.value)
                        }}
                    />
                </FormControl>
                <FormControl id="occupation" isRequired={true} isDisabled={read_only}>
                    <FormLabel>Occupation</FormLabel>
                    <Input 
                        value={guardian_state.occupation} 
                        onChange={(value) => {
                            update_state(value.target.id, value.target.value)
                        }}
                    />
                </FormControl>
            </Stack>

            <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                <FormControl id="email" isRequired={true} isDisabled={read_only}>
                    <FormLabel>Email</FormLabel>
                    <Input 
                        value={guardian_state.email} 
                        onChange={(value) => {
                            update_state(value.target.id, value.target.value)
                        }}
                    />
                </FormControl>
                <FormControl id="phone" isRequired isDisabled={read_only}>
                    <FormLabel>Primary Phone</FormLabel>
                    <InputGroup>
                        <InputLeftAddon children='+1' />
                        <Input 
                            value={guardian_state.phone} 
                            onChange={(value) => {
                                update_state(value.target.id, value.target.value)
                            }}
                        />
                    </InputGroup>
                </FormControl>
                <FormControl id="cell_phone" isDisabled={read_only}>
                    <FormLabel>Secondary Phone</FormLabel>
                    <InputGroup>
                        <InputLeftAddon children='+1' />
                        <Input 
                            value={guardian_state.cell_phone} 
                            onChange={(value) => {
                                update_state(value.target.id, value.target.value)
                            }}
                        />
                    </InputGroup>
                </FormControl>
            </Stack> 
            
            <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                <FormControl id="address" isRequired={true} isDisabled={read_only}>
                    <FormLabel>Address</FormLabel>
                    { read_only ?
                     <>
                        <Input 
                            value={guardian_state.address} 
                        />
                     </>
                        :
                     <>
                        <GooglePlacesAutocomplete
                            apiKey={GOOGLE_MAP_API_KEY}
                            selectProps={{
                                placeholder: guardian_state.address,
                                onChange: (value:any) => {
                                    update_state("address", value.label)
                                },
                            }}

                            autocompletionRequest={{
                                componentRestrictions: {
                                country: ['ca'],
                                }
                                }}
                            
                        />
                     </>
                    }
                </FormControl>
            </Stack>
        </Stack>
    </Box>
        </>
    )
})