import React, { useEffect, useState } from "react"
import {
    Box,
    Badge,
    Button,
    Stack,
    HStack,
    FormControl,
    Input,
    InputGroup,
    InputLeftElement,
    usePrevious, Spacer,
} from '@chakra-ui/react';
import { useLocation } from "react-router-dom";
import { TableContent } from "../table/TableContent";
import { BsSearch } from "react-icons/bs";
import { TablePagination } from "../table/TablePagination";
import { SemesterAPI } from "../../api/SemesterAPI";
import LocalStorageService from "../../api/LocalStorageService";
import DatePicker from "react-datepicker";
import { DateTime } from "../../utils/DateTime";
import {FiPlus} from "react-icons/fi";
import {UserRole} from "../../api/UserApi";
import CreateSemesterDialog from "./CreateSemester";

interface Card {
    archived?: boolean;
}

export const SemesterList = ({ archived }: Card) => {
    const PER_PAGE = 15;
    const location = useLocation();
    const [semestersData, setSemestersData] = useState<Array<SemesterObj>>([]);
    const [semestersPage, setSemestersPage] = useState<number>(1);
    const [semestersMaxPage, setSemestersMaxPage] = useState<number>(0);
    const [totalSemesters, setTotalSemesters] = useState<number>(0);
    const [semesterNameSearch, setSemesterNameSearch] = useState<string>("");
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [loading, setLoading] = useState<boolean>(true);


    const [isOpen_semester, setIsOpen_semester] = useState(false)
    const onClose_semester = () => setIsOpen_semester(false)
    const cancelRef_semester  = React.useRef()


    const previousSearch = usePrevious(semesterNameSearch);

    const user = LocalStorageService.getInstance().getUser();
    const canModifySemester = user?.has_role(UserRole.Can_Modify_Semester);

    const getSemesters = (_page: number, name_search: string | undefined, start_date: Date | null, end_date: Date | null) => {
        return SemesterAPI.semester_list(PER_PAGE, _page, name_search, start_date, end_date, archived)
            .then((res) => res!);
    }

    const _setSemesterList = (res: SemesterAPI.SemesterList) => {
        setSemestersData(res.semesters.map((semester) => {
            return {
                archived: semester.archived,
                name: semester.name,
                start_date: semester.start_date,
                end_date: semester.end_date,
                id: semester._id.$oid,
                link: location.pathname + "/semester/" + semester._id.$oid,
            } as SemesterObj;
        }));

        setTotalSemesters(res.semesters_count);
        setSemestersMaxPage(Math.ceil(res.semesters_count / PER_PAGE));
    }

    const setSemesterList = (_page?: number, name_search?: string) => {
        setLoading(true);

        getSemesters(_page!, name_search, startDate, endDate)
            .then(res => {
                _setSemesterList(res);
            })
            .finally(() => {
                setLoading(false);
            });

    }

    useEffect(() => {
        if (previousSearch !== semesterNameSearch) setSemestersPage(1);

        setSemesterList(semestersPage, semesterNameSearch);
    }, [semestersPage, semesterNameSearch, startDate, endDate, archived]);

    const getSemesterColumns = () => {
        const columns = [
            {
                Header: "Name",
                accessor: "name",
            },
            {
                Header: "Start Date",
                accessor: "start_date",

                Cell: function DateCell(data: any) {
                    const formattedDate = data
                        ? data.toLocaleDateString()
                        : "N/A"; // Fallback if date is not present

                    return <span>{formattedDate}</span>;
                },
            },
            {
                Header: "End Date",
                accessor: "end_date",
                Cell: function DateCell(data: any) {
                    const formattedDate = data
                        ? data.toLocaleDateString()
                        : "N/A"; // Fallback if date is not present

                    return <span>{formattedDate}</span>;
                },
            },
            {
                Header: "Archived",
                accessor: "archived",

                Cell: function DateCell(data: any) {
                    return <Badge fontSize="xs" colorScheme={data ? 'red' : 'green'}>
                        {data ? "Archived" : "Active"}
                    </Badge>
                },
            }
        ];

        return columns;
    }

    return (
        <Box>
            <Stack>
                <Stack spacing="4" direction={{ base: 'column', md: 'row' }} justify="space-between">
                    <HStack>
                        <FormControl minW={{ md: '320px' }} id="search">
                            <InputGroup size="sm">
                                <InputLeftElement pointerEvents="none" color="gray.400">
                                    <BsSearch />
                                </InputLeftElement>
                                <Input rounded="base" type="search" placeholder="Search by name..." onChange={(e) => setSemesterNameSearch(e.target.value)} />
                            </InputGroup>
                        </FormControl>
                        <DatePicker
                            selected={startDate}
                            onChange={date => setStartDate(date)}
                            placeholderText="Start Date"
                        />
                        <DatePicker
                            selected={endDate}
                            onChange={date => setEndDate(date)}
                            placeholderText="End Date"
                        />

                    </HStack>
                    <Spacer/>
                    {canModifySemester && (
                        <Button
                            colorScheme='green'
                            leftIcon={<FiPlus/>}
                            onClick={() => setIsOpen_semester(true)}
                        >
                            Add Semester
                        </Button>

                    )}
                    <CreateSemesterDialog
                        isOpen={isOpen_semester} onClose={onClose_semester} cancelRef={cancelRef_semester}
                    />
                </Stack>

                <TableContent loading={loading} data={semestersData} columns={getSemesterColumns()} />
                <TablePagination text={`Showing ${PER_PAGE * semestersPage} of ${totalSemesters}`} set_page={setSemestersPage} page={semestersPage} max_page={semestersMaxPage} />
            </Stack>
        </Box>
    );
}

interface SemesterObj {
    id: string;
    archived: boolean;
    name: string;
    start_date: Date;
    end_date: Date;
    link?: string;
}