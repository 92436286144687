import {
  Box,
  Divider,
  Flex,
  HStack,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useColorModeValue as mode,
} from '@chakra-ui/react';
import { useNavigate } from "react-router-dom";
import { BlCard } from '../layout/Card';
import EmptyPlaceholder from '../layout/EmptyPlaceholder';
import SkeletonTable from './TableSkeleton';

export interface Column {
  Header: string;
  accessor: string;
  Cell?: any | undefined;
}

interface UserProps {
  loading?: boolean
  columns: Array<Column>,
  data?: Array<any>
  hover_color?: string
  disable_hover?: boolean
  mobileBreakpoint?: boolean;
}


export const TableContent = ({columns, data = [], hover_color = 'gray.200', disable_hover, loading = false, mobileBreakpoint  = true}: UserProps) => {
  const navigate = useNavigate()

  // Decide whether to show table or card layout based on the screen size
  const isMobile = useBreakpointValue({ base: true, md: false });

  const getHoverStyle = (row: any) => {
    let hover_style: any = {};
  
    // Add background color if hover_color is provided and hover is not disabled
    if (hover_color && !disable_hover) hover_style.bg = hover_color;
  
    // Add cursor pointer if the row has a link or function
    if (row.link || row.run_func) hover_style.cursor = 'pointer';
  
    return hover_style;
  };

  // Render card layout for mobile
  const renderCardLayout = () => (
    <Box as={Stack} bg='bg-surface' my="8" rounded="lg">
      {data.map((row, index) => (
        <>
        <BlCard 
          boxShadow="none" 
          onClick={row.run_func ? row.run_func : () => {
            if (row.link) navigate(row.link);
          }}
        >
          {columns.map(column => {
            const cell = row[column.accessor];
            const element = column.Cell?.(cell, row) ?? cell;

            return (
              <Flex key={column.accessor} justify="space-between" align="center">
                <Text fontWeight="bold" color="gray.600"> {column.Header} </Text>

                <Text color="gray.500"> {element} </Text>
              </Flex>
            );
          })}
        </BlCard>

        { index + 1 !== data.length && (
          <HStack justify="center">
            <Divider mx="4" />
          </HStack>
        )}
        </>
      ))}
    </Box>
  );

  // Render table layout for larger screens
  const renderTableLayout = () => (
    <Table my="8" borderWidth="1px" fontSize="sm">
      <Thead bg={mode('gray.50', 'gray.800')}>
        <Tr>
          {columns.map((column, index) => (
            <Th scope="col" key={index}> {column.Header} </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {data.map((row, index) => (
          <Tr
            key={index}
            _hover={getHoverStyle(row)}
            bgColor={row.selected ? hover_color : undefined}
            onClick={row.run_func ? row.run_func : () => {
              if (row.link) navigate(row.link);
            }}
          >
            {columns.map((column, colIndex) => {
              const cell = row[column.accessor];
              const element = column.Cell?.(cell, row) ?? cell;

              return (
                <Td whiteSpace="nowrap" key={colIndex}>
                  {element}
                </Td>
              );
            })}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );

  // Loading state
  if (loading) {
    return <SkeletonTable />;
  }
  
  // No data available state
  if (!data || data.length === 0) {
    return <EmptyPlaceholder message="No items to show" />;
  }

  // Conditionally render table or card layout based on screen size and mobileBreakpoint prop
  return mobileBreakpoint && isMobile ? renderCardLayout() : renderTableLayout();
}