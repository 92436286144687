import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    FormControl,
    FormLabel,
    HStack,
    Skeleton,
    Spacer,
    Stack,
    Text,
    useToast,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select'
import React, { useEffect, useRef } from 'react'
import { HomeroomAPI } from '../../api/HomeroomAPI'
import { CourseAPI } from '../../api/CourseAPI'
import { StudentAPI } from '../../api/StudentAPI'
import { StudentStatus } from '../../models/student'
import { ObjectId, to_oid } from '../../utils/ObjectId'
import { TableContent } from '../table/TableContent'
import { getLookupLabel, Lookup } from '../../models/lookup'
import { RemoveIconButton } from '../layout/RemoveIconButton'

interface AddStudentsDialogProps {
	homeroom?: ObjectId
	course?: ObjectId
	isOpen: boolean
	onClose: () => void
}

export default React.memo(({ homeroom, course, isOpen, onClose }: AddStudentsDialogProps) => {
	const toast = useToast()

	const [loading, setLoading] = React.useState(false)
	const [students_list, set_students_list] = React.useState<Lookup[]>([])
	const [students, set_students] = React.useState<ObjectId[]>([])

	const cancelRef = useRef<HTMLButtonElement>(null)

	useEffect(() => {
		StudentAPI.students_list(undefined, undefined, null, StudentStatus.Enrolled).then(res => {
			const data = res!.students.map(e => ({
				value: e._id,
				label: `${e.profile.first_name} ${e.profile.last_name}`
			}))
			set_students_list(data)
		})
	}, [])

	const add = () => {
		if (students.length > 0) {
			setLoading(true)

			const apiCall = homeroom ? HomeroomAPI.add_students(homeroom, students) : CourseAPI.add_students(course!, students)

			apiCall
				.then(() => {
					toast({
						title: 'Success',
						description: "Student added successfully",
						status: 'success',
						duration: 5000,
						isClosable: true,
							})

					setLoading(false)
					cancel()
				})
				.catch(() => {
					setLoading(false)
					toast({
						title: 'Error.',
						description: 'An error occurred while adding students.',
						status: 'error',
						duration: 5000,
						isClosable: true
					})
				})
		} else {
			toast({
				title: 'Error.',
				description: 'Please add students',
				status: 'error',
				duration: 5000,
				isClosable: true
			})
		}
	}

	const cancel = () => {
		set_students([])
		onClose()
	}

	const StudentColumns = [
		{
			Header: 'Name',
			accessor: '$oid',
			Cell: function cell($oid: string) {
				return (
					<HStack>
						<Text>{getLookupLabel(students_list, to_oid($oid))}</Text>

						<Spacer />

						<RemoveIconButton
							onClick={() => set_students(students.filter(e => e.$oid !== $oid))}
						/>
					</HStack>
				)
			}
		}
	]

	return (
		<AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
			<AlertDialogOverlay>
				<AlertDialogContent>
					<AlertDialogHeader fontSize='lg' fontWeight='bold'>
						Add Students
					</AlertDialogHeader>

					<AlertDialogBody>
						{loading ? (
							<Stack>
								<Text>Please Wait... Students are being added</Text>
								<Skeleton height='20px' />
								<Skeleton height='20px' />
								<Skeleton height='20px' />
							</Stack>
						) : (
							<>
								<FormControl id='student' isRequired>
									<FormLabel>Student:</FormLabel>
									<Select 
										options={students_list} 
										placeholder='Select a student...' 
										onChange={e => set_students([...students, { $oid: e!.value.$oid }])} 
									/>
								</FormControl>
								<br />

								<FormLabel>Students:</FormLabel>

								<TableContent data={students} columns={StudentColumns} />
							</>
						)}
					</AlertDialogBody>

					<AlertDialogFooter>
						{!loading && (
							<>
								<Button ref={cancelRef} onClick={cancel}>
									Cancel
								</Button>
								<Button colorScheme='green' onClick={add} ml={3}>
									Add
								</Button>
							</>
						)}
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialogOverlay>
		</AlertDialog>
	)
})
