import * as React from "react"
import { Box, Heading, Text, Button, Center, Container } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export default function PaymentConfirmed() {
    let navigate = useNavigate()
    const [timeout, set_timeout] = React.useState<number>(5)

    const handle_back = () => {
        navigate(-1);
    }

    setTimeout(function() { handle_back(); }, 4000);
    setTimeout(function() { set_timeout(timeout-1); }, 1000);

  return (
      <Container>
      <Center>
        <Box textAlign="center" py={10} px={6}>
        <Heading
            display="inline-block"
            as="h2"
            size="2xl"
            bgGradient="linear(to-r, teal.400, teal.600)"
            backgroundClip="text">
            Payment Confirmed
        </Heading>
        <Text color={'gray.500'} mb={6}>
            In {timeout} seconds you will be re-directed back, or click the button below...
        </Text>

        <Button
            colorScheme="teal"
            bgGradient="linear(to-r, teal.400, teal.500, teal.600)"
            color="white"
            variant="solid"
            onClick={handle_back}
            >
            Go to back
        </Button>
        </Box>
    </Center>
    </Container>
  );
}