import HTTPApi from "./ApiCommon";
import { ObjectId } from "../utils/ObjectId";
import { Teacher } from "../models/teacher";
import { Course } from "../models/course";
import { Timeslot } from "../models/timeslot";
import { DateTime } from "../utils/DateTime";
import { utils } from "../utils/date_utils";


export namespace TimeslotAPI {

   
  
    export const get_timeslot = async (timeslot_id: ObjectId) => {
        return HTTPApi.get("/timeslots/" + timeslot_id.$oid)
            .then((res) => {
                return new Timeslot(res.data) 
            })
    }

    export const get_timneslots_by_course = async (course_id: ObjectId) => {
        return HTTPApi.post(
                    "timeslots/get_timeslots_by_course",
                    {
                        course_id: course_id.$oid,
                
                    }
                ).then((res) => {
                    return (res.data as Array<Timeslot>).map((e) => new Timeslot(e)) 
                })
    }

    export const get_timeslots_by_subject = async (subject_id: ObjectId) => {
        return HTTPApi.post(
                    "timeslots/get_timeslots_by_subject",
                    {
                        subjectt_id: subject_id.$oid,
                
                    }
                ).then((res) => {
                    return (res.data as Array<Timeslot>).map((e) => new Timeslot(e))
                })
    }

    export const get_timeslots_by_teacher = async (teacher_id: ObjectId) => {
        return HTTPApi.post(
            "timeslots/get_timeslots_by_teacher",
            {
                teacher_id: teacher_id.$oid,

            }
        ).then((res) => {
            return (res.data as Array<Timeslot>).map((e) => new Timeslot(e))
        })
    }


 

    export const create_timeslot = async (name: string, start_time: Date, end_time: Date, description?: string, teacher_id?: ObjectId, course_id?: ObjectId, subject_id?: ObjectId) => {
        
        return HTTPApi.post(
                    "/timeslots/create",
                    {
                        name: name,
                        description: description,
                        start_time: start_time.toISOString(),
                        end_time: end_time.toISOString(),
                        course_id: course_id,
                        subject_id: subject_id,
                        teacher_id: teacher_id,
                        
                    }
                ).then((res) => {
                    return new Timeslot(res.data) 
                })
    }
    

    export const update_timeslot = async (_id: ObjectId, timeslot: Timeslot) => {
        return HTTPApi.post(
                    "/timeslots/update",
                    {
                        _id: _id,
                        timeslot: timeslot
                        
                    }
                ).then((res) => {
                    return new Timeslot(res.data) 
                })
    }



  

    export const delete_timeslot = async (timeslot_id: ObjectId) => {
        return HTTPApi.get("/timeslots/delete/" + timeslot_id.$oid)
            .then()
    }

    export const delete_by_course = async (course_id: ObjectId) => {
        return  HTTPApi.post(
            "/timeslots/delete_by_course",
            {
                course_id: course_id
            }
        ).then()
    }
    export const delete_by_subject = async (subject_id: ObjectId) => {
        return  HTTPApi.post(
            "/timeslots/delete_by_subject",
            {
                subject_id: subject_id
            }
        ).then()
    }
    export const delete_by_teacher = async (teacher_id: ObjectId) => {
        return  HTTPApi.post(
            "/timeslots/delete_by_teacher",
            {
                teacher_id: teacher_id
            }
        ).then()
    }


}
