import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Center,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Heading,
    IconButton, Input,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    Radio,
    RadioGroup,
    SimpleGrid,
    Spacer,
    Spinner,
    Stack,
    Tab,
    Table,
    TableContainer,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useToast,
    Wrap
} from "@chakra-ui/react";
import {Select as SearchSelect,} from "chakra-react-select";
import React, {useEffect} from "react";
import {CourseAPI} from "../../api/CourseAPI";
import {HomeroomAPI} from "../../api/HomeroomAPI";
import {Subject} from "../../models/subject";
import {CardContent} from "../3rd_party/CardContent";
import {Card} from "../3rd_party/Card";
import {TeacherAPI} from "../../api/TeacherAPI";
import {Teacher} from "../../models/teacher";
import {Homeroom} from "../../models/homeroom";
import {Course} from "../../models/course";
import {CanTeachAPI} from "../../api/CanTeachAPI";
import {RepeatBy, ScheduleInput, ScheduleOutput} from "../../models/schedule";
import {ScheduleAPI} from "../../api/ScheduleAPI";
import {Event} from 'react-big-calendar'
import {MyCalenderTest} from "./calender";
import {Timeslot} from "../../models/timeslot";
import {DateTime} from "../../utils/DateTime";
import {new_oid} from "../../utils/ObjectId";
import {PublishPopUp, ScheduleDatePicker, ScheduleDateRangePicker, ScheduleTimePicker} from "./scheduleTimePicker";
import {ArrowLeftIcon, ArrowRightIcon} from "@chakra-ui/icons";
import {FiPlus} from "react-icons/fi";
import ListHomeroom = HomeroomAPI.ListHomeroom;
import format from "date-fns/format";

interface ScheduleSubject {
    subject: Subject;
    homeroom: Homeroom;
    teachers: Array<Teacher>;
    teacher: Teacher;
    weekly_time: number;

    period_length: number;

}
interface HomeRoomMap {
    homeroom: ListHomeroom,
    subjects: ScheduleSubject[]
}

interface ScheduleCourse {
    course: Course;
    teachers: Array<Teacher>;
    teacher: Teacher;
    weekly_time: number;
    period_length: number;

}


export const Schedule = React.memo(() =>  {

    const [isOpen_publish, setIsOpen_publish] = React.useState(false)
    const onClose_publish = () => setIsOpen_publish(false)
    const cancelRef_publish  = React.useRef()

    const toast = useToast()
    const [loading, set_loading] = React.useState<boolean>(true);
    const [unselected_courses, set_unselected_courses] = React.useState<Array<ScheduleCourse>>([]);
    const [selected_courses,set_selected_courses] = React.useState<Array<ScheduleCourse>>([]);
    const [selected_subjects,set_selected_subjects] = React.useState<Array<ScheduleSubject>>([]);
    const [unselected_subjects,set_unselected_subjects] = React.useState<Array<ScheduleSubject>>([]);
    const [start, set_start] = React.useState<Date>(new Date());
    const [end, set_end] = React.useState<Date>(new Date());
    const [start_time, set_start_time] = React.useState<Date>(new Date(start.getUTCFullYear(), start.getUTCMonth(), start.getUTCDate(),8,0));
    const [end_time, set_end_time] = React.useState<Date>(new Date(start.getUTCFullYear(), start.getUTCMonth(), start.getUTCDate(),16,0));
    const [solution_limit,set_solution_limit] = React.useState<number>(10);
    const [events,set_events] = React.useState<Event[]>([]);
    const [schedule_number, set_schedule_number] = React.useState<number>(1);
    const [schedule_name, set_schedule_name] = React.useState("");
    const [schedules,set_schedules]= React.useState<ScheduleOutput>();
    const [homerooms,set_homerooms] = React.useState<HomeRoomMap[]>([]);
    const [holiday_name,set_holiday_name] =React.useState("");
    const default_timeslot = new Timeslot({
        _id: new_oid(),
        name: "",
        description: '',
        start_time: DateTime.from_date(start_time),
        end_time: DateTime.from_date(end_time),
        course_id: null,
        subject_id: null,
        teacher_id: null
    });
    const [holidays, set_holidays] = React.useState<Array<Timeslot>>([]);
    const [holiday,set_holiday] = React.useState<Timeslot>(default_timeslot);
    const [end_date, set_end_date] = React.useState<Date>(end);
    const [repeat_by, set_repeat_by] = React.useState<RepeatBy>(RepeatBy.Weekly);

    const [index, set_index] = React.useState<number>(0);
    const update_schedule_number = (num: number) => {
        let number = (schedule_number+num)%solution_limit
        if (number<0) {
            number = solution_limit-1
        }
        set_schedule_number(number)
    };
    const onDateRangeChange = (dates: any) => {
        const [start, end] = dates;
        let start_pure = new Date(start)
        let end_pure = new Date(end)

        set_start(new Date(start_pure.getUTCFullYear(), start_pure.getUTCMonth(), start_pure.getUTCDate()));
        set_end(new Date(end_pure.getUTCFullYear(), end_pure.getUTCMonth(), end_pure.getUTCDate()));
    };

    const get_events = () => {
        if (schedules) {
            if (schedules.schedules.length>0 && schedules.schedules[schedule_number]) {
                let classes = schedules.schedules[schedule_number].timeslots.map((e) => {
                    let event: Event = {
                        start: new Date(parseInt(e.start_time.$date.$numberLong)),
                        end: new Date(parseInt(e.end_time.$date.$numberLong)),
                        title: e.name
                    }
                    return event
                })
                set_events(classes)
                set_schedule_name(schedules.schedules[schedule_number].name)
            }
        }
    }
const add_holiday = () => {
    set_holiday_name("")
    let default_t  = default_timeslot
    set_holidays([...holidays, holiday])
    default_t.start_time =holiday.start_time
    default_t.end_time = holiday.end_time
    set_holiday(default_t)

}

    const update_course = (course: ScheduleCourse) => {
        let courses = [...selected_courses]
        let update = courses.findIndex((a) => a.course._id.$oid === course.course._id.$oid)
        courses[update] = course
        set_selected_courses(courses)
    }
    const update_subject = (subject: ScheduleSubject) => {
        let subjects = [...selected_subjects]
        let update = subjects.findIndex((a) => a.subject._id.$oid === subject.subject._id.$oid)
        subjects[update] = subject
        set_selected_subjects(subjects)
    }
    const generate_schedules = () => {
        set_loading(true)
        let input = get_schedule_inputs()
        ScheduleAPI.get_schedule(input).then((output) => {
            if (output.schedules.length>0 ) {
                set_index(1)
                set_schedules(output)
                set_schedule_number(0)
                set_loading(false)
                toast({
                    title: 'Success.',
                    description: "Schedule solutions generated, check Schedules Page",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
            } else {
                set_loading(false)
                toast({
                    title: 'Error.',
                    description: "No Viable Solutions",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            }
            }).catch((error)=> {
            set_loading(false)
            toast({
                title: 'Error.',
                description: error.response.data,
                status: 'error',
                duration: 5000,
                isClosable: true,
            })

            });
    }
    const get_schedule_inputs = () => {
        let courses = selected_courses.map((e) => {
            let weekly_time = (e.weekly_time>e.period_length) ? e.weekly_time : e.period_length
            return {
                course_id: e.course._id,

                teacher_id: e.teacher._id,

                weekly_time: weekly_time,

                period_length: e.period_length
            }
        })

        let subjects = selected_subjects.map((e ) => {
            let weekly_time = (e.weekly_time>e.period_length) ? e.weekly_time : e.period_length
            return {
                subject_id: e.subject._id,
                teacher_id: e.teacher._id,
                weekly_time: weekly_time,
                period_length: e.period_length
            }
        })
        let timeslots: Array<Timeslot> = []
        let block_start = new Date(start)
        let block_start_day = block_start.getDate()-1
        block_start.setDate(block_start_day)
        for (let a = block_start; a<=new Date(end); a.setDate(a.getDate()+1)) {
            const start_date = new Date(Date.UTC(a.getUTCFullYear(),a.getUTCMonth(),a.getUTCDate(),end_time.getUTCHours(),end_time.getUTCMinutes()))
            const end_date = new Date(Date.UTC(a.getUTCFullYear(),a.getUTCMonth(),a.getUTCDate()+1,start_time.getUTCHours(),start_time.getUTCMinutes()))
            let morning_block = new Timeslot({
                _id: new_oid(),
                name: "block",
                description: '',
                start_time: DateTime.from_date(start_date),
                end_time: DateTime.from_date(end_date),
                course_id: null,
                subject_id: null,
                teacher_id: null})
            timeslots = [...timeslots, morning_block]
        }

        let input: ScheduleInput =  {
            scheduling_params: {
                start: start,
                end: end,
                solution_limit: solution_limit
            },
            courses: courses,
            subjects: subjects,
            timeslots: timeslots
        }
        return input
    }
    const update_multiple_selected_subjects = (subjects: ScheduleSubject[]) => {
            let selected = selected_subjects;
            let unselected = unselected_subjects;
            subjects.map((e) => {
                if (e.teacher) {
                    selected=[...selected, e]
                    unselected = unselected.filter((j) => {
                        return j.subject._id.$oid !== e.subject._id.$oid
                    })
                } else {
                    toast({
                        title: 'Error.',
                        description: "No teacher assigned to subject. Please add teachers to " + e.subject.name,
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    })
                }

            })
        set_selected_subjects(selected)
        set_unselected_subjects(unselected)
    }
    const update_selected_courses = (state: boolean, course: ScheduleCourse) => {
            if(state) {
                set_selected_courses( [...selected_courses, course])
                set_unselected_courses(unselected_courses.filter((e) => {
                    return e.course._id.$oid !== course.course._id.$oid}))
            } else {
                set_selected_courses(selected_courses.filter((e) => {
                  return e.course._id.$oid !== course.course._id.$oid}))
                set_unselected_courses( [...unselected_courses, course])
            }
    }
    const update_selected_subjects = (state: boolean, subject:ScheduleSubject) => {
        if(state) {
            if (subject.teacher) {
                set_selected_subjects([...selected_subjects, subject])
                set_unselected_subjects(unselected_subjects.filter((e) => {
                    return e.subject._id.$oid !== subject.subject._id.$oid
                }))

            } else {
                toast({
                    title: 'Error.',
                    description: "No teacher assigned to subject. Please add teachers to " + subject.subject.name,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            }

        } else {
            set_selected_subjects(selected_subjects.filter((e) => {
                return e.subject._id.$oid !== subject.subject._id.$oid}))
            set_unselected_subjects( [...unselected_subjects, subject])
        }
    }
    useEffect(()=>{
        set_homerooms([])
    CourseAPI.course_list(null, null, undefined, false,undefined,undefined)
        .then((res) => {
            let schedule_courses: ScheduleCourse[] = []
            // eslint-disable-next-line array-callback-return
            res!.courses.map((course) => {
                CourseAPI.get_course(course._id).then((crs) => {
                    CanTeachAPI.get_teachers_by_course(course._id).then((teachers) => {
                        let weekly_time = 0
                        if (crs.weekly_time) {
                            weekly_time= crs.weekly_time
                        }
                        let teacher = teachers[0]
                        let schedule_course: ScheduleCourse =  {
                            course: crs,
                            teachers: teachers,
                            teacher: teacher,
                            weekly_time: weekly_time,
                            period_length: 75,
                        }
                        if (crs.teacher_ids.length >0 && crs.teacher_ids[0]) {
                            TeacherAPI.get_teacher(crs.teacher_ids[0]).then((tch) => {
                                schedule_course.teacher = tch
                            })
                        }
                        schedule_courses = [...schedule_courses, schedule_course]
                        set_unselected_courses(schedule_courses)
                    })
                })
            })
        })
    HomeroomAPI.homeroom_list(null, null, undefined,false,undefined,undefined)
        .then((res) => {
            let schedule_subjects: ScheduleSubject[] = []
            // eslint-disable-next-line array-callback-return
            set_homerooms(res!.homerooms.map((e)=>{ return {homeroom:e, subjects:[]}}))
            res!.homerooms.map((e) => {
                HomeroomAPI.get_subjects(e._id).then((subjects) => {
                    // eslint-disable-next-line array-callback-return
                    subjects.map((subject) => {
                        HomeroomAPI.get_homeroom(e._id).then((homeroom) => {
                            CanTeachAPI.get_teachers_by_subject(subject._id).then((teachers) => {
                                let weekly_time = 0
                                if (subject.weekly_time) {
                                    weekly_time= subject.weekly_time
                                }
                                let teacher = teachers[0]
                                let schedule_subject : ScheduleSubject = {
                                    subject: subject,
                                    homeroom: homeroom,
                                    teachers: teachers,
                                    teacher: teacher,
                                    weekly_time: weekly_time,
                                    period_length: 45,
                                }
                                if (subject.teachers.length >0 && subject.teachers[0]) {
                                    TeacherAPI.get_teacher(subject.teachers[0]).then((tch) => {
                                        schedule_subject.teacher = tch
                                    })
                                }
                                    schedule_subjects = [...schedule_subjects, schedule_subject]
                                    set_unselected_subjects(schedule_subjects)
                         })
                        })
                    })
                })
            })
            set_loading(false)
        })
    },[])

    useEffect(()=> {
        set_selected_courses(selected_courses)
    },[selected_courses,set_selected_courses])

    useEffect(()=>{
        get_events()
    },[schedule_number])

    useEffect(()=> {
        get_events()
    },[schedules])

    useEffect(()=>{
        let new_homerooms = [...homerooms]
        new_homerooms.map((e) => {
            e.subjects=[]
        })
        set_homerooms(new_homerooms)
        unselected_subjects.map((s)=> {
            let new_homerooms = [...homerooms]
            let homeroom = new_homerooms.find((value) => value.homeroom._id.$oid === s.homeroom._id.$oid)
            if (homeroom) {
                homeroom!.subjects = [...homeroom.subjects, s]
            }
            })

    },[unselected_subjects])

    return (<Box>
            <Center>
                <Heading size="md" mb="6">
                    Scheduling
                </Heading>

            </Center>
            <Tabs index={index}>
                <TabList>
                    <Tab onClick={() => set_index(0)}>Select Parameters</Tab>
                    <Tab isDisabled={null == schedules} onClick={() => set_index(1)}>Schedule</Tab>

                </TabList>
                <TabPanels>

                    <TabPanel>
                        <Grid
                            templateAreas={`
                                          "nav main"
                                          "nav footer"`}
                            gridAutoRows={"auto"}
                            gridAutoColumns={'auto'}
                            gridAutoFlow={'inherit'}
                            gap='5'
                            color='blackAlpha.700'
                        >
                            <GridItem pl='2' area={'nav'}>
                                <Card mx="auto">
                                    <CardContent>
                                        <Accordion defaultIndex={[0]} allowToggle>
                                            <AccordionItem>
                                                <AccordionButton>
                                                    <Heading size="md" mb={3}>
                                                        Schedule Settings
                                                    </Heading>
                                                    <Spacer/>
                                                    <AccordionIcon/>
                                                </AccordionButton>
                                                <AccordionPanel pb={4}>
                                                    <Stack alignItems="center">
                                                        <ScheduleDateRangePicker
                                                            onChange={onDateRangeChange}
                                                            selectedDate={start_time}
                                                            label={"Schedule Range"}/>
                                                        <SimpleGrid columns={2} spacing={3}>
                                                            <ScheduleTimePicker
                                                                onChange={(value) => {
                                                                    set_start_time(value as Date)
                                                                }}
                                                                selectedDate={start_time}
                                                                label={"Start Time"}
                                                            />
                                                            <ScheduleTimePicker
                                                                onChange={(value) => {
                                                                    set_end_time(value as Date)
                                                                }}
                                                                selectedDate={end_time}
                                                                label={"End Time"}
                                                            />

                                                        </SimpleGrid>
                                                        <FormControl>
                                                            <FormLabel>Max # of Solutions</FormLabel>
                                                            <NumberInput defaultValue={solution_limit}
                                                                         value={solution_limit}
                                                                         min={2}
                                                                         max={100}
                                                                         onChange={(value) => set_solution_limit(Number(value.valueOf()))}>
                                                                <NumberInputField/>
                                                                <NumberInputStepper>
                                                                    <NumberIncrementStepper/>
                                                                    <NumberDecrementStepper/>
                                                                </NumberInputStepper>
                                                            </NumberInput>
                                                        </FormControl>
                                                    </Stack>
                                                </AccordionPanel>
                                            </AccordionItem>
                                        </Accordion>
                                    </CardContent>
                                </Card>
                                <br/>
                                <Card mx="auto">
                                    <CardContent>
                                        <Accordion defaultIndex={[0]} allowToggle>
                                            <AccordionItem>
                                                <AccordionButton>
                                                    <Heading size="md" mb={3}>
                                                        Publishing Settings
                                                    </Heading>
                                                    <Spacer/>
                                                    <AccordionIcon/>
                                                </AccordionButton>
                                                <AccordionPanel pb={4}>
                                                    <Stack alignItems="center" margin={0}>
                                                        <Stack direction={"column"} alignItems={"center"} margin={0} >
                                                            <FormControl id="repeatBy">
                                                                <Stack alignItems={"center"}>
                                                                    <FormLabel>Repeat By</FormLabel>
                                                                    <RadioGroup
                                                                        margin={0}
                                                                        onChange={(value) => set_repeat_by(value as RepeatBy)}
                                                                        value={repeat_by}>
                                                                        <Stack direction='row' margin={0}>
                                                                            <Radio value={RepeatBy.Daily}>Daily</Radio>
                                                                            <Radio value={RepeatBy.Weekly}>Weekly</Radio>
                                                                            <Radio value={RepeatBy.ScheduleLength}>Schedule Length</Radio>
                                                                        </Stack>
                                                                    </RadioGroup>
                                                                </Stack>
                                                            </FormControl>
                                                            <FormControl>
                                                                <Stack alignItems={"center"}>
                                                                <FormLabel marginBottom={-3}> End Date</FormLabel>
                                                                <ScheduleDatePicker onChange={(value) => {
                                                                    set_end_date(value as Date)
                                                                }} selectedDate={end_date}
                                                                                          ></ScheduleDatePicker>
                                                                </Stack>
                                                            </FormControl>
                                                        </Stack>/
                                                        <br/>
                                                        <Heading size={"xs"}>Holidays</Heading>
                                                        <FormControl>
                                                            <Stack alignItems={"center"}>
                                                                <FormLabel margin={-1}>Name</FormLabel>
                                                                <Input value={holiday_name} key={"holiday"}
                                                                       onChange={(event) => {
                                                                           set_holiday_name(event.target.value)
                                                                           let timeslot = holiday;
                                                                           timeslot.name = event.target.value;
                                                                           set_holiday(timeslot)
                                                                       }}>
                                                                </Input>
                                                            </Stack>
                                                        </FormControl>
                                                        <SimpleGrid columns={3} spacing={3}>
                                                            <ScheduleTimePicker key={"holiday"}
                                                                onChange={(value) => {
                                                                    let date = value as Date
                                                                    let timeslot = holiday;
                                                                    let start_date = timeslot.start_time.to_date()
                                                                    let start_time = new Date(start_date.getFullYear(), start_date.getMonth(), start_date.getDate(), date.getHours(), date.getMinutes())
                                                                    timeslot.start_time = DateTime.from_date(start_time)
                                                                    set_holiday(timeslot)
                                                                }}
                                                                selectedDate={holiday.start_time.to_date()}

                                                                label={"Start Time"}
                                                            />
                                                            <FormControl>
                                                                <Stack alignItems={"center"}>
                                                                    <FormLabel margin={-1}>Date</FormLabel>
                                                                    <ScheduleDatePicker onChange={(value) => {
                                                                        let date = value as Date
                                                                        let timeslot = holiday;
                                                                        let start_date = timeslot.start_time.to_date()
                                                                        let start_time = new Date(date.getFullYear(), date.getMonth(), date.getDate(), start_date.getHours(), start_date.getMinutes())
                                                                        timeslot.start_time = DateTime.from_date(start_time);
                                                                        let end_date = timeslot.end_time.to_date()
                                                                        let end_time = new Date(date.getFullYear(), date.getMonth(), date.getDate(), end_date.getHours(), end_date.getMinutes())
                                                                        timeslot.end_time = DateTime.from_date(end_time);
                                                                        set_holiday(timeslot)
                                                                    }}
                                                                    selectedDate={holiday.start_time.to_date()}></ScheduleDatePicker>
                                                                </Stack>
                                                            </FormControl>
                                                            <ScheduleTimePicker
                                                                onChange={(value) => {
                                                                    let date = value as Date
                                                                    let timeslot = holiday;
                                                                    let end_date = timeslot.start_time.to_date()
                                                                    let end_time = new Date(end_date.getFullYear(), end_date.getMonth(), end_date.getDate(), date.getHours(), date.getMinutes())
                                                                    timeslot.end_time = DateTime.from_date(end_time);
                                                                    set_holiday(timeslot)
                                                                }}
                                                                key={"holiday"}
                                                                selectedDate={holiday.end_time.to_date()}
                                                                label={"End Time"}
                                                            />
                                                        </SimpleGrid>
                                                    </Stack>
                                                    <Stack direction={"row"} justify={"right"}>
                                                        <Button alignSelf={"left"} key={"holiday"} margin={2} onClick={() => {
                                                         add_holiday()
                                                        }
                                                        }>Add Blocked Time</Button>
                                                    </Stack>
                                                    <TableContainer>
                                                        <Table variant='striped' size={'sm'}>
                                                            <Thead>
                                                                <Th>Name</Th>
                                                                <Th>Date</Th>
                                                            </Thead>
                                                            <Tbody>
                                                                {holidays.map((v) => {

                                                                    return <Tr key={v._id.$oid}>
                                                                        <Td>
                                                                            <Button size={'sm'}
                                                                                    onClick={(a) => {
                                                                                        let holiday_list = [...holidays];
                                                                                        holiday_list = [...holiday_list.filter((a) => {
                                                                                            return a._id.$oid !== v._id.$oid
                                                                                        })]
                                                                                        set_holidays(holiday_list)
                                                                                    }
                                                                                    }>
                                                                                {v.name.toString().replaceAll("_", " ")}
                                                                            </Button>
                                                                        </Td>
                                                                        <Td>
                                                                            {format(v.start_time.to_date(), "MMM dd, yyyy ")}
                                                                            {format(v.start_time.to_date(), "hh:mm a -")}
                                                                            {format(v.end_time.to_date(), " hh:mm a")}
                                                                        </Td>
                                                                    </Tr>;
                                                                })
                                                                }
                                                            </Tbody>
                                                        </Table>
                                                    </TableContainer>

                                                </AccordionPanel>
                                            </AccordionItem>
                                        </Accordion>
                                    </CardContent>
                                </Card>
                            </GridItem>
                            <GridItem pl='2' area={'main'}>
                                <Card mx="auto">
                                    <CardContent>
                                        <Accordion defaultIndex={[0]} allowToggle>
                                            <AccordionItem>
                                                <AccordionButton>
                                                    <Heading size="md" mb="3" as={"span"}>
                                                        Courses
                                                    </Heading>
                                                    <Spacer/>
                                                    <AccordionIcon/>
                                                </AccordionButton>
                                                <AccordionPanel pb={4}>
                                                    <Stack>
                                                        <br/>
                                                        <TableContainer>
                                                            <Table variant='striped' flexShrink={1} size={'sm'}>
                                                                <Thead>
                                                                    <Th>Course</Th>
                                                                    <Th>Teacher</Th>
                                                                    <Th>Weekly Time</Th>
                                                                    <Th>Period Length</Th>
                                                                </Thead>
                                                                <Tbody>
                                                                    {selected_courses.map((v) => {

                                                                        let teacher = {
                                                                            "value": v.teacher._id,
                                                                            "label": v.teacher.profile.first_name + " " + v.teacher.profile.last_name
                                                                        }
                                                                        return <Tr key={v.course._id.$oid}>
                                                                            <Td><Button size={'sm'}
                                                                                        onClick={() => update_selected_courses(false, v)}>
                                                                                {v.course.name.toString().replaceAll("_", " ") + " | " + v.course.course_code}
                                                                            </Button></Td>

                                                                            <Td>
                                                                                <Box width={'300px'}>
                                                                                    <SearchSelect size={'sm'}
                                                                                                  name="flavours"
                                                                                                  options={v.teachers.map((e) => {
                                                                                                      return {
                                                                                                          "value": e._id,
                                                                                                          "label": e.profile.first_name + " " + e.profile.last_name
                                                                                                      }
                                                                                                  })}
                                                                                                  defaultValue={teacher}
                                                                                                  defaultInputValue={teacher.label}

                                                                                                  onChange={(e) => {
                                                                                                      if (e) {
                                                                                                          let tch = v.teachers.find((t) => t._id.$oid === e.value.$oid)
                                                                                                          if (tch) {
                                                                                                              v.teacher = tch
                                                                                                          }
                                                                                                          update_course(v)
                                                                                                      }

                                                                                                  }}
                                                                                                  placeholder={teacher.label}
                                                                                    />
                                                                                </Box>
                                                                            </Td>

                                                                            <Td>
                                                                                <NumberInput size={'sm'}
                                                                                             flexShrink={2}
                                                                                             defaultValue={(v.weekly_time > v.period_length) ? v.weekly_time : v.period_length}
                                                                                             min={v.period_length}
                                                                                             step={5} max={1440}
                                                                                             onChange={(value) => {
                                                                                                 v.weekly_time = Number(value.valueOf())
                                                                                                 update_course(v)
                                                                                             }
                                                                                             }>
                                                                                    <NumberInputField/>

                                                                                    <NumberInputStepper>
                                                                                        <NumberIncrementStepper/>
                                                                                        <NumberDecrementStepper/>
                                                                                    </NumberInputStepper>
                                                                                </NumberInput>

                                                                            </Td>
                                                                            <Td>
                                                                                <NumberInput size={'sm'}
                                                                                             flexShrink={2}
                                                                                             defaultValue={75}
                                                                                             min={5}
                                                                                             step={5}
                                                                                             max={1440}
                                                                                             onChange={(value) => {
                                                                                                 v.period_length = Number(value.valueOf())
                                                                                                 update_course(v)
                                                                                             }
                                                                                             }>
                                                                                    <NumberInputField/>
                                                                                    <NumberInputStepper>
                                                                                        <NumberIncrementStepper/>
                                                                                        <NumberDecrementStepper/>
                                                                                    </NumberInputStepper>
                                                                                </NumberInput>

                                                                            </Td>
                                                                        </Tr>;
                                                                    })
                                                                    }
                                                                </Tbody>
                                                                <br/>
                                                                <br/>
                                                                <br/>
                                                            </Table>
                                                        </TableContainer>

                                                        <Accordion defaultIndex={[0]} allowToggle>
                                                            <AccordionItem>
                                                                <AccordionButton>

                                                                    <Box as="span" alignItems={"left"}>
                                                                        Course Bank
                                                                    </Box>
                                                                    <Spacer/>
                                                                    <AccordionIcon/>


                                                                </AccordionButton>
                                                                <AccordionPanel pb={4}>
                                                                    <Stack alignItems="center">
                                                                        <Wrap>
                                                                            {unselected_courses.map((v) => {
                                                                                return <Button
                                                                                    onClick={() => update_selected_courses(true, v)}>
                                                                                    {v.course.name.toString().replaceAll("_", " ") + " | " + v.course.course_code}
                                                                                </Button>
                                                                            })
                                                                            }
                                                                        </Wrap>
                                                                    </Stack>
                                                                </AccordionPanel>
                                                            </AccordionItem>
                                                        </Accordion>
                                                    </Stack>
                                                    <br/>
                                                </AccordionPanel>
                                            </AccordionItem>
                                        </Accordion>
                                    </CardContent>
                                </Card>
                                <br/>
                                <Card mx="auto">
                                    <CardContent>
                                        <Accordion defaultIndex={[0]} allowToggle>
                                            <AccordionItem>
                                                <AccordionButton>
                                                    <Heading size="md" mb="3">
                                                        Subjects
                                                    </Heading>
                                                    <Spacer/>
                                                    <AccordionIcon/>
                                                </AccordionButton>
                                                <AccordionPanel pb={4}>
                                                    <Stack>
                                                        <br/>
                                                        <TableContainer>
                                                            <Table variant='striped' size={'sm'}>
                                                                <Thead>
                                                                    <Th>Subject</Th>
                                                                    <Th>Teacher</Th>
                                                                    <Th>Weekly Time</Th>
                                                                    <Th>Period Length</Th>
                                                                </Thead>
                                                                <Tbody>
                                                                    {selected_subjects.map((v) => {
                                                                        let teacher = {
                                                                            "value": v.teacher._id,
                                                                            "label": v.teacher.profile.first_name + " " + v.teacher.profile.last_name
                                                                        }
                                                                        return <Tr key={v.subject._id.$oid}>
                                                                            <Td>
                                                                                <Button size={'sm'}
                                                                                        onClick={() => update_selected_subjects(false, v)}>
                                                                                    {v.subject.name.toString().replaceAll("_", " ") + " | " + v.homeroom.name}
                                                                                </Button>
                                                                            </Td>
                                                                            <Td>
                                                                                <Box width={'300px'}>
                                                                                    <SearchSelect size={'sm'}
                                                                                                  name="flavors"
                                                                                                  defaultValue={teacher}
                                                                                                  defaultInputValue={teacher.label}
                                                                                                  options={v.teachers.map((e) => {
                                                                                                      return {
                                                                                                          "value": e._id,
                                                                                                          "label": e.profile.first_name + " " + e.profile.last_name
                                                                                                      }
                                                                                                  })}
                                                                                                  onChange={(e) => {
                                                                                                      if (e) {
                                                                                                          let tch = v.teachers.find((t) => t._id.$oid === e.value.$oid)
                                                                                                          if (tch) {
                                                                                                              v.teacher = tch
                                                                                                          }
                                                                                                          update_subject(v)
                                                                                                      }
                                                                                                  }}
                                                                                                  placeholder={teacher.label}
                                                                                    />
                                                                                </Box>

                                                                            </Td>
                                                                            <Td>
                                                                                <NumberInput size={'sm'}
                                                                                             defaultValue={(v.weekly_time > v.period_length) ? v.weekly_time : v.period_length}
                                                                                             min={v.period_length}
                                                                                             step={5} max={1440}
                                                                                             onChange={(value) => {
                                                                                                 v.weekly_time = Number(value.valueOf())
                                                                                                 update_subject(v)
                                                                                             }}>
                                                                                    <NumberInputField/>
                                                                                    <NumberInputStepper>
                                                                                        <NumberIncrementStepper/>
                                                                                        <NumberDecrementStepper/>
                                                                                    </NumberInputStepper>
                                                                                </NumberInput>

                                                                            </Td>
                                                                            <Td>
                                                                                <NumberInput size={'sm'}
                                                                                             defaultValue={45}
                                                                                             min={5}
                                                                                             step={5}
                                                                                             max={1440}
                                                                                             onChange={(value) => {
                                                                                                 v.period_length = Number(value.valueOf())
                                                                                                 update_subject(v)
                                                                                             }}>
                                                                                    <NumberInputField/>
                                                                                    <NumberInputStepper>
                                                                                        <NumberIncrementStepper/>
                                                                                        <NumberDecrementStepper/>
                                                                                    </NumberInputStepper>
                                                                                </NumberInput>

                                                                            </Td>
                                                                        </Tr>;
                                                                    })
                                                                    }
                                                                </Tbody>
                                                                <br/>
                                                                <br/>
                                                                <br/>
                                                            </Table>
                                                        </TableContainer>
                                                        <Accordion defaultIndex={[0]} allowToggle>
                                                            <AccordionItem>
                                                                <AccordionButton>

                                                                    <Box as="span" alignItems={"left"}>
                                                                        Subject Bank
                                                                    </Box>
                                                                    <Spacer/>
                                                                    <AccordionIcon/>
                                                                </AccordionButton>
                                                                <AccordionPanel pb={4}>

                                                                    <Stack alignItems="center">


                                                                        <Wrap>
                                                                            {homerooms.map((h) => {
                                                                                return <Box>
                                                                                    {h.subjects.length > 0 &&
                                                                                        <Accordion allowToggle
                                                                                                   bg={"gray.50"}
                                                                                                   borderRadius={"10"}>
                                                                                            <AccordionItem border={"0"}>
                                                                                                <AccordionButton>
                                                                                                    <IconButton
                                                                                                        icon={<FiPlus/>}
                                                                                                        onClick={() => {
                                                                                                            update_multiple_selected_subjects(h.subjects)
                                                                                                        }
                                                                                                        }
                                                                                                        aria-label={"add"}/>
                                                                                                    <Box padding={'2'}>
                                                                                                        {h.homeroom.name}
                                                                                                    </Box>
                                                                                                    <AccordionIcon/>
                                                                                                </AccordionButton>

                                                                                                <AccordionPanel pb={4}>
                                                                                                    <Stack>
                                                                                                        {h.subjects.map((s) => {
                                                                                                            return <Button
                                                                                                                onClick={() => update_selected_subjects(true, s)}
                                                                                                                colorScheme={"gray"}>
                                                                                                                {s.subject.name}
                                                                                                            </Button>
                                                                                                        })
                                                                                                        }
                                                                                                    </Stack>
                                                                                                </AccordionPanel>
                                                                                            </AccordionItem>
                                                                                        </Accordion>
                                                                                    }
                                                                                </Box>;

                                                                            })
                                                                            }


                                                                        </Wrap>


                                                                    </Stack>
                                                                </AccordionPanel>
                                                            </AccordionItem>
                                                        </Accordion>
                                                    </Stack>
                                                    <br/>
                                                </AccordionPanel>
                                            </AccordionItem>
                                        </Accordion>
                                    </CardContent>
                                </Card>
                                {/*</Stack >*/}
                            </GridItem>
                            <GridItem pl='2' area={'footer'}>
                                <Stack direction={"row"}>
                                    <Spacer/>
                                    {(selected_courses.length + selected_subjects.length) >= 1 &&
                                        <Button
                                            colorScheme={"green"}
                                            onClick={() => {
                                                generate_schedules()
                                            }}

                                        >
                                            {loading ?
                                                <Spinner/>
                                                // "wait"
                                                :
                                                "Schedule"
                                            }
                                            {/*sup*/}
                                        </Button>
                                    }
                                </Stack>
                            </GridItem>
                        </Grid>

                    </TabPanel>
                    {schedules && (
                        <TabPanel>
                            <Card mx="auto">
                                <CardContent>
                                    <Stack direction="column" alignItems={"center"}>
                                        {schedules.schedules[schedule_number] && (
                                            <Stack direction="row">
                                                <IconButton
                                                    aria-label={"previous schedule"}
                                                    icon={<ArrowLeftIcon/>}
                                                    onClick={() => {
                                                        update_schedule_number(-1)

                                                    }}

                                                />
                                                <Heading size={"sm"}>
                                                    {schedules.schedules[schedule_number].name}
                                                </Heading>
                                                <IconButton
                                                    aria-label={"previous schedule"}
                                                    icon={<ArrowRightIcon/>}
                                                    onClick={() => {
                                                        update_schedule_number(+1)
                                                    }}

                                                />
                                            </Stack>
                                        )}
                                        <MyCalenderTest
                                            defaultDate={start}
                                            schedule_events={events}
                                            schedule_name={schedule_name}/>
                                    </Stack>
                                    <Stack direction={"row"} justify={"right"}>
                                        <Button colorScheme={"green"} marginBottom={"5px"} marginRight={"10px"}
                                                onClick={() => setIsOpen_publish(true)}>Publish</Button>
                                    </Stack>
                                    <PublishPopUp cancelRef={cancelRef_publish} isOpen={isOpen_publish}
                                                  onClose={onClose_publish}
                                                  schedule_input={schedules.schedules[schedule_number]}
                                                  holidays={holidays}
                                                  schedule_start={start}
                                                  schedule_end={end}
                                                  end_date_input={end_date}
                                                  repeat_by_input={repeat_by}
                                                  onRepeatByChange={(value) => set_repeat_by(value as RepeatBy)}
                                                  onDateChange={(value) => set_end_date(value as Date)}/>

                                </CardContent>
                            </Card>
                        </TabPanel>
                    )}
                </TabPanels>
            </Tabs>
        </Box>

    );
});
