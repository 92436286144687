import * as React from "react"
import {useEffect, useState} from "react";
import {
    Center,
    Heading,
    Grid,
    GridItem,
    VStack,
    Link,
    Text,
    Stack

}
from '@chakra-ui/react'
import { Card } from "./3rd_party/Card";
import { CardContent } from "./3rd_party/CardContent";
import { CardHeader } from "./3rd_party/CardHeader";
import { Property } from "./3rd_party/Property";
import { Calendar, momentLocalizer, Views, Event } from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { LabeledProperty } from "./3rd_party/LabeledProperty";
import {BrowserView, MobileView} from 'react-device-detect';
import LocalStorageService from "../api/LocalStorageService";
import {TimeslotAPI} from "../api/TimeslotAPI";


const localizer = momentLocalizer(moment)

export const Home: React.FC = (props) =>  {

    const [events, setEvents] = useState<Event[]>([])
    
    const important_links = (
        <Card maxW="3xl" mx="auto">
            <CardHeader title="Important Links"/>
            <CardContent>
                <LabeledProperty label="Telegram Support Channel" value={<Link href='https://t.me/+Q_6kbDsSAUkwOTQx' isExternal> Click Here</Link>}/>
                <LabeledProperty label="Software Usage Wiki" value={<Link href='https://ahmadb.notion.site/Public-Wiki-9b44fc076c964fa4acdab9c550df5fb8' isExternal> Click Here</Link>}/>
            </CardContent>
        </Card>
    )

    const announcements = (
        <Card maxW="3xl" mx="auto">
            <CardHeader title="Announcements"/>
            <CardContent>
                <Property label="Aug 23, 2024" value="Welcome to the new 2024-2025 school year!" />
            </CardContent>
        </Card>
    )

    const calendar = (
        <div>
            <Card maxW="3xl" mx="auto">

                <CardContent>
        <Calendar
            // defaultView='week'
            min={new Date(2020,1,1,7,0,0)}
            max={new Date(2020,1,1,18,0,0)}
            style={{ height: '75vh' }}
            events={events}
            views={allViews}
            step={15}
            showMultiDayTimes
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
        />
                </CardContent>
            </Card>
        </div>
    )

    return (
        <VStack>
            <Heading as="h1" size="md">Hello {LocalStorageService.getInstance().getUser()?.full_name}!</Heading>
            <Text fontSize="xl">Date: {new Date().toDateString()}</Text>
            <Center>
                <BrowserView>
                    <Grid
                    templateColumns='repeat(2, 1fr)'
                    gap={4}
                    >
                        <GridItem>
                            {important_links}
                        </GridItem>
                        <GridItem>
                            {announcements}
                        </GridItem>
                    </Grid>
                    <br/>
                    {/* {calendar} */}
                </BrowserView>
                <MobileView>
                    <Stack>
                    {important_links}
                    {announcements}
                        {/* {calendar} */}
                    </Stack>
                </MobileView>

            </Center>
        </VStack>

    );
}

// @ts-expect-error
let allViews = Object.keys(Views).map(k => Views[k])

const ColoredDateCellWrapper = ({ children }: any) =>
  React.cloneElement(React.Children.only(children), {
    style: {
      backgroundColor: 'lightblue',
    },
  })
