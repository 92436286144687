import * as yup from 'yup';
import { ObjectId } from '../utils/ObjectId';

const createSemesterSchema = yup.object().shape({
	name: yup.string().required('Name is required'),
	start_date: yup.date().required('Start date is required'),
	end_date: yup.date().required('End date is required')
		.min(yup.ref('start_date'), "End date can't be before start date"),
});

const semesterSchema = yup.object().shape({
	_id: yup.mixed<ObjectId>().required('ID is required'),
	name: yup.string().required('Name is required'),
	start_date: yup.date().required('Start date is required'),
	end_date: yup.date().required('End date is required')
		.min(
			yup.ref('start_date'),
			"End date can't be before start date"
		),
	archived: yup.boolean().required('Archived is required')
});

export { semesterSchema, createSemesterSchema };
