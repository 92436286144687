import { Box, Center, Heading, Stack } from "@chakra-ui/react"
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { FamilyAPI } from "../../api/FamilyAPI";
import LocalStorageService from "../../api/LocalStorageService";
import { UserType } from "../../api/UserApi";
import { Family } from "../../models/family";
import { new_oid, to_oid } from "../../utils/ObjectId";
import { FamilyCard } from "../cards/family/FamilyCard";

export const FamilyEdit: React.FC = () => {
    let { family_id } = useParams();
    const [family, set_family] = React.useState<Family>();

    const save_family = (family: Family) => {
        FamilyAPI.update(family).then((res) => {
            window.open("/parent/family", "_self")
        })
    }

    useEffect(()=> {
        FamilyAPI.get_family(to_oid(family_id!))
            .then((res) => {
                set_family(res)
            })
    }, [])

    return (
        <>
        <Box>
                <Box overflowX="auto" overflowY="hidden">
                    <Center>
                        <Heading size="md" mb="6">
                            Update Family
                        </Heading>
                        
                    </Center>
                    { family &&
                        <Stack>
                            <FamilyCard family={family} save_family={save_family} save_on_edit={false}/>
                        </Stack>
                    }   
                </Box>
            </Box>
        </>
    )
}