interface LongDate{
    $numberLong: string
}
export interface BsonDate{
    $date: LongDate
}

export class DateTime {
    $date: LongDate;
    constructor(date: BsonDate){
        this.$date = date.$date
    }
    
    static from_date(date: Date) : DateTime{
        return new DateTime({
            $date : {
                $numberLong: (date.getTime()).toString()
            }
        })
    }

    to_date(): Date{
        return new Date(parseInt(this.$date.$numberLong))
    }

    to_utc_string_date(): string {
        return this.to_date().toLocaleDateString('en-GB', {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            timeZone: 'UTC',
        });
    }
}