import axios from "axios";

const API_BASE_URL: string | undefined = process.env.REACT_APP_API_BASE_URL;
export const STRIPE: string | undefined = process.env.REACT_APP_STRIPE_KEY;
export const GOOGLE_MAP_API_KEY: string | undefined = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const instance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  },
  withCredentials: true  // Include credentials like cookies and tokens
});

export default instance;
