// @ts-nocheck
import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
  Elements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Item } from "../../models/transaction";
import { PaymentAPI } from "../../api/PaymentAPI";
import { Alert, AlertDescription, AlertIcon, AlertTitle, Box, Button, Center, CloseButton, Container, FormControl, FormLabel, Input, Spinner, Stack, Table, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr, useColorModeValue, VStack } from "@chakra-ui/react";
import validator from 'validator'
import { useLocation } from "react-router-dom";
import { ObjectId } from "../../utils/ObjectId";
import { STRIPE } from "../../api/ApiCommon";

interface CheckoutFormCard {
  price: number
}

const CheckoutForm = ({price}: CheckoutFormCard) => {
  const stripe = useStripe();
  const elements = useElements();
  const location = useLocation()

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  setTimeout(function() { setIsDisabled(false); }, 1000);

  const payment_element = <PaymentElement id="payment-element" />

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.origin + "/payment_confirmed",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occured.");
    }

    setIsLoading(false);
  };

  return (
      <Stack>
        {message &&
          <Alert status='error'>
            <AlertIcon />
            <AlertTitle mr={2}>A payment error occurred!</AlertTitle>
            <AlertDescription>{message}</AlertDescription>
          </Alert>
        }
        {payment_element && stripe && 
          <>
            {payment_element}
            <br></br>
            {isLoading ? 
                <Center><Spinner /></Center>
                :
                <Button disabled={isLoading || !stripe || !elements || isDisabled} colorScheme="green" onClick={handleSubmit}>
                  Pay Now - {price}$
                </Button>
              }
              <br></br>
          </>
        }
          
      </Stack>
  );
}

const stripePromise = loadStripe(STRIPE);

interface Card {
  user_id: ObjectId
  description: string
  items: Array<Item>
}

export const Payments = React.memo(({user_id, description, items}: Card) => {
  const [clientSecret, setClientSecret] = useState("");
  const [name, set_name] = useState("");
  const [email, set_email] = useState("");
  const [onPaymentPage, setOnPaymentPage] = useState(false);

  useEffect(() => {
    console.log(window.location.origin )
    if(onPaymentPage && name && validator.isEmail(email)){
      PaymentAPI.new_payment(user_id, name,email,description, items)
        .then(res => setClientSecret(res.data))
    }
    else{
      setOnPaymentPage(false)
    }   
  }, [onPaymentPage]);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
    >
      <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
      <Stack>
        <TableContainer>
          <Table size='sm' colorScheme="green">
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th isNumeric>Price</Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.map((item) => {
                return (
                  <Tr>
                    <Td>{item.name}</Td>
                    <Td isNumeric>${item.price}</Td>
                  </Tr>
                )
              })}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th>Total</Th>
                <Th isNumeric>${items.map(item => item.price).reduce((a,b) => a+b)}</Th>
              </Tr>
            </Tfoot>
          </Table>
        </TableContainer>
      </Stack>
      <br/>
      
      { !onPaymentPage ? 
        <Stack spacing={5}>
          <FormControl isRequired>
            <FormLabel>Full Name:</FormLabel>
            <Input 
                value={name} 
                onChange={(value) => {
                  set_name(value.target.value)
                }}
            />
          </FormControl>
          <FormControl isRequired>
              <FormLabel>Email:</FormLabel>
              <Input 
                  value={email} 
                  onChange={(value) => {
                    set_email(value.target.value)
                  }}
              />
          </FormControl>
          <Button type="submit" colorScheme="green" onClick={() => setOnPaymentPage(true)}>
            Next
          </Button>
          <br></br>
        </Stack>
        :
        <Container maxW='container.lg'>
          <div className="App">
            {clientSecret && (
              <Elements options={options} stripe={stripePromise}>
                <CheckoutForm price={items.map(item => item.price).reduce((a,b) => a+b)}/>
              </Elements>
            )}
          </div>
        </Container>
          
      }
    </Stack>
    </Box>
  );
})