import {
    Box,
    Button,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Select,
    useColorModeValue,
    useToast,
    Spacer,
    RadioGroup,
    Radio,
    Text,
    Alert,
    AlertIcon,
    VStack,
    Link, Heading,
} from '@chakra-ui/react'
    import * as React from 'react'
    import 'react-datepicker/dist/react-datepicker.css';
    import { StudentAPI } from '../../../api/StudentAPI';
    import { Grades, SensitiveStudent, Student, StudentStatus } from '../../../models/student';
    import { DateTime } from '../../../utils/DateTime';
    import DatePicker from '../../DatePicker'
import {useEffect} from "react";
    
    interface Card {
        student: Student
        set_student: React.Dispatch<React.SetStateAction<Student | undefined>>
    }
    
    export const DonationCard = React.memo(({student, set_student}: Card) => {
        const toast = useToast()

        const required_info = () => {
            return (student.donation.donation != "" || student.status == StudentStatus.ReRegistration)
        }

        const update_donation_state = (name: any, value: any) => {
            set_student(
                prevState => (prevState ? {
                    ...prevState,
                    donation: { ...prevState.donation, [name]: value}
                } : undefined)
            );
        }
    
        const update_donation= () => {
            if(required_info()){
                StudentAPI.update_donation(student._id, student.donation)
                    .then((res) => {
                        toast({
                            title: 'Success',
                            description: "Saved Donations",
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                        })
                    })
                    .catch((err) => {
                        console.log(err)
                        toast({
                            title: 'Error.',
                            description: "Failed to update Donations",
                            status: 'error',
                            duration: 5000,
                            isClosable: true,
                        })
                    })
            }
            else{
                toast({
                    title: 'Error.',
                    description: "Please fill in all required fields.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            }
            
        }
    
        return (
            <Box
                as="form"
                bg="bg-surface"
                boxShadow={useColorModeValue('sm', 'sm-dark')}
                borderRadius="lg"
                flex="1"
            >
                <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                    <Text fontWeight={"bold"} align={"left"} fontSize="4xl">Donation</Text>
                    <VStack>
                        <Alert status='info' variant='subtle'>
                            <AlertIcon />
                            Sponsor a student's tuition at AB Academy
                        </Alert>
                        <Alert status='info' variant='subtle' dir={"rtl"} >
                            <AlertIcon />
                            كفالة طالب علم في مدرسة الفجر الباسم:
                            <br /> تتيح مدرسة الفجر الباسم الفرصة لمن يحب لكفالة طالب (طلاب) من المدرسة والذين لا يستطيعون دفع كامل الأقساط المدرسية السنوية أو يستطيعون دفع جزء منها فقط.
                            إذا أردت مشاركتنا في الأجر فبادر باختيار الخيار المناسب لك

                            </Alert>
                    </VStack>
                    <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                        <FormControl id="student_sponsorship">
                            <FormLabel>Donation</FormLabel>
                            <RadioGroup onChange={(e ) => update_donation_state("donation", e)} value={student.donation.donation} >
                                <Stack>
                                    {/*<Radio value='full-onetime'>Full Student Scholarship - 9800$ Annually</Radio>*/}
                                    {/*<Radio value='full-10'>Full Student Scholarship - 9800$ Over 10 Months</Radio>*/}
                                    {/*<Radio value='full-12'>Full Student Scholarship - 9800$ Over 12 Months</Radio>*/}
                                    {/*<Radio value='half-onetime'>Half Student Scholarship - 4400$ Annually</Radio>*/}
                                    {/*<Radio value='half-10'>Half Student Scholarship - 4400$ Over 10 Months</Radio>*/}
                                    {/*<Radio value='half-12'>Half Student Scholarship - 4400$ Over 12 Months</Radio>*/}
                                    <Radio value='$0'>$0</Radio>
                                    <Radio value='50'>Monthly $50</Radio>
                                    <Radio value='100'>Monthly $100</Radio>
                                    <Radio value='250'>Monthly $250</Radio>
                                    <Radio value='500'>Monthly $500</Radio>
                                    <Radio value='750'>Monthly $750</Radio>
                                    <Radio value='1000'>Monthly $1,000</Radio>

                                </Stack>
                            </RadioGroup>
                        </FormControl>
                    </Stack>
                </Stack>
                <Divider />
                <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
                    <Button onClick={update_donation}>
                        Save
                    </Button>
                </Flex>
            </Box>
        )
    
    })
    