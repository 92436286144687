import HTTPApi from "./ApiCommon";
import { ObjectId } from "../utils/ObjectId";
import { Semester } from "../models/semester";
import {utils} from "../utils/date_utils";
import {DateTime} from "../utils/DateTime";


export namespace SemesterAPI {
    export interface ListSemester {
        _id: ObjectId,
        archived: boolean,
        name: string,
        start_date: Date,
        end_date: Date,
    }

    export interface SemesterList {
        semesters: Array<ListSemester>,
        semesters_count: number
    }

    export const semester_list = async (
        per_page: number | null = null,
        page: number | null = null,
        name_search: string | undefined = undefined,
        start_date: Date | null = null,
        end_date: Date | null = null,
        archived: boolean | undefined = undefined
    ): Promise<SemesterList | void> => {
        return HTTPApi.post(
            "semester/semesters_list", {
                per_page: per_page,
                page: page,
                name_search: name_search,
                start_date: start_date ? utils.get_utc_date(start_date).toISOString() : null,
                end_date: end_date ? utils.get_utc_date(end_date).toISOString() : null,
                archived: archived,
            })
            .then((res) => {
                return { semesters: res.data.semesters.map((semester: any) => {
                        return {
                            _id: semester._id,
                            archived: semester.archived,
                            name: semester.name,
                            start_date: new DateTime(semester.start_date).to_date(),
                            end_date: new DateTime(semester.end_date).to_date(),
                                }
                        }),
                    semesters_count: res.data.semesters_count


                } as SemesterList;
            })
            .catch((e) => {
                console.log(e);
            });
    }


    export const get_active_semesters = async () => {
        return HTTPApi.get("/semester/get_active_semesters")
            .then((res) => {
                return res.data.map((semesterData: any) => new Semester(semesterData));
            });
    }


    export const get_semester = async (semester_id: ObjectId) => {
        return HTTPApi.get("/semester/get/" + semester_id.$oid)
            .then((res) => {
                return new Semester(res.data);
            });
    }

    export const archive_semester = async (semester_id: ObjectId) => {
        return HTTPApi.post(
            "semester/archive", {
                _id: semester_id,
            }
        );
    }

    export const create = async (name: string, start_date: Date, end_date: Date) => {
        return HTTPApi.post(
            "semester/create", {
                name: name,
                start_date: start_date,
                end_date: end_date,
            }
        ).then((res) => {
            return new Semester(res.data);
        });
    }

    export const update_semester = async (semester_id: ObjectId, name: string, start_date: Date, end_date: Date) => {
        return HTTPApi.post(
            "semester/update", {
                _id: semester_id,
                name: name,
                start_date: utils.get_utc_date(start_date).toISOString(),
                end_date: utils.get_utc_date(end_date).toISOString(),
            }
        ).then((res) => {
            return new Semester(res.data);
        });
    }
}