import React from "react";
import {
    Box,
    Stack,
    Skeleton,
    IconButton,
    useToast,
    Input,
    useDisclosure,
    Alert,
    HStack,
    Spacer,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverBody,
    Popover,
    AlertIcon,
} from '@chakra-ui/react';
import {TableContent} from "../../table/TableContent";
import {DeleteDialog} from "../../dialog/DeleteDialog";
import 'react-datepicker/dist/react-datepicker.css';
import {DeleteIcon, QuestionIcon} from "@chakra-ui/icons";
import {MarkCategory} from "../../../models/mark_category";
import {MarkCategoriesAPI} from "../../../api/MarkCategoryAPI";

interface MarkCategoryObj {
    mark_category_obj: MarkCategory,
    run1: any
}

interface MarkCategoryTableObj {
    data: MarkCategoryObj,
}

interface MarkbookProps {
    entry_data: Array<MarkCategoryTableObj> | undefined,
    total_weight: number
    update_func: any
}

export const MarkCategoryTable = React.memo(({entry_data, total_weight, update_func}: MarkbookProps) =>  {
    const toast = useToast()

    return (
        <Box>
            <HStack>
                {total_weight == 100 ?
                <Alert status='success'>
                    <AlertIcon />
                        Total Weight: {total_weight!}
                    <Spacer/>
                    <HStack>
                        <Popover placement='left'>
                            <PopoverTrigger>
                                <IconButton aria-label={"Weight"} >
                                    <QuestionIcon/>
                                </IconButton>
                            </PopoverTrigger>
                            <PopoverContent>
                                <PopoverArrow />
                                <PopoverCloseButton />
                                    <PopoverBody>
                                        The final weight should always be 100%
                                        <Box>
                                        </Box>
                                    </PopoverBody>
                            </PopoverContent>
                        </Popover>
                    </HStack>
                </Alert>
                :
                <Alert status='error'>
                    <AlertIcon />
                        Total Weight: {total_weight!}
                    <Spacer/>
                    <Popover placement='left'>
                        <PopoverTrigger>
                            <IconButton aria-label={"Weight"} >
                                <QuestionIcon/>
                            </IconButton>
                        </PopoverTrigger>
                        <PopoverContent>
                            <PopoverArrow />
                            <PopoverCloseButton />
                                <PopoverBody>
                                    The final weight should always be 100%
                                    <Box>
                                    </Box>
                                </PopoverBody>
                        </PopoverContent>
                    </Popover>
                </Alert>
            }
            </HStack>
            {entry_data ?
                    <TableContent data={entry_data} columns={MarkbookColumns} hover_color='cyan.300' disable_hover={true}/>
                :
                    <Stack>
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                    </Stack>
            }
        </Box>
    );
} );

let MarkbookColumns = [
    {
        Header: "Name",
        accessor: "data",
        Cell: function StatusCell(data: MarkCategoryObj) {
            const toast = useToast()

            const on_value_change = (value: string) => {
                if(value == data.mark_category_obj.name){
                    return
                }
                data.mark_category_obj.name = value
                MarkCategoriesAPI.update_mark_category(data.mark_category_obj)
                    .then(() => {
                        data.run1()
                        toast({
                            title: 'Success.',
                            description: "Updated Evaluation: " + data.mark_category_obj.name,
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                      })
                    })
                    .catch((e) => {
                        toast({
                            title: 'Error.',
                            description: "Failed to update " + data.mark_category_obj.name + " total",
                            status: 'error',
                            duration: 5000,
                            isClosable: true,
                          })
                    })
            }
            return (
                <Box minW='100px'>
                    <Input
                        defaultValue={String(data.mark_category_obj.name)}
                        onBlur={(value) => on_value_change(value.target.value)}
                        colorScheme={"green"}
                    />
                </Box>
            )
        },
    },
    {
        Header: "Weight",
        accessor: "data",
        Cell: function StatusCell(data: MarkCategoryObj) {
            const toast = useToast()

            const on_value_change = (value: string) => {
                if(Number(value) == data.mark_category_obj.weight){
                    return
                }
                data.mark_category_obj.weight = Number(value)
                data.run1()
                MarkCategoriesAPI.update_mark_category(data.mark_category_obj)
                    .then(()=> {
                        data.run1()
                        toast({
                            title: 'Success.',
                            description: "Updated Mark Category Weight: " + data.mark_category_obj.weight,
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                      })
                    })
                    .catch((e) => {console.log(e)
                        toast({
                            title: 'Error.',
                            description: "Failed to update " + data.mark_category_obj.weight + " weight",
                            status: 'error',
                            duration: 5000,
                            isClosable: true,
                          })
                })
            }
            return (
                <Box minW='60px' maxW='80px'>
                    <Input
                        defaultValue={String(data.mark_category_obj.weight)}
                        onBlur={(value) => on_value_change(value.target.value)}
                    />
                </Box>
            )
        },
    },
    {
        Header: "Delete",
        accessor: "data",
        Cell: function StatusCell(data: MarkCategoryObj) {

            const { isOpen, onOpen, onClose } = useDisclosure()

            const delete_action = () => {
                MarkCategoriesAPI.delete_mark_category(data.mark_category_obj._id)
                    .then(() => {
                        data.run1()
                    })
            }

            return (
                <Box>
                    <IconButton aria-label={"Delete"} colorScheme='red' onClick={onOpen}>
                        {<DeleteIcon />}
                    </IconButton>
                    <DeleteDialog isOpen={isOpen} onClose={onClose} action={delete_action}/>
                </Box>
            )
        },
    },
]
