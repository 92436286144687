import {
Box,
Button,
Divider,
Flex,
FormControl,
FormLabel,
Stack,
useColorModeValue,
useToast,
} from '@chakra-ui/react'
import * as React from 'react'
import 'react-datepicker/dist/react-datepicker.css';
import LocalStorageService from '../../../api/LocalStorageService';
import { StudentAPI } from '../../../api/StudentAPI';
import { UserType } from '../../../api/UserApi';
import { Student } from '../../../models/student';
import { DateTime } from '../../../utils/DateTime';
import DatePicker from '../../DatePicker'
import { ImmigrationStatusCard } from './ImmigrationStatusCard';
import { PreviousSchoolCard } from './PreviousSchoolCard';

interface Card {
    student: Student
    set_student: React.Dispatch<React.SetStateAction<Student | undefined>>
}

export const OfficeInformationCard = React.memo(({student, set_student}: Card) => {
    const toast = useToast()

    const update_office_information_state = (name: any, value: any) => {
        set_student(
            prevState => (prevState ? {
                ...prevState,
                office_information: { ...prevState.office_information, [name]: value}
            } : undefined)
        );
    }

    const update_office_information = () => {
        StudentAPI.update_office_information(student._id, student.office_information)
        .then((res) => {
            StudentAPI.get_student(student._id.$oid).then(res => set_student(res!))
            toast({
                title: 'Success',
                description: "Saved Historical Info",
                status: 'success',
                duration: 5000,
                isClosable: true,
                })
        })
        .catch((err) => {
            console.log(err)
            toast({
                title: 'Error.',
                description: "Failed to update office info",
                status: 'error',
                duration: 5000,
                isClosable: true,
                })
        })
    }

    return (
        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
        >
            <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                { LocalStorageService.getInstance().getUser()?.user_type == UserType.Parent &&

                    <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                        <FormControl id="registration_date">
                            <FormLabel>Registration Date</FormLabel>
                            <DatePicker 
                                selectedDate={student.office_information.registration_date ? student.office_information.registration_date.to_date() : undefined} 
                                onChange={(value) => {
                                    update_office_information_state("registration_date", DateTime.from_date(value as Date))
                                }}
                            />
                        </FormControl>
                        <FormControl id="admission_date">
                            <FormLabel>Admission Date</FormLabel>
                            <DatePicker 
                                selectedDate={student.office_information.admission_date ? student.office_information.admission_date.to_date() : undefined} 
                                onChange={(value) => {
                                    update_office_information_state("admission_date", DateTime.from_date(value as Date))
                                }}
                            />
                        </FormControl>
                    </Stack>

                }
                

                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    <FormLabel>Immigration Status</FormLabel>
                    <ImmigrationStatusCard student={student} set_student={set_student}/>
                </Stack>
                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    <FormLabel>Previous School Info</FormLabel>
                    <PreviousSchoolCard student={student} set_student={set_student}/>
                </Stack>
            </Stack>
            <Divider />
            <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
                <Button onClick={update_office_information}>
                    Save
                </Button>
            </Flex>
        </Box>
    )

})
