import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Stack,
    Text,
    useColorModeValue,
    useToast,
    HStack,
    Spacer,
    Heading,
    SimpleGrid,
    TabPanels,
    Tabs,
    TabList,
    Tab,
    TabPanel,
} from '@chakra-ui/react';
import { ObjectId, to_oid } from "../../utils/ObjectId";

import {FiArchive, FiCopy, FiPlus} from "react-icons/fi";
import { Course, CourseUpdate } from "../../models/course";
import { CourseAPI } from "../../api/CourseAPI";
import { AttendanceAPI } from "../../api/AttendanceAPI";
import DatePicker from "../DatePicker";
import { CheckIcon } from "@chakra-ui/icons";
import { UserRole } from "../../api/UserApi";
import LocalStorageService from "../../api/LocalStorageService";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { updateCourseSchema } from "../../validations/course";
import FormInput from "../forms/FormInput";
import ExternalLinks from "./ExternalLinks";
import {SemesterAPI} from "../../api/SemesterAPI";
import FormSelectOptions from "../forms/FormSelectObjects";
import { Teacher } from "../../models/teacher";
import { Lookup } from "../../models/lookup";
import { TeacherAPI } from "../../api/TeacherAPI";
import { LightButton } from "../layout/LightButton";
import SimpleTeacherList from "../teachers/SimpleTeacherList";
import AddStudentsDialog from "./AddStudentsDialog";
import AddTeachersDialog from "./AddTeachersDialog";
import ReportLayoutForm from "./cards/ReportLayoutForm";
import { ReportLayout } from "../../models/report_layout";
import {CreateCourseDialog} from "./CreateCourseDialog";
import GClassItemViewer from "../gclass/GClassItemViewer";
import { StudentAPI } from "../../api/StudentAPI";
import { Student } from "../../models/student";
import SimpleStudentsList from "../students/SimpleStudentsList";


export const CourseDashboard = React.memo(() =>  {
    let { course_id } = useParams();

    const toast = useToast()
    const [course, set_course] = useState<Course>()
    const [semesters, set_semesters] = useState<Lookup[]>([]);
    const [students, set_students] = useState<Student[]>();
    const [teachers, set_teachers] = useState<Teacher[]>();
    const [teachers_lookup, set_teachers_lookup] = useState<Lookup[]>([]);
    const { register, handleSubmit, reset, control, formState: { errors } } = useForm<CourseUpdate>({
        resolver: yupResolver(updateCourseSchema),
    });

    const [reportLayout, setReportLayout] = useState<ReportLayout | undefined>();

    const [loading, set_loading] = useState<boolean>(false);
    const [archive_loading, set_archive_loading] = useState<boolean>(false);

    const [isOpen_add_teachers, setIsOpen_add_teachers] = useState(false)

    const [isOpen_add_students, setIsOpen_add_students] = useState(false)

    const [attendance_start_date, set_attendance_start_date] = useState<Date>(new Date(Date.now()))
    const [attendance_end_date, set_attendance_end_date] = useState<Date>(new Date(Date.now()))

    const user = LocalStorageService.getInstance().getUser();
    const canModifyCourse = user?.has_role(UserRole.Can_Modify_Course);

    const [isOpen_course, setIsOpen_course] = useState(false)
    const onClose_course = () => setIsOpen_course(false)

    const fetchCourseAndReportLayout = () => {
        CourseAPI.get_course(to_oid(course_id!)).then((res) => {

            set_course(res)

            let course_update: CourseUpdate = {
                ...res,
                semester_id: res.semester._id, // get semester id from course object
            }
            reset(course_update)

            if (res?.teacher_ids.length) {
                TeacherAPI.teachers_by_ids(res.teacher_ids).then(res => {
                    set_teachers(res)
                })
            } else set_teachers([])

            if (res?.student_ids.length) {
                StudentAPI.students_by_ids(res.student_ids).then(res => {
                    set_students(res)
                })
            } else set_students([])

        })

        CourseAPI.get_report_layout(to_oid(course_id!)).then((res) => {
            setReportLayout(res)
        })

    }

    const onClose_add_teachers = () => {
        setIsOpen_add_teachers(false)

        fetchCourseAndReportLayout()
    }
    const onClose_add_students = () => {
        setIsOpen_add_students(false)

        fetchCourseAndReportLayout()
    }

    useEffect(() => {
        fetchCourseAndReportLayout()
    }, []);

    useEffect(()=>{
        SemesterAPI.semester_list(null, undefined, "", null,null, false)
            .then((res) => {
                let data = res!.semesters.map((e) => {
                    return {
                        "value": e._id,
                        "label": e.name
                    }
                })
                set_semesters(data)
            })

        TeacherAPI.teachers_list(null, null, null)
            .then((res) => {
                let data = res!.teachers.map((e) => {
                    return {
                        "value": e._id,
                        "label": e.profile.first_name + " " + e.profile.last_name
                    }
                })
                set_teachers_lookup(data)
            })
    }, [])

    const download_attendance_csv = () => {
        AttendanceAPI.course_attendance_csv_data(course!, attendance_start_date, attendance_end_date)
            .catch((err) => {
                toast({
                    title: 'Error',
                    description: "Failed to download attendance csv",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            })
    }

    const update_course = async (data: CourseUpdate) => {
        set_loading(true)
        try {
            const updatedCourse = await CourseAPI.update(
                data._id,
                data.semester_id,
                data.name,
                data.year,
                data.course_code
            );

            set_course(updatedCourse);

            toast({
                title: 'Success',
                description: "Course updated successfully",
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Error',
                description: "Course failed to update",
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            set_loading(false)
        }
    }

    const archive = () => {
        set_archive_loading(true)
        CourseAPI.archive_course(course!._id)
        .then(() => {
            toast({
                title: 'Success',
                description: "Course Archived",
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
        })
        .catch(() => {
            toast({
                title: 'Error',
                description: "Failed to Archive Course",
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        })
    }

    const removeTeacher = async (teacherId: ObjectId) => {
        try {
            await CourseAPI.remove_teachers(to_oid(course_id!), [teacherId]);
        
            toast({
                title: 'Success',
                description: 'Teacher removed successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });

            fetchCourseAndReportLayout()
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to remove Teacher',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const removeStudent = async (studentId: ObjectId) => {
        try {
            await CourseAPI.remove_students(to_oid(course_id!), [studentId]);
        
            toast({
                title: 'Success',
                description: 'Student removed successfully',
                status: 'success',
                duration: 5000,
                isClosable: true,
            });

            fetchCourseAndReportLayout()
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to remove Student',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <>
        <HStack align={"center"} mb={8}>
            <Heading as="h1" size="md" fontWeight={"bold"}>
                {course?.name} Course
            </Heading>

            <Spacer/>

            <ExternalLinks type="course" id={course_id!} />
        </HStack>
        <Tabs variant='line'>
            <TabList>
                <Tab>General</Tab>

                <Tab>Academic Tracker</Tab>
            </TabList>

            <TabPanels>
                <TabPanel>

                    <Box
                        as="form"
                        bg="bg-surface"
                        boxShadow={useColorModeValue('sm', 'sm-dark')}
                        borderRadius="lg"
                        flex="1"
                        mb="4"
                        onSubmit={handleSubmit(update_course)}
                    >
                        <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>

                            <HStack>
                                <FormLabel fontSize={"lg"} fontWeight="bold">
                                    Course Information
                                </FormLabel>

                                <Spacer />

                                { canModifyCourse && (
                                        <Button
                                            isLoading={loading}
                                            colorScheme='green'
                                            leftIcon={<CheckIcon/>}
                                            type="submit"
                                        >
                                            Update
                                        </Button>
                                    )
                                }
                            </HStack>

                            <SimpleGrid columns={{sm: 1, md: 4}} spacing={10}>
                                <FormInput
                                    name="name"
                                    label="Name"
                                    register={register('name', { required: true })}
                                    error={errors.name?.message}
                                    isDisabled={!canModifyCourse}
                                    isRequired
                                />

                                <FormInput
                                    name="year"
                                    label="Year"
                                    register={register('year', { required: true })}
                                    error={errors.year?.message}
                                    isDisabled={!canModifyCourse}
                                    isRequired
                                />
                                <FormSelectOptions
                                    name="semester_id"
                                    label="Semester"
                                    options={semesters}
                                    control={control}
                                    error={errors.semester_id?.message}
                                    isDisabled={!canModifyCourse}
                                    isRequired
                                />
                                <FormInput
                                    name="course_code"
                                    label="Course Code"
                                    register={register('course_code', { required: true })}
                                    error={errors.course_code?.message}
                                    isDisabled={!canModifyCourse}
                                    isRequired
                                />
                            </SimpleGrid>
                        </Stack>
                    </Box>

                    <Box
                        bg="bg-surface"
                        boxShadow={useColorModeValue('sm', 'sm-dark')}
                        borderRadius="lg"
                        flex="1"
                        mb="4"
                    >
                        <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                            <HStack>
                                <FormLabel fontSize={"lg"} fontWeight="bold">
                                    Teachers
                                </FormLabel>

                                <Spacer/>

                                <LightButton
                                    color="green"
                                    leftIcon={<FiPlus />} 
                                    onClick={() => setIsOpen_add_teachers(true)}
                                >
                                    Add Teacher
                                </LightButton>

                                { course && 
                                    <AddTeachersDialog
                                        course={course._id}
                                        isOpen={isOpen_add_teachers} 
                                        onClose={onClose_add_teachers}
                                    />
                                }
                            </HStack>

                            <SimpleTeacherList teachers={teachers} onDelete={removeTeacher} />
                        </Stack>
                    </Box>

                    <Box
                        bg="bg-surface"
                        boxShadow={useColorModeValue('sm', 'sm-dark')}
                        borderRadius="lg"
                        flex="1"
                        mb="4"
                    >
                        <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                            <HStack>
                                <FormLabel fontSize={"lg"} fontWeight="bold">
                                    Students
                                </FormLabel>

                                <Spacer/>

                                <LightButton
                                    color="green"
                                    leftIcon={<FiPlus />} 
                                    onClick={() => setIsOpen_add_students(true)}
                                >
                                    Add Students
                                </LightButton>

                                { course && 
                                    <AddStudentsDialog
                                        course={course._id}
                                        isOpen={isOpen_add_students} 
                                        onClose={onClose_add_students}
                                    />
                                }
                            </HStack>

                            <SimpleStudentsList students={students} onDelete={removeStudent} />
                        </Stack>
                    </Box>

                    <Box
                        bg="bg-surface"
                        boxShadow={useColorModeValue('sm', 'sm-dark')}
                        borderRadius="lg"
                        flex="1"
                        mb="4"
                    >
                        <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                            <HStack>
                                <Text fontSize={"lg"} fontWeight="bold">
                                    Attendance Export
                                </Text>

                                <Spacer/>

                                <LightButton
                                    color="blue"
                                    onClick={download_attendance_csv}
                                >
                                    Export Attendance CSV
                                </LightButton>
                            </HStack>

                            <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                                <FormControl id="start_date" isRequired>
                                    <FormLabel>Start Date</FormLabel>
                                    <DatePicker
                                        selectedDate={attendance_start_date}
                                        onChange={(value) => {
                                            set_attendance_start_date(value as Date)
                                        }}
                                    />
                                </FormControl>
                                <FormControl id="end_date" isRequired>
                                    <FormLabel>End Date</FormLabel>
                                    <DatePicker
                                        selectedDate={attendance_end_date}
                                        onChange={(value) => {
                                            set_attendance_end_date(value as Date)
                                        }}
                                    />
                                </FormControl>
                            </Stack>
                        </Stack>
                    </Box>

                    <Box mt="8">
                        <ReportLayoutForm
                            reportLayout={reportLayout}
                            onSubmitSuccess={(updatedLayout) =>setReportLayout(updatedLayout)}
                        />
                    </Box>

                    
                    <HStack>
                        <Spacer />
                        {course?.homeroom === null && (
                            <Button
                                bgColor={"blue.100"}
                                textColor="blue.700"
                                leftIcon={<FiCopy />}
                                onClick={() => setIsOpen_course(true)}
                            >
                                Clone Course
                            </Button>
                        )}
                        {course && (
                            <CreateCourseDialog
                                isOpen={isOpen_course}
                                onClose={onClose_course}
                                clone_course_from={course}
                                teachers={teachers_lookup}
                                clone_report_layout_from={reportLayout}
                                semesters={semesters}
                            />
                        )}

                        <Button
                            bgColor={"orange.100"}
                            textColor="orange.700"
                            colorScheme='yellow'
                            leftIcon={<FiArchive/>}
                            onClick={archive}
                            isDisabled={course?.archived || archive_loading}
                        >
                            Archive
                        </Button>
                    </HStack>
                </TabPanel>

                <TabPanel>
                    <Box
                        bg="bg-surface"
                        boxShadow={useColorModeValue('sm', 'sm-dark')}
                        borderRadius="lg"
                        flex="1"
                        mb="4"
                    >
                        <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                            {
                                course && <GClassItemViewer type="course" item={course!} />
                            }
                        </Stack>
                    </Box>
                </TabPanel>

            </TabPanels>
        </Tabs>
        </>
    );
})
