import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Center, Heading, Skeleton, Stack } from '@chakra-ui/react';
import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { StudentAPI } from '../../api/StudentAPI';
import { FormDataInterface, Student } from '../../models/student';
import { FormStatusCard } from './FormStatus';

export const FormStatusRoute = (props: any) => {

    let navigate = useNavigate();
    let { _form_type, student_id } = useParams();
    const form_type = _form_type as keyof FormDataInterface;

    const [student, set_student] = React.useState<Student>();

    React.useEffect(() => {
        StudentAPI.get_student(student_id!)
            .then((res) => {
                set_student(res!)
            })
    }, []);


    return (
        <>
        { student ?
            <>
            <Box>
                <Box overflowX="auto" overflowY="hidden">
                    <Center>
                        <Heading size="lg" mb="6">
                            Student Forms
                        </Heading>
                    </Center>
                    <Stack>
                        <Breadcrumb spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
                            <BreadcrumbItem>
                                <BreadcrumbLink href='/parent/home'>Home</BreadcrumbLink>
                            </BreadcrumbItem>

                            <BreadcrumbItem>
                                <BreadcrumbLink href='/parent/family'>Family</BreadcrumbLink>
                            </BreadcrumbItem>

                            <BreadcrumbItem>
                                {student&& <BreadcrumbLink href={"/parent/student/" + student?._id.$oid}>{student?.profile.first_name + " " + student?.profile.last_name}</BreadcrumbLink>}
                            </BreadcrumbItem>

                            <BreadcrumbItem isCurrentPage>
                                <BreadcrumbLink href='#'>Form</BreadcrumbLink>
                            </BreadcrumbItem>
                        </Breadcrumb>
                        <FormStatusCard form_type={form_type} student={student} set_student={set_student}/>
                    </Stack>
                </Box>
            </Box>
        
            </>
            
        :
            (
                <Stack>
                    <Skeleton height='100px' />
                    <Skeleton height='100px' />
                    <Skeleton height='100px' />
                </Stack>
            )
        }
        </>
    )
}