import { Box, Button, HStack, Skeleton, Spacer, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useToast, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import LocalStorageService from "../../api/LocalStorageService";
import { ReportsAPI } from "../../api/ReportsAPI";
import { StudentAPI } from "../../api/StudentAPI";
import { UserRole } from "../../api/UserApi";
import { FrontReportEntry } from "../../models/front_report_entry";
import { Homeroom } from "../../models/homeroom";
import { ReportEntry, ReportType } from "../../models/report_entry";
import { Student } from "../../models/student";
import { Subject } from "../../models/subject";
import { ObjectId, to_oid } from "../../utils/ObjectId";
import { FrontPageReport } from "./FrontPageReport";
import { HomeroomSubject } from "./HomeroomSubject";


export const StudentHomeroomReports = () =>  {
    let { class_id, student_id } = useParams();

    const toast = useToast();

    let [reports, set_reports] = React.useState<Array<ReportEntry>>()
    let [progress_front_report_entry, set_progress_front_report_entry] = React.useState<FrontReportEntry>()
    let [midterm_front_report_entry, set_midterm_front_report_entry] = React.useState<FrontReportEntry>()
    let [final_front_report_entry, set_final_front_report_entry] = React.useState<FrontReportEntry>()

    let [_homeroom, set_homeroom] = React.useState<Homeroom>()
    const [subjects, set_subjects] = React.useState<Subject[]>();
    let [student, set_student] = React.useState<Student>()

    let [refresh_subjects, set_refresh_subjects] = React.useState<boolean>(false)

    let [pdf_downloading, set_pdf_downloading] = React.useState<boolean>(false)

    const get_reports = () => {
        set_reports(undefined)
        set_midterm_front_report_entry(undefined)
        set_final_front_report_entry(undefined)
        set_subjects(undefined)

        StudentAPI.get_student(student_id!).then((e) => set_student(e!))

        ReportsAPI.homeroom_reports(to_oid(student_id!), to_oid(class_id!)).then((res)=> {
            set_homeroom(res!.homeroom)
            set_subjects(res!.subjects)
            set_progress_front_report_entry(res!.progress_front_report_entry)
            set_midterm_front_report_entry(res!.midterm_front_report_entry)
            set_final_front_report_entry(res!.final_front_report_entry)

            if(LocalStorageService.getInstance().getUser()?.has_role(UserRole.Super_Admin) || LocalStorageService.getInstance().getUser()?.has_role(UserRole.Can_See_All_Reports) || res!.homeroom.teacher_ids.find((a)=> a.$oid == LocalStorageService.getInstance().getUser()!._id.$oid)) {
                set_reports(res!.reports)
            }
            else{
                let sections = res!.subjects
                    .filter((e) => e.teachers.length > 0  && e.teachers.find((a)=> a.$oid == LocalStorageService.getInstance().getUser()!._id.$oid) )
                    .map((e) => e._id.$oid)
                let r = res!.reports.filter((e) => sections.includes(e.homeroom_subject_layout!.$oid))
                set_reports(r)
            }
        })
    }

    useEffect(() => {
        get_reports()
    }, []);

    useEffect(() => {
        if(refresh_subjects){
            get_reports()
            set_refresh_subjects(false)
        }
    }, [refresh_subjects]);


    return (
            <Box >
                    <HStack>
                        <VStack>
                            <Text>Student: {student?.profile.first_name} {student?.profile.last_name}</Text>
                            <Text>OEN: {student?.profile.OEN}</Text>
                        </VStack>
                        <Spacer/>
                        <Button
                            colorScheme="green"
                            isLoading={pdf_downloading}
                            onClick={() => {
                                if(student){
                                    set_pdf_downloading(true)
                                    ReportsAPI.elementary_progress_report_card_pdf(to_oid(student_id!), to_oid(class_id!), student.profile.first_name + "_" + student.profile.last_name).then((res) => {
                                        set_pdf_downloading(false)
                                    })
                                    .catch((err) => {
                                        toast({
                                            title: 'Error.',
                                            description: "Failed to download PDF",
                                            status: 'error',
                                            duration: 5000,
                                            isClosable: true,
                                        })
                                        set_pdf_downloading(false)
                                    })
                                }

                            }}
                        >Download Progress PDF</Button>
                        <Button
                            colorScheme="green"
                            isLoading={pdf_downloading}
                            onClick={() => {
                                if(student){
                                    set_pdf_downloading(true)
                                    ReportsAPI.elementary_final_report_card_pdf(to_oid(student_id!), to_oid(class_id!), student.profile.first_name + "_" + student.profile.last_name).then((res) => {
                                        set_pdf_downloading(false)
                                    })
                                    .catch((err) => {
                                        toast({
                                            title: 'Error.',
                                            description: "Failed to download PDF",
                                            status: 'error',
                                            duration: 5000,
                                            isClosable: true,
                                        })
                                        set_pdf_downloading(false)
                                    })
                                }

                            }}
                        >Download Report PDF</Button>
                    </HStack>

                    <Stack>
                        <Tabs isFitted>
                        <TabList>
                            <Tab>Progress</Tab>
                            <Tab>Midterm</Tab>
                            <Tab>Final</Tab>
                            <Tab>Learning Skills</Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel>
                            <Stack>
                                {reports ?
                                    reports.filter(e => e.report_type == ReportType.Progress).map((e) => {
                                        console.log(e)
                                        return (
                                            <>
                                            <HomeroomSubject report_entry={e} homeroom={_homeroom!} subjects={subjects!} set_refresh={set_refresh_subjects}/>
                                            </>

                                        )
                                    })
                                    :
                                    <Stack>
                                        <Skeleton height='100px' />
                                        <Skeleton height='100px' />
                                        <Skeleton height='100px' />
                                        <Skeleton height='100px' />
                                        <Skeleton height='100px' />
                                        <Skeleton height='100px' />
                                        <Skeleton height='100px' />
                                        <Skeleton height='100px' />
                                        <Skeleton height='100px' />
                                    </Stack>
                                }
                                </Stack>
                            </TabPanel>
                            <TabPanel>
                                <Stack>
                                {reports ?
                                    reports.filter(e => e.report_type == ReportType.Midterm).map((e) => {
                                        return (
                                            <>
                                            <HomeroomSubject report_entry={e} homeroom={_homeroom!} subjects={subjects!} set_refresh={set_refresh_subjects}/>
                                            </>

                                        )
                                    })
                                    :
                                    <Stack>
                                        <Skeleton height='100px' />
                                        <Skeleton height='100px' />
                                        <Skeleton height='100px' />
                                        <Skeleton height='100px' />
                                        <Skeleton height='100px' />
                                        <Skeleton height='100px' />
                                        <Skeleton height='100px' />
                                        <Skeleton height='100px' />
                                        <Skeleton height='100px' />
                                    </Stack>
                                }
                                </Stack>
                            </TabPanel>
                            <TabPanel>
                                <Stack>
                                {reports ?
                                    reports.filter(e => e.report_type == ReportType.Final).map((e) => {
                                        return (
                                            <>
                                            <HomeroomSubject report_entry={e} homeroom={_homeroom!} subjects={subjects!} set_refresh={set_refresh_subjects}/>
                                            </>

                                        )
                                    })
                                    :
                                    <Stack>
                                        <Skeleton height='100px' />
                                        <Skeleton height='100px' />
                                        <Skeleton height='100px' />
                                        <Skeleton height='100px' />
                                        <Skeleton height='100px' />
                                        <Skeleton height='100px' />
                                        <Skeleton height='100px' />
                                        <Skeleton height='100px' />
                                        <Skeleton height='100px' />
                                    </Stack>
                                }
                                </Stack>
                            </TabPanel>
                            <TabPanel>
                                <Tabs isFitted>
                                <TabList>
                                    <Tab>Progress</Tab>
                                    <Tab>Midterm</Tab>
                                    <Tab>Final</Tab>
                                </TabList>

                                <TabPanels>
                                    <TabPanel>
                                        {_homeroom && progress_front_report_entry &&
                                            <FrontPageReport homeroom={_homeroom} _report={progress_front_report_entry}/>
                                        }
                                    </TabPanel>
                                    <TabPanel>
                                        {_homeroom && midterm_front_report_entry &&
                                            <FrontPageReport homeroom={_homeroom} _report={midterm_front_report_entry}/>
                                        }
                                    </TabPanel>

                                    <TabPanel>
                                        {_homeroom && final_front_report_entry &&
                                            <FrontPageReport homeroom={_homeroom} _report={final_front_report_entry}/>
                                        }
                                    </TabPanel>
                                </TabPanels>
                                </Tabs>
                            </TabPanel>
                        </TabPanels>
                        </Tabs>
                    </Stack>

            </Box>
    );
};
