import { object, string} from 'yup'
import { ObjectIdSchema, objectWithId } from './schema'

export const updateHomeroomSchema = objectWithId.concat(
	object().shape({
		name: string().required('Name is required'),
		semester_id: ObjectIdSchema.required('semester id is required'),
		grade: string().required('Grade is Required'),
	})
)
