import {
    Box,
    FormControl,
    FormLabel,
    Input,
    Stack,
    VStack,
    useColorModeValue,
    useToast,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    Skeleton,
    AlertDialogFooter, Button, Select,
} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import {Evaluation} from "../../../models/evaluation";
import {new_oid, ObjectId} from "../../../utils/ObjectId";
import {EvaluationAPI} from "../../../api/EvaluationAPI";
import {MarkCategoriesAPI} from "../../../api/MarkCategoryAPI";
import {MarkCategory} from "../../../models/mark_category";
import {
    Select as SearchSelect,
} from "chakra-react-select";
import {ObjectID} from "bson";
import {DateTime} from "../../../utils/DateTime";


interface CreateEvaluationDialogCard {
    class_id: ObjectId
    isOpen: boolean
    onClose: any
    cancelRef: any
}


export const CreateEvaluation = React.memo(({class_id, isOpen, onClose, cancelRef}: CreateEvaluationDialogCard) => {
    const toast = useToast()
    const [loading, set_loading] = React.useState<boolean>(false);

    const [evaluation, set_evaluation] = React.useState<Evaluation>(
        {
            _id: new_oid(),
            class_id: class_id,
            name: "",
            total: 0,
            weight: 0,
            date: DateTime.from_date(new Date()),
            mark_category: undefined
        }
    );

    const required_info = () => {
        return evaluation.name !== "" &&
            evaluation.total !== 0 &&
            evaluation.weight !== 0 &&
            evaluation.mark_category !== undefined
    }

    const create = () => {
        if(required_info()){
            set_loading(true)
            EvaluationAPI.create_evaluation(evaluation)
                .then()
                .finally(() => {
                    cancel()
                })
            set_loading(false)

        }else{
            toast({
                title: 'Error.',
                description: "Please fill in all the required information",
                status: 'error',
                duration: 5000,
                isClosable: true,
              })
        }
    }

    const cancel = () => {
        set_evaluation(
            {
                _id: new_oid(),
                class_id: class_id,
                name: "",
                total: 0,
                weight: 0,
                date: DateTime.from_date(new Date()),
                mark_category: undefined
            })
        onClose()
    }

    return (
        <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Create Evaluation
            </AlertDialogHeader>

            <AlertDialogBody>
              {loading ?
                <Stack>
                  <Skeleton height='20px' />
                  <Skeleton height='20px' />
                  <Skeleton height='20px' />
                </Stack>
                :
                <>
                  <CreateEvaluationDialog evaluation={evaluation} set_evaluation={set_evaluation} show_overview={false} show_save={false}/>
                </>
              }
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={cancel} isDisabled={loading}>
                Cancel
              </Button>
              <Button colorScheme='green' onClick={create} ml={3} isDisabled={loading}>
                Create
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    )

})


interface CreateEvaluationCard {
    evaluation: Evaluation,
    set_evaluation: React.Dispatch<React.SetStateAction<Evaluation | undefined>> | React.Dispatch<React.SetStateAction<Evaluation>>
    show_save?: boolean
    show_overview?: boolean
}

export const CreateEvaluationDialog = ({evaluation, set_evaluation} : CreateEvaluationCard) =>  {

    const [mark_categories, set_mark_categories] = useState<Array<MarkCategory>>()

    const update_evaluation_state = (name: string, value: any) => {
        set_evaluation(
            (prevState: any) =>
                ({...prevState,
                [name]: value})
        );
    }

    const get_mark_categories = () => {
        MarkCategoriesAPI.get_mark_category_list_from_class(evaluation.class_id)
            .then((res) => {
            set_mark_categories(res)
        })
    }

    useEffect(() => {
        get_mark_categories()
    }, [])

    return (
        <Stack>
        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
        >
            <Stack px={{ base: '4'}}>
                <br></br>
                <VStack spacing="2" direction={{ base: 'column', md: 'row' }}>
                    <FormControl id="name" isRequired>
                        <FormLabel>Name</FormLabel>
                        <Input
                            value={evaluation.name}
                            onChange={(value) => {
                                update_evaluation_state(value.target.id, value.target.value)
                            }}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>
                            Mark Book Categories
                        </FormLabel>
                        <SearchSelect
                            name="flavors"
                            options={mark_categories?.map((res) => {
                                return {
                                    label: res.name,
                                    value: res
                                }
                            })}
                            placeholder={evaluation.mark_category}
                            onChange={(value) => {
                                update_evaluation_state("mark_category", value!.value._id)
                            }}
                        />
                    </FormControl>
                    <FormControl id="total" isRequired>
                        <FormLabel>Total</FormLabel>
                        <Input
                            value={evaluation.total}
                            onChange={(value) => {
                                update_evaluation_state(value.target.id, Number(value.target.value))
                            }}
                        />
                    </FormControl>
                    <FormControl id="weight" isRequired>
                        <FormLabel>Weight</FormLabel>
                        <Input
                            value={evaluation.weight}
                            onChange={(value) => {
                                update_evaluation_state(value.target.id, Number(value.target.value))
                            }}
                        />
                    </FormControl>
                </VStack>
                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>

                </Stack>
            </Stack>
        </Box>
        </Stack>
    )

}
