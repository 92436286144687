import instance from "../api/ApiCommon";
import {processErrorMessage} from "../utils/error";
import { NavigateFunction } from "react-router-dom";
import LocalStorageService from "../api/LocalStorageService";

const localStorageService = LocalStorageService.getInstance();

const SetupInterceptors = (navigate: NavigateFunction) => {
    instance.interceptors.request.use(
        config => {
            const token = localStorageService.getAccessToken();
            if (token) {
                config.headers!['Authorization'] = 'Bearer ' + token;
            } else {
                LocalStorageService.getInstance().clearToken();
                navigate("/login");
            }
            return config;
        },
        error => {
            return Promise.reject(error);
        }
    );


    instance.interceptors.response.use(
        (response) => {
            return response;
        }, 
        (error) => {
            let errorMessage = 'An unexpected error occurred.';
            let errorCode = 599;  // Default to 599 if no code is provided

            // Check if error response exists
            if (error.response) {
                errorCode = error.response.status;  // Get the error status code
                errorMessage = processErrorMessage(error);
                
                // Check for specific codes like 401 for unauthorized
                if (errorCode === 401) {
                    LocalStorageService.getInstance().clearToken();
                    navigate("/login");
                }
            } else if (!error.response) {
                // Handle CORS or network issues
                errorMessage = "Network or CORS error. Please check your network connection.";
                console.log(errorMessage)
            }

            // Wrap the error and add the status code
            const wrappedError = {
                response: {
                    status: errorCode,  // Include the error code
                    data: errorMessage  // Assign the processed error message to `data`
                }
            };

            return Promise.reject(wrappedError);
        
        }
    );
};

export default SetupInterceptors;