import { Box, Button, Divider, Flex, Heading, HStack, Input, SimpleGrid, Spacer, Stack, Text, Textarea, useColorModeValue, useToast } from "@chakra-ui/react"
import React from "react"
import { ReportsAPI } from "../../api/ReportsAPI"
import { FrontReportEntry } from "../../models/front_report_entry"
import { Homeroom } from "../../models/homeroom"
import { ObjectId } from "../../utils/ObjectId"

interface Card {
    homeroom: Homeroom,
    _report: FrontReportEntry
}

export const FrontPageReport = ({homeroom, _report}: Card) => {
    const toast = useToast()

    const COMMENT_LIMIT = 950

    let [report_entry, set_report_entry] = React.useState<FrontReportEntry>(_report)

    const update_learning_skill_mark = (mark: string, layout_id: ObjectId) => {
        console.log(report_entry)
        let section_index = report_entry.learning_skills.findIndex((e) => e._id.$oid == layout_id.$oid)

        let new_sections = report_entry.learning_skills
        new_sections[section_index].mark = mark

        set_report_entry(
            prevState => (prevState ? {
                ...prevState,
                learning_skills: new_sections
            } : prevState)
        )
    }

    const handle_comment_change = (comment: string) => {
        if(comment.length <= COMMENT_LIMIT){
            set_report_entry(
                prevState => (prevState ? {
                    ...prevState,
                    comment: comment
                } : prevState)
            )
        }
    }

    const update_report = () =>{
        ReportsAPI.update_front_report_entry(report_entry).then((res) => {
            toast({
                title: 'Success',
                description: "Saved Entry",
                status: 'success',
                duration: 5000,
                isClosable: true,
            })
        }).catch((err) => {
            console.log(err)
            toast({
                title: 'Error.',
                description: "Failed to update entry",
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        })
    }

    return (
        <>
        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
        >




        <SimpleGrid columns={2} spacing={8} px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
            {
                homeroom.learning_skills_layout.map((e) => {
                    let report_learning_skill = report_entry.learning_skills.find((entry) => e._id.$oid == entry._id.$oid)!
                    return (
                    <Box shadow='md' borderWidth='1px' px={{ base: '4', md: '2' }} py={{ base: '5', md: '2' }} borderRadius="lg" overflow="auto" flex="1">
                        <Heading px={{ base: '4', md: '4' }} fontSize="xx-large">{e.name}</Heading>
                        <HStack>
                            <Box flex="4">
                                {e.description.split('\n').map((line) => {
                                    return (
                                        <Text>• {line}</Text>
                                        )
                                    })

                                }
                            </Box>
                            <Spacer/>
                            {report_learning_skill &&
                                <Box flex="1">
                                    <Input
                                        placeholder='Mark'
                                        value={report_learning_skill.mark}
                                        onChange={(e) => update_learning_skill_mark(e.target.value, report_learning_skill._id)}
                                    />
                                </Box>
                            }
                        </HStack>
                    </Box>
                    )
                })
            }
            
            
        </SimpleGrid>
        <br></br>
        <Stack direction="row" spacing={8}  px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
        <Box shadow='md' borderWidth='1px' px={{ base: '4', md: '2' }} py={{ base: '5', md: '2' }} borderRadius="lg" overflow="auto" flex="1">
            <Text>Comment:</Text>
            <Textarea 
                value={report_entry.comment}
                onChange={(e) => handle_comment_change(e.target.value)}
            />
            <HStack>
                <Spacer/>
                <Text fontSize="md">Character Limit {report_entry.comment.length}/{COMMENT_LIMIT}</Text>
            </HStack>
        </Box>
        </Stack>

        <Divider />
        <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
            <Button colorScheme="green" onClick={update_report}>
                Save
            </Button>
        </Flex>
        </Box>
        </>
        
    )
}