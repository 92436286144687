import {ObjectId} from "../utils/ObjectId";
import {Timeslot} from "./timeslot";


export enum RepeatBy {
    Weekly="Weekly",
    Daily="Daily",
    ScheduleLength="Schedule Length"
}
export interface ScheduleInput {
    scheduling_params: ScheduleParams;
    courses: Array<CourseInput>;
    subjects: Array<SubjectInput>;
    timeslots: Array<Timeslot>;
}

export interface CourseInput {
    course_id: ObjectId;

    teacher_id: ObjectId;

    weekly_time: number;

    period_length: number;
}

export interface SubjectInput {
    subject_id: ObjectId;

    teacher_id: ObjectId;

    weekly_time: number;

    period_length: number;
}
export interface ScheduleParams {

    start: Date;
    end: Date;
    solution_limit: number;

}
export interface Schedule {
    _id: ObjectId;
    name: string;
    timeslots: Array<Timeslot>
}

export interface ScheduleOutput {

    _id: ObjectId;
    schedules: Array<Schedule>

}
