import HTTPApi from "./ApiCommon";
import { ObjectId } from "../utils/ObjectId";
import {Evaluation, EvaluationStatistics, StudentFinalMark} from "../models/evaluation";


export namespace EvaluationAPI {

    export const get_evaluation_list_from_course = async (class_id: ObjectId) => {
        return HTTPApi.get("/evaluations/evaluations_list/" + class_id.$oid)
            .then((res) => {
                return res.data as Array<Evaluation>
            })
    }

    export const get_evaluation = async (evaluation_id: ObjectId) => {
        return HTTPApi.get("/evaluations/" + evaluation_id.$oid)
            .then((res) => {
                return res.data as Evaluation
            })
    }

    export const get_students_final_mark = async (class_id: ObjectId) => {
        return HTTPApi.get("/evaluations/students_final_mark/" + class_id.$oid)
            .then((res) => {
                return res.data as Array<StudentFinalMark>
            })
    }

    export const get_evaluation_statistics = async (evaluation_id: ObjectId) => {
        return HTTPApi.get("/evaluations/evaluation_statistics/" + evaluation_id.$oid)
            .then((res) => {
                return res.data as EvaluationStatistics
            })
    }

    export const update_evaluation = async (evaluation: Evaluation) => {
        return HTTPApi.post(
                    "evaluations/update",
                    {
                        _id: evaluation._id,
                        name: evaluation.name,
                        total: evaluation.total,
                        weight: evaluation.weight,
                        date: evaluation.date,
                        mark_category: evaluation.mark_category,
                    }
                ).then((res) => {
                    return res.data as Evaluation
                })
    }

    export const create_evaluation = async (evaluation: Evaluation) => {
        return HTTPApi.post(
                    "/evaluations/create",
                    {
                        class_id: evaluation.class_id,
                        name: evaluation.name,
                        total: evaluation.total,
                        weight: evaluation.weight,
                        date: evaluation.date,
                        mark_category: evaluation.mark_category,
                    }
                ).then((res) => {
                    return res.data as Evaluation
                })
    }

    export const delete_evaluation = async (evaluation_id: ObjectId) => {
        return HTTPApi.get("/evaluations/delete/" + evaluation_id.$oid)
            .then()
    }
}
