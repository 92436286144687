import {Attendance} from "../../models/attendance";
import {AttendanceTableData} from "./AttendanceCourse";
import React, {useEffect, useRef, useState} from "react";
import {RStatusRadioEnum, StatusRadioEnum} from "./Attendance";
import {
    AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay,
    Badge,
    Box, Button, Center, Checkbox,
    Popover, PopoverArrow, PopoverCloseButton, PopoverContent,
    PopoverTrigger,
    Radio,
    RadioGroup,
    SimpleGrid,
    Text, Textarea,
    useToast,
    VStack
} from "@chakra-ui/react";
import {AttendanceAPI} from "../../api/AttendanceAPI";
import {UserType} from "../../api/UserApi";
import {DateTime} from "../../utils/DateTime";
import TimePicker from "../TimePicker";
import {FiCheck, FiPlus} from "react-icons/fi";
import FocusLock from "react-focus-lock";

interface StatusCellProps {
    attendance: Attendance;
    table_data: AttendanceTableData;
    editable: boolean;
    mobile?: boolean;
    update?:()=>void;
}

export const StatusCell: React.FC<StatusCellProps> = ({attendance, table_data, editable, mobile = false, update}) => {
    const [status, set_status] = useState<string>(StatusRadioEnum[attendance.status]);
    const toast = useToast();

    const statusText: Record<string, string> = {
        '1': 'Present',
        '2': 'Late',
        '3': 'Absent'
    };

    const statusColor: Record<string, string> = {
        '1': 'green',
        '2': 'orange',
        '3': 'red'
    };
    const [isDialogOpen, set_isDialogOpen] = useState(false);
    useEffect(() => {
        set_status(StatusRadioEnum[attendance.status]);
    }, [attendance]);

    const handleClick = (data: string) => {
        if (!editable) return;
        if (data === '2') { // 'Late' selected
            set_isDialogOpen(true);
        } else {
            updateStatus(data);
        }
    };
    const updateStatus = (data: string) => {
        const new_status = status === data ? StatusRadioEnum['None'] : data;
        AttendanceAPI.update_entry_status(attendance._id.$oid, RStatusRadioEnum[new_status])
            .then((res) => {
                set_status(new_status);
                if (update) {
                    update();
                }
                toast({
                    title: 'Status Updated.',
                    description: 'Attendance status updated successfully.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            })
            .catch((e) => {
                set_status(status);
                toast({
                    title: 'Failed to Update.',
                    description: e.response.data,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            })
    };
    const handleDialogConfirm = (signInTime: Date) => {
        AttendanceAPI.update_signed_time(table_data.attendance_entry._id.$oid, "sign_in_time", signInTime)
            .then((res) => {
                toast({
                    title: 'Sign In Time Updated.',
                    description: 'Sign in time updated successfully.',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
                updateStatus('2'); // Update status to 'Late' after setting the sign-in time
            })
            .catch((e) => {
                toast({
                    title: 'Error.',
                    description: "Failed to update sign-in time.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            });
    };

    return attendance.student_excused_by && attendance.student_excused_by_user_type == UserType.Parent ? (
        <Badge colorScheme="red">Excused by Parent</Badge>
    ) : attendance.student_excused_by && attendance.student_excused_by_user_type == UserType.Teacher ? (
        <Badge colorScheme="blue">Excused by Teacher</Badge>
    ) : editable ? (
        <RadioGroup value={status} onChange={handleClick}>
            <SimpleGrid columns={mobile ? 3 : 1} spacingX="5px" spacingY="5px">
                <Radio colorScheme="green" value="1">
                    Present
                </Radio>
                <Radio colorScheme="orange" value="2">
                    Late
                </Radio>
                <Radio colorScheme="red" value="3">
                    Absent
                </Radio>
                <SignInDialog
                    isOpen={isDialogOpen}
                    onClose={() => set_isDialogOpen(false)}
                    onConfirm={handleDialogConfirm}
                    initialTime={table_data.attendance_entry.sign_in_time ? new DateTime(table_data.attendance_entry.sign_in_time as any).to_date() : undefined}
                />
            </SimpleGrid>
        </RadioGroup>
    ) : (
        <Badge colorScheme={statusColor[status]}>{statusText[status]}</Badge>
    );
};

interface SignInCellProps {
    table_data: AttendanceTableData;
    editable: boolean;
}

export const SignInCell: React.FC<SignInCellProps> = ({ table_data, editable }) => {
    const toast = useToast();
    const [time, set_time] = useState<Date | undefined>(
        table_data.attendance_entry.sign_in_time
            ? new DateTime(table_data.attendance_entry.sign_in_time as any).to_date()
            : undefined
    );

    useEffect(() => {
        set_time(
            table_data.attendance_entry.sign_in_time
                ? new DateTime(table_data.attendance_entry.sign_in_time as any).to_date()
                : undefined
        );
    }, [table_data]);

    const on_date_change = (date: any) => {
        if (!editable) return;
        AttendanceAPI.update_signed_time(table_data.attendance_entry._id.$oid, "sign_in_time", date)
            .then((res) => {
                set_time(date);
                toast({
                    title: "Sign In Time Updated.",
                    description: "Sign in time updated successfully.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            })
            .catch((e) => {
                console.log(e);
                set_time(time);
                toast({
                    title: "Error.",
                    description: "Failed to update " + table_data.first_name + "'s sign in time",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            });
    };

    return (
        <Box w="150px">
            {editable ? (
                <TimePicker selectedDate={time} onChange={(d) => on_date_change(d)} />
            ) : time ? (
                <Text>{time.toLocaleTimeString()}</Text>
            ) : (
                <Badge colorScheme="gray">None</Badge>
            )}
        </Box>
    );




};

interface SignOutCellProps {
    table_data: AttendanceTableData;
    editable: boolean;
}

export const SignOutCell: React.FC<SignOutCellProps> = ({ table_data, editable }) => {
    const toast = useToast();
    const [time, set_time] = useState<Date | undefined>(
        table_data.attendance_entry.sign_out_time
            ? new DateTime(table_data.attendance_entry.sign_out_time as any).to_date()
            : undefined
    );

    useEffect(() => {
        set_time(
            table_data.attendance_entry.sign_out_time
                ? new DateTime(table_data.attendance_entry.sign_out_time as any).to_date()
                : undefined
        );
    }, [table_data]);

    const on_date_change = (date: any) => {
        if (!editable) return;
        AttendanceAPI.update_signed_time(table_data.attendance_entry._id.$oid, "sign_out_time", date)
            .then((res) => {
                set_time(date);
                toast({
                    title: "Sign Out Time Updated.",
                    description: "Sign out time updated successfully.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            })
            .catch((e) => {
                set_time(time);
                toast({
                    title: "Failed to Update.",
                    description: e.response.data,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            });
    };

    return (
        <Box w="150px">
            {editable ? (
                <TimePicker selectedDate={time} onChange={(d) => on_date_change(d)} />
            ) : time ? (
                <Text>{time.toLocaleTimeString()}</Text>
            ) : (
                <Badge colorScheme="gray">None</Badge>
            )}
        </Box>
    );
};

interface MiscCellProps {
    table_data: AttendanceTableData;
    editable: boolean;
}

export const MiscCell: React.FC<MiscCellProps> = ({ table_data, editable }) => {
    const toast = useToast();
    const [reason, set_reason] = useState<string>(table_data.attendance_entry.reason);
    const [time, set_time] = useState<Date | undefined>(
        table_data.attendance_entry.late_dismissal_date
            ? new DateTime(table_data.attendance_entry.late_dismissal_date as any).to_date()
            : undefined
    );

    useEffect(() => {
        set_reason(table_data.attendance_entry.reason);
        set_time(
            table_data.attendance_entry.late_dismissal_date
                ? new DateTime(table_data.attendance_entry.late_dismissal_date as any).to_date()
                : undefined
        );
    }, [table_data]);

    const reason_saved = () => {
        if (!editable) return;
        AttendanceAPI.update_reason(table_data.attendance_entry._id.$oid, reason)
            .then((res) => {
                toast({
                    title: "Reason Updated.",
                    description: "Reason updated successfully.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            })
            .catch((e) => {
                console.log(e);
                toast({
                    title: "Error.",
                    description: "Failed to update " + table_data.first_name + "'s reason",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            });
    };

    const check_dismissal = (state: boolean) => {
        if (!editable) return;
        let late_dismissal_date = state ? new Date() : undefined;
        AttendanceAPI.update_late_dismissal_date(table_data.attendance_entry._id.$oid, late_dismissal_date)
            .then((res) => {
                set_time(late_dismissal_date);
                toast({
                    title: "Late Dismissal Updated.",
                    description: "Late dismissal status updated successfully.",
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            });
    };

    return (
        <VStack align="flex-start">
            <Box w="75%">
                <Popover placement="right" closeOnBlur={true}>
                    {({ isOpen, onClose }) => (
                        <>
                            <PopoverTrigger>
                                <Button leftIcon={<FiPlus />} colorScheme="teal" variant="outline">
                                    Reason
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent p={5}>
                                <FocusLock returnFocus persistentFocus={false}>
                                    <Center>
                                        <PopoverArrow />
                                        <PopoverCloseButton />
                                        <VStack spacing={4}>
                                            <Textarea
                                                placeholder="Reason"
                                                value={reason}
                                                onChange={(text) => set_reason(text.target.value)}
                                                isDisabled={!editable}
                                            />
                                            <Button
                                                leftIcon={<FiCheck />}
                                                colorScheme="green"
                                                variant="solid"
                                                onClick={() => {
                                                    reason_saved();
                                                    onClose();
                                                }}
                                                isDisabled={!editable}
                                            >
                                                Save
                                            </Button>
                                        </VStack>
                                    </Center>
                                </FocusLock>
                            </PopoverContent>
                        </>
                    )}
                </Popover>
            </Box>
            <Checkbox
                colorScheme={"red"}
                isChecked={time != undefined}
                onChange={(e) => {
                    check_dismissal(e.target.checked);
                }}
                isDisabled={!editable}
            >
                Late Dismissal
            </Checkbox>
        </VStack>
    );
};

const SignInDialog = ({ isOpen, onClose, onConfirm, initialTime }: { isOpen: boolean, onClose: () => void, onConfirm: (time: Date) => void, initialTime?: Date }) => {
    const [time, set_time] = useState<any>(initialTime ?? new Date());
    const cancelRef = useRef(null);

    const handleConfirm = () => {
        onConfirm(time);
        onClose();
    };

    return (
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={onClose}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader>Sign In Time Required</AlertDialogHeader>
                    <AlertDialogBody>
                        <TimePicker  selectedDate={time} onChange={(value) => set_time(value)}/>
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme="blue" onClick={handleConfirm} ml={3}>
                            Confirm
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
};