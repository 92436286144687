import React, {useEffect, useRef} from "react"
import { useState } from "react";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    Grid,
    GridItem,
    HStack,
    Skeleton,
    Stack,
    Text,
    useToast,
    Alert,
    AlertTitle,
    AlertDescription, TabList, TabPanels, TabPanel,
    AlertIcon,
    VStack, Flex, IconButton, SimpleGrid, Box, Spacer, Center, Divider, Tabs, Tab, Heading
}
    from '@chakra-ui/react'
import {useParams} from "react-router-dom";
import 'react-datepicker/dist/react-datepicker.css';
import { Student } from "../../../models/student";
import { Course } from "../../../models/course";
import { EvaluationEntryTable } from "./EvaluationEntryTable";
import {FiPlus} from "react-icons/fi";
import {EvaluationAPI} from "../../../api/EvaluationAPI";
import {EvaluationsTable} from "./EvaluationsTable";
import {CreateEvaluation, CreateEvaluationDialog} from "./CreateEvaluationDialog";
import {Evaluation} from "../../../models/evaluation";
import {StudentAPI} from "../../../api/StudentAPI";
import {EvaluationEntryAPI} from "../../../api/EvaluationEntryAPI";
import {to_oid, ObjectId, new_oid} from "../../../utils/ObjectId";
import {EvaluationEntry} from "../../../models/evaluation_entry";
import {CourseAPI} from "../../../api/CourseAPI";
import {StudentsFinalMarkTable} from "../StudentView/StudentsFinalMarkTable";
import {MarkBookCategories} from "../MarkCategory/MarkBookCategories";
import {StudentSelector} from "../StudentView/StudentSelector";

interface EvaluationData {
    evaluation: Evaluation
    data: {
        evaluation: Evaluation,
        page_refresh: any
    }
    run_func?: any
    selected?: boolean
}

interface EvaluationEntryData {
    student: Student,
    data: {
        evaluation_entry: EvaluationEntry,
        evaluation: Evaluation,
        page_refresh: any
    }
}

interface StudentMarkData {
    student: Student,
    final_mark?: number
    maximum_potential_mark?: number
}

export const MarkbookCourse = React.memo(() =>  {
    let { course_id } = useParams();
    const [eval_data, set_eval_data] = useState<Array<EvaluationData>>();
    const [eval_entry_data, set_eval_entry_data] = useState<Array<EvaluationEntryData>>();
    const [selected_eval, set_selected_eval] = useState<Evaluation>();
    const [course, set_course] = useState<Course>();
    const [mean, set_mean] = useState<string>();
    const [median, set_median] = useState<string>();
    const [total_weight, set_total_weight] = useState<string>();
    const [students_final_mark, set_students_final_mark] = useState<Array<StudentMarkData>>()


    const [isOpen, setIsOpen] = React.useState(false)
    const onClose = () => {
        setIsOpen(false)
        get_evaluations()
    }
    const cancelRef = React.useRef()

    const get_course = () => {
        CourseAPI.get_course(to_oid(course_id!))
            .then((res) => {
                set_course(res)
            })
    }

    const get_evaluations = () => {

        EvaluationAPI.get_evaluation_list_from_course(to_oid(course_id!))
            .then((res) => {
                let data = res!;
                let entry = data.map((evaluation) => {
                    return {
                        evaluation: evaluation,
                        data: {
                            evaluation: evaluation,
                            page_refresh: page_refresh,
                        },
                        run_func: () => {
                            set_selected_eval(evaluation)
                        }
                    }
                })
                entry = entry.slice().sort((a, b) => {
                    return parseInt(a.evaluation.date.$date.$numberLong) - parseInt(b.evaluation.date.$date.$numberLong);
                });
                set_eval_data(undefined)
                set_eval_data(entry)
                set_eval_entry_data([])
        })
    }

    const get_students_final_mark = () => {
        StudentAPI.students_list(undefined, undefined, null, undefined, to_oid(course_id!), undefined)
            .then((student_list) => {
                EvaluationAPI.get_students_final_mark(to_oid(course_id!))
                    .then((res) => {
                        let students = student_list!.students
                        let students_final_mark = res!.map((entry) => {
                            return {
                                student: students[students.findIndex((s) => s._id.$oid === entry.student.$oid)],
                                final_mark: entry.final_mark,
                                maximum_potential_mark: entry.maximum_potential_mark,
                            }
                        })
                        set_students_final_mark(undefined)
                        set_students_final_mark(students_final_mark)
                })
        })
    }

    const _get_evaluations_entries = (evaluation_id: ObjectId) => {
        StudentAPI.students_list(undefined, undefined, null, undefined, to_oid(course_id!), undefined)
            .then((student_list) => {
                EvaluationEntryAPI.get_evaluation_entries_list_from_evaluation(evaluation_id)
                    .then((eval_entries) => {
                        let students = student_list!.students
                        let entry = eval_entries!.map((e) => {
                            return {
                                student: students[students.findIndex((s) => s._id.$oid === e.student.$oid)],
                                data: {
                                    evaluation_entry: e,
                                    evaluation: selected_eval!,
                                    page_refresh: page_refresh_for_eval_entry,
                                },
                            }
                        })
                        set_eval_entry_data(undefined)
                        set_eval_entry_data(entry)
                })
        })
        set_banner(evaluation_id)
    }

    const set_banner = (evaluation_id: ObjectId) => {
        EvaluationAPI.get_evaluation_statistics(evaluation_id)
            .then((res) => {
                set_mean(res.mean.toFixed(2) + "%")
                set_median(res.median.toFixed(2) + "%")
                set_total_weight(res.total_weight + "%")
            })
    }

    useEffect(() => {
        get_course();
        get_evaluations();
        get_students_final_mark();
    }, []);

    const page_refresh = () => {
        get_course();
        get_evaluations();
        get_students_final_mark();
    }

    const page_refresh_for_eval_entry = () => {
        get_students_final_mark();
    }

    useEffect(() => {
        if(selected_eval){
            // Set selected
            let data = [...eval_data!]
            // Deselect
            data = data.map((e) => {
                return {
                    ...e,
                    selected: false
                }
            })

            let idx = data.findIndex((e) => e.evaluation._id.$oid == selected_eval._id.$oid)
            data[idx] = {
                ...data[idx],
                selected: true,
            }
            set_eval_data(data)

            // Get Entries
            _get_evaluations_entries(selected_eval._id)
        }
    }, [selected_eval]);

    return (
        <Box>
            <Center>
                <Heading size="md" mb="6">Markbook</Heading>
            </Center>
            <Center>
                <Text>Course: {course?.name}</Text>
            </Center>

            <Tabs>
                <TabList>
                    <Tab onClick={page_refresh}>Mark Books View</Tab>
                    <Tab onClick={get_students_final_mark}>Final Marks View</Tab>
                    <Tab onClick={get_students_final_mark}>Student View</Tab>
                    <Tab>Mark Categories</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel>
                        <Box overflowX="auto" overflowY="hidden">
                            <Stack direction={"row"}>
                                { selected_eval &&
                                <Box flex='2'>
                                    <Alert
                                        status='info'
                                        flex="1"
                                        >
                                        <HStack>
                                            <AlertDescription maxWidth='md'>
                                                Markbook Total Weight: {<span style={{ fontWeight: 'bold' }}>{total_weight}</span>}
                                            </AlertDescription>
                                            <Divider orientation='vertical' />
                                            <AlertDescription maxWidth='md'>
                                                Evaluation Average Mark: {<span style={{ fontWeight: 'bold' }}>{mean}</span>}
                                            </AlertDescription>
                                            <Divider orientation='vertical' />
                                            <AlertDescription maxWidth='md'>
                                                Evaluation Median Mark: {<span style={{ fontWeight: 'bold' }}>{median}</span>}
                                            </AlertDescription>
                                        </HStack>
                                        <Spacer/>
                                    </Alert>
                                </Box>
                                }
                                <Spacer/>
                            </Stack>
                            <Stack direction={"row"}>
                                <Box flex='7' maxWidth={"1000px"}>
                                    <Stack>
                                        <EvaluationsTable entry_data={eval_data}/>
                                    <>
                                        <IconButton aria-label={"Add Evaluation"} colorScheme='green' icon={<FiPlus/>} onClick={() => setIsOpen(true)}/>
                                        <CreateEvaluation class_id={to_oid(course_id!)} isOpen={isOpen} onClose={onClose} cancelRef={cancelRef}/>
                                    </>
                                    </Stack>
                                </Box>
                                {
                                    selected_eval &&
                                    <Box flex='2' maxWidth={"500px"}>
                                        <EvaluationEntryTable evaluation={selected_eval} entry_data={eval_entry_data} students_final_mark={students_final_mark}/>
                                    </Box>
                                }
                            </Stack>
                        </Box>
                    </TabPanel>
                    <TabPanel>
                        <Box flex='1'>
                            <StudentsFinalMarkTable entry_data={students_final_mark}/>
                        </Box>
                    </TabPanel>
                    <TabPanel>
                        <StudentSelector students_marks={students_final_mark!}/>
                    </TabPanel>
                    <TabPanel>
                        <Box>
                            <MarkBookCategories class_id={to_oid(course_id!)}/>
                        </Box>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Box>
    );
} );
