import {
Box,
Button,
Divider,
Flex,
FormControl,
FormLabel,
Input,
Stack,
Select,
useColorModeValue,
useToast,
Badge,
Spacer,
Skeleton,
HStack,
Square,
Icon,
Text,

} from '@chakra-ui/react'
import * as React from 'react'
import 'react-datepicker/dist/react-datepicker.css';
import { FiFileText, FiMaximize, FiMaximize2 } from 'react-icons/fi';
import { StudentAPI } from '../../../api/StudentAPI';
import { Document, Grades, Student, DocumentType } from '../../../models/student';
import { DateTime } from '../../../utils/DateTime';
import { DropUpload } from '../../3rd_party/DropUpload';
import DatePicker from '../../DatePicker'
import { TableContent } from '../../table/TableContent';
import { DocumentUploadCard } from './DocumentUploadCard';

interface Card {
    student: Student
    set_student: React.Dispatch<React.SetStateAction<Student | undefined>>
}

export const DocumentsCard = React.memo(({student, set_student}: Card) => {
    const toast = useToast()
    const [documents, set_documents] = React.useState<Array<DocumentTableObj>>();

    const [isOpen, setIsOpen] = React.useState(false)
    const onClose = () => setIsOpen(false)
    const cancelRef = React.useRef()

    React.useEffect(() => {
        set_documents(
            student.documents
                .filter((e) => e.document_type != DocumentType.Signature)
                .map((document) => {
                    return {
                        id: document._id.$oid,
                        name: document.name,
                        date: document.date.to_date().toDateString(),
                        document_type: document.document_type.toString(),
                        run_func: () => {
                            StudentAPI.get_document_url(student._id, document._id)
                                .then((res) => {
                                    let url = res.data
                                    window.open(url)
                                })
                                .catch((res) => {
                                    console.log(res)
                                    toast({
                                        title: 'Error.',
                                        description: "Unable to open document",
                                        status: 'error',
                                        duration: 5000,
                                        isClosable: true,
                                    })
                                })
                        }
                    }
                })
        )
    },[student])


    return (
        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
        >
            <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    <Spacer />
                    <Button colorScheme='green' onClick={() => setIsOpen(true)} >
                        Upload Document
                    </Button>
                    <DocumentUploadCard student={student} set_student={set_student} isOpen={isOpen} onClose={onClose} cancelRef={cancelRef}/>
                    <br></br>
                </Stack>
                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    <FormLabel>Documents</FormLabel>
                    <Stack w='100%'>
                    
                    {documents ?
                            documents.map((e) => {
                                return DocumentItem(e.name, e.document_type, e.date, e.run_func)
                            })
                        :
                            <Stack>
                                <Skeleton height='20px' />
                                <Skeleton height='20px' />
                                <Skeleton height='20px' />
                            </Stack>
                    }   
                    </Stack>
                </Stack>
            </Stack>
            <Divider />
        </Box>
    )

})

const DocumentItem = (name: string, document_type: string, date: string, run_func: any) => {
    return (
        <Box borderWidth={{ base: '0', md: '1px' }} p={{ base: '0', md: '4' }} borderRadius="lg">
            <Stack justify="space-between" direction={{ base: 'column', md: 'row' }} spacing="5">
              <HStack spacing="3">
                <Square size="10" bg="bg-subtle" borderRadius="lg">
                  <Icon as={FiFileText} boxSize="5" />
                </Square>
                <Box fontSize="sm">
                  <Text color="empahsized" fontWeight="medium">
                    {name}
                  </Text>
                  <Badge fontSize="xs" colorScheme={badgeEnum[document_type]}>
                    {EnumName[document_type]}
                  </Badge>
                  <Text color="muted">{date}</Text>
                </Box>
              </HStack>
              <Stack spacing="3" direction={{ base: 'column-reverse', md: 'row' }}>
                {/* <Button>Delete</Button> */}
                <Button colorScheme="blue" onClick={() => run_func()} leftIcon={<FiMaximize2/>}>View</Button>
              </Stack>
            </Stack>
        </Box>
    )
}
interface DocumentTableObj {
    id: string
    name: string,
    document_type: string,
    date: string,
    link?: string,
    run_func?: any
}

const badgeEnum: Record<string, string> = {
    "HealthRecord": "green",
    "LegalDocument": "orange",
    "ProofOfAddress": "yellow",
    "EducationDocument": "blue",
    "VoidCheck": "purple",
    "Other": "red",
}

const EnumName: Record<string, string> = {
    "HealthRecord": "Health Record",
    "LegalDocument": "Legal Document",
    "ProofOfAddress": "Proof of Address",
    "EducationDocument": "Education Document",
    "VoidCheck": "Void Check",
    "Other": "Other",
}

const DocumentColumns = [
    {
        Header: "Name",
        accessor: "name",
    },
    {
        Header: "Date",
        accessor: "date",
    },
    {
        Header: "Type",
        accessor: "document_type",
        Cell: function StatusCell(data: any) {
            return (
              <Badge fontSize="xs" colorScheme={badgeEnum[data]}>
                {data}
              </Badge>
            )
          },
    },
]
