import React from "react";
import { Box, Heading, Center, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import { Outlet, useLocation } from 'react-router-dom'
import { StudentsList } from "./../StudentsList";
import { StudentStatus } from "../../../models/student";

export const OnboardingList = React.memo((props) =>  {
    const location = useLocation();

    return (
            <Box >
                <Center>
                    <Heading size="md" mb="6">
                        Students
                    </Heading>
                </Center>
                {location.pathname === "/onboarding" ?
                    <>
                        <Tabs>
                        <TabList>
                            <Tab>Applicants</Tab>
                            <Tab>Rejected</Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel>
                                <>
                                <StudentsList status={StudentStatus.Applicant} />
                                </>
                            </TabPanel>
                            <TabPanel>
                                <>
                                <StudentsList status={StudentStatus.Rejected} />
                                </>
                            </TabPanel>
                        </TabPanels>
                        </Tabs>
                    </>
                :
                    <Outlet/>
                }
                
            </Box>
    );
} );


// LEGACY: CUSTOM COLUMNS PROP IN STUDENT LIST
// [
//     {
//         Header: "Name",
//         accessor: "_id",
//         Cell: function StatusCell(data: any, student: Student) {
//             return (
//             student.profile.first_name + " " + student.profile.last_name
//             )
//         },
//     },
//     {
//         Header: "Status",
//         accessor: "_id",
//         Cell: function StatusCell(data: any, student: Student) {
//             const forms_filled = () => {
//                 return student.form_data.profile.status != FormDataStatus.None
//                 && student.form_data.documents.status != FormDataStatus.None
//                 && student.form_data.financial.status != FormDataStatus.None
//                 && student.form_data.health.status != FormDataStatus.None
//                 && student.form_data.family.status != FormDataStatus.None
//                 && student.form_data.office_information.status != FormDataStatus.None
//                 && student.form_data.consent.status != FormDataStatus.None
//                 && student.form_data.uniform.status != FormDataStatus.None
//             }

//             return (
//             <Badge colorScheme={forms_filled() ? "green" : "red"}>
//                 {forms_filled() ? "Forms Filled" : "Not Filled"}
//             </Badge>
//             )
//         },
//     },
// ]