import React, { useState, useEffect } from "react";
import {
    Box,
    Stack,
    HStack,
    FormControl,
    InputGroup,
    InputLeftElement,
    Input,
    Badge,
} from "@chakra-ui/react";
import { BsSearch } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { StudentAPI } from "../../api/StudentAPI";
import { ObjectId } from "../../utils/ObjectId";
import { StudentStatus } from "../../models/student";
import { TablePagination } from "../table/TablePagination";
import { TableContent } from "../table/TableContent";

interface Card {
    status?: StudentStatus;
    course_id?: ObjectId;
    homeroom_id?: ObjectId;
    redirect_to_profile?: boolean;
    //! TODO: IT'S NO LONGER USED HERE BUT IT'S STILL USED IN ON-BOARDING AND RE-REGISTRATION TABLES
    custom_columns?: Array<any>; 
}

export const StudentsList = React.memo(
    ({
         status,
         course_id,
         homeroom_id,
         redirect_to_profile,
     }: Card) => {

        const location = useLocation();
        const PER_PAGE = 15;

        const [students_data, set_students_data] = useState<Array<StudentsObj>>([]);

        const [page, set_page] = useState<number>(1);
        const [max_page, set_max_page] = useState<number>();
        const [loading, setLoading] = useState<boolean>(true);
        const [total_students, set_total_students] = useState<number>();
        const [name_search, set_name_search] = useState<string>("");

        let StudentColumns = [
                {
                    Header: "Name",
                    accessor: "name",
                },
                {
                    Header: "Status",
                    accessor: "status",
                    Cell: function StatusCell(data: any) {
                        return (
                        <Badge fontSize="xs" colorScheme={badgeEnum[data]}>
                            {data}
                        </Badge>
                        )
                    },
                }
        ]

        const set_student_list = (_page: number, name_search: string | null) => {
            setLoading(true)
            const fetchStudents = course_id
                ? StudentAPI.students_list(PER_PAGE, _page, name_search, status, course_id)
                : homeroom_id
                    ? StudentAPI.students_list(
                        PER_PAGE,
                        _page,
                        name_search,
                        status,
                        undefined,
                        homeroom_id
                    )
                    : StudentAPI.students_list(PER_PAGE, _page, name_search, status);

            fetchStudents.then((res) => {
                const data = res!;
                const students = data.students.map((student) => ({
                    _id: student._id.$oid,
                    name: student.profile.first_name + " " + student.profile.last_name,
                    status: student.status,
                    link: redirect_to_profile
                        ? "/students/" + student._id.$oid
                        : location.pathname + "/" + student._id.$oid,
                }));
                setLoading(false)
                set_students_data(students);
                set_total_students(data.students_count);
                set_max_page(Math.ceil(data.students_count / PER_PAGE));
            });
        };

        useEffect(() => {
            set_student_list(page, name_search);
        }, [page]);

        useEffect(() => {
            set_page(1);

            set_student_list(1, name_search);
        }, [name_search]);

        return (
            <Box>
                    <>
                        <Stack>
                            <Stack
                                spacing="4"
                                direction={{ base: "column", md: "row" }}
                                justify="space-between"
                            >
                                <HStack>
                                    <FormControl minW={{ md: "320px" }} id="search">
                                        <InputGroup size="sm">
                                            <InputLeftElement pointerEvents="none" color="gray.400">
                                                <BsSearch />
                                            </InputLeftElement>
                                            <Input
                                                rounded="base"
                                                type="search"
                                                placeholder="Search by name..."
                                                onChange={(e) => set_name_search(e.target.value)}
                                            />
                                        </InputGroup>
                                    </FormControl>
                                </HStack>
                            </Stack>
                            <TableContent loading={loading} data={students_data} columns={StudentColumns} />

                            <TablePagination
                                text={`Showing ${PER_PAGE * page} of ${total_students}`}
                                set_page={set_page}
                                page={page}
                                max_page={max_page!}
                            />
                        </Stack>
                    </>
            </Box>
        );
    }
);

interface StudentsObj {
    _id: string;
    name: string;
    status: string;
    link?: string;
}

const badgeEnum: Record<string, string> = {
    Enrolled: "green",
    Withdrawn: "red",
};