import { ObjectID } from "bson";

export interface ObjectId {
    $oid: string;
}

export const to_oid = (id: string): ObjectId => {
    return {
        $oid: id
    }
}

export const new_oid = (): ObjectId => {
    let id  = new ObjectID();
    return {
        $oid: id.toString()
    }
}
