import { Badge, Box, HStack, Spacer, Stack, Text, useColorModeValue, useToast } from "@chakra-ui/react"
import React from "react"
import { PaymentAPI } from "../../../api/PaymentAPI"
import { Student } from "../../../models/student"
import { Transaction } from "../../../models/transaction"
import { DateTime } from "../../../utils/DateTime"

interface Card {
    student: Student
}

export const TransactionsCard = React.memo(({student}: Card) => {
    const [transactions, set_transactions] = React.useState<Transaction[]>()

    React.useEffect(() => {
        PaymentAPI.get_transactions(student._id).then((transactions) => {
            set_transactions(transactions)
        })
    }, [])

    return (
        <>
        {transactions?.map(e => {
            return (
                <>
                <TransactionCard transaction={e}/>
                <br></br>
                </>
            )
        })
        }
        </>
    )
})

interface TransactionCardCard {
    transaction: Transaction
}


export const TransactionCard = ({transaction}: TransactionCardCard) => {
    console.log(transaction)
    return (
        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
        >
            <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                <Stack>
                    <HStack>
                        <Text>Status: </Text>
                        <Badge colorScheme={badgeEnum[transaction.status]}>
                                {transaction.status}
                        </Badge>
                    </HStack>
                    <Text>Description: {transaction.description}</Text>
                    <Text>Date: {transaction.date.to_date().toLocaleDateString()} {transaction.date.to_date().toLocaleTimeString()}</Text>
                    <HStack>
                        <Text>Items: </Text>
                        <Spacer/>
                        <>
                        {transaction.items.map(e => {
                            return (
                                <Stack>
                                    <Text>Item Name: {e.name}</Text>
                                    <Text>Item Price: {e.price}</Text>
                                </Stack>
                            )
                        })}
                        </>
                        <Spacer/>
                    </HStack>
                </Stack>
            </Stack>

        </Box>
    )
}


const badgeEnum: Record<string, string> = {
    "Paid": "green",
    "Created": "orange",
    "Declined": "red",
}