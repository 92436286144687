import React, { useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Stack, useToast, Text, IconButton, HStack, Collapse, Spacer } from "@chakra-ui/react";
import { updateReportLayoutSchema } from "../../../validations/report_layout";
import { ReportLayout } from "../../../models/report_layout";
import { CourseAPI } from "../../../api/CourseAPI";
import { new_oid } from "../../../utils/ObjectId";
import FormInput from "../../forms/FormInput";
import { AddIcon, MinusIcon, ChevronDownIcon, ChevronUpIcon, CheckIcon } from "@chakra-ui/icons";
import { DeleteDialog } from "../../dialog/DeleteDialog";
import { LightButton } from "../../layout/LightButton";

interface ReportLayoutFormProps {
    reportLayout?: ReportLayout;
    onSubmitSuccess: (updatedLayout: ReportLayout) => void;
    hideUpdateButton?: boolean;
}

const ReportLayoutForm: React.FC<ReportLayoutFormProps> = ({ reportLayout, onSubmitSuccess, hideUpdateButton = false }) => {
    const toast = useToast();
    const { register, handleSubmit, reset, control, formState: { errors } } = useForm<ReportLayout>({
        resolver: yupResolver(updateReportLayoutSchema),
    });

    const [isSectionOpen, setIsSectionOpen] = useState<boolean>(true);
    const [isCheckboxOpen, setIsCheckboxOpen] = useState<boolean>(true);
    const [isCheckboxAlertOpen, setIsCheckboxAlertOpen] = useState<boolean>(false);
    const [isSectionAlertOpen, setIsSectionAlertOpen] = useState<boolean>(false);
    const [sectionRemoveIndex, setSectionRemoveIndex] = useState<{ section?: number}>({});
    const [checkboxRemoveIndex, setCheckboxRemoveIndex] = useState<{ checkbox?: number}>({});

    // Handle dynamic sections
    const { fields: sectionFields, append: appendSection, remove: removeSection } = useFieldArray({
        control,
        name: "sections",
    });

    // Handle dynamic checkboxes
    const { fields: CheckBoxFields, append: appendCheckbox, remove: removeCheckbox } = useFieldArray({
        control,
        name: "check_boxes",
    });

    // Update the form with passed-in reportLayout
    useEffect(() => {
        if (reportLayout) {
            reset(reportLayout);
        }
    }, [reportLayout, reset]);

    const toggleSections = () => {
        setIsSectionOpen(!isSectionOpen );
    };

    const toggleCheckBoxes = () => {
        setIsCheckboxOpen(!isCheckboxOpen);
    };

    const onSubmit = async (data: ReportLayout) => {
        try {
            const updatedLayout = await CourseAPI.update_report_layout(
                data._id,
                data.course_id,
                data.comment_char_limit,
                data.sections,
                data.check_boxes,
            );
            onSubmitSuccess(updatedLayout);

            toast({
                title: "Success",
                description: "Report layout updated successfully",
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to update report layout",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const handleRemoveSection = (index: number) => {
        setSectionRemoveIndex({ section: index });
        setIsSectionAlertOpen(true);
    };

    const handleRemoveCheckbox = ( checkboxIndex: number) => {
        setCheckboxRemoveIndex({checkbox: checkboxIndex });
        setIsCheckboxAlertOpen(true);
    };

    const confirmCheckboxRemove = () => {
        removeCheckbox(checkboxRemoveIndex.checkbox);
        setIsCheckboxAlertOpen(false);
    };

    const confirmSectionRemove = () => {
        removeSection(sectionRemoveIndex.section);
        setIsSectionAlertOpen(false);
    };

    return (
        <Box
            as="form"
            bg="bg-surface"
            boxShadow="sm"
            borderRadius="lg"
            flex="1"
            mb="4"
            onSubmit={handleSubmit(onSubmit)}
        >
            <Stack spacing="5" px="6" py="6">

                <HStack>
                    <Text fontSize={"lg"} fontWeight="bold">
                        Report Layout
                    </Text>

                    <Spacer />

                    <LightButton
                        leftIcon={<CheckIcon/>}
                        type="submit" 
                        color="green" 
                        isLoading={false} 
                        hidden={hideUpdateButton}
                        mt={4} 
                    >
                        Update Report Layout
                    </LightButton>
                </HStack>

                <FormInput
                    name="comment_char_limit"
                    label="Comment Character Limit"
                    register={register("comment_char_limit")}
                    error={errors.comment_char_limit?.message}
                    isRequired
                />
                <HStack>
                    <Text fontSize={"md"} fontWeight="bold">
                        Checkboxes
                    </Text>

                    <Spacer />

                    <IconButton
                        aria-label="Toggle Sections"
                        icon={isCheckboxOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                        onClick={toggleCheckBoxes}
                        variant="outline"
                        size="sm"
                    />
                </HStack>
                <Collapse in={isCheckboxOpen}>
                <Box p="4" borderWidth="1px" borderRadius="lg" mt={4}>
                        <Stack spacing={4} >
                            {CheckBoxFields.map((checkbox, index) => (
                                <HStack key={checkbox.id}>
                                    <FormInput
                                        label={''}
                                        name={`check_boxes.${index}.name`}
                                        register={register(`check_boxes.${index}.name` as const)}
                                        error={errors.check_boxes?.[index]?.name?.message}
                                    />

                                    <IconButton
                                        aria-label="Remove Checkbox"
                                        icon={<MinusIcon />}
                                        variant="outline"
                                        borderColor="red.600"
                                        textColor="red.600"
                                        size="sm"
                                        onClick={() => handleRemoveCheckbox( index)}
                                    />
                                </HStack>
                            ))}
                            <Button
                                aria-label="Add Checkbox"
                                leftIcon={<AddIcon />}
                                variant="outline"
                                border="1px dashed"
                                borderColor="gray.400"
                                size="sm"
                                alignSelf="start"
                                onClick={() => appendCheckbox({
                                    _id: new_oid(),
                                    name: '',
                                })}
                            >
                                add checkbox
                            </Button>
                        </Stack>
                    </Box>
                </Collapse>
                <HStack>
                    <Text fontSize={"md"} fontWeight="bold">
                        Sections
                    </Text>

                    <Spacer />

                    <IconButton
                        aria-label="Toggle Sections"
                        icon={isSectionOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                        onClick={toggleSections}
                        variant="outline"
                        size="sm"
                    />
                </HStack>
                <Collapse in={isSectionOpen}>
                {/* Sections */}
                {sectionFields.map((section, index) => (
                    <Box key={section.id} p="1"  borderRadius="sm" mb={1}>
                        <HStack alignItems="center" borderRadius="sm">
                            <FormInput
                                name={`sections.${index}.name` as const}
                                label={``}
                                register={register(`sections.${index}.name` as const)}
                                error={errors.sections?.[index]?.name?.message}
                                isRequired
                                isHeader={true}
                            />
                            <IconButton
                                aria-label="Remove Section"
                                icon={<MinusIcon />}
                                variant="outline"
                                borderColor="red.600"
                                textColor="red.600"
                                size="sm"
                                onClick={() => handleRemoveSection(index)}
                            />
                        </HStack>
                    </Box>

                ))}
                </Collapse>
                {/* Add Section Button */}
                <HStack justifyContent="flex-start" mt={4}>
                    <Button
                        aria-label="Add Section"
                        leftIcon={<AddIcon />}
                        onClick={() => appendSection({
                            _id: new_oid(),
                            name: '',
                        })}
                        variant="outline"
                        border="1px dashed"
                        borderColor="gray.400"
                        size="sm"
                    >
                        Add Section
                    </Button>
                </HStack>

            </Stack>

            <DeleteDialog
                item="section"
                isOpen={isSectionAlertOpen}
                action={confirmSectionRemove}
                onClose={() => setIsSectionAlertOpen(false)}
            />

            <DeleteDialog
                item="checkbox"
                isOpen={isCheckboxAlertOpen}
                action={confirmCheckboxRemove}
                onClose={() => setIsCheckboxAlertOpen(false)}
            />
        </Box>
    );
};

export default ReportLayoutForm;
