import React, { useEffect, useState } from "react"
import {
    Box,
    Stack,
    HStack,
    FormControl,
    Input,
    Skeleton,
    InputGroup,
    InputLeftElement
} from '@chakra-ui/react'
import {useLocation, useNavigate} from "react-router-dom";
import { TableContent } from "../table/TableContent";
import { TeacherAPI } from "../../api/TeacherAPI";
import { ObjectId } from "../../utils/ObjectId";
import { BsSearch } from "react-icons/bs";
import { TablePagination } from "../table/TablePagination";


interface Card {
    teacher_id?: ObjectId,
    attendance_teacher_id?: ObjectId,
}

export const TeacherList = ({teacher_id, attendance_teacher_id}: Card) => {
    let navigate = useNavigate();

    const PER_PAGE = 15
    const location = useLocation();
    const [teachers_data, set_teachers_data] = useState<Array<TeacherObj>>();
    const [teachers_page, set_teachers_page] = useState<number>(1);
    const [teachers_max_page, set_teachers_max_page] = useState<number>();
    const [total_teachers, set_total_teachers] = useState<number>();
    const [teachers_name_search, set_teachers_name_search] = useState<string>();

    const _set_teacher_list = (res: TeacherAPI.TeacherList) => {
        let teachers = res.teachers.map((teacher) => {
            return {
                id: teacher._id.$oid,
                name: teacher.profile.first_name + " " + teacher.profile.last_name,
                link: location.pathname + "/" + teacher._id.$oid
            }
        })
        set_teachers_data(teachers)
        set_total_teachers(res.teachers_count)
        set_teachers_max_page(Math.ceil(res.teachers_count/15))
    }

    const set_course_list = (_page: number, name_search: string | undefined) => {
        TeacherAPI.teachers_list(name_search, PER_PAGE, _page)
        .then((res) => {
            _set_teacher_list(res!)
        })
    }

    useEffect(() => {
        set_course_list(teachers_page, teachers_name_search)
    }, [teachers_page]);

    useEffect(() => {
        set_teachers_page(1)
        set_course_list(1, teachers_name_search)
    }, [teachers_name_search]);


return (
        <Box>
            { teachers_data ?
                (
                    <>
                    <Stack>
                        <Stack spacing="4" direction={{ base: 'column', md: 'row' }} justify="space-between">
                            <HStack>
                            <FormControl minW={{ md: '320px' }} id="search">
                                <InputGroup size="sm">
                                    <InputLeftElement pointerEvents="none" color="gray.400">
                                    <BsSearch />
                                    </InputLeftElement>
                                    <Input rounded="base" type="search" placeholder="Search by name..." onChange={(e) => set_teachers_name_search(e.target.value)} />
                                </InputGroup>
                            </FormControl>
                            </HStack>
                        </Stack>


                        <TableContent data={teachers_data} columns={CourseColumns} hover_color='cyan.300'/>
                        <TablePagination text={"Showing " + PER_PAGE*teachers_page + " of " + total_teachers} set_page={set_teachers_page} page={teachers_page} max_page={teachers_max_page!}/>
                    </Stack>
                    </>

                )
                    :
                        <Stack>
                            <Skeleton height='100px' />
                            <Skeleton height='100px' />
                            <Skeleton height='100px' />
                            <Skeleton height='100px' />
                            <Skeleton height='100px' />
                            <Skeleton height='100px' />
                            <Skeleton height='100px' />
                            <Skeleton height='100px' />
                            <Skeleton height='100px' />
                        </Stack>
            }
        </Box>
    );
}


interface TeacherObj {
    id: string,
    name: string,
    link?: string
}

const badgeEnum: Record<string, string> = {
    Enrolled: 'green',
    Withdrawn: 'red',
}

const CourseColumns = [
    {
        Header: "Name",
        accessor: "name",
    },
    // {
    //     Header: "Teacher Name",
    //     accessor: "teacher_name",
    // },
    // {
    //     Header: "Status",
    //     accessor: "status",
    //     Cell: function StatusCell(data: any) {
    //         return (
    //           <Badge fontSize="xs" colorScheme={badgeEnum[data]}>
    //             {data}
    //           </Badge>
    //         )
    //       },
    // },
]
