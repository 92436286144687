import React, {useEffect, useState} from "react"
import {Box, FormControl, IconButton, Input, Skeleton, Stack, Text, useDisclosure, useToast} from '@chakra-ui/react'
import {TableContent} from "../../table/TableContent";
import {DeleteDialog} from "../../dialog/DeleteDialog";
import 'react-datepicker/dist/react-datepicker.css';
import {DeleteIcon} from "@chakra-ui/icons";
import {EvaluationAPI} from "../../../api/EvaluationAPI";
import {Evaluation} from "../../../models/evaluation";
import {ObjectId} from "../../../utils/ObjectId";
import {MarkCategory} from "../../../models/mark_category";
import {MarkCategoriesAPI} from "../../../api/MarkCategoryAPI";
import {Select as SearchSelect} from "chakra-react-select";
import DatePicker from "../../DatePicker";
import {DateTime} from "../../../utils/DateTime";

interface EvaluationData {
    evaluation: Evaluation
    data: EvalData
    run_func?: any
}

interface EvalData {
    evaluation: Evaluation,
    page_refresh: any
}

interface MarkbookProps {
    entry_data: Array<EvaluationData> | undefined,
}

export const EvaluationsTable = React.memo(({entry_data}: MarkbookProps) =>  {
    return (
        <Box>
            {entry_data ?
                    <TableContent data={entry_data} columns={MarkbookColumns} hover_color='cyan.300' disable_hover={true}/>
                :
                    <Stack>
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                    </Stack>
            }
        </Box>
    );
} );

let MarkbookColumns = [
    {
        Header: "Evaluation",
        accessor: "evaluation",
        Cell: function StatusCell(evaluation: Evaluation) {
            const toast = useToast()

            const on_value_change = (value: string) => {
                if(value == evaluation.name){
                    return
                }
                evaluation.name = value
                EvaluationAPI.update_evaluation(evaluation)
                    .then(() => {
                        toast({
                            title: 'Success.',
                            description: "Updated Evaluation: " + evaluation.name,
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                        })
                    })
                    .catch((e) => {
                        toast({
                            title: 'Error.',
                            description: "Failed to update " + evaluation.name + " total",
                            status: 'error',
                            duration: 5000,
                            isClosable: true,
                        })
                    })
            }
            return (
                <Box minW='100px'>
                    <Input
                        defaultValue={String(evaluation.name!)}
                        onBlur={(value) => on_value_change(value.target.value)}
                        colorScheme={"green"}
                    />
                </Box>
            )
        },
    },
    {
        Header: "Date",
        accessor: "data",
        Cell: function StatusCell(d: EvalData) {
            const toast = useToast()

            console.log(d.evaluation)
            const on_value_change = (value: DateTime) => {

                d.evaluation.date = value
                EvaluationAPI.update_evaluation(d.evaluation)
                    .then(() => {
                        toast({
                            title: 'Success.',
                            description: "Updated Evaluation Date",
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                        })
                        d.page_refresh()
                    })
                    .catch((e) => {
                        toast({
                            title: 'Error.',
                            description: "Failed to update date",
                            status: 'error',
                            duration: 5000,
                            isClosable: true,
                        })
                    })
            }
            return (
                <Box minW='120px'>
                    <Text>
                    </Text>
                    <DatePicker
                        onChange={(value)=>{on_value_change(DateTime.from_date(value as Date))}}
                        selectedDate={new Date(parseInt(d.evaluation.date.$date.$numberLong))}
                    />
                </Box>
            )
        },
    },
    {
        Header: "Mark Category",
        accessor: "data",
        Cell: function StatusCell(data: EvalData) {
            const toast = useToast()
            const [mark_category_name, set_mark_category_name] = useState<string>();
            const [mark_categories, set_mark_categories] = useState<Array<MarkCategory>>();

            const get_mark_categories = () => {
                MarkCategoriesAPI.get_mark_category_list_from_class(data.evaluation.class_id)
                    .then((res) => {
                        set_mark_categories(res)
                })
            }

            const get_mark_category_name = () => {
                if (data.evaluation.mark_category == undefined) {
                    set_mark_category_name("Unassigned")
                } else {
                    MarkCategoriesAPI.get_mark_category(data.evaluation.mark_category)
                        .then((res) => {
                            set_mark_category_name(res.name)
                        })
                }
            }

            useEffect(() => {
                get_mark_categories()
                get_mark_category_name()
            }, [])

            const on_value_change = (value: ObjectId) => {
                if (value === data.evaluation.mark_category){
                    return
                }
                data.evaluation.mark_category = value
                EvaluationAPI.update_evaluation(data.evaluation)
                    .then(() => {
                        data.page_refresh()
                        toast({
                            title: 'Success.',
                            description: "Updated Evaluation Mark Category",
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                      })
                    })
                    .catch((e) => {
                        console.log(e)
                        toast({
                            title: 'Error.',
                            description: "Updated Evaluation Mark Category",
                            status: 'error',
                            duration: 5000,
                            isClosable: true,
                          })
                    })
            }
            return (
                <Box minW='170px'>
                    <FormControl>
                        <SearchSelect
                            name="flavors"
                            size={"sm"}
                            options={mark_categories?.map((res) => {
                                return {
                                    label: res.name,
                                    value: res
                                }
                            })}
                            placeholder={mark_category_name}
                            onChange={(value) => {
                                on_value_change(value!.value._id)
                            }}
                        />
                    </FormControl>
                </Box>
            )
        },
    },
    {
        Header: "Total",
        accessor: "data",
        Cell: function StatusCell(data: EvalData) {
            const toast = useToast()

            const on_value_change = (value: string) => {
                if(Number(value) == data.evaluation.total){
                    return
                }
                data.evaluation.total = Number(value)
                if (data.evaluation.total === 0) {
                    toast({
                            title: 'Error.',
                            description: "Failed to update " + data.evaluation.total + " total, 0 is not allowed for total",
                            status: 'error',
                            duration: 5000,
                            isClosable: true,
                          })
                } else {
                    EvaluationAPI.update_evaluation(data.evaluation)
                        .then(() => {
                            data.page_refresh()
                            toast({
                                title: 'Success.',
                                description: "Updated Evaluation: " + data.evaluation.name,
                                status: 'success',
                                duration: 5000,
                                isClosable: true,
                          })
                        })
                        .catch((e) => {
                            toast({
                                title: 'Error.',
                                description: "Failed to update " + data.evaluation.total + " total",
                                status: 'error',
                                duration: 5000,
                                isClosable: true,
                              })
                         })
                }
            }
            return (
                <Box minW='60px' maxW='80px'>
                    <Input
                        defaultValue={String(data.evaluation.total!)}
                        onBlur={(value) => on_value_change(value.target.value)}
                        colorScheme={"green"}
                    />
                </Box>
            )
        },
    },
    {
        Header: "Weight",
        accessor: "data",
        Cell: function StatusCell(data: EvalData) {
            const toast = useToast()

            const on_value_change = (value: string) => {
                if(Number(value) == data.evaluation.weight){
                    return
                }
                data.evaluation.weight = Number(value)
                EvaluationAPI.update_evaluation(data.evaluation)
                    .then(()=> {
                        data.page_refresh()
                        toast({
                            title: 'Success.',
                            description: "Updated Evaluation: " + data.evaluation.name,
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                      })
                    })
                    .catch((e) => {console.log(e)
                        toast({
                            title: 'Error.',
                            description: "Failed to update " + data.evaluation.name + " weight",
                            status: 'error',
                            duration: 5000,
                            isClosable: true,
                          })
                })
            }
            return (
                <Box minW='60px' maxW='80px'>
                    <Input
                        defaultValue={String(data.evaluation.weight!)}
                        onBlur={(value) => on_value_change(value.target.value)}
                    />
                </Box>
            )
        },
    },
    {
        Header: "Delete",
        accessor: "data",
        Cell: function StatusCell(data: EvalData) {

            const { isOpen, onOpen, onClose } = useDisclosure()

            const delete_action = () => {
                EvaluationAPI.delete_evaluation(data.evaluation._id)
                    .then(() => {
                        data.page_refresh()
                    })
            }

            return (
                <Box>
                    <IconButton aria-label={"Delete"} colorScheme='red' onClick={onOpen}>
                        {<DeleteIcon />}
                    </IconButton>
                    <DeleteDialog isOpen={isOpen} onClose={onClose} action={delete_action}/>
                </Box>
            )
        },
    },

]
