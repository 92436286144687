import { DateTime } from "../utils/DateTime"
import { ObjectId } from "../utils/ObjectId"

export enum MigrationStatus{
    Active="Active",
    Inactive="Inactive",

    Running="Running",
    Ran="Ran",

    RollingBack="RollingBack",
    RolledBack="RolledBack",

    Error="Error",
}

export class Migration {
    _id: ObjectId;
    file_name: string;
    status: MigrationStatus;
    progress: number;
    progress_total: number;
    date_ran?: DateTime;
    date_finished?: DateTime;
    error_reason?: string;

    constructor(data: any){
        this._id = data._id
        this.file_name = data.file_name
        this.status = data.status
        this.progress = data.progress
        this.progress_total = data.progress_total
        this.date_ran = data.date_ran ? new DateTime(data.date_ran) : undefined
        this.date_finished = data.date_finished ? new DateTime(data.date_finished) : undefined
        this.error_reason = data.error_reason
    }
}