import React, {HTMLAttributes, useEffect} from 'react';
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    FormControl,
    FormLabel,
    Radio,
    RadioGroup,
    Stack,
    useColorMode,
    useToast
} from '@chakra-ui/react';

import 'react-datepicker/dist/react-datepicker.css';
import format from "date-fns/format";
import TimePicker from "../TimePicker";
import ReactDatePicker from "react-datepicker";
import {RepeatBy, Schedule} from "../../models/schedule";
import {ScheduleAPI} from "../../api/ScheduleAPI";
import {Timeslot} from "../../models/timeslot";
import moment from "moment";

interface TimePickerProps {
    label?: String;
    isClearable?: boolean;
    onChange: (date: Date) => any;
    selectedDate: Date | undefined;
    showPopperArrow?: boolean;
    inline?: boolean;
}

const ScheduleTimePicker = ({
                        label,
                        selectedDate,
                        onChange,
                        isClearable = false,
                        showPopperArrow = false,
                        inline = false,
                        ...props
                    }: TimePickerProps & HTMLAttributes<HTMLElement>) => {

    const [isOpen, setIsOpen] = React.useState(false);
    const [time, set_time] = React.useState<Date>(((selectedDate) ? selectedDate : new Date()));
    const handleChange = (e: any) => {
        onChange(e)
        setIsOpen(!isOpen);
        set_time(e);
    };
    const handleClick = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };
    return (
        <FormControl>
            <Stack alignItems={"center"}>
                {label && (
                <FormLabel>{label}</FormLabel>
                )}
                <Button  variant={"outline"} onClick={handleClick}>
                    {moment(time).format('h:mm A')}
                </Button>
                {isOpen && (
                    <TimePicker
                        defaultValue={time.getTime()}
                        inline={true}
                        onChange={handleChange}
                        selectedDate={time}/>
                )}
            </Stack>
        </FormControl>
    );
};





interface DateRangeProps {
    label?: String
    isClearable?: boolean;
    onChange: (dates: [any,any]) => void;
    selectedDate: Date | undefined;
    showPopperArrow?: boolean;
}

const ScheduleDateRangePicker =({
                        label,
                        selectedDate,
                        onChange,
                        isClearable = false,
                        showPopperArrow = false,
                        ...props
                    }: DateRangeProps    & HTMLAttributes<HTMLElement>) => {

    const [isOpen, setIsOpen] = React.useState(false);
    const [startDate, setStartDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(null);
    const handleChange = (e: any) => {
        //setIsOpen(!isOpen);
        onChange(e)
        onDateRangeChange(e);
    };
    const handleClick = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };
    const onDateRangeChange = (dates: [any, any]) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };
    const isLight = useColorMode().colorMode==='light';//you can check what theme you are using right now however you want
    return (
        <FormControl>
            <Stack alignItems={"center"}>
                <FormLabel>{label}</FormLabel>
                <Button variant={"outline"}
                        onClick={handleClick}>
                    {format(startDate, "MMM dd, yyyy")} - {endDate && (format(endDate, "MMM dd, yyyy"))}
                </Button>
                {isOpen && (
                    <div className={isLight ? "light-theme" : "dark-theme"}>
                        <ReactDatePicker
                            selected={startDate}
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="react-datapicker__input-text"
                            inline
                            onChange={handleChange}
                        />
                    </div>
                )}
            </Stack>
        </FormControl>

    );
};
interface DateProps {
    label?: String
    isClearable?: boolean;
    onChange: (date: Date) => any;
    selectedDate: Date | undefined;
    showPopperArrow?: boolean;
}
const ScheduleDatePicker = ({
                                     label,
                                     selectedDate = new Date(),
                                     onChange,
                                     isClearable = false,
                                     showPopperArrow = false,
                                     ...props
                                 }: DateProps    & HTMLAttributes<HTMLElement>) => {

    const [isOpen, setIsOpen] = React.useState(false);
    const [date, setDate] = React.useState(selectedDate);

    const handleChange = (e: any) => {
        onChange(e)
        setDate(e as Date);
    };
    const handleClick = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    const isLight = useColorMode().colorMode==='light';//you can check what theme you are using right now however you want
    return (
        <FormControl>
            <Stack alignItems={"center"}>
                <FormLabel>{label}</FormLabel>
                <Button className="example-custom-input"
                        variant={"outline"}
                        onClick={handleClick}>
                    {format(date, "MMM dd, yyyy")}
                </Button>
                {isOpen && (
                    <div className={isLight?"light-theme":"dark-theme"}>
                        <ReactDatePicker
                            selected={date}
                            startDate={date}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            className="react-datapicker__input-text"
                            inline
                            onChange={handleChange}
                        />
                    </div>
                )}
            </Stack>
        </FormControl>

    );
};
interface SchedulePublishPopUp {
    schedule_input: Schedule;

    repeat_by_input?: RepeatBy

    end_date_input: Date

    isOpen: boolean
    cancelRef: any
    onClose:any
    onDateChange: (date: Date) => void
    onRepeatByChange: (repeat_by: RepeatBy) => void
    holidays: Array<Timeslot>
    schedule_start: Date
    schedule_end: Date

}

const PublishPopUp = React.memo(({
                          schedule_input,
                          repeat_by_input = RepeatBy.Weekly,
                          isOpen = false,
                         onDateChange,
                         onRepeatByChange,
                          end_date_input,
                          cancelRef,
                        schedule_start,
                        schedule_end,
                        holidays,
                        onClose,
                                ...props
                            }: SchedulePublishPopUp & HTMLAttributes<HTMLElement>) => {

    const [end_date, set_end_date] = React.useState<Date>(end_date_input);
    const [repeat_by, set_repeat_by] = React.useState<RepeatBy>(repeat_by_input);
    const toast = useToast()

    useEffect(()=> {
        set_end_date(end_date_input)
        set_repeat_by(repeat_by_input)
    },[isOpen]);
    const publish_schedule = () => {
        let repeat=1
            if (repeat_by===RepeatBy.Weekly) {
                repeat=7
            } else if (repeat_by===RepeatBy.Daily) {
                repeat=1
            } else if (repeat_by===RepeatBy.ScheduleLength) {
               repeat = Math.ceil((schedule_end.valueOf() - schedule_start.valueOf() )/ (1000    * 3600 * 24))+1;
            }
            ScheduleAPI.publish_schedule( repeat, schedule_input, end_date, holidays ).then((res) =>
                toast({
                    title: 'Success.',
                    description: "Schedule Published!",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })

            ).catch((err) => {
                toast({
                    title: 'Error.',
                    description: err.toString(),
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            })
        onClose()

    };

    return (

        <AlertDialog
            isOpen={isOpen}
            onClose={onClose}
            leastDestructiveRef={cancelRef}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Publish Schedule: {schedule_input.name}
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        <FormControl id="repeatBy" isRequired>
                            <FormLabel>Repeat By</FormLabel>
                            <RadioGroup onChange={(value) => {
                                set_repeat_by(value as RepeatBy)
                                onRepeatByChange(value as RepeatBy)
                            }
                            } value={repeat_by}>
                                <Stack direction='row'>
                                    <Radio value={RepeatBy.Daily}>Daily</Radio>
                                    <Radio value={RepeatBy.Weekly}>Weekly</Radio>
                                    <Radio value={RepeatBy.ScheduleLength}>Schedule Length</Radio>
                                </Stack>
                            </RadioGroup>
                        </FormControl>
                        <br/>
                        <FormControl>
                            <FormLabel> End Date</FormLabel>
                            <ScheduleDatePicker onChange={(value) => {
                                set_end_date(value as Date)
                                onDateChange(value as Date)
                            }} selectedDate={end_date}></ScheduleDatePicker>
                        </FormControl>
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme='green' onClick={publish_schedule} ml={3}>
                            Publish
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    );
});



export {ScheduleDateRangePicker, ScheduleTimePicker,PublishPopUp, ScheduleDatePicker};