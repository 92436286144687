import {
Box,
Button,
Divider,
Flex,
FormControl,
FormLabel,
Input,
Stack,
Select,
useColorModeValue,
useToast,
Text,
Link,
HStack,
Spacer,
} from '@chakra-ui/react'
import * as React from 'react'
import 'react-datepicker/dist/react-datepicker.css';
import { FiExternalLink } from 'react-icons/fi';
import { FamilyAPI } from '../../../api/FamilyAPI';
import LocalStorageService from '../../../api/LocalStorageService';
import { StudentAPI } from '../../../api/StudentAPI';
import { UserType } from '../../../api/UserApi';
import { Family } from '../../../models/family';
import { Student } from '../../../models/student';
import { FamilyCard } from '../../cards/family/FamilyCard';

interface Card {
    student: Student
}

export const StudentFamilyCard = React.memo(({student}: Card) => {
    const toast = useToast()
    const [family, set_family] = React.useState<Family>();

    React.useEffect(() => {
        FamilyAPI.get_family(student.family_id).then(e => set_family(e))
    }, [])

    const openInNewTab = (url: string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
        >
            {family &&
            <>
                { LocalStorageService.getInstance().getUser()?.user_type == UserType.Parent &&
                    <>
                    <Link href={"/parent/family/update/" + family._id.$oid}>Family information can not be edited directly here, click here to go and edit the family information</Link>
                    </>

                }

                { LocalStorageService.getInstance().getUser()?.user_type == UserType.Teacher &&
                    <HStack>
                        <Spacer/>
                        <Button colorScheme={"blue"} onClick={() => {openInNewTab("/family/" + family._id.$oid)}} rightIcon={<FiExternalLink/>}>View in Family Manager</Button>
                    </HStack>

                }
            
                <FamilyCard family={family} read_only={true}/>
            </>
            }
            
        </Box>
    )

})
