import HTTPApi from "./ApiCommon";
import { ObjectId } from "../utils/ObjectId";
import { EvaluationEntry, } from "../models/evaluation_entry";


export namespace EvaluationEntryAPI {

    export const get_evaluation_entries_list_from_evaluation = async (evaluation_id: ObjectId) => {
        return HTTPApi.get("/evaluation_entries/evaluation_entry_list/" + evaluation_id.$oid)
            .then((res) => {
                return res.data as Array<EvaluationEntry>
            })
    }

    export const get_evaluation_entries_list_for_student = async (student_id: ObjectId) => {
        return HTTPApi.get("/evaluation_entries/for_student/" + student_id.$oid)
            .then((res) => {
                return res.data as Array<EvaluationEntry>
            })
    }

    export const get_evaluation_entry = async (evaluation_entry_id: ObjectId) => {
        return HTTPApi.get("/evaluation_entries/" + evaluation_entry_id.$oid)
                .then((res) => {
                    return res.data as EvaluationEntry
                })
    }

    export const update_evaluation_entry = async (evaluation_entry: EvaluationEntry) => {
        return HTTPApi.post(
                    "evaluation_entries/update",
                    {
                        _id: evaluation_entry._id,
                        mark: evaluation_entry.mark,
                    }
                ).then((res) => {
                    return res.data as EvaluationEntry
                })
    }

    export const create_evaluation_entry = async (evaluation_entry: EvaluationEntry) => {
        return HTTPApi.post(
                    "/evaluation_entries/create",
                    {
                        evaluation_id: evaluation_entry.evaluation_id,
                        student: evaluation_entry.student,
                        mark: evaluation_entry.mark,
                    }
                ).then((res) => {
                    return res.data as EvaluationEntry
                })
    }

    export const delete_evaluation_entry = async (evaluation_entry_id: ObjectId) => {
        return HTTPApi.get("/evaluation_entries/delete/" + evaluation_entry_id.$oid)
                .then()
    }
}
