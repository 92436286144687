import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Box,
    Button,
    FormControl,
    FormLabel,
    HStack,
    IconButton,
    Input,
    Skeleton,
    Spacer,
    Stack,
    Switch, Tab, TabList, TabPanel,
    TabPanels, Tabs,
    Text,
    Textarea,
    useColorModeValue,
    useToast
} from "@chakra-ui/react"
import { ObjectId } from "../../utils/ObjectId";
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { TimeslotAPI } from "../../api/TimeslotAPI"
import LocalStorageService from "../../api/LocalStorageService"
import { TeacherAPI } from "../../api/TeacherAPI"
import { UserRole } from "../../api/UserApi"
import { HomeroomAPI } from "../../api/HomeroomAPI"
import { Teacher } from "../../models/teacher"
import { CourseAPI } from "../../api/CourseAPI";
import {
    Select as SearchSelect,
} from "chakra-react-select"; 
import DatePicker from "../DatePicker";
import TimePicker from "../TimePicker";
import { DateTime } from "../../utils/DateTime";
import { TableContent } from "../table/TableContent";
import { Timeslot } from "../../models/timeslot";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {Calendar, Event, momentLocalizer, Views} from "react-big-calendar";
import moment from "moment/moment";
import {FiPlus} from "react-icons/fi";


interface Card {
    teacher: Teacher,
    set_teacher: React.Dispatch<React.SetStateAction<Teacher | undefined>> | React.Dispatch<React.SetStateAction<Teacher>>
}
const localizer = momentLocalizer(moment)
// @ts-expect-error
let allViews = Object.keys(Views).map(k => Views[k])
export const TeacherSchedule = ({teacher, set_teacher} : Card) =>  {
    const [isOpen_timeslot, setIsOpen_timeslot] = React.useState(false)
    const onClose_timeslot = () => setIsOpen_timeslot(false)
    const cancelRef_timeslot  = React.useRef()
    const [isOpen_update_timeslot, setIsOpen_update_timeslot] = React.useState(false)
    const onClose_update_timeslot = () => {
        setIsOpen_update_timeslot(false)
        // get_timeslots()
    }
    const cancelRef_update_timeslot  = React.useRef()

    const [events, setEvents] = useState<Event[]>([])

    const [timeslots_data, set_timeslots_data] = useState<Array<Timeslot>>([]);
    const [timeslot, set_timeslot] = React.useState<Timeslot | undefined>()
    // const get_timeslots = () => {   
    //     return TimeslotAPI.get_timeslots_by_teacher(teacher._id)
    //         .then((res) => {
    //             return  set_timeslots_data(res)
    //         })
    // }

    const get_timeslots_columns = () => {
        
            return [
                {
                    Header: "Name",
                    accessor: "name",
                },
                {
                    Header: "Description",
                    accessor: "description"
                },
                {
                    Header: "Start Time",
                    accessor: "start_time",
                    Cell: function StatusCell(time: DateTime, table_data: Timeslot) {
                       
                        return (
                            <Text>{time.to_date().toLocaleString()}</Text>
                        )
                    },
                },
                {
                    Header: "End Time",
                    accessor: "end_time",
                    Cell: function StatusCell(time: DateTime, table_data: Timeslot) {
                     
                        return (
                            <Text>{time.to_date().toLocaleString()}</Text>
                        )
                    },
                },
             
                    {
                        Header: "",
                        accessor: "id",
                        Cell: function StatusCell(id: ObjectId, table_data: Timeslot) {  
                        
                            return (
                                
                                <Stack>
                                    {LocalStorageService.getInstance().getUser()?.has_role(UserRole.Can_Modify_Scheduling) &&
                                    <>
                                    <IconButton
                                        aria-label='Update' 
                                        colorScheme={"green"}
                                        icon={<EditIcon/>}
                                        onClick={() => {
                                            setIsOpen_update_timeslot(true)
                                            set_timeslot(table_data)
                                        }}
                                    />
                                    <UpdateTimeslotDialog
                                        timeslot={timeslot!}
                                        isOpen={isOpen_update_timeslot} onClose={onClose_update_timeslot} cancelRef={cancelRef_update_timeslot} 
                                    /> 
                                    </>   
                                    }                   
                                </Stack>
                            )
                        },
                    },
                    {
                        Header: "",
                        accessor: "_id",    
                        Cell: function StatusCell(id: ObjectId, table_data: Timeslot) {
                            return (
                                <>
                                {LocalStorageService.getInstance().getUser()?.has_role(UserRole.Can_Modify_Scheduling) &&
                                    <>
                                <IconButton
                                    aria-label='Remove' 
                                    colorScheme={"red"}
                                    icon={<DeleteIcon/>}
                                    onClick={() => {
                                        TimeslotAPI.delete_timeslot(id).then((res) =>{
                                            // get_timeslots()
                                        })
                                    }}
                                />
                                </>
                                }
                                </>
                            )
                        },
                    },
                
            ]
    }

    useEffect( () => {
        let add_events = timeslots_data.map((e) => {
            let event: Event = {
                start: e.start_time.to_date(),
                end: e.end_time.to_date(),
                title: e.name
            }
            return event
        })
        setEvents(add_events)

    },[timeslots_data])
    React.useEffect(()=>{
        // get_timeslots()
    },[isOpen_timeslot])

    return (
        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
        >
            <Stack>
                <HStack>
                    <Spacer/>

                    {LocalStorageService.getInstance().getUser()?.has_role(UserRole.Can_Modify_Scheduling) &&
                        <>
                            <Stack spacing="5" px={{base: '4', md: '6'}} py={{base: '5', md: '6'}}>
                                <Button
                                    colorScheme='green'
                                    leftIcon={<FiPlus/>}
                                    onClick={() => setIsOpen_timeslot(true)}
                                >
                                    Add Timeslot
                                </Button>
                                <CreateTimeslotDialog
                                    isOpen={isOpen_timeslot} onClose={onClose_timeslot} cancelRef={cancelRef_timeslot}
                                    teacher_default={teacher}
                                />
                            </Stack>

                        </>
                    }
                </HStack>
                <Tabs>
                    <TabList>
                        <Tab>List View</Tab>
                        <Tab>Calender View</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <TableContent data={timeslots_data} columns={get_timeslots_columns()}
                                          hover_color='cyan.300'/>
                        </TabPanel>
                        <TabPanel>
                            <Calendar
                                // defaultView='week'
                                min={new Date(2020, 1, 1, 7, 0, 0)}
                                max={new Date(2020, 1, 1, 18, 0, 0)}
                                style={{height: '75vh'}}
                                events={events}
                                step={15}
                                views={allViews}
                                showMultiDayTimes
                                localizer={localizer}
                                startAccessor="start"
                                endAccessor="end"
                            />
                        </TabPanel>
                    </TabPanels>
                </Tabs>

            </Stack>
        </Box>
    );
}

interface CreateTimeslotDialogProps {
    isOpen: boolean
    onClose: any
    cancelRef: any
    teacher_default: Teacher
}


const CreateTimeslotDialog = React.memo(({isOpen, onClose, cancelRef, teacher_default}: CreateTimeslotDialogProps) => {
    const toast = useToast() 
    const navigate = useNavigate()
    const [loading, set_loading] = React.useState<boolean>(false);

    const [teachers, set_teachers] = React.useState<any>();
    const [courses, set_courses] = React.useState<any>();
    const [homerooms, set_homerooms] = React.useState<any>();
    const [subjects, set_subjects] = React.useState<any>();
    const [name, set_name] = React.useState<string>("");
    const [description, set_description] = React.useState<string>()
    const [date, set_date] = React.useState<Date>(new Date())
    const [start_time, set_start_time] = React.useState<Date>(new Date())
    const [end_time, set_end_time] = React.useState<Date>(new Date())
    const [teacher, set_teacher] = React.useState<ObjectId>(teacher_default._id);
    const [course, set_course] = React.useState<ObjectId>();
    const [subject, set_subject] = React.useState<ObjectId>();
    const [homeroom, set_homeroom] = React.useState<ObjectId>();
    const [enabled, set_enabled] = React.useState<boolean>(false);
    const [is_course, set_is_course] = React.useState<boolean>(false);

    useEffect(()=>{
        TeacherAPI.teachers_list(null, null, null)
            .then((res) => {
                let data = res!.teachers.map((e) => {
                    return {
                        "value": e._id,
                        "label": e.profile.first_name + " " + e.profile.last_name          
                    }
                })
                set_teachers(data)
            })
        CourseAPI.course_list(null, null, undefined,undefined,undefined,undefined)
            .then((res) => {
                let data = res!.courses.map((e) => {
                    return {
                        "value": e._id,
                        "label": e.name        
                    }
                })
                set_courses(data)
            })

            HomeroomAPI.homeroom_list(null, null, undefined,undefined,undefined,undefined)
            .then((res) => {
                let data = res!.homerooms.map((e) => {
                    return {
                        "value": e._id,
                        "label": e.name        
                    }
                })
                set_homerooms(data)
            })
            if (homeroom) {
            HomeroomAPI.get_subjects(homeroom)
            .then((res) => {
                let data = res!.map((e) => {
                    return {
                        "value": e._id,
                        "label": e.name
                    }
                })
                set_subjects(data)
            })
        }
    }, [])

    const create = () => {
        if(name && date && start_time && end_time){
            const start_date = new Date(Date.UTC(date.getUTCFullYear(),date.getUTCMonth(),date.getUTCDate(),start_time.getUTCHours(),start_time.getUTCMinutes()))
            const end_date = new Date(Date.UTC(date.getUTCFullYear(),date.getUTCMonth(),date.getUTCDate(),end_time.getUTCHours(),end_time.getUTCMinutes()))
            set_loading(true)
            
            TimeslotAPI.create_timeslot(name, start_date, end_date, description, teacher,course,subject).then((res) => {
                set_loading(false)
                toast({
                    title: 'Success.',
                    description: "Timeslot succesfully created",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })           
                cancel()             
            }).catch((error) => {
                set_loading(false)
                toast({
                    title: 'Error.',
                    description: error.response.data,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            })
        }else{
            toast({
                title: 'Error.',
                description: "Please fill in all the required information",
                status: 'error',
                duration: 50000,
                isClosable: true,
              })
        }
    }

    const cancel = () => {
        set_name("")
        set_date(new Date())
        set_start_time(new Date())
        set_end_time(new Date())
        set_description(undefined)
        set_teacher(teacher_default._id)
        set_course(undefined)
        set_subject(undefined)
        set_homeroom(undefined)
        set_enabled(false)
        onClose()
    }

    return (
        <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Create Timeslot
            </AlertDialogHeader>

            <AlertDialogBody>
              {loading ?
                <Stack>
                    <Text>Please Wait... You will be redirected soon</Text>
                  <Skeleton height='20px' />
                  <Skeleton height='20px' />
                  <Skeleton height='20px' />
                </Stack>
                :
                <>
                  <FormControl id="name" isRequired>
                    <FormLabel>Name</FormLabel>
                    <Input isRequired
                        value={name}
                        onChange={(value) => {set_name(value.target.value)}}
                    />
                  </FormControl>
                  <br></br>
                  <FormControl id="description">
                    <FormLabel>Description</FormLabel>
                    <Textarea
                        value={description}
                        onChange={(value) => {set_description(value.target.value)}}
                    />
                  </FormControl>
                  <br></br>
                  <FormControl>
                    <FormLabel> Date</FormLabel>
                    <DatePicker onChange={(value) => {set_date(value as Date)}} selectedDate={date}></DatePicker>
                  </FormControl>
                  <br></br>
                  <FormLabel> Time</FormLabel>
                  <FormControl id="time" isRequired display='flex' alignItems='center' >
                  <FormControl id="start_time" isRequired  alignItems='center' >
                    
                    
                    <TimePicker onChange={(value) => {set_start_time(value as Date)}} selectedDate={start_time}></TimePicker>
                  </FormControl>
             
                  <FormControl id="end_time" isRequired  alignItems='center' >
                 
                    
                    <TimePicker onChange={(value) => {set_end_time(value as Date)}} selectedDate={end_time}></TimePicker>
                  </FormControl>
                  </FormControl>
                
                  <br></br>
                  <FormControl id="teacher" isRequired>
                    <FormLabel>Teacher</FormLabel>
                    <SearchSelect
                        name="flavors"
                        options={teachers}
                        defaultValue={teacher_default._id}
                        defaultInputValue={teacher_default.profile.first_name+" "+teacher_default.profile.last_name}
                        placeholder="Select a teacher..."
                        onChange={(e: any) => {set_teacher(e.value as ObjectId)}}
                    />
                  </FormControl>
             
                  <br></br>
                  <FormControl isRequired display='flex' alignItems='center'>
                  <FormLabel  htmlFor='is_course' mb='0'>Toggle Course or Subject</FormLabel>
                  <Switch
                            id="is_course"
                            onChange={(value) => {
                                set_is_course(!is_course)
                            }}>
                  
                           
                  </Switch>
                 
                  </FormControl>
                
                  <FormControl id="course" hidden={is_course}>
                  <br></br>
                    <FormLabel>Course</FormLabel>
                    <SearchSelect
                        name="flavors"
                        options={courses}
                        placeholder="Select a course..."
                        onChange={(e: any) => {set_course(e.value as ObjectId)}}
                    />
                  </FormControl>
                 
                  <FormControl id="homeroom"  hidden={!is_course}>
                  <br></br>
                    <FormLabel>Homeroom</FormLabel>
                    <SearchSelect
                        name="flavors"
                        options={homerooms}
                        placeholder="Select a homeroom..."
                        onChange={(e: any) => {set_course(e.value as ObjectId)
                                set_enabled(true)
                                HomeroomAPI.get_subjects(e.value as ObjectId)
                                .then((res) => {
                                    let data = res!.map((e) => {
                                        return {
                                            "value": e._id,
                                            "label": e.name
                                        }
                                    })
                                    set_subjects(data)
                                }) 
                        }}
                    />
                  </FormControl>
                  <br></br>
                  <FormControl id="subject" hidden={(!enabled || !is_course)}>
                    <FormLabel>Subject</FormLabel>
                    <SearchSelect
                        name="flavors"
                        options={subjects}
                        placeholder="Select a subject..."
                        onChange={(e: any) => {set_subject(e.value as ObjectId)}}
                    />
                  </FormControl>
                  
                </>
              }
            </AlertDialogBody>

            <AlertDialogFooter>
                {loading ? 
                    <></>
                    :
                    <>
                    <Button ref={cancelRef} onClick={cancel}>
                        Cancel
                    </Button>
                    <Button colorScheme='green' onClick={create} ml={3}>
                        Create
                    </Button>
                    </>
                }
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    )
})

interface UpdateTimeslotDialogProps {
    isOpen: boolean
    onClose: any
    cancelRef: any
    timeslot: Timeslot
}

const UpdateTimeslotDialog = React.memo(({isOpen, onClose, cancelRef,timeslot}: UpdateTimeslotDialogProps) => {
    const wait_until = 7
    const toast = useToast() 
    const navigate = useNavigate()
    const [loading, set_loading] = React.useState<boolean>(false);
    const [teachers, set_teachers] = React.useState<any>();
    const [courses, set_courses] = React.useState<any>();
    const [homerooms, set_homerooms] = React.useState<any>();
    const [subjects, set_subjects] = React.useState<any>();
    const [name, set_name] = React.useState<string>();
    const [description, set_description] = React.useState<string>()
    const [date, set_date] = React.useState<Date>()
    const [start_time, set_start_time] = React.useState<Date>()
    const [end_time, set_end_time] = React.useState<Date>()
    const [teacher, set_teacher] = React.useState<ObjectId>();
    const [course, set_course] = React.useState<ObjectId>();
    const [subject, set_subject] = React.useState<ObjectId>();
    const [homeroom, set_homeroom] = React.useState<ObjectId>();
    const [enabled, set_enabled] = React.useState<boolean>(false);
    const [is_course, set_is_course] = React.useState<boolean>(false);
    const [teacher_name, set_teacher_name]  = React.useState<string>();
    const [subject_name, set_subject_name]  = React.useState<string>()
    const [course_name, set_course_name]  = React.useState<string>()
    const [homeroom_name, set_hoomroom_name]  = React.useState<string>()

    const _set_teachers = ()=> {
        TeacherAPI.teachers_list(null, null, null)
        .then((res) => {
            let data = res!.teachers.map((e) => {
                return {
                    "value": e._id,
                    "label": e.profile.first_name + " " + e.profile.last_name          
                }
            })
            set_teachers(data)
          
         
            _set_courses()
        })
    }
    const _set_courses = () => {
        CourseAPI.course_list(null, null, undefined,undefined,undefined,undefined)
        .then((res) => {
            let data = res!.courses.map((e) => {
                return {
                    "value": e._id,
                    "label": e.name        
                }
            })
            
            set_courses(data)

            _set_hoomrooms()
        })
    }
    const _set_hoomrooms = () => {
        HomeroomAPI.homeroom_list(null, null, undefined,undefined,undefined,undefined)
        .then((res) => {
            let data = res!.homerooms.map((e) => {
                return {
                    "value": e._id,
                    "label": e.name        
                }
            })
            set_homerooms(data)
            _set_subjects()
     
        })
    }
    const _set_subjects = () => {
        if (homeroom) {
            HomeroomAPI.get_subjects(homeroom)
            .then((res) => {
                let data = res!.map((e) => {
                    return {
                        "value": e._id,
                        "label": e.name
                    }
                })
                set_subjects(data)
       
                _set_teacher()
            })
        } else {
            _set_teacher()
        }
    }

    const _set_teacher = () => {
        if (timeslot) {
            set_name(timeslot.name)
            set_date(timeslot.start_time.to_date())
            set_start_time(timeslot.start_time.to_date())
            set_end_time(timeslot.start_time.to_date())
            set_description(timeslot.description)
            if (timeslot.teacher_id) {
                set_teacher(timeslot.teacher_id)
                TeacherAPI.get_teacher(timeslot.teacher_id).then((teacher) => {
                    set_teacher_name(teacher.profile.first_name+" "+teacher.profile.last_name)
                    _set_course()
                })
            } else {            
                _set_course()
            }
        } else {
            set_loading(false)
        }
    } 
    const _set_course = () => {
        if (timeslot.course_id) {    
            set_is_course(true)
            set_course(timeslot.course_id)
            CourseAPI.get_course(timeslot.course_id).then((e) => {
                set_course_name(e.name)
                set_loading(false)
            })
        } else {          
            _set_subject()
        }
    }
    const _set_subject = () => {

        if (timeslot.subject_id) {
            set_subject(timeslot.subject_id)
            set_is_course(false)
            set_enabled(true)
            HomeroomAPI.get_subject(timeslot.subject_id).then((e) => {
                set_subject_name(e.name)
                set_homeroom(e.homeroom)
                HomeroomAPI.get_homeroom(e.homeroom).then((homeroom)=> {
           
                    set_hoomroom_name(homeroom.name)
                   
                   

                    set_loading(false)

                })
            })
        } else {

            set_loading(false)
           
        }
    }
   
    const set_defaults = () => {
        set_name("")
        set_date(new Date())
        set_start_time(new Date())
        set_end_time(new Date())
        set_description(undefined)
        set_teacher(undefined)
        set_course(undefined)
        set_subject(undefined)
        set_homeroom(undefined)
        set_enabled(false)
        set_teacher_name(undefined)
        set_subject_name(undefined)
        set_course_name("")
        set_hoomroom_name(undefined)
        set_loading(true)
        _set_teachers()
        
    }
  
    useEffect(()=>{
        if (subject) {
            HomeroomAPI.get_subject(subject).then((res)=> {
                    set_homeroom(res.homeroom)
                })
            }
        
    },[subject])

    useEffect(()=> {
        set_defaults()
    },[isOpen])
    
    const update = () => {
        if(name && date && start_time && end_time){
            const start_date = new Date(Date.UTC(date.getUTCFullYear(),date.getUTCMonth(),date.getUTCDate(),start_time.getUTCHours(),start_time.getUTCMinutes()))
            const end_date = new Date(Date.UTC(date.getUTCFullYear(),date.getUTCMonth(),date.getUTCDate(),end_time.getUTCHours(),end_time.getUTCMinutes()))
            
            set_loading(true)
            
            const updated_timeslot = new Timeslot({ 
                _id: timeslot._id,
                name: name,
                description: description,
                start_time: DateTime.from_date(start_date),
                end_time: DateTime.from_date(end_date),
                course_id: course,
                subject_id: subject,
                teacher_id: teacher})

            TimeslotAPI.update_timeslot(timeslot._id,updated_timeslot).then((res) => {
                set_loading(false)

                toast({
                    title: 'Success.',
                    description: "Timeslot succesfully updated",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
                
                cancel()
               
            }).catch((error) => {
                set_loading(false)
                toast({
                    title: 'Error.',
                    description: error.response.data,
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            })
        }else{
            toast({
                title: 'Error.',
                description: "Please fill in all the required information",
                status: 'error',
                duration: 5000,
                isClosable: true,
              })
        }
    }

    const cancel = () => {
        set_name("")
        set_date(new Date())
        set_start_time(new Date())
        set_end_time(new Date())
        set_description(undefined)
        set_teacher(undefined)
        set_course(undefined)
        set_subject(undefined)
        set_homeroom(undefined)
        set_enabled(false)
        set_teacher_name(undefined)
        set_subject_name(undefined)
        set_course_name(undefined)
        set_hoomroom_name(undefined)
        onClose()
    }
  

    return (
        <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              Update Timeslot
            </AlertDialogHeader>

            <AlertDialogBody>
              {loading ?
                <Stack>
                    <Text>Please Wait... You will be redirected soon</Text>
                  <Skeleton height='20px' />
                  <Skeleton height='20px' />
                  <Skeleton height='20px' />
                </Stack>
                :
                <>
                  <FormControl id="name" isRequired>
                    <FormLabel>Name</FormLabel>
                    <Input isRequired
                        value={name}
                        onChange={(value) => {set_name(value.target.value)}}
                    />
                  </FormControl>
                  <br></br>
                  <FormControl id="description" isRequired>
                    <FormLabel>Description</FormLabel>
                    <Textarea
                        value={description}
                        onChange={(value) => {set_description(value.target.value)}}
                    />
                  </FormControl>
                  <br></br>
                  <FormControl>
                    <FormLabel> Date</FormLabel>
                    <DatePicker onChange={(value) => {set_date(value as Date)}} selectedDate={date}></DatePicker>
                  </FormControl>
                  <br></br>
                  <FormLabel> Time</FormLabel>
                  <FormControl id="time" isRequired display='flex' alignItems='center' >
                  <FormControl id="start_time" isRequired  alignItems='center' >
                    
                    
                    <TimePicker onChange={(value) => {set_start_time(value as Date)}} selectedDate={start_time}></TimePicker>
                  </FormControl>
             
                  <FormControl id="end_time" isRequired  alignItems='center' >
                 
                    
                    <TimePicker onChange={(value) => {set_end_time(value as Date)}} selectedDate={end_time}></TimePicker>
                  </FormControl>
                  </FormControl>
                
                  <br></br>
                  <FormControl id="teacher" isRequired>
                    <FormLabel>Teacher</FormLabel>
                    
                    <SearchSelect
                        name="flavors"
                        options={teachers}
                        defaultValue={teacher}
                        defaultInputValue={teacher_name!}
                        placeholder="Select a teacher..."
                        onChange={(e: any) => {set_teacher(e.value as ObjectId)}}
                    />
                  </FormControl>
             
                  <br></br>
                  <FormControl isRequired display='flex' alignItems='center'>
                  <FormLabel  htmlFor='is_course' mb='0'>Toggle Course or Subject</FormLabel>
                  <Switch isRequired
                            id="is_course"
                            defaultChecked = {!is_course}
                            onChange={(value) => {
                                set_is_course(!is_course)
                            }}>
                  
                           
                  </Switch>
                 
                  </FormControl>
                
                  <FormControl id="course" isRequired hidden={!is_course}>
                  <br></br>
                    <FormLabel>Course</FormLabel>
                    <SearchSelect
                        name="flavors"
                        options={courses}
                        defaultValue={course}
                        defaultInputValue={course_name!}
                        placeholder="Select a course..."
                        onChange={(e: any) => {set_course(e.value as ObjectId)}}
                    />
                  </FormControl>
                 
                  <FormControl id="homeroom" isRequired  hidden={is_course}>
                  <br></br>
                    <FormLabel>Homeroom</FormLabel>
                    <SearchSelect
                        name="flavors"
                        options={homerooms}
                        defaultValue={homeroom}
                        defaultInputValue={homeroom_name!}
                        placeholder="Select a homeroom..."
                        onChange={(e: any) => {set_course(e.value as ObjectId)
                                set_enabled(true)
                                HomeroomAPI.get_subjects(e.value as ObjectId)
                                .then((res) => {
                                    let data = res!.map((e) => {
                                        return {
                                            "value": e._id,
                                            "label": e.name
                                        }
                                    })
                                    set_subjects(data)
                                })
                            
                        
                        }}
                    />
                  </FormControl>
                  <br></br>
                  <FormControl id="subject" isRequired hidden={(!enabled || is_course)}>
                    <FormLabel>Subject</FormLabel>
                
                    <SearchSelect
                        name="flavors"
                        defaultValue={subject}
                        defaultInputValue={subject_name!}
                        options={subjects}
                        placeholder="Select a subject..."
                        onChange={(e: any) => {set_subject(e.value as ObjectId)}}
                    />
                  </FormControl>
                  
                </>
              }
            </AlertDialogBody>

            <AlertDialogFooter>
                {loading ? 
                    <></>
                    :
                    <>
                    <Button ref={cancelRef} onClick={cancel}>
                        Cancel
                    </Button>
                    <Button colorScheme='green' onClick={update} ml={3}>
                        Update
                    </Button>
                    </>
                }
              
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    )

})