import { DateTime } from "../utils/DateTime";
import { ObjectId } from "../utils/ObjectId"

export enum StudentIncidentStatus {
    InProgress = "InProgress",
    Filled = "Filled",
}

export enum StudentIncidentSeverity {
    Low = "Low",
    Medium = "Medium",
    High = "High",
    Severe = "Severe"
}

export class StudentIncident {
    _id: ObjectId;
    student: ObjectId;

    created_by_teacher: ObjectId;

    title: string;
    date: DateTime;
    description: string;
    status: StudentIncidentStatus;
    severity: StudentIncidentSeverity;


    police_date_contact: DateTime | null;
    police_date_investigation: DateTime | null;
    police_officer_name: string;
    police_case_id: string;

    action_taken: string;

    constructor(data: any){
        this._id = data._id
        this.student = data.student
        this.created_by_teacher = data.created_by_teacher
        this.title = data.title
        this.date = new DateTime(data.date)
        this.description = data.description
        this.status = data.status
        this.severity = data.severity
        this.police_date_contact = data.police_date_contact? new DateTime(data.police_date_contact) : null
        this.police_date_investigation = data.police_date_investigation? new DateTime(data.police_date_investigation) : null
        this.police_officer_name = data.police_officer_name
        this.police_case_id = data.police_case_id
        this.action_taken = data.action_taken
    }

}