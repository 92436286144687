import {
    Box,
    Button,
    Divider,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Stack,
    Select,
    useColorModeValue,
    useToast,
    Spacer,
    RadioGroup,
    Radio,
    Text,
    Alert,
    AlertIcon,
    VStack,
    Link, Heading,
} from '@chakra-ui/react'
    import * as React from 'react'
    import 'react-datepicker/dist/react-datepicker.css';
    import { StudentAPI } from '../../../api/StudentAPI';
    import { Grades, SensitiveStudent, Student, StudentStatus } from '../../../models/student';
    import { DateTime } from '../../../utils/DateTime';
    import DatePicker from '../../DatePicker'
import {useEffect} from "react";
    
    interface Card {
        student: Student
        sensitive_student: SensitiveStudent
        set_sensitive_student: React.Dispatch<React.SetStateAction<SensitiveStudent | undefined>>
    }
    
    export const FinancialCard = React.memo(({student, sensitive_student, set_sensitive_student}: Card) => {
        const toast = useToast()

        const required_info = () => {
            return sensitive_student.financial.bank_account_name != "" &&
                sensitive_student.financial.bank_account_number != "" &&
                sensitive_student.financial.bank_transit_number != "" &&
                sensitive_student.financial.financial_institution_number != "" &&
                sensitive_student.financial.tuition_cost != "" &&
                sensitive_student.financial.tuition_payments != "" &&
                (sensitive_student.financial.first_time_fee != "" || student.status == StudentStatus.ReRegistration)
        }
        useEffect(()=> {
            set_sensitive_student(
                prevState => (prevState ? {
                    ...prevState,
                    financial: { ...prevState.financial,
                        bank_account_name: " ",
                        bank_account_number: " ",
                        bank_transit_number: " ",
                        financial_institution_number: " "
                        }
                } : undefined)
            );
        },[])
        const update_financial_state = (name: any, value: any) => {
            set_sensitive_student(
                prevState => (prevState ? {
                    ...prevState,
                    financial: { ...prevState.financial, [name]: value}
                } : undefined)
            );
        }
    
        const update_financial= () => {
            if(required_info()){
                StudentAPI.update_financial(sensitive_student._id, sensitive_student.financial)
                    .then((res) => {
                        toast({
                            title: 'Success',
                            description: "Saved Financial",
                            status: 'success',
                            duration: 5000,
                            isClosable: true,
                        })
                    })
                    .catch((err) => {
                        console.log(err)
                        toast({
                            title: 'Error.',
                            description: "Failed to update Financial",
                            status: 'error',
                            duration: 5000,
                            isClosable: true,
                        })
                    })
            }
            else{
                toast({
                    title: 'Error.',
                    description: "Please fill in all required fields.",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            }
            
        }
    
        return (
            <Box
                as="form"
                bg="bg-surface"
                boxShadow={useColorModeValue('sm', 'sm-dark')}
                borderRadius="lg"
                flex="1"
            >
                <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>

                    <VStack>
                        <Alert status='info' variant='subtle'>
                            <AlertIcon />
                            *The subsidized tuition does not cover all of the student's expenses, as the remaining costs are covered by donors
                        </Alert>
                        <Alert status='info' variant='subtle'  dir={"rtl"}>
                            <AlertIcon />
                            *الرسوم الدراسية المدعومة لا تغطي كافة تكاليف الطالب، حيث يتم تغطية باقي التكاليف من المتبرعين
                        </Alert>
                    </VStack>
                    <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                        <FormControl id="tuition_cost" isRequired>
                            <FormLabel>Tuition Cost</FormLabel>
                            <RadioGroup onChange={(e) => update_financial_state("tuition_cost", e)} value={sensitive_student.financial.tuition_cost}>
                                <Stack>
                                    {["grade9", "grade10"].indexOf(student.profile.grade) > -1 &&
                                        <>
                                            <Radio value='Highschool11500'>$11,500 Annually: High school (Actual/الحقيقية)</Radio>
                                            <Radio value='Highschool9950'>$9,950 Annually: High school (Subsidized/المدعومة)*</Radio>
                                            {/* <Radio value='Highschool7500'>7,500$ Annually: High school Regular Tuition</Radio> */}
                                        </>     
                                    }
                                    {["grade11", "grade12"].indexOf(student.profile.grade) > -1 &&
                                        <>
                                            <Radio value='Highschool11800'>$11,800 Annually: High School (Actual/الحقيقية)</Radio>
                                            <Radio value='Highschool10500'>$10,500 Annually: High School (Subsidized/المدعومة)*</Radio>

                                            {/* <Radio value='Highschool7500'>7,500$ Annually: Highschool Regular Tuition</Radio> */}
                                        </>
                                    }


                                    {["grade1", "grade2", "grade3", "grade4", "grade5"].indexOf(student.profile.grade) > -1 &&
                                        <>
                                            <Radio value='Elementary10500'>$10,500 Annually (Actual/الحقيقية)</Radio>
                                            {/* <Radio value='Elementary6500'>6,500$ Annually: Elementary Regular Tuition</Radio> */}
                                            <Radio value='Elementary8900'>$8,900 Annually (Subsidized/المدعومة)*</Radio>
                                        </>

                                    }
                                    {["grade6", "grade7", "grade8"].indexOf(student.profile.grade) > -1 &&
                                        <>
                                            <Radio value='Elementary10500'>$10,500 Annually (Actual/الحقيقية)</Radio>
                                            <Radio value='Elementary9500'>9,500$ Annually (Subsidized/المدعومة)*</Radio>
                                            {/* <Radio value='Elementary6500'>6,500$ Annually: Elementary Regular Tuition</Radio> */}
                                        </>     
                                    }

                                    {[ "jk", "sk"].indexOf(student.profile.grade) > -1 &&
                                        <>
                                            <Radio value='FullMontessori11000'>$11,000  Annually: Montessori + After-School (8AM - 4PM) (Actual/الحقيقية)</Radio>
                                            <Radio value='FullMontessori10500'>$10,500  Annually: Montessori + After-School (8AM - 4PM) (Subsidized/المدعومة)*</Radio>
                                            <Radio value='Montessori9500'>$9,500 2 Annually: Montessori (8AM - 2PM) (Actual/الحقيقية)</Radio>
                                            <Radio value='Montessori9000'>$9,000 2 Annually: Montessori (8AM - 2PM) (Subsidized/المدعومة)*</Radio>
                                        </>     
                                    }
                                    {[ "pk"].indexOf(student.profile.grade) > -1 &&
                                        <>
                                            <Radio value='PreSchool9000'>$9,000  Annually: Pre-School (Actual/الحقيقية) </Radio>
                                            <Radio value='PreSchool10500'>$8,000  Annually: Pre-School (Subsidized/المدعومة)* </Radio>
                                        </>
                                    }
                                </Stack>
                            </RadioGroup>
                        </FormControl>
                        <FormControl id="tuition_payments" isRequired>
                            <FormLabel>Tuition Payments</FormLabel>
                            <RadioGroup onChange={(e) => update_financial_state("tuition_payments", e)} value={sensitive_student.financial.tuition_payments}>
                                <Stack>
                                    <Radio value='1'>One Time Payment</Radio>
                                    {/* <Radio value='10'>10 Month Payments</Radio>  */}
                                    {/* { sensitive_student.financial.tuition_payments == "12" && */}
                                        <Radio value='12'>12 Month Payments</Radio>
                                    {/* } */}
                                </Stack>
                            </RadioGroup>
                        </FormControl>
                    </Stack>
                    <Spacer/>
                    <Divider/>
                    { student.status != StudentStatus.ReRegistration && 
                        <>
                        <VStack>


                            <Alert status='info' variant='subtle'>
                                <AlertIcon />
                                One time entry fee of $2,500 / per family of newly enrolled students.
                                This fee is paid once in a lifetime per family.
                                If you are enrolling more than one student you will NOT be charged multiple times, select the same desired option for all students.
                            </Alert>
                        </VStack>
                        <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                            <FormControl id="first_time_fee" isRequired>
                                <FormLabel>First Time Family Fee: 2500$ (Not included in tuition) - Only Charged To First Time Families and Once Per Family Per Lifetime</FormLabel>
                                <RadioGroup onChange={(e) => update_financial_state("first_time_fee", e)} value={sensitive_student.financial.first_time_fee}>
                                    <Stack>
                                        <Radio value='1'>Pay One Time Payment</Radio>
                                        <Radio value='12'>Pay Over 12 Months</Radio>
                                        <Radio value='Done'>Already Paid</Radio>

                                    </Stack>
                                </RadioGroup>
                            </FormControl>
                        </Stack>
                        </>
                    }
                </Stack>
                <Divider />
                <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
                    <Button onClick={update_financial}>
                        Save
                    </Button>
                </Flex>
            </Box>
        )

    })
    