import { ChevronRightIcon } from '@chakra-ui/icons';
import {
Box,
Button,
Divider,
Flex,
FormControl,
FormLabel,
Input,
Stack,
Select,
useColorModeValue,
useToast,
Text,
Badge,
Textarea,
Breadcrumb,
BreadcrumbItem,
BreadcrumbLink,
Heading,
Center,
HStack,
Checkbox,
} from '@chakra-ui/react'
import * as React from 'react'
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate, useParams } from 'react-router-dom';
import { StudentAPI } from '../../api/StudentAPI';
import { FormDataDocuments, FormDataObj, FormDataInterface, Grades, Student, FormDataStatus, SensitiveStudent } from '../../models/student';
import { DateTime } from '../../utils/DateTime';
import { DocumentsCard } from '../students/Cards/DocumentsCard';
import { HealthCard } from '../students/Cards/HealthCard';
import { OfficeInformationCard } from '../students/Cards/OfficeInfoCard';
import { ProfileCard } from '../students/Cards/ProfileCard';
import { FinancialCard } from '../students/Cards/FinancialCard';
import { ConsentCard } from '../students/Cards/ConsentCard';
import { UniformCard } from '../students/Cards/UniformCard';
import LocalStorageService from '../../api/LocalStorageService';
import { UserRole, UserType } from '../../api/UserApi';
import { StudentFamilyCard } from '../students/Cards/StudentFamilyCard';
import {DonationCard} from "../students/Cards/DonationCard";


const get_form = (form_type: keyof FormDataInterface, student: Student, set_student: any,  sensitive_student: SensitiveStudent | null, set_sensitive_student: any) => {
    if(form_type == "profile"){
        return <ProfileCard student={student} set_student={set_student}/>
    }
    else if(form_type == "health"){
        return <HealthCard student={student} set_student={set_student}/>
    }
    else if(form_type == "family"){
        return <StudentFamilyCard student={student}/>
    }
    else if(form_type == "office_information"){
        return <OfficeInformationCard student={student} set_student={set_student}/>
    }
    else if(form_type == "documents"){
        return <DocumentsCard student={student} set_student={set_student}/>
    }
    else if(form_type == "consent"){
        return <ConsentCard student={student} set_student={set_student}/>
    }
    else if(form_type == "uniform"){
        return <UniformCard student={student} set_student={set_student}/>
    }
    else if(form_type == "financial" && sensitive_student && set_sensitive_student){
        return <FinancialCard student={student} sensitive_student={sensitive_student!} set_sensitive_student={set_sensitive_student}/>
    }
    else if(form_type == "donation"){
        return <DonationCard student={student} set_student={set_student}/>
    }
}

interface Card {
    form_type: keyof FormDataInterface
    student: Student
    set_student: React.Dispatch<React.SetStateAction<Student | undefined>>
}

export const FormStatusCard = React.memo(({form_type, student, set_student}: Card) => {
    const toast = useToast();
    let navigate = useNavigate();

    const valid_form_type = (s: Student) => {
        return form_type in s.form_data
    }

    const [sensitive_student, set_sensitive_student] = React.useState<SensitiveStudent>()

    React.useEffect(()=>{
        if(!valid_form_type(student)){
            navigate("/404");
        }
        if(form_type == "financial"){
            StudentAPI.get_sensitive_student(student._id.$oid).then((res) => {
                let s = res!
                set_sensitive_student(s)
            })
        }
    }, [])

    const update_form_status_state = (name: any, value: any) => {
        set_student(
            prevState => (prevState ? {
                ...prevState,
                form_data: { 
                    ...prevState.form_data,
                    [form_type] : {
                        ...prevState.form_data[form_type],
                        [name]: value
                    }
                }
            } : undefined)
        );
    }

    const update_document_form_status_state = (name: any, value: any) => {
        if(form_type =="documents"){
            set_student(
                prevState => (prevState ? {
                    ...prevState,
                    form_data: { 
                        ...prevState.form_data,
                        [form_type] : {
                            ...prevState.form_data[form_type],
                            check_list: {
                                ...prevState.form_data[form_type].check_list,
                                [name]: value
                            }
                        }
                    }
                } : undefined)
            );
        }
        
    }


    const update_form_data = (status: keyof typeof FormDataStatus) => {
        if(LocalStorageService.getInstance().getUser()?.has_role(UserRole.Can_Enroll_Student)){
            if(form_type =="documents"){
                if(status == FormDataStatus.Approved 
                    && student.form_data.documents.check_list.immunization_record 
                    && student.form_data.documents.check_list.citizenship_passport_PR 
                    && student.form_data.documents.check_list.proof_of_address 
                    && student.form_data.documents.check_list.education_documents 
                    && student.form_data.documents.check_list.legal_documents
                    && student.form_data.documents.check_list.void_check){

                        let form_data = student.form_data.documents;
                        form_data.status = status as FormDataStatus;

                        StudentAPI
                            .update_documents_form_data(student!._id, form_data)
                            .then((res) => {
                                update_form_status_state("status", status);
                                toast({
                                    title: 'Success',
                                    description: "Form Status Updated",
                                    status: 'success',
                                    duration: 5000,
                                    isClosable: true,
                                })
                            })

                }
                else if (status == FormDataStatus.NeedsChange){
                    let form_data = student.form_data.documents;
                    form_data.status = status as FormDataStatus;
                    StudentAPI
                            .update_documents_form_data(student!._id, form_data)
                            .then((res) => {
                                update_form_status_state("status", status);
                                toast({
                                    title: 'Success',
                                    description: "Form Status Updated",
                                    status: 'success',
                                    duration: 5000,
                                    isClosable: true,
                                })
                            })
                }
                else{
                    toast({
                        title: 'Error',
                        description: "Can't Approve without all the checklist being selected",
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    })
                }
            }
            else {
                StudentAPI.update_form_data(
                    student!._id,
                    form_type, 
                    {
                        status: FormDataStatus[status],
                        comment: student!.form_data[form_type].comment
                    }
                )
                .then((res) => {
                    update_form_status_state("status", status);
                    toast({
                        title: 'Success',
                        description: "Form Status Updated",
                        status: 'success',
                        duration: 5000,
                        isClosable: true,
                    })
                })
            }
        }
    }

    return (
        
        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
        >
            <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                { student &&
                    <>
                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                        <Text>
                            Status:
                        </Text>
                        <Badge fontSize="xs" colorScheme={badgeEnum[student!.form_data[form_type].status]}>
                            {badgeEnumName[student!.form_data[form_type].status]}
                        </Badge>
                </Stack>
                { form_type == "documents" &&
                    <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                        <FormControl id="check_list">
                            <FormLabel>Documents Checklist:</FormLabel>
                            <Stack>
                                <Checkbox
                                    isDisabled={LocalStorageService.getInstance().getUser()?.user_type == UserType.Parent}
                                    colorScheme='green'
                                    isChecked={student!.form_data[form_type].check_list.immunization_record}
                                    onChange={(e) => update_document_form_status_state("immunization_record", e.target.checked)}
                                >Immunization Records</Checkbox>
                                <Checkbox
                                    isDisabled={LocalStorageService.getInstance().getUser()?.user_type == UserType.Parent}
                                    colorScheme='green'
                                    isChecked={student!.form_data[form_type].check_list.citizenship_passport_PR}
                                    onChange={(e) => update_document_form_status_state("citizenship_passport_PR", e.target.checked)}
                                >Citizenship or Passport or Permanent Residency or Birth Certificate</Checkbox>
                                <Checkbox
                                    isDisabled={LocalStorageService.getInstance().getUser()?.user_type == UserType.Parent}
                                    colorScheme='green'
                                    isChecked={student!.form_data[form_type].check_list.proof_of_address}
                                    onChange={(e) => update_document_form_status_state("proof_of_address", e.target.checked)}
                                >Proof of Address - Bank statement or Insurance statement or Utility bill</Checkbox>
                                <Checkbox
                                    isDisabled={LocalStorageService.getInstance().getUser()?.user_type == UserType.Parent}
                                    colorScheme='green'
                                    isChecked={student!.form_data[form_type].check_list.education_documents}
                                    onChange={(e) => update_document_form_status_state("education_documents", e.target.checked)}
                                >Education Documents - Any relevant education documents such as report cards, previous school enrollment, etc...</Checkbox>
                                <Checkbox
                                    isDisabled={LocalStorageService.getInstance().getUser()?.user_type == UserType.Parent}
                                    colorScheme='green'
                                    isChecked={student!.form_data[form_type].check_list.legal_documents}
                                    onChange={(e) => update_document_form_status_state("legal_documents", e.target.checked)}
                                >Legal Documents - Any relevant legal documents</Checkbox>
                                <Checkbox
                                    isDisabled={LocalStorageService.getInstance().getUser()?.user_type == UserType.Parent}
                                    colorScheme='green'
                                    isChecked={student!.form_data[form_type].check_list.void_check}
                                    onChange={(e) => update_document_form_status_state("void_check", e.target.checked)}
                                >Void Check - For tuition payments</Checkbox>
                            </Stack>
                        </FormControl>
                        </Stack>

                }
                { LocalStorageService.getInstance().getUser()?.has_role(UserRole.Can_Enroll_Student) || (student!.form_data[form_type].status != "None" && student!.form_data[form_type].status != "Approved" && student!.form_data[form_type].comment != "")?
                    <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                            <FormControl id="comment">
                                <FormLabel>Admin Comments:</FormLabel>
                                <Textarea
                                    value={student!.form_data[form_type].comment}
                                    onChange={(value) => {
                                        update_form_status_state(value.target.id, value.target.value)
                                    }}
                                    placeholder='Please enter any comments you have regarding this form.'
                                    isReadOnly={LocalStorageService.getInstance().getUser()?.user_type == UserType.Parent}
                                />
                            </FormControl>
                    </Stack>
                    : <></>
                }

                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    {
                        (form_type == "financial")?
                            sensitive_student &&
                            get_form(form_type, student, set_student, sensitive_student, set_sensitive_student)
                        :
                            get_form(form_type, student, set_student, null, null)
                    }
                </Stack>
                </>
                }

            </Stack>
            <Divider />
            { LocalStorageService.getInstance().getUser()?.has_role(UserRole.Can_Enroll_Student) &&
                <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
                    <HStack>
                        <Button colorScheme="red" onClick={() => update_form_data("NeedsChange")}>
                            Deny
                        </Button>
                        <Button colorScheme="green" onClick={() => update_form_data("Approved")}>
                            Approve
                        </Button>
                    </HStack>
                </Flex>
            }
            
        </Box>
       
    )

})

const badgeEnum: Record<string, string> = {
    None: "red",
    Waiting: "orange",
    NeedsChange: "red",
    Approved: "green",
    Submitted: "green"
}

const badgeEnumName: Record<string, string> = {
    None: "Needs to be Done",
    Waiting: "Filled",
    NeedsChange: "Admin Requested Changes",
    Approved: "Approved",
    Submitted: "Submitted"
}
