import React, {ComponentClass, FC, useEffect, useState} from 'react'
import {Calendar, CalendarProps, dateFnsLocalizer, Event} from 'react-big-calendar'
import withDragAndDrop, { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop'
import format from 'date-fns/format'
import parse from 'date-fns/parse'
import startOfWeek from 'date-fns/startOfWeek'
import getDay from 'date-fns/getDay'
import enUS from 'date-fns/locale/en-US'
import addHours from 'date-fns/addHours'
import startOfHour from 'date-fns/startOfHour'

import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
interface ScheduleInput{
    schedule_events: Event[],
    schedule_name: string,
    defaultDate: Date


}
interface MyCalendarEvent extends Event {
    title: string;
    start: Date;
    end: Date;
    custom?: boolean;
}

export const MyCalenderTest = React.memo(({schedule_events,schedule_name,defaultDate}: ScheduleInput) => {
    const [events, setEvents] = useState<Event[]>(schedule_events)
    // const events: MyCalendarEvent[] = [
    //     {
    //         title: 'Example Event',
    //         start: new Date(),
    //         end: new Date(),
    //         custom: true,
    //     },
    // ];

    const customEventStyles = (event: MyCalendarEvent) => {
        if (event.custom) {
            return {
                className: 'custom-event',
                style: {},
            };
        }
        return {};
    };

    const CustomEvent = ({ event }: { event: MyCalendarEvent }) => {
        return (
            <>
                {event.custom && <span className="custom-event-icon">🚀</span>}
                {event.title}
            </>
        );
    };

    useEffect(() => {
        setEvents(schedule_events)
    }, [schedule_events]);
    const onEventResize: withDragAndDropProps['onEventResize'] = data => {
        const { event ,start, end } = data
        setEvents(currentEvents => {
            currentEvents = currentEvents.filter((a) => {
                return a !== event
            })
            const firstEvent = {
                title: event.title,
                start: new Date(start),
                end: new Date(end),
            }
            return [...currentEvents, firstEvent]
        })
    }


    // const onEventDrop: withDragAndDropProps['onEventDrop'] = data => {
    //     const {event,start, end } = data
    //
    //     setEvents(currentEvents => {
    //         currentEvents = currentEvents.filter((a) => {
    //             return a !== event
    //         })
    //         const firstEvent = {
    //             title: event.title,
    //             start: new Date(start),
    //             end: new Date(end),
    //         }
    //         return [...currentEvents, firstEvent]
    //     })
    // }

    return (
        <DnDCalendar
            defaultView='week'
            defaultDate={defaultDate}
            events={events}
            localizer={localizer}
            // onEventDrop={onEventDrop}
            // onEventResize={onEventResize}
            resizable
            style={{ height: '75vh', width: '130vh' }}
        />
    )
});

const locales = {
    'en-US': enUS,
}
const endOfHour = (date: Date): Date => addHours(startOfHour(date), 1)
const now = new Date()
const start = endOfHour(now)
const end = addHours(start, 2)
// The types here are `object`. Strongly consider making them better as removing `locales` caused a fatal error
const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
})

const DnDCalendar = withDragAndDrop(Calendar as ComponentClass<CalendarProps<object>>)

