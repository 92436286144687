import HTTPApi from "./ApiCommon";
import { ObjectId } from "../utils/ObjectId";
import {CanTeach} from "../models/can_teach";
import { Teacher } from "../models/teacher";
import { Course } from "../models/course";


export namespace CanTeachAPI {

   

    export const get_can_teach = async (can_teach_id: ObjectId) => {
        return HTTPApi.get("/can_teach/" + can_teach_id.$oid)
            .then((res) => {
                return res.data as CanTeach
            })
    }

    export const get_teachers_by_course = async (course_id: ObjectId) => {
        return HTTPApi.post(
                    "can_teach/get_teachers_by_course",
                    {
                        course_id: course_id.$oid,
                
                    }
                ).then((res) => {
                    return res.data as Array<Teacher>
                })
    }

    export const get_teachers_by_subject = async (subject_id: ObjectId) => {
        return HTTPApi.post(
                    "can_teach/get_teachers_by_subject",
                    {
                        subject_id: subject_id.$oid,
                
                    }
                ).then((res) => {
                    return res.data as Array<Teacher>
                })
    }

    export const get_courses = async (teacher_id: ObjectId) => {
        return HTTPApi.post(
                    "can_teach/get_courses",
                    {
                        teacher_id: teacher_id.$oid,
                
                    }
                ).then((res) => {
                    return res.data as Array<Course>
                })
    }
    export const get_subjects = async (teacher_id: ObjectId) => {
        return HTTPApi.post(
                    "can_teach/get_subjects",
                    {
                        teacher_id: teacher_id.$oid,
                
                    }
                ).then((res) => {
                    return res.data as Array<Course>
                })
    }

   

    export const create_can_teach = async (teacher_id: ObjectId, course_id?: ObjectId, subject_id?: ObjectId) => {
        return HTTPApi.post(
                    "/can_teach/create",
                    {
                        course_id: course_id,
                        subject_id: subject_id,
                        teacher_id: teacher_id
                        
                    }
                ).then((res) => {
                    return res.data as CanTeach
                })
    }

    export const delete_can_teach = async (can_teach_id: ObjectId) => {
        return HTTPApi.get("/can_teach/delete/" + can_teach_id.$oid)
            .then()
    }
    export const delete_by_course = async (course_id: ObjectId,teacher_id: ObjectId) => {
        return  HTTPApi.post(
            "/can_teach/delete_by_course",
            {
                course_id: course_id,
                teacher_id: teacher_id
                
            }
        ).then()
    }
    export const delete_by_subject = async (subject_id: ObjectId,teacher_id: ObjectId) => {
        return  HTTPApi.post(
            "/can_teach/delete_by_subject",
            {
                subject_id: subject_id,
                teacher_id: teacher_id
                
            }
        ).then()
    }


}
