import { Box, Stack, Image, StackDivider, Text, Grid, useColorModeValue, Tabs, TabList, Tab, TabPanels, TabPanel, HStack, Heading, VStack, GridItem, SimpleGrid, Badge, Flex, Button, Spacer, Center, Spinner, useToast, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody, FormControl, FormLabel, Input, Checkbox, useDisclosure, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogHeader, AlertDialogBody, AlertDialogFooter } from '@chakra-ui/react'
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ParentAPI } from "../../../api/ParentAPI";
import { PaymentAPI } from "../../../api/PaymentAPI";
import { StudentAPI } from "../../../api/StudentAPI";
import { Parent } from "../../../models/parent";
import { FormDataStatus, Student, StudentStatus } from "../../../models/student";
import { PaymentStatus, Transaction } from "../../../models/transaction";
import { DateTime } from '../../../utils/DateTime';
import { TransactionCard } from './TransactionsCard';

interface StudentOverviewCard{
    student: Student
    form_name: string
}

export const FormCard = ({student, form_name}: StudentOverviewCard) => {
    let navigate = useNavigate();
    let toast = useToast();

    const form_submitted = student.forms.filter((e) => e.form_name == form_name).length > 0
    const [transaction, set_transaction] = React.useState<Transaction>();
    const [signature_url, set_signature_url] = React.useState<string>();
    const [parent, set_parent] = React.useState<Parent>();
    const [enroll_button_clicked, set_enroll_button_clicked] = React.useState<boolean>(false);

    const forms_approved = () => {
        return student.form_data.profile.status == FormDataStatus.Approved
        && student.form_data.documents.status == FormDataStatus.Approved
        && student.form_data.financial.status == FormDataStatus.Approved
        && student.form_data.health.status == FormDataStatus.Approved
        && student.form_data.family.status == FormDataStatus.Approved
        && student.form_data.donation.status == FormDataStatus.Approved
        && student.form_data.office_information.status == FormDataStatus.Approved
        && student.form_data.consent.status == FormDataStatus.Approved
        && student.form_data.uniform.status == FormDataStatus.Approved
    }

    const enroll_student = () => {
        set_enroll_button_clicked(true)
        StudentAPI.enroll(student._id)
        .then((res) => {
            navigate("/students/" + student._id.$oid)
        })
        .catch((e) => {
            console.log(e)
            toast({
                title: 'Enrollment Failed.',
                description: "Enrollment Failed",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        })
    }

    const re_register_student = () => {
        set_enroll_button_clicked(true)
        StudentAPI.re_register(student._id)
        .then((res) => {
            navigate("/students/" + student._id.$oid)
        })
        .catch((e) => {
            console.log(e)
            toast({
                title: 'Re Registration Failed.',
                description: "Re Registration Failed",
                status: 'error',
                duration: 9000,
                isClosable: true,
            })
        })
    }

    useEffect(()=>{
        PaymentAPI.get_paid_transaction(student._id, form_name)
                .then((res) => set_transaction(res))
                .catch((res) => set_transaction(undefined))
        if(form_submitted){
            let form = student.forms.filter((e) => e.form_name == form_name).slice(-1)[0]
            let signature = form.signature
            StudentAPI.get_document_url(student._id, signature.document_id).then((res) => {
                let url = res.data
                set_signature_url(url)
            })
            ParentAPI.get_parent(signature.signed_by).then((res) => {
                set_parent(res)
            })
        }
    },[])
    return (
        <>
        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
        >
            <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                <HStack>
                    <Text fontSize={"xl"} fontWeight="bold">Form Name: </Text>
                    <Text fontSize={"xl"}>{form_name}</Text>
                </HStack>
                <HStack>
                    <Text fontWeight="bold">Status:</Text>
                    <Flex>
                        <Badge colorScheme={form_submitted ? "green" : "red"}>
                            {form_submitted ? "Submitted" : "Incomplete"}
                        </Badge>
                    </Flex>
                </HStack>
                <HStack>
                    <Spacer/>
                    <Stack>
                        <Text fontWeight="bold">Transaction:</Text>
                        {form_submitted &&
                            <>
                            {transaction ?
                                <>
                                <TransactionCard transaction={transaction}/>
                                </>
                                
                                :
                                <Text> Status: 
                                    <Badge colorScheme="red">
                                    Unpaid
                                    </Badge>
                                </Text>
                            }
                            </>
                        }
                    </Stack>
                    <Spacer/>
                    <Stack>
                        <Text fontWeight="bold">Signature:</Text>
                        {form_submitted &&
                            <>
                            {signature_url && parent &&
                            <>
                                <Text>Signed By: {parent.name}</Text>
                                <Box width='100' height='150'>
                                    <Image src={signature_url} />
                                </Box>
                            </>       
                            }
                            </>
                        }
                    </Stack>
                    <Spacer/>
                </HStack>
                <br/>
                <Stack spacing={5}>
                   { student.status == StudentStatus.Applicant &&
                    <>
                        { forms_approved() && transaction && transaction.status == PaymentStatus.Paid ?
                                <>
                                <br/>
                                    {!enroll_button_clicked ?
                                        <Button colorScheme="green" onClick={enroll_student}>Enroll Student</Button>
                                        :
                                        <Center><Spinner /></Center>
                                    }
                                </>
                                :
                                <Button colorScheme="green" isDisabled>Enroll Student - Disabled Due to Unapproved Forms</Button>

                            }
                    </>
                   }
                   { student.status == StudentStatus.ReRegistration &&
                    <>
                        { forms_approved() && transaction && transaction.status == PaymentStatus.Paid ?
                                <>
                                <br/>
                                    {!enroll_button_clicked ?
                                        <Button colorScheme="green" onClick={re_register_student}>Re-Register Student</Button>
                                        :
                                        <Center><Spinner /></Center>
                                    }
                                </>
                                :
                                <Button colorScheme="green" isDisabled>Re-Register Student - Disabled Due to Unapproved Forms</Button>

                            }
                    </>
                   }
                     
                </Stack>
            </Stack>
        </Box>
        </>
    )
}
