import { Button } from "@chakra-ui/react";
import { getLookupLabel, Lookup } from "../models/lookup";
import { ObjectId } from "../utils/ObjectId";

export const useTeacherCell = (data: ObjectId[] = [], teachers: Lookup[] = []) => {
  return data.map((teacherId: ObjectId) => (
    <Button size="sm" ms="1" variant="hover" key={teacherId.$oid}>
      {getLookupLabel(teachers, teacherId)}
    </Button>
  ));
};
