import { Box, Stack, Image, StackDivider, Text, Grid, useColorModeValue, Tabs, TabList, Tab, TabPanels, TabPanel, HStack, Heading, VStack, GridItem, SimpleGrid, Badge, Flex, Button, Spacer, Center, Spinner, useToast } from '@chakra-ui/react'
import React, {useEffect, useRef} from "react"
import { useNavigate, useParams } from "react-router-dom";
import { ProfileCard } from "./Cards/ProfileCard";
import { FormDataStatus, Grades, SensitiveStudent, Student, StudentStatus } from "../../models/student";
import { StudentAPI } from '../../api/StudentAPI';
import { StudentFamilyCard } from './Cards/StudentFamilyCard';
import { HealthCard } from './Cards/HealthCard';
import { OfficeInformationCard } from './Cards/OfficeInfoCard';
import { DocumentsCard } from './Cards/DocumentsCard';
import { FinancialCard } from './Cards/FinancialCard';
import { ConsentCard } from './Cards/ConsentCard';
import { UniformCard } from './Cards/UniformCard';
import { FormStatusCard } from '../parents/FormStatus';
import { PaymentAPI } from '../../api/PaymentAPI';
import { PaymentStatus, Transaction } from '../../models/transaction';
import { DateTime } from '../../utils/DateTime';
import { Parent } from '../../models/parent';
import { ParentAPI } from '../../api/ParentAPI';


export const PrintableStudent = () =>  {

    let { student_id } = useParams();

    const [student, set_student] = React.useState<Student>();

    useEffect(() => {
        StudentAPI.get_student(student_id!)
            .then((res) => {
                set_student(res!)
            })
    }, []);

    return (
        <>
  
                    
                {student &&
                <>
                    <ProfileCard student={student} set_student={set_student}/>
                    <StudentFamilyCard student={student}/>
                    <HealthCard student={student} set_student={set_student}/>
                </>
                }

        
        </>
    )
}
