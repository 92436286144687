import React from "react"
import {
    Box,
    Stack,
    Skeleton, useToast, Input, Text, Badge, HStack, VStack, Spacer,
}
    from '@chakra-ui/react'
import {TableContent} from "../../table/TableContent";
import 'react-datepicker/dist/react-datepicker.css';
import { Student } from "../../../models/student";
import {EvaluationEntry} from "../../../models/evaluation_entry";
import {EvaluationEntryAPI} from "../../../api/EvaluationEntryAPI";
import {Evaluation} from "../../../models/evaluation";

interface EvaluationEntryData {
    student: Student,
    data: EntryData
}

interface EntryData {
    evaluation_entry: EvaluationEntry,
    evaluation: Evaluation,
    page_refresh: any
}

interface StudentMarkData {
    student: Student,
    final_mark?: number
}

interface MarkbookProps {
    evaluation: Evaluation | undefined
    entry_data: Array<EvaluationEntryData> | undefined,
    students_final_mark?: StudentMarkData[]
}

export const EvaluationEntryTable = React.memo(({evaluation, entry_data, students_final_mark}: MarkbookProps) =>  {

    let MarkbookColumns = [
        {
            Header: "Student Name",
            accessor: "student",
            Cell: function StatusCell(student: Student) {

                return (
                    <Box>
                        <Text>{student.profile.first_name + " " + student.profile.last_name}</Text>
                    </Box>
                )
            },
        },
        {
            Header: "Mark",
            accessor: "data",
            Cell: function StatusCell(data: EntryData) {
                const toast = useToast()
                const [mark, set_mark] = React.useState<string | undefined>(data.evaluation_entry.mark?.toString())

                const update = (value: string) => {
                    if((value.length == 0 && data.evaluation_entry.mark == undefined) || Number(value) == data.evaluation_entry.mark){
                        return
                    }
                        if (value.length === 0) {
                            data.evaluation_entry.mark = undefined
                        }
                        else {
                            data.evaluation_entry.mark = Number(value)
                        }
                        EvaluationEntryAPI.update_evaluation_entry(data.evaluation_entry)
                            .then(()=> {
                                data.page_refresh()
                                toast({
                                        title: 'Success.',
                                        description: "Updated Entry",
                                        status: 'success',
                                        duration: 5000,
                                        isClosable: true,
                                  })
                            })
                            .catch((e) => {console.log(e)
                                toast({
                                    title: 'Error.',
                                    description: "Failed to update " + data.evaluation_entry.mark + " mark",
                                    status: 'error',
                                    duration: 5000,
                                    isClosable: true,
                                  })
                            })
                    //}
                }
                return (
                    <Box>
                        <HStack>
                            <Input
                                minW='60px' maxW='80px'
                                placeholder={"N/A"}
                                value={mark}
                                onChange={(value) => set_mark(value.target.value)}
                                onBlur={(v) => update(v.target.value)}
                            />
                            <Text>
                                / {data.evaluation.total}
                            </Text>
                            <Spacer/>
                            {
                                typeof (data.evaluation_entry.mark)=="number"?
                                        <Badge colorScheme={"green"}>
                                            {(data.evaluation_entry.mark!/data.evaluation.total*100).toFixed(2)}%
                                        </Badge>
                                    :
                                        <></>
                            }
                        </HStack>
                    </Box>
                )
            },
        },
    ]



    return (
        <Box>
            {entry_data && evaluation && students_final_mark ?
                    <TableContent data={entry_data} columns={MarkbookColumns}/>
                :
                    <Stack>
                        <br/>
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                        <Skeleton height='100px' />
                    </Stack>
            }
        </Box>
    );
} );
