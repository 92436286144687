import * as React from 'react'
import 'react-datepicker/dist/react-datepicker.css';
import { Student } from '../../../models/student';
import { useLocation } from 'react-router-dom';
import { CourseObj } from '../../../models/course';
import { ObjectId } from '../../../utils/ObjectId';
import { useTeacherCell } from '../../../hooks/useTeacherCell';
import { Semester } from '../../../models/semester';
import { useChipCell } from '../../../hooks/useChipCell';
import { StudentAPI } from '../../../api/StudentAPI';
import { Column, TableContent } from '../../table/TableContent';
import { useTeachersLookup } from '../../../hooks/useTeachersLookup';
import { FormLabel } from '@chakra-ui/react';
import { BlCard } from '../../layout/Card';
import { useUser } from '../../../hooks/useUser';

interface Card {
    student: Student
    link?: string
}

export const StudentCourseList = React.memo(({ student, link }: Card) => {
	const [courses_data, set_courses_data] = React.useState<CourseObj[]>();

    const [loading, setLoading] = React.useState<boolean>(true);

    // https://albassem.atlassian.net/browse/BL-465
	//! show teacher name in parent portal
    const { userIsTeacher } = useUser() 

    const location = useLocation();
    const { teachers } = useTeachersLookup();


    const columns = React.useMemo(() => [
        {
            Header: "Name",
            accessor: "name",
        },
        userIsTeacher && {
            Header: "Teacher Name",
            accessor: "teacher_ids",
            Cell: (data: ObjectId[]) => useTeacherCell(data, teachers)
        },
        {
            Header: "Semester",
            accessor: "semester",
            Cell: (data: Semester) => useChipCell(data.name, 'blue')
        }
    ].filter(Boolean) as Column[], [teachers, userIsTeacher])

    React.useEffect(() => {
		setLoading(true);
        
        StudentAPI.get_courses(student._id)
            .then((res) => {
                let courses = res
                    .filter((course) => !course.homeroom)
                    .map((course) => ({
                        ...course,
                        link: link ? link  + course._id.$oid : undefined
                    }))

                set_courses_data(courses)
            }).finally(() => {
                setLoading(false);
            })
    }, [location.pathname, student._id])
    
    return (
		courses_data?.length ?
        <BlCard>
            <FormLabel fontSize={"lg"} fontWeight="bold">
                Courses
            </FormLabel>

            <TableContent
                loading={loading} 
                data={courses_data} 
                columns={columns} 
            />
		</BlCard> : <></>
    )
})