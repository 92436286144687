import HTTPApi from "./ApiCommon";
import { sha3_256 } from 'js-sha3';
import { ObjectId } from "../utils/ObjectId";

export enum UserType {
    None = "None",
    Student = "Student",
    Teacher = "Teacher",
    Parent = "Parent"
}

export enum UserRole {
    Developer = "Developer",
    Super_Admin = "Super_Admin",
    Can_Access_Student = "Can_Access_Student",
    Can_Modify_Student = "Can_Modify_Student",
    Can_Access_All_Student = "Can_Access_All_Student",
    Can_Modify_All_Student = "Can_Modify_All_Student",
    Can_Access_Student_Documents = "Can_Access_Student_Documents",
    Can_Modify_Student_Documents = "Can_Modify_Student_Documents",
    Can_Access_SensitiveStudent = "Can_Access_SensitiveStudent",
    Can_Modify_SensitiveStudent = "Can_Modify_SensitiveStudent",
    Can_Enroll_Student = "Can_Enroll_Student",
    Can_Access_Teacher = "Can_Access_Teacher",
    Can_Modify_Teacher = "Can_Modify_Teacher",
    Can_Create_Teacher = 'Can_Create_Teacher',
    Can_Access_Parent = "Can_Access_Parent",
    Can_Modify_Parent = "Can_Modify_Parent",
    Can_Access_All_Teacher = "Can_Access_All_Teacher",
    Can_Access_Homeroom = "Can_Access_Homeroom",
    Can_Modify_Homeroom = "Can_Modify_Homeroom",
    Can_Create_Homeroom = "Can_Create_Homeroom",
    Can_Access_Course = "Can_Access_Course",
    Can_Modify_Course = "Can_Modify_Course",
    Can_Create_Course = "Can_Create_Course",
    Can_Access_All_Homeroom = "Can_Access_All_Homeroom",
    Can_Modify_All_Homeroom = "Can_Modify_All_Homeroom",
    Can_Access_All_Course = "Can_Access_All_Course",
    Can_Modify_All_Course = "Can_Modify_All_Course",
    Can_Access_All_Attendance = "Can_Access_All_Attendance",
    Can_Access_Attendance = "Can_Access_Attendance",
    Can_Access_Transaction = "Can_Access_Transaction",
    Can_Modify_Transaction = "Can_Modify_Transaction",
    Can_Access_Violation = "Can_Access_Violation",
    Can_Access_ReportEntry = "Can_Access_ReportEntry",
    Can_Access_Student_Incident = "Can_Access_Student_Incident",
    Can_Modify_Student_Incident = "Can_Modify_Student_Incident",
    Can_Modify_Scheduling = "Can_Modify_Scheduling",
    Can_Access_Scheduling = "Can_Access_Scheduling",
    Can_See_All_Reports = "Can_See_All_Reports",
    Can_Access_Family_Manager = "Can_Access_Family_Manager",
    Can_Modify_Semester = "Can_Modify_Semester",
    Can_Access_Semester = "Can_Access_Semester"
}


export namespace UserAPI {
    export class UserInfoResponse {
        _id: ObjectId
        full_name: string
        user_type: UserType
        user_roles: Array<UserRole>
        photo_url: string
        constructor(data: any){
            this._id = data._id
            this.full_name = data.full_name
            this.user_type = data.user_type
            this.user_roles = data.user_roles
            this.photo_url = data.photo_url
        }

        public has_role(role: UserRole) {
            if(this.user_roles.includes(UserRole.Super_Admin) && role != UserRole.Developer){
                return true
            }
            return this.user_roles.includes(role)
        }
    }

    export const get_user_info = async () => {
        return HTTPApi.get(
                "/auth/get_user_info"
            ).then(res => new UserInfoResponse(res.data))
    }
}