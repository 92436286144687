// validationSchemas.ts
import { object, string } from 'yup'

export const objectWithId = object().shape({
	_id: object().shape({
		$oid: string().required('_id is required')
	})
})

export const ObjectIdSchema = object().shape({
    $oid: string().required('$oid is required'),
});