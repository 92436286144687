import { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
    Box,
    FormLabel,
    Stack,
    useColorModeValue,
    SimpleGrid,
    Badge,
    HStack,
    Spacer,
    Heading,
    Text,
    Skeleton,
} from '@chakra-ui/react';
import { Homeroom } from "../../models/homeroom";
import { HomeroomAPI } from "../../api/HomeroomAPI";
import { ObjectId, to_oid } from "../../utils/ObjectId";
import ExternalLinks from "../classes/ExternalLinks";
import { Student } from "../../models/student";
import { StudentAPI } from "../../api/StudentAPI";
import GClassStudentViewer from "../gclass/GClassStudentViewer";
import { Course } from "../../models/course";
import { TableContent } from "../table/TableContent";
import { useTeacherCell } from "../../hooks/useTeacherCell";
import { useTeachersLookup } from "../../hooks/useTeachersLookup";
import { useChipCell } from "../../hooks/useChipCell";
import { Semester } from "../../models/semester";

export const StudentHomeroomDashboard = () =>  {
    let { student_id, homeroom_id } = useParams();

    const [student, set_student] = useState<Student>();
    const [homeroom, set_homeroom] = useState<Homeroom>();
    const [courses, set_courses] = useState<Course[]>();
    
    const { teachers } = useTeachersLookup();

    const courseTableColumns = [
        { Header: "Name", accessor: "name" },

        {
            Header: "Teachers",
            accessor: "teacher_ids",
            Cell: (data:  ObjectId[]) => useTeacherCell(data, teachers)
        },
        {
            Header: "Semester",
            accessor: "semester",
            Cell: (data:  Semester) => useChipCell(data.name)
        },
    ]

    useEffect(() => {
        HomeroomAPI.get_homeroom(to_oid(homeroom_id!)).then((res) => {
            set_homeroom(res)
        })

        StudentAPI.get_student(student_id!).then((res) => {
            set_student(res!)
        })

        HomeroomAPI.get_courses(to_oid(homeroom_id!)).then((res) => {
            let courses = res.map((course) => {
                return {
                    ...course,
                    link: `/students/${student_id!}/course/${course._id.$oid}`
                }
            })

            set_courses(courses)
        })


    }, [student_id, homeroom_id]);

    return (
        <>
        {
            student ? (
                <Heading as="h1" size="sm" fontWeight={"bold"} mb="4">
                    {student?.profile.first_name} {student?.profile.last_name}
                </Heading>
            ) : <Skeleton height="8" maxW="300" mb="4" />
        }

        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
            mb="4"
        >
            <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                <HStack>
                    <FormLabel fontSize={"lg"} fontWeight="bold">
                        Homeroom Information
                    </FormLabel>

                    <Spacer />

                    <ExternalLinks type="homeroom" id={homeroom_id!} />
                </HStack>

                {
                    homeroom ? (
                        <SimpleGrid columns={{ sm: 1, md: 2, lg: 4 }} spacing={4}>
                            <Box as={HStack} alignItems="center" mb={1}> 
                                <Text fontWeight="bold">Name</Text>
                                <Text>{homeroom.name}</Text>
                            </Box>
        
                            <Box as={HStack} alignItems="center" mb={1}> 
                                <Text fontWeight="bold">Semester</Text>
                                <Badge fontSize="xs" colorScheme="blue">
                                    {homeroom.semester.name}
                                </Badge>
                            </Box>
        
                            <Box as={HStack} alignItems="center" mb={1}> 
                                <Text fontWeight="bold">Grade</Text>
                                <Badge fontSize="xs" colorScheme="purple">
                                    {homeroom.grade}
                                </Badge>
                            </Box>
        
                            <Box as={HStack} alignItems="center" mb={1}> 
                                <Text fontWeight="bold">Status</Text>
        
                                <Badge colorScheme={homeroom.archived ? "red" : "green"}>
                                    {homeroom.archived ? "Archived" : "Active"}
                                </Badge>
                            </Box>
                        </SimpleGrid>
                    ) : (
                        <SimpleGrid columns={{ sm: 1, md: 2, lg: 4 }} spacing={4} mb={8}>
                            {/* Four smaller skeletons */}
                            {Array.from({ length: 4 }, (_, index) => (
                                <Skeleton key={index} height="150px" rounded="md" />
                            ))}
                        </SimpleGrid>
                    )
                }
                
            </Stack>
        </Box>

        <Box
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
            mb="4"
        >
            <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                <HStack>
                    <FormLabel fontSize={"lg"} fontWeight="bold">
                        Courses
                    </FormLabel>
                </HStack>

                <TableContent
                    data={courses}
                    columns={courseTableColumns}
                />
            </Stack>
        </Box>


        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
            mb="4"
        >
            <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                <FormLabel fontSize={"lg"} fontWeight="bold">
                    Google Classroom Work
                </FormLabel>

                <GClassStudentViewer studentId={to_oid(student_id!)} type={"homeroom"} itemId={to_oid(homeroom_id!)} />
            </Stack>
        </Box>

        </>
    );
};