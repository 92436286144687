import React, { useEffect } from "react"
import { Center, Badge, VStack, Text } from '@chakra-ui/react';
import { TableContent } from "../table/TableContent";
import { Student, Grades } from "../../models/student";
import { Family } from "../../models/family";
import { FamilyAPI } from "../../api/FamilyAPI";

interface Card {
    family: Family
    read_only?: boolean
    redirect_to_profile?: boolean
}

export const FamilyStudentList = React.memo(({family, read_only, redirect_to_profile} : Card) => {
    const [students, set_students] = React.useState<Array<Student>>()
    const [data, set_data] = React.useState<Array<StudentFormColumnsProps>>()


    useEffect(()=>{
        FamilyAPI.get_students(family._id)
            .then((res) => {
                let r = res.map((s: any) => new Student(s))
                set_students(r)
            })
    }, [family._id])

    useEffect(() => {
        let res = students?.map((s) => {
            return {
                name: s.profile.first_name + " " + s.profile.last_name,
                grade: Grades.get(s.profile.grade)!,
                status: s.status.toString(),
                link: redirect_to_profile ? "/students/" + s._id.$oid : "/parent/student/" + s._id.$oid
            }
        })
        set_data(res)
    }, [redirect_to_profile, students]);

    return (
        data && data.length > 0 ? 
            <TableContent
                data={data}
                columns={StudentFormColumns}
            />
            : 
        <>
            <Center>
                <VStack>
                    <Text>No Students...</Text>
                    { !read_only &&
                        <Text> Add a student using the above button to the right</Text>
                    }
                </VStack>   
            </Center>
        </>
    );
})

const badgeEnum: Record<string, string> = {
    Applicant : 'orange',
    Processed : 'green',
    Rejected : 'red',
    Enrolled : 'green',
    Withdrawn : 'red',
}

const StudentFormColumns = [
    {
        Header: "Student Name",
        accessor: "name",
    },
    {
        Header: "Student Grade",
        accessor: "grade",
    },
    {
        Header: "Student Status",
        accessor: "status",
        Cell: function StatusCell(data: any) {
            return (
                <Badge fontSize="xs" colorScheme={badgeEnum[data]}>
                    {data}
                </Badge>
            )
        },
    }
]

interface StudentFormColumnsProps {
    name: string,
    grade: string,
    status: string,
    link: string
}