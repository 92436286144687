import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Badge,
Box,
Button,
Center,
Divider,
Flex,
FormControl,
FormLabel,
HStack,
Input,
Popover,
PopoverArrow,
PopoverBody,
PopoverCloseButton,
PopoverContent,
PopoverHeader,
PopoverTrigger,
Select,
Spacer,
Stack,
Text,
Textarea,
useColorModeValue,
useDisclosure,
useToast,
} from '@chakra-ui/react'
import { ObjectId as  BSONObjectId} from 'bson';
import * as React from 'react'
import 'react-datepicker/dist/react-datepicker.css';
import { FiPlus } from 'react-icons/fi';
import LocalStorageService from '../../../api/LocalStorageService';
import { StudentAPI } from '../../../api/StudentAPI';
import { UserRole, UserType } from '../../../api/UserApi';
import { Student } from '../../../models/student';
import { StudentIncident, StudentIncidentSeverity, StudentIncidentStatus } from '../../../models/student_incident';
import { DateTime } from '../../../utils/DateTime';
import { ObjectId, to_oid } from '../../../utils/ObjectId';
import DatePicker from '../../DatePicker'

interface Card {
    student: Student
    set_student: React.Dispatch<React.SetStateAction<Student | undefined>>
}
export const IncidentCard = React.memo(({student, set_student}: Card) => {
    const toast = useToast()

    const [incidents, set_incidents] = React.useState<Array<StudentIncident>>([])

    const get_incidents = () => {
        StudentAPI.get_student_incidents(student._id)
            .then((res) => {
                set_incidents(res)
            })
            .catch((err) => {
                toast({
                    title: 'Error.',
                    description: "Failed to get incidents",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            })

    }


    const status_badge = (incident: StudentIncident) => {
        if(incident.status == StudentIncidentStatus.Filled){
            return <Badge variant='solid' colorScheme='green'>Filled</Badge>
        }
        else if (incident.status == StudentIncidentStatus.InProgress){
            return <Badge variant='outline' colorScheme='green'>In Progress</Badge>
        }
    }

    const severity_badge = (incident: StudentIncident) => {
        if(incident.severity == StudentIncidentSeverity.Low){
            return <Badge variant='outline' colorScheme='green'>Low</Badge>
        }
        else if (incident.severity == StudentIncidentSeverity.Medium){
            return <Badge variant='subtle' colorScheme='green'>Medium</Badge>
        }
        else if(incident.severity == StudentIncidentSeverity.High){
            return <Badge variant='subtle' colorScheme='red'>High</Badge>
        }
        else if (incident.severity == StudentIncidentSeverity.Severe){
            return <Badge variant='solid' colorScheme='red'>Severe</Badge>
        }
    }

    const add_incident = (incident: StudentIncident) => {
        let arr = [incident, ...incidents]
        set_incidents(arr);
    }

    const update_incident = (id: ObjectId, incident: StudentIncident) => {
        let idx = incidents.findIndex((e) => e._id.$oid == id.$oid)
        let arr = [...incidents]
        arr[idx] = incident
        set_incidents(arr);
    }

    React.useEffect(() => {
        get_incidents()
    }, []);

    return (
        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
        >
            <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                { LocalStorageService.getInstance().getUser()!.has_role(UserRole.Can_Modify_Student_Incident) &&
                    <HStack>
                        <CreateIncidentPopover student={student} add_incident={add_incident}/>
                        <Spacer/>
                    </HStack>
                }
                
                <Stack>
                    { incidents.map((incident)=> {
                        return (
                            <>
                                <Box as="span" flex='1' textAlign='left'>
                                    {severity_badge(incident)} {status_badge(incident)} {incident.title}
                                </Box>
                                <>
                                <Incident incident={incident} update_incident={update_incident}/>
                                </>
                            </>
                        )
                        })

                    }
            
                </Stack>
            </Stack>
        </Box>
    )

})


interface IncidentCard{
    incident: StudentIncident
    update_incident: (id: ObjectId, incident: StudentIncident) => void
}
const Incident = React.memo(({incident, update_incident}: IncidentCard) => {
    const toast = useToast()

    const update = (name: any, value: any) => {
        let new_incident = new StudentIncident({
            ...incident,
            [name]: value
        })
        update_incident(incident._id, new_incident)
    }

    const save = () => {
        StudentAPI.update_student_incidents(incident)
            .then((new_incident) => {
                update_incident(incident._id, new_incident)
                toast({
                    title: 'Success.',
                    description: "Updated Incident",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
            })
            .catch((err) => {
                toast({
                    title: 'Error.',
                    description: "Failed to update an incident",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            })
}

    return (
        <>
        <Box
            as="form"
            bg="bg-surface"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            borderRadius="lg"
            flex="1"
        >
            <Stack spacing="5" px={{ base: '4', md: '6' }} py={{ base: '5', md: '6' }}>
                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    <FormControl id="title" isRequired>
                        <FormLabel>Title</FormLabel>
                        <Input 
                            value={incident.title}
                            onChange={(value) => {
                                update(value.target.id, value.target.value)
                            }}
                        />
                    </FormControl>
                    <FormControl id="date" isRequired>
                        <FormLabel>Date</FormLabel>
                        <DatePicker 
                            selectedDate={incident.date.to_date()} 
                            onChange={(value) => {
                                update("date", DateTime.from_date(value as Date))
                            }}
                        />
                    </FormControl>
                </Stack>
                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    <FormControl id="status" isRequired>
                        <FormLabel>Status</FormLabel>
                        <Select 
                            value={incident.status}
                            onChange={(value) => {
                                update(value.target.id, value.target.value)
                            }}
                        >
                                <option value={StudentIncidentStatus.InProgress}>In Progress</option>
                                <option value={StudentIncidentStatus.Filled}>Filled</option>
                        </Select>
                    </FormControl>
                    <FormControl id="severity" isRequired>
                        <FormLabel>Severity</FormLabel>
                        <Select 
                            value={incident.severity}
                            onChange={(value) => {
                                update(value.target.id, value.target.value)
                            }}
                        >
                                <option value={StudentIncidentSeverity.Low}>Low</option>
                                <option value={StudentIncidentSeverity.Medium}>Medium</option>
                                <option value={StudentIncidentSeverity.High}>High</option>
                                <option value={StudentIncidentSeverity.Severe}>Severe</option>
                        </Select>
                    </FormControl>
                </Stack>
                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    <FormControl id="description" isRequired>
                        <FormLabel>Description</FormLabel>
                        <Textarea
                            value={incident.description}
                            onChange={(value) => {
                                update(value.target.id, value.target.value)
                            }}
                            placeholder='Description...'
                            size='md'
                        />
                    </FormControl>
                </Stack>
                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    <FormControl id="action_taken" isRequired>
                        <FormLabel>Action Taken</FormLabel>
                        <Input 
                            value={incident.action_taken}
                            onChange={(value) => {
                                update(value.target.id, value.target.value)
                            }}
                        />
                    </FormControl>
                </Stack>
                <Spacer/>
                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    <FormControl id="police_date_contact" isRequired>
                        <FormLabel>Police Date Contact</FormLabel>
                        <DatePicker 
                            selectedDate={incident.police_date_contact ? incident.police_date_contact.to_date() : undefined} 
                            onChange={(value) => {
                                update("police_date_contact", DateTime.from_date(value as Date))
                            }}
                        />
                    </FormControl>
                    <FormControl id="police_date_investigation" isRequired>
                        <FormLabel>Police Date Investigation</FormLabel>
                        <DatePicker 
                            selectedDate={incident.police_date_investigation ? incident.police_date_investigation.to_date() : undefined} 
                            onChange={(value) => {
                                update("police_date_investigation", DateTime.from_date(value as Date))
                            }}
                        />
                    </FormControl>
                </Stack>
                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    <FormControl id="police_officer_name" isRequired>
                        <FormLabel>Police Officer Name</FormLabel>
                        <Input 
                            value={incident.police_officer_name}
                            onChange={(value) => {
                                update(value.target.id, value.target.value)
                            }}
                        />
                    </FormControl>
                    <FormControl id="police_case_id" isRequired>
                        <FormLabel>Police Case ID</FormLabel>
                        <Input 
                            value={incident.police_case_id}
                            onChange={(value) => {
                                update(value.target.id, value.target.value)
                            }}
                        />
                    </FormControl>
                </Stack>
                <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
                    <Text>
                        Created By: {incident.created_by_teacher.$oid}
                    </Text>
                </Stack>
            </Stack>
            <Divider />
            <Flex direction="row-reverse" py="4" px={{ base: '4', md: '6' }}>
            { LocalStorageService.getInstance().getUser()!.has_role(UserRole.Can_Modify_Student_Incident) &&
                <Button colorScheme={"green"} onClick={save}>
                    Save
                </Button>
            } 
            </Flex>
        </Box>
        </>
    )
})


interface CreateIncidentPopoverCard{
    student: Student
    add_incident: (incident: StudentIncident) => void
}
const CreateIncidentPopover = ({student, add_incident}: CreateIncidentPopoverCard) => {
    const toast = useToast()

    const { isOpen, onToggle, onClose } = useDisclosure()

    const [title, set_title] = React.useState<string>("");

    const create_new_incident = () => {
        if(title.length < 5){
            toast({
                title: 'Error.',
                description: "Title must be longer than 5 characters",
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }
        else{
            StudentAPI.create_student_incidents(student._id, title, DateTime.from_date(new Date()))
                .then((incident) => {
                    add_incident(incident)
                })
                .catch((err) => {
                    toast({
                        title: 'Error.',
                        description: "Failed to create an incident",
                        status: 'error',
                        duration: 5000,
                        isClosable: true,
                    })
                })
        }
    }


    return (

    <Popover
    isOpen={isOpen}
    onClose={onClose}
    >
        <PopoverTrigger >
            <Button colorScheme="green" leftIcon={<FiPlus/>} onClick={onToggle}>New Incident</Button>
        </PopoverTrigger>
        <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>New Incident</PopoverHeader>
            <PopoverBody>
                <>
                <FormControl isRequired>
                    <FormLabel>Title</FormLabel>
                    <Input placeholder='Title' value={title} onChange={(value) => {set_title(value.target.value)}}/>
                </FormControl>
                <br/>
                <Center>
                    <Button colorScheme="green" onClick={create_new_incident}>Submit</Button>
                </Center>
                </>
            </PopoverBody>
        </PopoverContent>
    </Popover>

    )
}