import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    Box,
    Button,
    FormLabel,
    Stack,
    Text,
    useColorModeValue,
    useToast,
    HStack,
    Spacer,
    Heading,
    SimpleGrid,
} from '@chakra-ui/react';
import { ObjectId, to_oid } from "../../utils/ObjectId";
import { FiArchive} from "react-icons/fi";
import { Semester } from "../../models/semester";
import { CourseAPI } from "../../api/CourseAPI";
import {SemesterAPI} from "../../api/SemesterAPI";
import { TableContent } from "../table/TableContent";
import { CheckIcon } from "@chakra-ui/icons";
import { UserRole } from "../../api/UserApi";
import LocalStorageService from "../../api/LocalStorageService";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { semesterSchema } from "../../validations/semester";
import FormInput from "../forms/FormInput";
import FormDatePicker from "../forms/FormDatePicker";
import { TeacherAPI } from "../../api/TeacherAPI";
import { HomeroomAPI } from "../../api/HomeroomAPI";
import { getLookupLabel } from "../../models/lookup";

export const SemesterDashboard = React.memo(() => {
    let { semester_id } = useParams();

    interface CourseObj {
        id: string,
        archived: boolean,
        name: string,
        link?: string
        status?: string
    }

    const toast = useToast();
    const [semester, set_semester] = useState<Semester>();
    const [courses, set_courses] = useState<CourseObj[]>([]);
    const [coursesCount, setCoursesCount] = useState<number>(0);
    const [homerooms, set_homerooms] = useState<CourseObj[]>([]);
    const [homeroomsCount, setHomeroomsCount] = useState<number>(0);
    const [teachers, set_teachers] = useState<any[]>([]);

    const { register, handleSubmit, reset, control, formState: { errors } } = useForm<Semester>({
        resolver: yupResolver(semesterSchema),
    });

    const [loading, setLoading] = useState<boolean>(false);
    const [archiveLoading, setArchiveLoading] = useState<boolean>(false);

    const user = LocalStorageService.getInstance().getUser();
    const canModifySemester = user?.has_role(UserRole.Can_Modify_Semester);

    useEffect(() => {
        const getSemester = async () => {
            try {
                const res = await SemesterAPI.get_semester(to_oid(semester_id!));
                set_semester(res);
                reset(res);
            } catch (error) {
                toast({
                    title: 'Error',
                    description: "Failed to fetch semester data",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };

        getSemester();
    }, [semester_id, reset, toast]);

    useEffect(() => {
        const fetchCourses = async () => {
            try {
                const coursesRes = await CourseAPI.course_list(
                    null, // per_page
                    null, // page
                    undefined, // name_search
                    undefined, // archived
                    undefined, // teacher_id
                    undefined,
                    true,
                    to_oid(semester_id!), // semester
                );
                if (coursesRes) {
                    let courses = coursesRes.courses.map((course) => {
                        return {
                            ...course,
                            id: course._id.$oid,
                            link: '/class/courses/'+ course._id.$oid
                        }
                    })
                    set_courses(courses);
                    setCoursesCount(coursesRes.courses_count);
                }
            } catch (error) {
                toast({
                    title: 'Error',
                    description: "Failed to fetch courses",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };

        const fetchHomerooms = async () => {
            try {
                const homeroomsRes = await HomeroomAPI.homeroom_list(
                    null, // per_page
                    null, // page
                    undefined, // name_search
                    undefined, // archived
                    undefined, // teacher_id
                    undefined, // attendance_teacher_id
                    undefined,
                    to_oid(semester_id!), // semester
                );
                if (homeroomsRes) {
                    let homerooms = homeroomsRes.homerooms.map((homeroom) => {
                        return {
                            ...homeroom,
                            id: homeroom._id.$oid,
                            link: '/class/homerooms/' + homeroom._id.$oid
                        }
                    })
                    set_homerooms(homerooms);
                    setHomeroomsCount(homeroomsRes.homerooms_count);
                }
            } catch (error) {
                toast({
                    title: 'Error',
                    description: "Failed to fetch courses",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        };

        fetchCourses();
        fetchHomerooms();
    }, [semester_id, toast]);

    useEffect(()=>{
        TeacherAPI.teachers_list(null, null, null)
            .then((res) => {
                let data = res!.teachers.map((e) => {
                    return {
                        "value": e._id,
                        "label": e.profile.first_name + " " + e.profile.last_name
                    }
                })
                set_teachers(data)
            })
    },[])

    const updateSemester = async (data: Semester) => {
        setLoading(true);
        try {
            const updatedSemester = await SemesterAPI.update_semester(
                data._id,
                data.name,
                data.start_date,
                data.end_date
            );
            set_semester(updatedSemester);

            toast({
                title: 'Success',
                description: "Semester updated successfully",
                status: 'success',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: 'Error',
                description: "Failed to update semester",
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const archiveSemester = () => {
        setArchiveLoading(true);
        SemesterAPI.archive_semester(semester!._id)
            .then(() => {
                toast({
                    title: 'Success',
                    description: "Semester Archived",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            })
            .catch(() => {
                toast({
                    title: 'Error',
                    description: "Failed to Archive Semester",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            })
            .finally(() => setArchiveLoading(false));
    };
    return (
        <>
            <HStack align={"center"} mb={8}>
                <Heading as="h1" size="md" fontWeight={"bold"}>
                    {semester?.name} Semester
                </Heading>
                <Spacer/>
                {/*<ExternalLinks type="semester" id={semester_id!} />*/}
            </HStack>

            <Box
                as="form"
                bg="bg-surface"
                boxShadow={useColorModeValue('sm', 'sm-dark')}
                borderRadius="lg"
                flex="1"
                mb="4"
                onSubmit={handleSubmit(updateSemester)}
            >
                <Stack spacing="5" px={{base: '4', md: '6'}} py={{base: '5', md: '6'}}>
                    <HStack>
                        <FormLabel fontSize={"lg"} fontWeight="bold">
                            Semester Information
                        </FormLabel>
                        <Spacer/>
                        {canModifySemester && (
                            <Button
                                isLoading={loading}
                                colorScheme='green'
                                leftIcon={<CheckIcon/>}
                                type="submit"
                            >
                                Update
                            </Button>
                        )}
                    </HStack>

                    <SimpleGrid columns={{sm: 1, md: 3}} spacing={10}>
                        <FormInput
                            name="name"
                            label="Name"
                            register={register('name', {required: true})}
                            error={errors.name?.message}
                            isDisabled={!canModifySemester}
                            isRequired
                        />
                        <FormDatePicker
                            name="start_date"
                            label="Start Date"
                            control={control}
                            error={errors.start_date?.message}
                            isDisabled={!canModifySemester}
                            isRequired
                        />

                        <FormDatePicker
                            name="end_date"
                            label="End Date"
                            control={control}
                            error={errors.end_date?.message}
                            isDisabled={!canModifySemester}
                            isRequired
                        />

                    </SimpleGrid>
                </Stack>
            </Box>

            <Box
                bg="bg-surface"
                boxShadow={useColorModeValue('sm', 'sm-dark')}
                borderRadius="lg"
                flex="1"
                mb="4"
            >
                <Stack spacing="5" px={{base: '4', md: '6'}} py={{base: '5', md: '6'}}>
                    <HStack>
                        <FormLabel fontSize={"lg"} fontWeight="bold">
                            Courses ({coursesCount})
                        </FormLabel>

                    </HStack>
                    <TableContent
                        data={courses}
                        columns={[
                            {Header: "Name", accessor: "name"},
                            {
                                Header: "Teachers",
                                accessor: "teachers",
                                Cell: function TeachersCell(data:  ObjectId[]) {
                                    return ( <> {
                                        data.map((teacher: ObjectId) => {
                                            return <Text> { getLookupLabel(teachers, teacher)}</Text>;
                                        })
                                    } </>
                                    )

                                }
                            },
                        ]}
                    />
                </Stack>
            </Box>

            <Box
                bg="bg-surface"
                boxShadow={useColorModeValue('sm', 'sm-dark')}
                borderRadius="lg"
                flex="1"
                mb="4"
            >
                <Stack spacing="5" px={{base: '4', md: '6'}} py={{base: '5', md: '6'}}>
                    <HStack>
                        <FormLabel fontSize={"lg"} fontWeight="bold">
                            Homerooms ({homeroomsCount})
                        </FormLabel>

                    </HStack>
                    <TableContent
                        data={homerooms}
                        columns={[
                            {Header: "Name", accessor: "name"},
                            {
                                Header: "Teachers",
                                accessor: "teachers",
                                Cell: function TeachersCell(data:  ObjectId[]) {
                                    return ( <>{
                                            data.map((teacher: ObjectId) => {
                                                return <Text> { getLookupLabel(teachers, teacher)}</Text>;
                                            })
                                        } </>
                                    )

                                }
                            },
                        ]}
                    />
                </Stack>
            </Box>

            <HStack>
                <Spacer/>
                <Button
                    bgColor={"orange.100"}
                    textColor="orange.700"
                    colorScheme='yellow'
                    leftIcon={<FiArchive/>}
                    onClick={archiveSemester}
                    isDisabled={semester?.archived || archiveLoading}
                >
                    Archive
                </Button>
            </HStack>
        </>
    );
});

