import {
    AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay,
    Box, Button,
    Center, Checkbox, FormControl, FormLabel,
    Heading,
    HStack, Input,
    Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader,
    PopoverTrigger,
    Spacer,
    Text,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import React, {RefObject, useEffect} from "react";
import { useParams } from "react-router-dom";
import { TeacherAPI } from "../../api/TeacherAPI";
import { Teacher } from "../../models/teacher";
import {ObjectId, to_oid} from "../../utils/ObjectId";
import { TeacherProfile } from "./TeacherProfile";
import {FiAlertTriangle, FiArrowDown} from "react-icons/fi";
import {FocusableElement} from "@chakra-ui/utils";

export const TeacherProfileRoute = () =>  {
    let { teacher_id } = useParams();

    const [teacher, set_teacher] = React.useState<Teacher>();

    useEffect(() => {
        TeacherAPI.get_teacher(to_oid(teacher_id!))
            .then((res) => {
                set_teacher(res)
            })
    }, []);

    return (
        <Box>
            <Center>
                <Heading size="md" mb="6">Teacher Profile</Heading>
            </Center>
            <br></br>
            { teacher &&
                <>
                    <HStack>
                        <Text fontSize='4xl'>{teacher.profile.first_name+" "+teacher.profile.last_name}</Text>
                        <Spacer/>
                        { teacher &&
                            <ChangePasswordPopover teacher_id={teacher!._id}/>
                        }
                        { teacher &&
                            <WithdrawPopOver teacher={teacher}/>
                        }
                    </HStack>
                    <TeacherProfile teacher={teacher} set_teacher={set_teacher}/>
                </>
            }

        </Box>
    )

}

interface ChangePasswordPopoverCard{
    teacher_id: ObjectId
}
const ChangePasswordPopover = ({teacher_id}: ChangePasswordPopoverCard) => {
    const toast = useToast()

    const { isOpen, onToggle, onClose } = useDisclosure()

    const [password, set_password] = React.useState<string>("");
    const [change_password_at_next_login, set_change_password_at_next_login] = React.useState<boolean>(true);

    const change_password = () => {
        TeacherAPI.change_password(teacher_id, password, change_password_at_next_login)
            .then((res) => {
                onToggle()
                set_password("")
                toast({
                    title: 'Success',
                    description: "Password Change",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
            })
            .catch((err) => {
                set_password("")
                toast({
                    title: 'Error.',
                    description: "Failed to change password",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            })
    }


    return (

        <Popover
            isOpen={isOpen}
            onClose={onClose}
        >
            <PopoverTrigger >
                <Button colorScheme="green" rightIcon={<FiArrowDown/>} onClick={onToggle}>Change Password</Button>
            </PopoverTrigger>
            <PopoverContent>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>Change Password</PopoverHeader>
                <PopoverBody>
                    <>
                        <FormControl isRequired>
                            <FormLabel>Password</FormLabel>
                            <Input placeholder='Password' type="password" value={password} onChange={(value) => {set_password(value.target.value)}}/>
                        </FormControl>
                        <br/>
                        <FormControl isRequired>
                            <Checkbox
                                colorScheme="green"
                                isChecked={change_password_at_next_login}
                                onChange={(e) => set_change_password_at_next_login(e.target.checked)}
                            >Change Password at Next Login</Checkbox>
                        </FormControl>
                        <br/>
                        <Center>
                            <Button colorScheme="green" onClick={change_password}>Submit</Button>
                        </Center>
                    </>
                </PopoverBody>
            </PopoverContent>
        </Popover>

    )
}


interface WithdrawPopoverCard{
    teacher: Teacher
}
const WithdrawPopOver = ({teacher}: WithdrawPopoverCard) => {
    const toast = useToast()


    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef() as RefObject<FocusableElement>

    const [loading, set_loading] = React.useState<boolean>(false)


    const withdraw = () => {
        set_loading(true)
        TeacherAPI.withdraw(teacher._id)
            .then((res) => {
                onClose()
                toast({
                    title: 'Success',
                    description: "Teacher Withdrawn",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                })
                window.location.reload();
            })
            .catch((err) => {
                set_loading(false)
                toast({
                    title: 'Error.',
                    description: "Failed to withdraw teacher",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                })
            })
    }


    return (
        <>
            <Button colorScheme='red' rightIcon={<FiAlertTriangle/>} onClick={onOpen}>
                Withdraw Teacher
            </Button>

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={onClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                            Withdraw Teacher?
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            Are you sure? You can't undo this action afterwards.
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef as React.LegacyRef<HTMLButtonElement>} onClick={onClose}>
                                Cancel
                            </Button>
                            <Button colorScheme='red' onClick={withdraw} ml={3} isLoading={loading}>
                                Withdraw
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    )
}