import HTTPApi from "./ApiCommon";
import { ObjectId } from "../utils/ObjectId";
import {Homeroom} from "../models/homeroom";
import {Course, CourseClone, CourseCreate} from "../models/course";
import {ReportLayout, ReportLayoutCheckbox, ReportLayoutSection} from "../models/report_layout";
import { GClassCourseWork } from "../models/gclass";
import { Semester } from "../models/semester";

export namespace CourseAPI {
    export interface ListCourse {
        _id: ObjectId,
        archived: boolean,
        name: string,
        course_code: string,
        semester: Semester
        homeroom?: Homeroom
        teachers: Array<ObjectId>,
    }
    export interface CourseList {
        courses: Array<ListCourse>,
        courses_count: number
    }
    export const course_list = async (
        per_page: number | null = null, 
        page: number | null = null, 
        name_search: string | undefined = undefined, 
        archived: boolean | undefined = undefined,
        teacher_id: ObjectId | undefined = undefined,
        attendance_teacher_id: ObjectId | undefined = undefined,
        hide_homeroom_courses: boolean | undefined = undefined,
        semester_id: ObjectId | undefined = undefined,
        ): Promise<CourseList> => {
        return HTTPApi.post(
                "courses/courses_list", {
                    per_page: per_page,
                    page: page,
                    name_search: name_search,
                    semester: semester_id,
                    archived: archived,
                    teacher_id: teacher_id,
                    hide_homeroom_courses: hide_homeroom_courses,
                    attendance_teacher_id: attendance_teacher_id,
                })
                .then((res) => {
                    return res.data as CourseList
                })
                .catch((e) => {
                    throw e
                })
    }

    export const get_course = async (course_id: ObjectId) => {
        return HTTPApi.get("/courses/get/" + course_id.$oid)
                .then((res) => {
                    return res.data as Course
                })
    }

    export const get_report_layout = async (course_id: ObjectId) => {
        return HTTPApi.get("/courses/get_course_report_layout/" + course_id.$oid)
            .then((res) => {
                return res.data as ReportLayout
            })
    }

    export const archive_course = async (course_id: ObjectId) => {
        return HTTPApi.post(
            "courses/archive", {
                _id: course_id,
            }
        )
    }

    export const create = async (body : CourseCreate) => {
        return HTTPApi.post(
            "courses/create", body
        ).then((res) => {
            return res.data as Course
        })
    }

    export const homeroom_create = async (body : CourseCreate) => {
        return HTTPApi.post(
            "courses/homeroom/create", body
        ).then((res) => {
            return res.data as Course
        })
    }

    export const clone = async (body : CourseClone) => {
        return HTTPApi.post(
            "courses/clone", body
        ).then((res) => {
            return res.data as Course
        })
    }

    export const update = async (courseId: ObjectId, semester_id: ObjectId, name: string, year: string, course_code: string) => {
        return HTTPApi.post(
            "courses/update", 
            {
                _id: courseId, 
                name: name,
                year: year,
                semester_id: semester_id,
                course_code: course_code,
            }
        ).then((res) => {
            return res.data as Course;
        });
    };

    export const add_teachers = async (course_id: ObjectId, teachers: Array<ObjectId>) => {
        return HTTPApi.post(
            "courses/add_teachers", {
                _id: course_id,
                teachers: teachers
            }
        ).then((res) => {
            return res.data as Course
        })
    }

    export const remove_teachers = async (course_id: ObjectId, teachers: Array<ObjectId>) => {
        return HTTPApi.post(
            "courses/remove_teachers", {
                _id: course_id,
                teachers: teachers
            }
        ).then((res) => {
            return res.data as Course
        })
    }

    export const add_students = async (course_id: ObjectId, students: Array<ObjectId>) => {
        return HTTPApi.post(
            "courses/add_students", {
                _id: course_id,
                students: students
            }
        ).then((res) => {
            return res.data as Course
        })
    }

    export const remove_students = async (course_id: ObjectId, students: Array<ObjectId>) => {
        return HTTPApi.post(
            "courses/remove_students", {
                _id: course_id,
                students: students
            }
        ).then((res) => {
            return res.data as Homeroom
        })
    }

    export const get_gclass_url = async (course_id: ObjectId) => {
        return HTTPApi.get("/courses/get_gclass_url/" + course_id.$oid)
                .then((res) => {
                    return res.data as string
                })
    }

    export const get_gclass_work = async (course_id: ObjectId) => {
        return HTTPApi.get("/courses/get_gclass_work/" + course_id.$oid)
                .then((res) => {
                    return res.data as GClassCourseWork[]
                })
    }

    export const add_attendance_teacher = async (course_id: ObjectId, teachers: Array<ObjectId>) => {
        return HTTPApi.post(
            "courses/add_attendance_teacher", {
                _id: course_id,
                teachers: teachers
            }
        ).then((res) => {
            return res.data as Course
        })
    }

    export const update_report_layout = async (report_layout_id: ObjectId, course_id: ObjectId, comment_char_limit: number, sections: ReportLayoutSection[], check_boxes: ReportLayoutCheckbox[]) => {
        return HTTPApi.post(
            "courses/update_report_layout",
            {
                _id: report_layout_id,
                course_id: course_id,
                comment_char_limit: comment_char_limit,
                sections: sections,
                check_boxes: check_boxes,
            }
        ).then((res) => {
            return res.data as ReportLayout;
        });
    };

}